<section [ngClass]="{'px-container':isFirstTimeResetPasswordCall}">
  <div class="section-title px-mt-5" *ngIf="isFirstTimeResetPasswordCall">
      <h1>Change password</h1>
  </div>
  <div class="px-mt-3 required-warning">
    Asterisk items (<span>*</span>) are required to proceed.
  </div>
  <div class="px-mt-3">
    <phx-message>Password must be between 8-28 characters in length and at least 1 special character.</phx-message>
  </div>
  <div class="px-mt-3">
    <form name="changePasswordForm" [(formGroup)]="changePasswordForm">
      <div>
        <label for="oldPassword" class="required">Old password</label>
        <input phxInput type="password" id="oldPassword" [(ngModel)]="oldPassword" formControlName="oldPasswordF"
          name="oldPassword" required aria-describedby="oldPwdError" />
        <small class="px-form-text px-text-danger">
          <phx-error-control formControlName="oldPasswordF" id="oldPwdError"
            [errors]="{ required: 'Old Password is required.',pattern: 'New password does not meet the standards.' }">
          </phx-error-control>
        </small>
      </div>
      <div>
        <label for="newPassword" class="required">New password</label>
        <input phxInput type="password" id="newPassword" [(ngModel)]="newPassword" name="newPassword"
          formControlName="newPasswordF" required aria-describedby="newPwdError" />
        <small class="px-form-text px-text-danger">
          <phx-error-control formControlName="newPasswordF" id="newPwdError"
            [errors]="{ required: 'New Password is required.', pattern: 'New password does not meet the standards.' }">
          </phx-error-control>
        </small>
      </div>
      <div>
        <label for="retypePassword" class="required">Confirm password</label>
        <input phxInput type="password" id="retypePassword" [(ngModel)]="retypePassword" name="retypePassword"
          formControlName="retypePasswordF" required aria-describedby="retPwdError" />
        <small class="px-form-text px-text-danger">
          <phx-error-control formControlName="retypePasswordF" id="retPwdError"
            [errors]="{ required: 'Confirm password is required.', mustMatch: 'Passwords should match.' }">
          </phx-error-control>

          <!-- <span *ngIf="changePasswordForm.get('newPasswordF').touched 
            && changePasswordForm.get('retypePasswordF').touched && !validateMessage()" class="errorMessage">
            <i class="pxi px-minus-circle"></i> Password doesnot match.
          </span> -->
        </small>
      </div>
      <div class="px-mt-3">
        <button type="submit" phxButton="primary" class="px-mr-2" [disabled]="!changePasswordForm.valid"
          (click)="submitRequest()" title="Save">Save</button>
        <button type="reset" phxButton="secondary" (click)="resetFields()" title="Cancel">Cancel</button>
      </div>
    </form>
  </div>
</section>
<ng-template #successHeader>
  <h2 *ngIf="resetSuccess">Success</h2>
  <h2 *ngIf="resetError">Password change unsuccessful</h2>
</ng-template>
<ng-template #successMsg>
  <div *ngIf="resetSuccess">
    <div class="successDiv">
      <i class="pxi px-check-circle px-10x"></i> Success
    </div>
    <p>
      Password has been changed successfully.
    </p>
  </div>

  <div *ngIf="isFirstTimeResetPasswordCall && !resetError">
    <div class="successDiv">
      <i class="pxi px-check-circle px-10x"></i> Password changed successfully.
    </div>
    <p>
      You will now be redirected to the login page to sign in with your updated password.
    </p>
  </div>
  <div *ngIf="resetError">
    <div class="failureDiv">
      <i class="pxi px-minus-circle px-10x"></i> Error
    </div>
    <p>
      {{errorMsg}}
    </p>
  </div>
</ng-template>
<ng-template #footerSuccess>
  <button phxButton (click)="closeModal()" role="button">Close</button>
</ng-template>