/*Authentication Service*/

// angular.module(Blankprint.appName)
//     .service('authService', authService);
// authService.$inject = ['$q', '$http', 'apiUrls'];

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import * as Rx from "rxjs/Rx";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PhxCsrfConfigService } from '@phoenix/ui/common';
import { environment } from 'projects/DtsUI/src/environments/environment';
import { AppHttpService } from './app.http.service';
import { apiUrls } from '../../feature-modules/app.urls.config';
import { LoginResponseData } from '../../feature-modules/models/login-response-data';
import { restApiUrls } from '../../feature-modules/rest.svc.urls';
import { UserAuthenticationDetails } from '../../feature-modules/login/user.authentication.details';

// function authService($q, $http, apiUrls) {
//     'use strict';
//     /*jshint validthis:true*/

@Injectable()
export class AuthService {

    isLoggedIn: boolean = false;
    user: any = { name: 'Test User' };
    host: string;

    private dirName: string = 'TODO dirName';
    private fileName: string = 'TODO fileName'; loading: boolean;
    private data: any;

    // TODO:  Gett the authorization token in HTTP Interceptor before calling these services
    private httpOptions = {
        headers: new HttpHeaders({
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json;charset=UTF-8'
        })
    };
    private csrfToken = {
        tokenHeader:'',
        tokenValue:''
    }
    constructor(private http: HttpClient,
        private appHttpService: AppHttpService,
        private userAuthenticationDetails: UserAuthenticationDetails,
        private phxCsrfConfigService: PhxCsrfConfigService
    ) {
    }

    setHeaderWithAuthorization(xbt: any) {
        this.appHttpService.setHeaderWithAuthorization(xbt);
    }

    clearHeaderWithAuthorization(xbt: any) {
        this.appHttpService.setHeaderWithAuthorization('');
    }

    public authenticateUserFromService(req: object): Observable<any> {
        const config = {
            ref: 'api/login'
        };
        if (environment.bypassDtsController) {
            const url: string = environment.host + apiUrls.apiLogin;
            return this.appHttpService.doHttpGet(url, req, this.httpOptions);

        } else {

            const url: string = environment.host + restApiUrls.apiLogin;

            // return this.http.doHttpPost(url, req, this.httpOptions); // TODO: revert this line to Post once integrated in portal_external_ui
            this.appHttpService.doHttpGet<LoginResponseData>(url, config, this.httpOptions)                
                ;
        }
    }

    public authenticateUser(req: object): Observable<any> {
        const config = {
            ref: 'api/login'
        };

        if (environment.bypassDtsController === true) {
            const url: string = environment.host + restApiUrls.dtsB2biAuthenticateServiceUrl;
            
        } else {

            const url: string = environment.host + apiUrls.apiLogin;
            
            return this.http.post(url, req, {headers:this.httpOptions.headers, observe:'response'})
                .pipe(

                    map((response:any) => {
                        const xbt = response.headers.get('xbt');
                        this.csrfToken.tokenHeader=xbt.substr(xbt.indexOf('|') + 1, xbt.length);
                        this.csrfToken.tokenValue=xbt.substr(0, xbt.indexOf('|'));
                        this.userAuthenticationDetails.setCSRFToken(this.csrfToken);
                        this.phxCsrfConfigService.setCsrfConfig({
                                 header: this.csrfToken.tokenHeader,
                                 token: this.csrfToken.tokenValue
                         });
                            return response.body.responseData;
                        }, (err: { status: string; }) => {
                            console.log('Failed to GET http data - err.status: ' + err.status);
                            return err;
                        }
                    )
                );
        }
    }

    public getAuthKeys() {
        const url: string = environment.host + apiUrls.apiGetAuthKeys;
        const config = {
                ref: 'api/apiGetAuthKeys'
            };
        return this.appHttpService.doHttpGet(url, config, this.httpOptions);
    }

    public addSurveyDetails(data) {
        const url: string = environment.host + apiUrls.addSurveyDetails;

        return this.http.post(url, data, this.httpOptions).pipe(map(response=>response));

    }

    public sendAuthOTP(data) {
        const url: string = environment.host + apiUrls.sendAuthOTP;
        const config = {
                ref: 'api/sendAuthOTP',
            };
        return this.appHttpService.doHttpGet(url, config, this.httpOptions);
    }

    public validateAuthOTP(data) {
        const url: string = environment.host + apiUrls.validateAuthOTP;
        const config = {
                ref: 'api/validateAuthOTP',
            };
        return this.appHttpService.doHttpGet(url, config, this.httpOptions);
    }
}