<!-- Login content -->
<div class="login-container">
  <div class="hero-content">
    <div class="px-container">
      <div class="login-card">
        <form name="Form" class="form" [(formGroup)]="loginForm" (ngSubmit)="signInHandler()">
          <phx-message type="danger" [dismissible]="false" [(showMessage)]="loginFailed">
            The User ID or Password is incorrect. Please try again.
          </phx-message>
          <label for="userId">User ID</label>
          <input phxInput type="text" formControlName="onlineId" id="userId" (blur)="userIdPwdBlurHandler()" />
          <small class="px-form-text px-text-danger">
            <phx-error-control formControlName="onlineId" id="onlineId"
              [errors]="{ required: 'User ID is required', pattern: 'User ID is invalid' }"></phx-error-control>
          </small>
          <label for="password">Password</label>
          <input phxInput class="px-form-control" type="password" formControlName="passcode" id="password" (blur)="userIdPwdBlurHandler()" />
          <small class="px-form-text px-text-danger">
            <phx-error-control formControlName="passcode" id="passcode"
              [errors]="{ required: 'Password is required', pattern: 'Password is invalid' }"></phx-error-control>
          </small>
          <button [disabled]="!loginForm.valid || loginDisabled" type="submit" phxButton title="Sign in">
              Sign in
          </button>
          <a href="javascript:void(0)" title="Sign in assistance" (click)="openTroubleSignInModal()">Sign in
              assistance</a>
        </form>
      </div>
    </div>
  </div>
  <div class="main-content" [innerHtml]="mainContent">
  </div>
</div>
<section class="px-container px-mt-4 px-pl-2 px-pr-2 announcements" *ngIf="announcements && announcements.length > 0">
  <phx-message *ngFor="let announcement of announcements" type="{{announcement.type.toLowerCase()}}" [dismissible]="false" [(showMessage)]="announcements">
    <div innerHtml={{announcement.message}}></div>
  </phx-message>
</section>
<div id="additionalContent">
</div>
<dts-survey></dts-survey>
<dts-contact-us-modal></dts-contact-us-modal>
<dts-mfa-warning-modal></dts-mfa-warning-modal>