import { Pipe, PipeTransform } from '@angular/core';

const FILE_SIZE_UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
const FILE_SIZE_UNITS_LONG = ['Bytes', 'Kilobytes', 'Megabytes', 'Gigabytes', 'Pettabytes', 'Exabytes', 'Zettabytes', 'Yottabytes'];

@Pipe({
  name: 'formatFileSize'
})
export class FormatFileSizePipe implements PipeTransform {
  transform(sizeInBytes: number, longForm: boolean): string {
    const units = longForm
      ? FILE_SIZE_UNITS_LONG
      : FILE_SIZE_UNITS;
    const decimals = 2, k = 1024;

    if (!sizeInBytes || sizeInBytes <= 0 || sizeInBytes === 0.0) {
      return '0 b';
    }
    const i = Math.floor(Math.log(sizeInBytes) / Math.log(k));
    
    var formattedSize = parseFloat((sizeInBytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + units[i];

    return `${formattedSize}`;
  }
}