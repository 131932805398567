// App url config  DTScontroller Bypass

// ex: Original 'apiLogin':'/b2biportal/api/login',

// App url config  ( Actual)

export const apiUrls = {
	apiLogin:'/b2bi/services/login',
	apiLogout:'/b2bi/services/logout',
	apiAlerts:'/b2bi/services/alert',
	requestBankKeys:'/b2bi/services/requestBankKeys/',
	updatePgpKey :'/b2bi/services/updatePGPKey',
	token : '/b2bi/services/token',
	sendAuthOTP:'/b2bi/services/mfa',
	validateAuthOTP:'/b2bi/services/mfa',
	getRetrieveFilesMailBoxResults :'/b2bi/services/selectedMailboxRetrieveFiles',
	getBankCertificatesDetails :'',// TBD
	getRetrieveFileDirectories :'/b2bi/services/retrieveFilesDir',
	getSendMailbox:'/b2bi/services/sendFilesDir',
	fetchRecentTransmission:'/b2bi/services/recentTransmission',// Send page grid
	getTransmissionReports:'/b2bi/services/transmissionReports',
	getThreeSKeyAuditReports:'/b2bi/services/threeKeyAuditReports',
   uploadFile:'/b2bi/services/uploadSendFiles',
   changePassword : '/b2bi/services/changePassword',
   // 'getContactListMFA' : '',//TBD
   // 'updateContactMFA' : '',//TBD
   updateAccountDetails:'/b2bi/services/myAccount',
   retFileDownload:'/b2bi/services/fileDownload',
   requestOTP:'/b2bi/services/mfa',
   validateOTPForMFA:'/b2bi/services/mfa',
   certDownload:'/b2bi/services/certificate/',
   channels:'/b2bi/services/channels',
   usage:'/b2bi/services/usage',
   createChangeRequest:'/b2bi/services/submitClientRequest',
   getAllRequests : '/b2bi/services/allRequest',
   addComment : '/b2bi/services/addComment',
   pgpKeyCertDownload :'/b2bi/services/pgpKeyCertificate/',
   //apiMaintenance : '/b2bi/services/maintenance',
   recentRequests:'/b2bi/services/recentRequests',
   casestatus : '/b2bi/services/casestatus/',
   cancelRequest : '/b2bi/services/cancelRequest/',
   loadCertInfo : '/b2bi/services/protocalinfo',
   uploadFileSupport:'/b2bi/services/uploadSendFilesSupport',
   addSurveyDetails:'/b2bi/services/addSurvey',
   getApproverMailBoxDetailsResults :'/b2bi/services/selectedMailboxApprovalFiles',
   getApproverFileDirectories : '/b2bi/services/approverFilesDir',
   approveRejectFile : '/b2bi/services/approveRejectFile',
   startupService : '/b2bi/services/properties',
   getlogout:'/b2bi/services/logout',
   apiGetAuthKeys : '/b2bi/services/apiGetAuthKeys',

   replayTransmissionReports:'/b2bi/services/replayFile' 

};


// App url config  ( Actual)

// export const apiUrls = {
// 	'apiLogin':'/b2bi/services/login',
// 	'apiLogout':'/b2bi/services/logout',
// 	'apiAlerts':'/b2bi/services/alert',
// 	'requestBankKeys':'/b2bi/services/requestBankKeys/',
// 	'updatePgpKey' :'/b2bi/services/updatePGPKey',
// 	'token' : 'b2bi/services/token',
// 	'sendAuthOTP':'/b2bi/services/mfa',
// 	'validateAuthOTP':'/b2bi/services/mfa',
// 	'getRetrieveFilesMailBoxResults' :'/b2bi/services/selectedMailboxRetrieveFiles',
// 	'getBankCertificatesDetails' :'',//TBD
// 	'getRetrieveFileDirectories' :'/b2bi/services/retrieveFilesDir',
// 	'getSendMailbox':'/b2bi/services/sendFilesDir',
// 	'fetchRecentTransmission':'b2bi/services/recentTransmission',//Send page grid
// 	'getTransmissionReports':'/b2bi/services/transmissionReports',
// 	'getThreeSKeyAuditReports':'/b2bi/services/threeKeyAuditReports',
//    'uploadFile':'/b2bi/services/uploadSendFiles',
//    'changePassword' : 'b2bi/services/changePassword',
//    //'getContactListMFA' : '',//TBD
//    //'updateContactMFA' : '',//TBD
//    'updateAccountDetails':'b2bi/services/myAccount',
//    'retFileDownload':'b2bi/services/fileDownload',
//    'requestOTP':'/b2bi/services/mfa',
//    'validateOTPForMFA':'/b2bi/services/mfa',
//    'certDownload':'/b2bi/services/certificate/',
//    'channels':'/b2bi/services/channels',
//    'usage':'/b2bi/services/usage',
//    'createChangeRequest':'/b2bi/services/submitClientRequest',
//    'getAllRequests' : 'b2bi/services/allRequest',
//    'addComment' : 'b2bi/services/addComment',
//    'pgpKeyCertDownload' :'/b2bi/services/pgpKeyCertificate/',
//    'apiMaintenance' : '/b2bi/services/maintenance',
//    'recentRequests':'/b2bi/services/recentRequests',
//    'casestatus' : '/b2bi/services/casestatus/',
//    'cancelRequest' : '/b2bi/services/cancelRequest/',
//    'loadCertInfo' : '/b2bi/services/protocalinfo',
//    'uploadFileSupport':'/b2bi/services/uploadSendFilesSupport',
//    'addSurveyDetails':'/b2bi/services/addSurvey',
//    'getApproverMailBoxDetailsResults' :'/b2bi/services/selectedMailboxApprovalFiles',
//    'getApproverFileDirectories' : '/b2bi/services/approverFilesDir',
//    'approveRejectFile' : '/b2bi/services/approveRejectFile',


//    'apiGetAuthKeys' : '/b2bi/services/apiGetAuthKeys',

// };

// #Business Service URLS
// export const restApiUrls: any = {
// 	'dtsB2biClientRequestServiceUrl' : "https://wcdre2du.cdr-p01.chp.bankofamerica.com/v1/transact/cirs?userId : {0}&page : {1}",
// 	'dtsB2biAuthenticateServiceUrl' : "/b2biportal/api/authenticate",
// 	'dtsB2biRetrieveDirServiceUrl' : "/b2biportal/api/file/dirlist",
// 	'dtsB2biRetrieveDirFilesServiceUrl' : "/b2biportal/api/file/list",
// 	'dtsB2biSendFilesDirServiceUrl' : "/b2biportal/api/file/directories",
// 	'dtsB2biUploadSendFileServiceUrl' : "/b2biportal/api/file/upload",
// 	'dtsB2biTransmissionReportServiceUrl' : "/b2biportal/api/transmission/list",
// 	'dtsB2biBankInfoCertfServiceUrl' : "/b2biportal/api/file/certslist",
// 	'dtsB2biMyaccountServiceUrl' : "/b2biportal/api/users/update/",
// 	'dtsB2biChangePasswordServiceUrl' : "/b2biportal/api/users/changepassword/",
// 	'dtsB2biSendOTPServiceUrl' : "/b2biportal/api/sendotp?userName : {0}&deliveryType : {1}",
// 	'dtsB2biSubmitOTPServiceUrl' : "/b2biportal/api/validateusertoken?userName : {0}&otp : {1}",
// 	'dtsB2biRetieveFileDownloadServiceUrl' : "/b2biportal/api/file/download",
// 	'dtsB2biFetchRecentTransmissionUrl' : "/b2biportal/api/transmission/",
// 	'dtsB2biThreeKeyAuditReportsUrl' : "/b2biportal/api/keys/auditreport",
// 	'dtsB2biPGPKeysUrl' : "/b2biportal/api/keys/pgpkeylist",
// 	'dtsB2biFetch3sKeysUrl' : "/b2biportal/api/keys/list",
// 	'dtsB2bi3sKeysCreateTokenUrl' : "/b2biportal/api/keys/create",
// 	'dtsB2biUpdateMFAUrl' : "/b2biportal/api/users/updatemfa/",
// 	'dtsB2biCertDownloadUrl' : "/b2biportal/api/certificate/download?keyId :{0}",
// 	'dtsB2biCertListUrl' : "/b2biportal/api/certificate/list?protocol : {0}",
// 	'dtsB2bi3sKeysDeleteTokenUrl' : "/b2biportal/api/keys/delete/",
// 	'dtsB2biUserChannelsUrl' : "/b2biportal/api/users/channels/",
// 	'dtsB2biUPdatePGPKeysUrl' : "/b2biportal/api/keys/updatepgpkey",
// 	'dtsB2biMyUsageGraphUrl' : "/b2biportal/api/transmission/graphmyusage",
// 	'dtsB2biMyAlertsUrl' : "/b2biportal/api/users/notification",
// 	'dtsB2biDownloadPGPKeyUrl' : "/b2biportal/api/keys/downloadpgpkey/",
// 	'dtsB2biCertInfoUrl' : "/b2biportal/api/certificate/info",
// 	'dtsB2biAddSurveyServiceUrl' : "/b2biportal/api/users/addsurvey",
// 	'dtsB2biApprovalDirServiceUrl' : "/b2biportal/api/fileapprover/mailboxpath",
// 	'dtsB2biApprovalDirFilesServiceUrl' : "/b2biportal/api/fileapprover/retrieveFiles",
// 	'dtsB2biApproveRejectServiceUrl' : "/b2biportal/api/fileapprover/updateStatus"
// 	};


		// /**This block is reserved only for URL*/
		// public static final String UPDATE_MFA_URL = "dtsB2biUpdateMFAUrl";
		// public static final String CERTFICATE_DOWNLOAD_URL = "dtsB2biCertDownloadUrl";
		// public static final String DELETE_TOKEN_URL = "dtsB2bi3sKeysDeleteTokenUrl";
		// public static final String USER_CHANNELS_URL = "dtsB2biUserChannelsUrl";
		// public static final String AUTHENTICATE_SERVICE_URL = "dtsB2biAuthenticateServiceUrl";
		// public static final String RETRIEVE_DIR_SERVICE_URL = "dtsB2biRetrieveDirServiceUrl";
		// public static final String RETRIEVE_DIR_FILES_SERVICE_URL = "dtsB2biRetrieveDirFilesServiceUrl";
		// public static final String SEND_FILES_SERVICE_URL = "dtsB2biSendFilesDirServiceUrl";
		// public static final String RETRIEVE_FILE_DOWNLOAD_SERVICE_URL = "dtsB2biRetieveFileDownloadServiceUrl";
		// public static final String UPLOAD_SEND_FILES_SERVICE_URL = "dtsB2biUploadSendFileServiceUrl";
		// public static final String TRANSIMISSION_REPORT_SERVICE_URL = "dtsB2biTransmissionReportServiceUrl";
		// public static final String CLIENT_REQUEST_SERVICE_URL = "dtsB2biClientRequestServiceUrl";
		// public static final String BANK_KEYS_SERVICE_URL = "dtsB2biBankKeysServiceUrl";
		// public static final String CERTIFICATE_LIST_SERVICE_URL = "dtsB2biCertListUrl";
		// public static final String THREE_S_KEYS_SERVICE_URL = "dtsB2biFetch3sKeysUrl";
		// public static final String BANK_CERTIFICATES_SERVICE_URL = "dtsB2biBankCertificatesServiceUrl";
		// public static final String MY_ACCOUNT_SERVICE_URL = "dtsB2biMyaccountServiceUrl";
		// public static final String CHANGE_PASSWORD_SERVICE_URL = "dtsB2biChangePasswordServiceUrl";
		// public static final String SEND_OTP_SERVICE_URL = "dtsB2biSendOTPServiceUrl";
		// public static final String SUBMIT_OTP_SERVICE_URL = "dtsB2biSubmitOTPServiceUrl";
		// public static final String RECENT_TRANSMISSION_SERVICE_URL = "dtsB2biFetchRecentTransmissionUrl";
		// public static final String THREE_KEY_AUDIT_REPORT_SERVICE_URL = "dtsB2biThreeKeyAuditReportsUrl";
		// public static final String PGP_KEYS_LIST_SERVICE_URL = "dtsB2biPGPKeysUrl";
		// public static final String CREATE_3S_TOKEN_SERVICE_URL = "dtsB2bi3sKeysCreateTokenUrl";
		// public static final String UPDATE_PGPKEYS_SERVICE_URL = "dtsB2biUPdatePGPKeysUrl";
		// public static final String MYUSAGE_GRAPH_SERVICE_URL = "dtsB2biMyUsageGraphUrl";
		// public static final String MYALERTS_SERVICE_URL = "dtsB2biMyAlertsUrl";
		// public static final String DOWNLOAD_PGPKEY_SERVICE_URL = "dtsB2biDownloadPGPKeyUrl";
		// public static final String CERTIFICATE_INFO_SERVICE_URL = "dtsB2biCertInfoUrl";
		// public static final String ADD_SURVEY_SERVICE_URL = "dtsB2biAddSurveyServiceUrl";
		// public static final String APPROVER_DIR_SERVICE_URL = "dtsB2biApprovalDirServiceUrl";
		// public static final String APPROVER_DIR_FILES_SERVICE_URL = "dtsB2biApprovalDirFilesServiceUrl";
		// public static final String APPROVE_REJECT_SERVICE_URL = "dtsB2biApproveRejectServiceUrl";
		// public static final String PLATFORM = "environment";
		// public static final String B2BI = "B2Bi ";
		// public static final String SLASH = "/";
		// public static final String REST_ROOT = "/services";
		// public static final String BLACK_LIST = "fileBlackList";
		// public static final String USER_DATA="userData";
		// public static final String MFA_ENCRYPTED_KEY = "mfaKey";
		// public static final String DTS_UI_JS_CACHE="/b2bi/Dts/scripts/DtsUI";
		// public static final String DTS_UI_CSS_CACHE = "/b2bi/Dts/styles/DtsUI";
		// public static final String PROP_DIRECTORY = "portal_external_ui/";


// 		// App url config

//  export const apiUrls = {
// 	'apiLogin':'/b2bi/services/login',
// 	'apiLogout':'/b2bi/services/logout',
// 	'apiAlerts':'/b2bi/services/alert',
// 	'requestBankKeys':'/b2bi/services/requestBankKeys/',
// 	'updatePgpKey' :'/b2bi/services/updatePGPKey',
// 	'token' : 'b2bi/services/token',
// 	'sendAuthOTP':'/b2bi/services/mfa',
// 	'validateAuthOTP':'/b2bi/services/mfa',
// 	'getRetrieveFilesMailBoxResults' :'/b2bi/services/selectedMailboxRetrieveFiles',
// 	'getBankCertificatesDetails' :'',//TBD
// 	'getRetrieveFileDirectories' :'/b2bi/services/retrieveFilesDir',
// 	'getSendMailbox':'/b2bi/services/sendFilesDir',
// 	'fetchRecentTransmission':'b2bi/services/recentTransmission',//Send page grid
// 	'getTransmissionReports':'/b2bi/services/transmissionReports',
// 	'getThreeSKeyAuditReports':'/b2bi/services/threeKeyAuditReports',
//    'uploadFile':'/b2bi/services/uploadSendFiles',
//    'changePassword' : 'b2bi/services/changePassword',
//    //'getContactListMFA' : '',//TBD
//    //'updateContactMFA' : '',//TBD
//    'updateAccountDetails':'b2bi/services/myAccount',
//    'retFileDownload':'b2bi/services/fileDownload',
//    'requestOTP':'/b2bi/services/mfa',
//    'validateOTPForMFA':'/b2bi/services/mfa',
//    'certDownload':'/b2bi/services/certificate/',
//    'channels':'/b2bi/services/channels',
//    'usage':'/b2bi/services/usage',
//    'createChangeRequest':'/b2bi/services/submitClientRequest',
//    'getAllRequests' : 'b2bi/services/allRequest',
//    'addComment' : 'b2bi/services/addComment',
//    'pgpKeyCertDownload' :'/b2bi/services/pgpKeyCertificate/',
//    'apiMaintenance' : '/b2bi/services/maintenance',
//    'recentRequests':'/b2bi/services/recentRequests',
//    'casestatus' : '/b2bi/services/casestatus/',
//    'cancelRequest' : '/b2bi/services/cancelRequest/',
//    'loadCertInfo' : '/b2bi/services/protocalinfo',
//    'uploadFileSupport':'/b2bi/services/uploadSendFilesSupport',
//    'addSurveyDetails':'/b2bi/services/addSurvey',
//    'getApproverMailBoxDetailsResults' :'/b2bi/services/selectedMailboxApprovalFiles',
//    'getApproverFileDirectories' : '/b2bi/services/approverFilesDir',
//    'approveRejectFile' : '/b2bi/services/approveRejectFile',


//    'apiGetAuthKeys' : '/b2bi/services/apiGetAuthKeys',

// };