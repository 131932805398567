import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserAuthenticationDetails } from '../../feature-modules/login/user.authentication.details';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: UserAuthenticationDetails, private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean  {
    if (this.router.getCurrentNavigation().trigger !== 'imperative') {
      if(this.authService.getLoginStatus()){
        window.location.reload();
      }else{
        this.router.navigate(['/login']);
      }
      return false;
    }else if(this.authService.getLoginStatus()){
      return true;
    }else{
      this.router.navigate(['/login']);
      return false;
    }

  }
}
