import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from "rxjs";
import { SidebarOptions } from '@phoenix/ui/sidebar';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAuthenticationDetails } from '../../feature-modules/login/user.authentication.details';
import { ModalOptions, ModalRef, ModalService } from '@phoenix/ui/modal';
import { StartupService } from '../services/startup.service';
import { InstanceService } from '../services/instance.service';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'dts-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  menuStatus = false; //Flag to set the menu drawer state
  //Configure menu drawer
  options: SidebarOptions = {
    action: 'over', // default
    position: 'left',
    fullscreen: true, // only work with `over` action and `left` | `right` position,
    hasBackdrop: true,
    topMargin: '0', // works for both `px` and `%`. 113px of entire viewport
    panelWidth: '340px'
  };

  isStickyHeader: boolean = false; //Manage sticky header
  isMainMenu: boolean = true; //Menu drawer state, if true main menu is selected 
  logoName: string;
  userDto: any;
  isLoggedIn: boolean = false;
  rights: any[] = [];
  navItems: any[] = [];
  subNavItems: any[] = [];
  selectedNav: any;
  isInternalApp: boolean;
  isMarketApp: boolean;
  @ViewChild('logoutModal', { read: TemplateRef }) logoutModal: TemplateRef<any>;
  @ViewChild('logoutModalFooter', { read: TemplateRef }) logoutModalFooter: TemplateRef<any>;
  @ViewChild('logoutHeader', { read: TemplateRef }) logoutModalHeader: TemplateRef<any>;
  modalRef: ModalRef;
  subscribeLogin: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public userAuthenticationDetails: UserAuthenticationDetails,
    private modalService: ModalService,
    private startupService: StartupService,
    private instanceService: InstanceService,
    private cd: ChangeDetectorRef,
    private myApp: AppComponent) {

    if (!this.subscribeLogin) {
      this.subscribeLogin = this.userAuthenticationDetails.invokeLoginEmitter.subscribe((response: any) => {
        this.isLoggedIn = this.userAuthenticationDetails.getLoginStatus();
        this.userDto = undefined != this.userAuthenticationDetails.getTempUserObjString() ? JSON.parse(this.userAuthenticationDetails.getTempUserObjString()).userDto : '';
        this.rights = this.userDto.profile.rights;
        this.setUpNavigation();
      });
    }
  }

  ngOnInit() {
    this.isInternalApp = this.startupService.isInternalApp();
    //this.isMarketApp = this.startupService.isMarkets();
    this.isLoggedIn = this.userAuthenticationDetails.getLoginStatus();

    if (this.isLoggedIn) {
      this.userDto = undefined != this.userAuthenticationDetails.getTempUserObjString() ? JSON.parse(this.userAuthenticationDetails.getTempUserObjString()).userDto : '';
      this.rights = this.userDto.profile.rights;
      this.setUpNavigation();
    }
    // this.userDispName = '';
  }
  ngDoCheck(){
    if(this.instanceService.getInstanceLogo() != null){
      this.isMarketApp = this.instanceService.getInstanceLogo() === "ML" ? true : false;
      this.ngAfterViewChanged();
    }
  }
  ngOnDestroy() {
    if (this.subscribeLogin)
      this.subscribeLogin.unsubscribe();
  }


  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    this.setupHeaderSize();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(e) {
    this.setupHeaderSize();
  }

  /**
   * Validate if the user is logged in
   * @returns true:false
   */
  onAuthChange() {
    this.userDto = undefined != this.userAuthenticationDetails.getTempUserObjString() ? JSON.parse(this.userAuthenticationDetails.getTempUserObjString()).userDto : '';
    return this.userAuthenticationDetails.getLoginStatus();
  }

  //Shrink header on scroll
  //For smaller resolution just show the mobile version for the header
  setupHeaderSize() {
    let element = document.getElementsByTagName("HEADER")[0];

    if (window.pageYOffset >= 45 && !this.isStickyHeader) {
      element.classList.add('sticky');
      this.isStickyHeader = true;
    }
    else if (window.pageYOffset < 45 && this.isStickyHeader) {
      element.classList.remove('sticky');
      this.isStickyHeader = false;
    }
  }

  /**
   * Setup navigation
   */
  setUpNavigation() {
    this.navItems = [];
    this.buildNav('Home', '/home', null, "px-home", false, false);
    this.buildNav('Retrieve', '/retrieve', 'retrieve', null, false, true);
    this.buildNav('Send', '/send', 'send', null, false, true);
    this.buildNav('Approve', '/approver', 'approver', null, false, true);
    this.buildNav('Information center', null, 'BankInfo', null, true, true);
    this.buildNav('Reports', null, 'Reports', null, true, true);
  }

  /**
   * Build navigation
   * @param name 
   * @param route 
   * @param permissionName 
   * @param icon 
   * @param checkPermission 
   */
  buildNav(name: string, route: string, permissionName, icon: string, hasSubNav: boolean, checkPermission: boolean) {

    if (!checkPermission || this.validatePermission(permissionName))
      this.navItems.push({ name: name, route: route, icon: icon, hasSubNav: hasSubNav });
  }

  /**
   * Validate user permission for the nav
   * @param right 
   * @returns 
   */
  validatePermission(right: string) {
    let flag = false;
    this.rights.forEach((obj) => {
      if (obj.rightName.toLowerCase() === right.toLowerCase()) {
        flag = true;
      }
    });
    return flag;
  }

  /**
   * Nav item click handler
   * @param nav 
   */
  navClickHandler(nav: any) {
    if (nav.hasSubNav) {
      this.isMainMenu = !this.isMainMenu;
      this.selectedNav = nav;
      this.setSubNavLinks();

      this.myApp.setTitle(nav.name);
    }
    else {
      this.menuStatus = !this.menuStatus;
      this.myApp.setTitle(nav.name);
    }
  }

  /**
   * Sign out modal
   */
  signOutHandler() {
    const modalOptions: ModalOptions = {
      title: this.logoutModalHeader,
      content: this.logoutModal,
      footer: this.logoutModalFooter,
      // dismissable: false,
      position: {
        top: '120px',
      },
      size: 'md',
      hasBackdrop: true,
      draggable: true,
      closeBtnAriaLabel: 'close',
    };
    this.modalRef = this.modalService.open(modalOptions);
  }

  /**
   * User selects logout
   */
   logoutHandler() {
    this.instanceService.getlogout().subscribe((response: any) => {
    this.userAuthenticationDetails.clearTempUserObj();
    this.userAuthenticationDetails.clearUserAuthDetails();
    this.userAuthenticationDetails.clearSession();
    this.modalService.close(this.modalRef.modal);
    this.router.navigate(['/login'], { relativeTo: this.route });

    this.myApp.setTitle('');
  },error => {      console.log(error);
    this.userAuthenticationDetails.clearTempUserObj();
    this.userAuthenticationDetails.clearUserAuthDetails();
    this.userAuthenticationDetails.clearSession();
    this.modalService.close(this.modalRef.modal);
    this.router.navigate(['/login'], { relativeTo: this.route });

    this.myApp.setTitle('');
    });
  }

  /**
   * User cancels logout
   */
  closeHandler() {
    this.modalService.close(this.modalRef.modal);
  }

  /**
   * Set sub links for the selected nav
   */
  setSubNavLinks() {
    this.subNavItems = [];

    if (this.selectedNav.name === 'Information center') {
      if (!this.isInternalApp) {
        this.buildSubNav('Server protocol', '/informationCenter/serverProtocol', 'SeverProtocal');
        this.buildSubNav('Manage keys', '/informationCenter/manageKeys', 'RequestBankKeys');
        this.buildSubNav('Server keys & certificates', '/informationCenter/requestBankCertificates', 'RequestBankCertificates');
        this.buildSubNav('Security center', '/informationCenter/securityCenter', 'ProtocalGuide');
      }
      this.buildSubNav('Platform documentation', '/informationCenter/platformDocumentation', 'UserGuide');
    }
    else if (this.selectedNav.name === 'Reports') {
      this.buildSubNav('Transmissions', '/reports/transmissionsReport', 'Transmission');
      this.buildSubNav('3SKey audit', '/reports/threeKeyAudit', '3SKeyAudit');
    }
    else if (this.selectedNav.name === 'Administration') {
      this.selectedNav.name = "Administration";
      this.buildSubNav('Manage account', '/accountManagement/addAccount', 'AccountManagement');
      this.buildSubNav('Channels', '/accountManagement/channels', 'Channels');
    }
  }

  /**
   * Build navigation
   * @param name 
   * @param route 
   * @param permissionName 
   * @param icon 
   * @param checkPermission 
   */
  buildSubNav(name: string, route: string, permissionName: string) {

    if (this.validatePermission(permissionName))
      this.subNavItems.push({ name: name, route: route });
  }

  /**
   * Check if the nav is active, will change the style
   * @param nav 
   */
  navActiveClass(nav: any) {
    if (nav.hasSubNav) {
      var subNavRootName = nav.name.toLowerCase().replaceAll(" ", "");

      if (this.router.url.toLowerCase().indexOf("/" + subNavRootName) > -1)
        return 'nav-active nav-sub-root-active';

      if (this.router.url.toLowerCase().indexOf("/quickstart/") === 0 && (subNavRootName.indexOf("informationcenter") > -1 || subNavRootName.indexOf("/platformdocumentation") > -1))
        return 'nav-active nav-sub-root-active';
    }
    else {

      if (nav.route === this.router.url)
        return 'nav-active nav-link-active';

      if (this.router.url.toLowerCase().indexOf("/quickstart/") === 0 && nav.route.toLowerCase().indexOf("/platformdocumentation") > -1)
        return 'nav-active nav-link-active';
    }

    return '';
  }

  ngAfterViewChanged() {
    this.cd.detectChanges();
  }
}