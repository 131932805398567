import { Component, OnInit } from '@angular/core';
import { DchService } from '../../core/services/dch.service';
import { UserAuthenticationDetails } from '../login/user.authentication.details';
import { environment } from 'projects/DtsUI/src/environments/environment';
import { StartupService } from '../../core/services/startup.service';

@Component({
  selector: 'dts-security-center',
  templateUrl: './security-center.component.html',
  styleUrls: ['./security-center.component.scss']
})
export class SecurityCenterComponent implements OnInit {
  serverName: string = environment.dchDomainUrl;
  instance: string='';
  dchPath: string = '/PHXcms/private/content/b2bi/InformationCenter/SecurityCenter.json';

  breadcrumbItems = [
    { label: 'Home', link: '/home' },
    { label: 'Security center', link: '/informationCenter/'+this.instance+'/securityCenter' }
  ];

  constructor(
    private dchService: DchService,    
    public userAuthenticationDetails: UserAuthenticationDetails,
    private startupService: StartupService
    ) {
    this.instance = startupService.getInstance();
    }

  ngOnInit(): void {
    this.userAuthenticationDetails.breadcrumbEmitter.emit(this.breadcrumbItems);
    this.setPageContent();
  }
  
  /**
   * Get DCH content 
   */
  setPageContent() {
     
    this.dchService.getCCHPageDetail(this.serverName + this.dchPath).subscribe((response: any) => {

      setTimeout (() => {
        if(response.bodyContent) 
        {
          let responseUpdated: string = response.bodyContent;
          while(responseUpdated.indexOf('private_instance') != -1){
            responseUpdated = responseUpdated.replace('private_instance', '/'+this.instance+'/');
         }
         
          response.bodyContent = responseUpdated;
          let element = document.getElementById("content");
          element.innerHTML = response.bodyContent; 
        }
      }, 100);

    }, err => {
      console.log(err);
    });
  }
}