<ng-template #cookieCenterHeader>
    <h2>
      Cookie center
    </h2>
  </ng-template>
<ng-template #cookieCenter>
    <p>These cookies are necessary to function and cannot be switched off in our systems. Without these cookies, this
        site will not work properly. Examples include setting your privacy preferences, setting your language
        preferences or logging into a secured area requiring authentication. They may also detect transmission errors,
        data loss or distribute network traffic across different servers to ensure accessibility.</p>
    <a href="https://campus.bankofamerica.com/content/bamlcampus/en/disclaimers/cookie-policy.html" target="_blank"
        title="Cookie Policy">Cookie policy</a>
</ng-template>
<ng-template #cookieCenterFooter>
    <button type="button" phxButton (click)="closeCookieCenterHandler()" title="Close">Close</button>
</ng-template>