import { Component, OnInit, ViewChild, ViewEncapsulation, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FeatureModuleServices } from '../feature.module.services';
import { Logger } from '@phoenix/ui/common';
import { ActionTypes, DataTableComponent, DataTableEventData, DataTableOptions, ExternalEventInfo, MatchMode, SortType } from '@phoenix/ui/datatable';
import { DatepickerOptions } from '@phoenix/ui/datepicker';
import { Subscription } from 'rxjs';
import { UserAuthenticationDetails } from '../login/user.authentication.details';
import { DropdownComponent } from '@phoenix/ui/dropdown';

import {
  CdkConnectedOverlay,
  ConnectedPosition,
  ScrollStrategyOptions,
  ScrollStrategy,
} from '@angular/cdk/overlay';
import { LoadingScreenService } from '../../core/services/loading-screen.service';

@Component({
  selector: 'dts-retreive-files',
  templateUrl: './retreive-files.component.html',
  styleUrls: ['./retreive-files.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RetreiveFilesComponent implements OnInit {
  retrieveFromDate: Date;
  retrieveToDate: Date;
  defaultDate = new Date();
  openFrom = false;
  openTo = false;
  breadcrumbItems = [
    { label: 'Home', link: '/home' },
    { label: 'Retrieve', link: '/retrieve' }
  ];

  mailBoxTypes: string[] = [];
  mailBoxSelected: string = '';
  userData: { name: string; userName: string; pass: string };
  mailboxData: RetreiveFile[];
  gridSubscription: Subscription;

  directoriesLength: number;
  errMessage: string;
  dirLoaded: boolean;
  showMailBoxGrid: boolean;
  isRespSuccess: boolean;
  showResults: boolean;
  isDataExists: boolean;
  dirErrMsg: string;
  accName: string;
  totalRows: number;
  noDataTextMsg: string;
  data: any;
  showlastUpdTime: boolean;
  requestObj: any;
  fromDateObj: any;
  toDateObj: any;
  toDate: Date;
  fromDate: Date;
  fileName: any;
  minDate: Date;
  maxDate: Date;
  currentDate: Date;
  dateOptions: DatepickerOptions;
  sortedData: RetreiveFile[];
  public pageSize = 10;
  tableOptions: DataTableOptions;
  dateRangeOptions: DatepickerOptions;
  @ViewChild('tblRetrieve') tblRetrieve: DataTableComponent;
  @ViewChild('dropMailboxes') dropMailboxes: DropdownComponent;
  @ViewChild('timePicker', { read: TemplateRef }) timePickerBody: TemplateRef<any>;


  date1Format = 'MM/DD/YYYY';
  hoursData: string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  hoursVal = 12;
  minsData: number[];
  minVal = 1;
  secsData: number[];
  secVal = 1;
  timePickerSource = '';
  timePickerGroup: FormGroup;
  validDateRange: boolean = true;
  //retrieveFromDate: Date;
  retrieveFromTime: Date;
  retrieveFromHours: string;
  retrieveFromMins: string;
  retrieveFromSecs: string;
  retrieveFromAMPM: string;
  //retrieveToDate: Date;
  retrieveToTime: Date;
  retrieveToHours: string;
  retrieveToMins: string;
  retrieveToSecs: string;
  retrieveToAMPM: string;
  error: any = { isError: false, errorMessage: '' };
  positions: ConnectedPosition[] = [
    {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
      offsetY: -15,
    },
    {
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
      panelClass: 'no-enogh-space-at-bottom',
    },
  ];

  constructor(
    private logger: Logger,
    private retrieveFilesService: FeatureModuleServices,
    private userAuthenticationDetails: UserAuthenticationDetails,
    private loadingService: LoadingScreenService,
    private formBuilder: FormBuilder
  ) {
    this.gridDataHandler();
  }

  ngOnInit(): void {
    this.logger.info('Initializing fields');

    this.userAuthenticationDetails.breadcrumbEmitter.emit(this.breadcrumbItems);
    this.mailBoxSelected = null;
    this.currentDate = new Date();
    this.fromDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate() - 1);
    this.toDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate());
    this.maxDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate() + 2);
    this.dateOptions = { maxDate: this.maxDate };

    this.dateRangeOptions = {
      minDate: new Date(2015, 2, 10, 22),
      maxDate: new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth(),
        this.currentDate.getDate() + 1,
        this.hours12(this.currentDate),
        this.currentDate.getMinutes(),
        this.currentDate.getSeconds()
      )
    };
    this.showMailBoxGrid = false;

    this.errMessage = 'errMessage';
    this.dirLoaded = true;
    this.isRespSuccess = true;
    this.showResults = true;
    this.isDataExists = true;
    this.dirErrMsg = 'dirErrMsg';
    this.showResults = true;
    const d = new Date();
    const utc = d.getTime() + d.getTimezoneOffset() * 60000;
    this.currentDate = new Date(utc + 3600000 * -4);

    this.timePickerGroup = this.formBuilder.group({
      drHours: new FormControl('', Validators.compose([Validators.required])),
      drMins: new FormControl('', Validators.compose([Validators.required])),
      drSeconds: new FormControl('', [Validators.required]),
      AMPM: new FormControl('', [Validators.required])
    });

    this.userData = this.userAuthenticationDetails.getUserAuthDetails();
    this.accName = this.userData.userName;

    this.getRetrieveFileDirectoryService();
  }

  /**
   * Initialize fields
   */
  initializeFormFields() {
    this.logger.info('Initializing form fields');
    this.retrieveFromDate = new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth(),
      this.currentDate.getDate() - 1,
      this.currentDate.getHours(),
      this.currentDate.getMinutes(),
      this.currentDate.getSeconds()
    );
    this.retrieveFromHours = this.hours12(this.retrieveFromDate).toString();
    this.retrieveFromMins = this.retrieveFromDate.getMinutes() < 10 ? '0' + this.retrieveFromDate.getMinutes() : this.retrieveFromDate.getMinutes().toString();
    this.retrieveFromSecs = this.retrieveFromDate.getSeconds() < 10 ? '0' + this.retrieveFromDate.getSeconds().toString() : this.retrieveFromDate.getSeconds().toString();
    this.retrieveFromAMPM = this.retrieveFromDate.getHours() >= 12 ? 'PM' : 'AM';;

    var fromTimeVal;
    fromTimeVal = this.retrieveFromHours + ":" + this.retrieveFromMins + ":" + this.retrieveFromSecs + " " + this.retrieveFromAMPM;
    this.retrieveFromTime = fromTimeVal;

    this.retrieveToDate = new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth(),
      this.currentDate.getDate(),
      this.currentDate.getHours(),
      this.currentDate.getMinutes(),
      this.currentDate.getSeconds()
    );
    this.retrieveToHours = this.hours12(this.retrieveToDate).toString();
    this.retrieveToMins = this.retrieveToDate.getMinutes() < 10 ? '0' + this.retrieveToDate.getMinutes() : this.retrieveToDate.getMinutes().toString();
    this.retrieveToSecs = this.retrieveToDate.getSeconds() < 10 ? '0' + this.retrieveToDate.getSeconds() : this.retrieveToDate.getSeconds().toString();
    this.retrieveToAMPM = this.retrieveToDate.getHours() >= 12 ? 'PM' : 'AM';
    var toTimeVal;
    toTimeVal = this.retrieveToHours + ":" + this.retrieveToMins + ":" + this.retrieveToSecs + " " + this.retrieveToAMPM;
    this.retrieveToTime = toTimeVal;
  }

  /**
   * Handle search click
   */
  searchHandler() {

    this.setRequestObj();
    let eventInfo: ExternalEventInfo = {
      keepSorting: true,
      resetData: true
    };
    this.tblRetrieve.api.refresh({ info: eventInfo });
  }

  /**
   * Retrieve file directory
   */
  private getRetrieveFileDirectoryService() {
    this.initializeFormFields();
    this.loadingService.startLoading();
    this.logger.info('Making call to RetrieveFiles service to fetch FileDirectory');
    this.retrieveFilesService.getRetrieveFileDirectoryService().subscribe(
      (responseData) => {
        this.logger.info('RetrieveFiles service call for retrieveFileDirectpry completed successfully, response : ', responseData);
        this.directoriesLength = responseData.length;
        this.mailBoxTypes = responseData;
        if (this.directoriesLength === 1) {
          this.mailBoxSelected = this.mailBoxTypes[0];
        }
        this.createMailBoxObj(this.mailBoxTypes);
        this.dirLoaded = true;
        this.loadingService.stopLoading();
      },
      (error) => {
        this.loadingService.stopLoading();
        this.logger.error('RetrieveFiles service call for retrieveFileDirectpry failed, error : ', error);
      }
    );
  }

  /**
   * Create Mailbox options
   * @param mailBoxTypes 
   */
  createMailBoxObj(mailBoxTypes) {
    //this.logger.info('Invoked createMailBoxObj');
    const mailBoxTypesObj = [];
    if (this.directoriesLength === 1) {
      mailBoxTypes.forEach((value) => {
        if (value === '/') {
          mailBoxTypesObj[0] = { label: this.accName + " - Selection Not Needed", value: value };
        } else {
          mailBoxTypesObj[0] = { label: value, value: value };
        }
      });
    } else {
      mailBoxTypes.forEach((value) => {
        if (value === '/') {
          this.mailBoxSelected = value;
        }
        mailBoxTypesObj.push({ label: value, value: value });
      });
    }
    this.mailBoxTypes = mailBoxTypesObj;
  }

  hours12(date) {
    return (date.getHours() + 24) % 12 || 12;
  }

  /**
   * Set date time criterion to search
   */
  setRequestObj() {
    const startHours = this.retrieveFromHours;
    const endHours = this.retrieveToHours;
    const startMins = this.retrieveFromMins;
    const endMins = this.retrieveToMins;
    const startSec = this.retrieveFromSecs;
    const endSec = this.retrieveToSecs;
    this.requestObj = {
      messageName: '',
      StartDate:
      new Date(this.retrieveFromDate).getFullYear() + '-' + (new Date(this.retrieveFromDate).getMonth() + 1) + '-' + new Date(this.retrieveFromDate).getDate(),
      StartTime: startHours + ':' + startMins + ':' + startSec,
      EndDate: new Date(this.retrieveToDate).getFullYear() + '-' + (new Date(this.retrieveToDate).getMonth() + 1) + '-' + new Date(this.retrieveToDate).getDate(),
      EndTime: endHours + ':' + endMins + ':' + endSec,
      dirLoc: this.mailBoxSelected,
      StartAMPM: this.retrieveFromAMPM,
      EndAMPM: this.retrieveToAMPM
    };
  }

  /**
   * Clear search data
   */
  clearHandler() {
    const d = new Date();
    const utc = d.getTime() + d.getTimezoneOffset() * 60000;
    this.currentDate = new Date(utc + 3600000 * -4);
    this.initializeFormFields();
    this.mailBoxSelected = null;
    this.getRetrieveFileDirectoryService();
    this.showMailBoxGrid = false;
    this.isDataExists = true;
    this.timePickerGroup.reset();
    this.error = { isError: false, errorMessage: '' };
  }

  /**
   * Retrieve mail box details
   */
  fetchMailBoxDetails(events) {
    this.logger.info('Making call to retrieveFilesService to fetch the MailboxDetails');
    this.mailboxData = null;
    this.sortedData = null;
    this.showMailBoxGrid = false;
    this.retrieveFilesService.getMailBoxDetailsService(this.requestObj).subscribe(
      (resp) => {
        this.logger.info('RetrieveFilesService service call for mailBoxDetails completed successfully, response : ', resp);
        this.showResults = true;
        if (typeof resp === 'string' && resp !== null) {
          this.isDataExists = false;
        } else if (typeof resp === 'object' && resp !== null) {
          this.isDataExists = true;

          this.mailboxData = resp.retrieveDirList;
          this.showMailBoxGrid = true;
          this.isRespSuccess = true;
          this.showResults = true;
          this.isDataExists = true;
          this.mailboxData.forEach((file) => {
            const tempA = file.fileSize.split(' ');
            const sizeValA = parseFloat(tempA[0]);
            let sizeUnitA = tempA[1];
            sizeUnitA = sizeUnitA.toLowerCase();
            file.fileSizeBytes = this.getSizeInBytes(sizeValA, sizeUnitA);
          });
          this.sortedData = this.mailboxData.sort(this.sortData);

          if (events)
            this.onGridHandlerFn(events);
        }
      },
      (error) => {
        this.logger.error('RetrieveFilesService service call for mailboxDetails failed, error : ', error);
        this.showResults = false;
        this.totalRows = 0;
        this.isRespSuccess = true;
        this.errMessage = error;
      }
    );
  }

  sortData(a, b) {
    return new Date(b.fileDate).getTime() - new Date(a.fileDate).getTime();
  }

  /**
   * Handle PHX grid internal events
   * @param eventData Grid events
   * @returns 
   */
  handleEvents(eventData: DataTableEventData): void {

    const events = eventData.events;
    if (!events) return;
    if (events.length >= 2) {
      this.fetchMailBoxDetails(events);
    }
  }

  /**
   * Rebind the grid data
   * @param events Grid events
   */
  onGridHandlerFn(events: any) {
    const loadEvent = events.find((event) => event.type === ActionTypes.LOAD_DATA);
    if (loadEvent) loadEvent.result = { data: this.sortedData };

    this.tblRetrieve.api.handleResultEvents(events);
  }

  /**
 * Event to handle the row selection in the datatable. 
 * @param eventData Grid Events
 */
  internalEventHandler(eventData: DataTableEventData): void {
    if (eventData.events[0].type == 'LOAD') {
      window.scroll(0, 0);
    }
  }

  getSizeInBytes(val: number, unit: string) {
    switch (unit) {
      case 'bytes':
        // val = val;
        break;
      case 'kb':
        val = val * Math.pow(2, 10);
        break;
      case 'mb':
        val = val * Math.pow(2, 20);
        break;
      case 'gb':
        val = val * Math.pow(2, 30);
        break;
      case 'tb':
        val = val * Math.pow(2, 40);
        break;
    }
    return val;
  }

  /**
   * Download the selected file
   * @param row 
   */
  fileDownloadHandler(row) {
    this.logger.info('Starting file download');
    this.logger.info('FILEDOWNLOAD: Mailbox: ' + this.mailBoxSelected + '    for row data:   ' + JSON.stringify(row));
    this.logger.info('Mailbox: ' + this.mailBoxSelected + ' row.fileName: ' + row.fileName + ' row.nodeName: ' + row.nodeName);
    var w = window.open('/b2bi/services/fileDownload?dirName=' + this.mailBoxSelected + '&fileName=' + row.fileName, '_self');
    setTimeout(() => {
      let eventInfo: ExternalEventInfo = {
        keepSorting: true,
        resetData: true
      };
      this.tblRetrieve.api.refresh({ info: eventInfo });
    }, 3000);
  }


  /**
   * Bind grid
   */
  gridDataHandler() {
    this.tableOptions = {
      smartView: {
        alertMessage: "No data found.",
        pagination: true,
        handleEventsInternally: true,
        pageSize: 10,
        rowsPerPageOptions: [10, 20, 40]
      },
      columnDefs: [
        {
          field: 'fileName',
          name: 'File name',
          filter: {
            matchMode: MatchMode.CONTAINS
          }
        },
        {
          field: 'fileDate',
          name: 'Received file date',
          filter: { enabled: false },
          width: '200px'
        },
        {
          field: 'fileSizeBytes', name: 'Size',
          filter: { enabled: false },
          sort: { type: SortType.NUMBER },
          width: '120px'
        },
        {
          field: 'fileDownload', name: ' ',
          filter: { enabled: false },
          sort: { enabled: false },
          width: '40px'
        }
      ],
      data: []
    };

  }

  /**
   * Get values from the time picker panel
   * @param data 
   */
  getTimeHandler(data) {
    if (this.timePickerSource == "From") {
      this.retrieveFromTime = data.timeVal;
      this.retrieveFromHours = data.drHours;
      this.retrieveFromMins = data.drMins;
      this.retrieveFromSecs = data.drSeconds;
      this.retrieveFromAMPM = data.AMPM;

      this.openFrom = false;
    } else {
      this.retrieveToTime = data.timeVal;
      this.retrieveToHours = data.drHours;
      this.retrieveToMins = data.drMins;
      this.retrieveToSecs = data.drSeconds;
      this.retrieveToAMPM = data.AMPM;

      this.openTo = false;
    }
    if (this.validateDateTime()) {
      this.timePickerGroup.reset();
    }
  }

  validateDateTime() {
    this.validDateRange = true;
    var fullFromDate = new Date(this.retrieveFromDate).getFullYear() + '-' + (new Date(this.retrieveFromDate).getMonth() + 1) + '-' + new Date(this.retrieveFromDate).getDate() + ' ' + new Date(this.retrieveFromTime);
    var fullToDate = new Date(this.retrieveToDate).getFullYear() + '-' + (new Date(this.retrieveToDate).getMonth() + 1) + '-' + new Date(this.retrieveToDate).getDate() + ' ' + new Date(this.retrieveToTime);

    if (new Date(fullToDate) < new Date(fullFromDate)) {
      this.error = { isError: true, errorMessage: 'End date should be greater than start date.' };
      this.validDateRange = false;
    } else {
      this.error = { isError: false, errorMessage: '' };
      this.validDateRange = true;
    }
    return this.validDateRange;
  }

  /**
   * Hide time picker overlay
   */
  hideTimePickerHandler(data) {
    this.openFrom = data;
    this.openTo = data;
  }

  /**
   * Set values from front end.
   * @param source 
   */
  setDateTimeValue(source) {
    if (source == 'From') {
      this.openFrom = true;
      this.openTo = false;
      this.timePickerGroup.patchValue({
        drHours: this.retrieveFromHours,
        drMins: this.retrieveFromMins,
        drSeconds: this.retrieveFromSecs,
        AMPM: this.retrieveFromAMPM
      });
    } else {
      this.openFrom = false;
      this.openTo = true;
      this.timePickerGroup.patchValue({
        drHours: this.retrieveToHours,
        drMins: this.retrieveToMins,
        drSeconds: this.retrieveToSecs,
        AMPM: this.retrieveToAMPM
      });
    }
    this.timePickerSource = source;
  }

  /**
   * Method to close the picker panel.
   * @param source 
   */
  closePanel(source) {
    if (source === 'From')
      this.openFrom = false;
    else
      this.openTo = false;
  }
}

interface RetreiveFile {
  fileDate: Date;
  fileName: string;
  fileSize: string;
  fileSizeBytes: number;
}