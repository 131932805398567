import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Logger } from '@phoenix/ui/common';
import * as CryptoJS from 'crypto-js';
import { environment } from 'projects/DtsUI/src/environments/environment';
import { LoadingScreenService } from '../../core/services/loading-screen.service';
import { UserAuthenticationDetails } from '../login/user.authentication.details';
import { AccountManagementService } from '../../core/services/account-management.service';
import { ModalOptions, ModalService } from '@phoenix/ui/modal';

@Component({
  selector: 'dts-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit, AfterViewInit {

  @ViewChild('successHeader', { read: TemplateRef }) successMsgHeader: TemplateRef<any>;
  @ViewChild('successMsg', { read: TemplateRef }) successMsg: TemplateRef<any>;
  @ViewChild('footerSuccess', { read: TemplateRef }) footerSuccess: TemplateRef<any>;

  changePasswordForm: FormGroup;
  changePswdCtrl: any;
  pswdRegEx: string;
  oldPswdRegEx: string;
  isTrustedSys: string;
  resetSuccess: boolean;
  resetError: boolean;
  onValChange: any;
  onNewPswdChange: any;
  errorMsg: string;
  mfaStatus: any;
  tabrights: any;
  addaccright: any;
  chnlright: any;
  oldPassword: string;
  newPassword: string;
  retypePassword: string;
  userData: any;
  rootScope: { base64Key: any; iv: any };
  userAuthDetailsObj: any;
  private modalRef: any;

  oldPasswordF = new FormControl('', [Validators.required])
  newPasswordF = new FormControl('', [Validators.required])
  retypePasswordF = new FormControl('', [Validators.required])
  passwordErr: boolean = false;
  errMessage: string;
  isFirstTimeResetPasswordCall: boolean = false;//Mode - Change password VS Forced reset password for first time login
  userId: any;

  breadcrumbItems = [
    { label: 'Home', link: '/home' },
    { label: 'User profile', link: '/accountmanagement' }
  ];

  constructor(
    private userAuthenticationDetails: UserAuthenticationDetails,
    private updateAccountDetailsService: AccountManagementService,
    private loadingService: LoadingScreenService,
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    private modalService: ModalService,
    private logger: Logger,
    public router: Router,
    private route: ActivatedRoute
  ) { }

  ngAfterViewInit(): void {
    const ngThis = this;
    if(this.successMsgHeader) {
      const interval = setInterval(() => {
        const ele = document.querySelector('span[id^="modal_close-"]');
        if (ele) {
          ele.parentNode.addEventListener('click', () => {
            ngThis.closeModal();
          }, true)
        }
      },1000);
    }
  }

  ngOnInit(): void {
    if (this.router.url === "/resetPassword")
      this.isFirstTimeResetPasswordCall = true;

    if (!this.isFirstTimeResetPasswordCall)
      this.userAuthenticationDetails.breadcrumbEmitter.emit(this.breadcrumbItems);

    this.errorMsg = '';
    this.pswdRegEx = '^[\\S]{8,28}$';
    this.oldPswdRegEx = '^[\\S]+$';
    this.isTrustedSys = '';
    this.resetSuccess = false;
    this.resetError = false;
    this.oldPassword = '';
    this.newPassword = '';
    this.retypePassword = '';
    this.rootScope = { base64Key: '', iv: '' };
    this.rootScope.base64Key = CryptoJS.enc.Base64.parse(environment.rootScopeBase64Key);
    this.rootScope.iv = CryptoJS.enc.Base64.parse(environment.rootScopeIv);

    if (!this.isFirstTimeResetPasswordCall) {
      this.userData = this.userAuthenticationDetails.getUserAuthDetails();
      this.userId = this.userData?.userName;
     // this.logger.info('this.userData : ', this.userData);
    }
    else {
      this.userId = this.userAuthenticationDetails?.getTempUserObj();
    }

    this.changePasswordForm = this.formBuilder.group({
      oldPasswordF: new FormControl('', [Validators.required, Validators.pattern(this.oldPswdRegEx)]),
      newPasswordF: new FormControl('', [Validators.required, Validators.pattern(this.pswdRegEx)]),
      retypePasswordF: new FormControl('', Validators.compose([Validators.required, Validators.pattern(this.pswdRegEx)]))
    });

    this.changePasswordForm.setValidators(this.validateMessage());
  }

  /**
   * Method to change password makes call to a service.
   */
  submitRequest() {
    this.loadingService.startLoading();
    /*this.logger.info(' password : \n' +
      '\nthis.oldPassword:: ' +
      this.oldPassword +
      '\nthis.newPassword:: ' +
      this.newPassword +
      '\nthis.retypePassword:: ' +
      this.retypePassword);*/

    this.oldPassword = this.changePasswordForm.value.oldPasswordF
    this.newPassword = this.changePasswordForm.value.newPasswordF
    this.retypePassword = this.changePasswordForm.value.retypePasswordF

    const key = this.rootScope.base64Key; // $rootScope.
    const iv = this.rootScope.iv; // $rootScope.
    let encryptedOldPswd = CryptoJS.AES.encrypt(this.oldPassword, key, { iv: iv });
    encryptedOldPswd = encryptedOldPswd.ciphertext.toString(CryptoJS.enc.Base64);

    let encryptedNewPswd = CryptoJS.AES.encrypt(this.newPassword, key, { iv: iv });
    encryptedNewPswd = encryptedNewPswd.ciphertext.toString(CryptoJS.enc.Base64);

    let encryptedRetypePswd = CryptoJS.AES.encrypt(this.retypePassword, key, { iv: iv });
    encryptedRetypePswd = encryptedRetypePswd.ciphertext.toString(CryptoJS.enc.Base64);

    let newEncryptedKey = CryptoJS.AES.encrypt(this.userId + ':' + this.newPassword, key, { iv: iv });
    newEncryptedKey = newEncryptedKey.ciphertext.toString(CryptoJS.enc.Base64);

    const requestObj = {
      oldPassword: encryptedOldPswd,
      newPassword: encryptedNewPswd,
      reTypeNewPassword: encryptedRetypePswd,
      newEncryptedKey: newEncryptedKey,
      userId: this.userId,
    };

    this.logger.info(' loadUserDto() : \n' +
      '\nencryptedOldPswd: ' +
      encryptedOldPswd +
      '\nencryptedNewPswd: ' +
      encryptedNewPswd +
      '\nencryptedRetypePswd: ' +
      encryptedRetypePswd +
      '\nnewEncryptedKey: ' +
      newEncryptedKey +
      '\nthis.userid: ' +
      this.userData?.userName);

    this.loadingService.startLoading();
    this.updateAccountDetailsService.submitChangePasswordRequest(requestObj).subscribe(
      (responseData) => {
        this.logger.info('UpdateAccountDetailsService call for changePassword completed successfully, response : ', responseData);
        if (!this.isFirstTimeResetPasswordCall) {
          this.resetSuccess = true;
          this.resetError = false;
        }
        this.loadingService.stopLoading();
        this.ngAfterViewChanged();
        this.openChangePasswordSucessModal();
      },
      (error) => {
        this.logger.error('UpdateAccountDetailsService call for changePassword failed, error : ', error);
        this.resetError = true;
        if (error.status === 400) {
          this.errorMsg = 'Old password is invalid. Please try again.';
          if(error.error.statusCode === '502'){
            this.errorMsg = error.error.statusDescription;
          }
        }
        else {
          this.errorMsg = 'An error has occurred. Please try again later or contact the service desk.';
        }

        this.loadingService.stopLoading();
        this.ngAfterViewChanged();
        this.openChangePasswordSucessModal();
      }
    );
  }

  /**
   * Reset the form fields
   */
  resetFields() {
    this.changePasswordForm.reset();
    //this.resetError = false;
    this.resetSuccess = false;
    this.errorMsg = '';
  }

  /**
  * Method to clear the file and close the success modal.
  */
  closeModal() {
    //If password successfully changed and its first time login mode then redirect to login page
    if (this.isFirstTimeResetPasswordCall && !this.resetError && this.oldPassword != null) {
      this.modalService.close(this.modalRef.modal);
      this.userAuthenticationDetails.clearTempUserObj();
      this.userAuthenticationDetails.clearUserAuthDetails();
      this.userAuthenticationDetails.clearSession();
      this.router.navigate(['/login'], { relativeTo: this.route });
    }
    else {
      this.modalService.close(this.modalRef.modal);
    }

    this.resetFields();
  }

  /**
  * Method to open modal with file upload success. 
  */
  openChangePasswordSucessModal() {
    const modalOptions: ModalOptions = {
      title: this.successMsgHeader,
      content: this.successMsg,
      footer: this.footerSuccess,
      dismissable: true,
      position: {
        top: '120px'
      },
      size: 'md',
      hasBackdrop: true,
      draggable: false
    };
    this.modalRef = this.modalService.open(modalOptions);
  }

  /**
   * Method to validate the new password and confirm password match.
   * @returns 
   */
  public validateMessage(): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors => {
      const control = formGroup.controls['newPasswordF'];
      const matchingControl = formGroup.controls['retypePasswordF'];

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
      return;
    };
  }

  ngAfterViewChanged() {
    this.cd.detectChanges();
  }
}