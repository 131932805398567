import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalOptions, ModalService } from '@phoenix/ui/modal';

@Component({
  selector: 'dts-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {

@ViewChild('fileDownloadFailed', { read: TemplateRef }) fileDownloadFailed: TemplateRef<any>;
@ViewChild('closeFooter', { read: TemplateRef }) closeFooter: TemplateRef<any>;
modalRef: any;

  constructor(public router: Router,
    private modalService: ModalService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.onFileDownloadFailed();
  }
  
  onFileDownloadFailed() {
		const modalOptions: ModalOptions = {
			title: 'Failed to download',
			content: this.fileDownloadFailed,
			footer: this.closeFooter,
			dismissable: false,
			position: {
				top: '120px'
			},
			size: 'md',
			hasBackdrop: true,
			draggable: false
		};
		this.modalRef = this.modalService.open(modalOptions);
	}

  goBacktoHome(){
    this.modalService.close();
    this.router.navigate(['/home']);
  }

}