import { Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UserAuthenticationDetails } from '../../feature-modules/login/user.authentication.details';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ModalOptions, ModalService } from '@phoenix/ui/modal';
import { Logger } from '@phoenix/ui/common';
import { InstanceService } from '../services/instance.service';

@Component({
  selector: 'dts-user-inactivity',
  templateUrl: './user-inactivity.component.html',
  styleUrls: ['./user-inactivity.component.scss']
})
export class UserInactivityComponent implements OnInit, OnDestroy {

  authDetails: any = this.userAuthenticationDetails;
  userActivity: any;
  userInactive: Subject<any> = new Subject();
  interval: any;

  @ViewChild('sessionLogoutMsg', { read: TemplateRef }) sessionLogoutMsg: TemplateRef<any>;
  @ViewChild('sessionLogoutConfirmMsg', { read: TemplateRef }) sessionLogoutConfirmMsg: TemplateRef<any>;
  @ViewChild('logutCloseMsg', { read: TemplateRef }) logutCloseMsg: TemplateRef<any>;
  @ViewChild('confirmMsgcloseMsg', { read: TemplateRef }) confirmMsgcloseMsg: TemplateRef<any>;
  @ViewChild('sessionLogoutHeader', { read: TemplateRef }) sessionLogoutHeader: TemplateRef<any>;

  modalRefSessionLogout: any;
  modalRefSessionLogoutConfirm: any;
  countdownTimer: number = 0;

  constructor(
    private userAuthenticationDetails: UserAuthenticationDetails,
    public router: Router,
    private logger: Logger,
    private instanceService: InstanceService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    if (this.userAuthenticationDetails.getLoginStatus()) {
      this.setTimeout();
      this.userInactive.subscribe(() => {
        //this.logger.info('UserInactivityComponent: User is Inactive');
        this.autoLogoutConfirmMessage();
        this.startCountDown(59);
      });
    }
  }

  ngOnDestroy() {
    this.userInactive.unsubscribe();
  }

  /**
   * Set timeout for logout warning
   */
  setTimeout() {
    this.userActivity = setTimeout(() => {
      this.userInactive.next()
    }, 13 * 60 * 1000);
  }

  @HostListener('window:mousemove', ['$event'])
  handleMouseEvent(event: MouseEvent) {
    if (this.userAuthenticationDetails.getLoginStatus()) {
      clearTimeout(this.userActivity);
      this.setTimeout();
    }
  }

  @HostListener('window:keyup', ['$event'])
  handleKeyUp(event: KeyboardEvent) {
    if (this.userAuthenticationDetails.getLoginStatus()) {
      clearTimeout(this.userActivity);
      this.setTimeout();
    }
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll(event: KeyboardEvent) {
    if (this.userAuthenticationDetails.getLoginStatus()) {
      clearTimeout(this.userActivity);
      this.setTimeout();
    }
  }

  @HostListener('window:focus', ['$event'])
  handleFocus(event: KeyboardEvent) {
    if (this.userAuthenticationDetails.getLoginStatus()) {
      clearTimeout(this.userActivity);
      this.setTimeout();
    }
  }

  /**
   * Logout
   */
   logoutHandler() {
    this.confirmMsgclose();
    this.autoLogoutMessage();
    clearInterval(this.interval);
    this.instanceService.getlogout().subscribe((response: any) => {
    this.userAuthenticationDetails.clearTempUserObj();
    this.userAuthenticationDetails.clearUserAuthDetails();
    this.userAuthenticationDetails.clearSession();
    this.router.navigate(['/login']);
    },error => {      console.log(error);
    this.userAuthenticationDetails.clearTempUserObj();
    this.userAuthenticationDetails.clearUserAuthDetails();
    this.userAuthenticationDetails.clearSession();
    this.router.navigate(['/login']);

    });
  }

  startCountDown(seconds: number) {
    this.countdownTimer = seconds;
    this.interval = setInterval(() => {
      setTimeout(() => {
        this.countdownTimer--;
      }, 1000);
      if (this.countdownTimer <= 0) {
        clearInterval(this.interval);
        this.logoutHandler();
      }
    }, 1000);
  }

  /**
   * Auto logout invoke modal
   */
  autoLogoutMessage() {

    const modalOptions: ModalOptions = {
      title: this.sessionLogoutHeader,
      content: this.sessionLogoutMsg,
      footer: this.logutCloseMsg,
      dismissable: true,
      position: {
        top: '120px'
      },
      size: 'md',
      hasBackdrop: true,
      draggable: false
    };
    this.modalRefSessionLogout = this.modalService.open(modalOptions);
  }

  /**
   * Close modal
   */
  closeHandler() {
    this.modalService.close(this.modalRefSessionLogout.modal);
  }

  /**
   * Auto logout confirmation modal
   */
  autoLogoutConfirmMessage() {
    const modalOptions: ModalOptions = {
      title: this.sessionLogoutHeader,
      content: this.sessionLogoutConfirmMsg,
      footer: this.confirmMsgcloseMsg,
      dismissable: true,
      position: {
        top: '120px'
      },
      size: 'md',
      hasBackdrop: true,
      draggable: false
    };
    this.modalRefSessionLogoutConfirm = this.modalService.open(modalOptions);
  }

  /**
   * Continue login, reset the timeour
   */
  continueLoggedInHandler() {
   // this.logger.info('UserInactivityComponent: User is Inactive');

    if (this.userAuthenticationDetails.getLoginStatus()) {
      this.confirmMsgclose();
      clearTimeout(this.userActivity);
      this.setTimeout();
      clearInterval(this.interval);
    }
  }

  /**
   * Confirm close modal message
   */
  confirmMsgclose() {
    this.modalService.close(this.modalRefSessionLogoutConfirm.modal);
  }
}