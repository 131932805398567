import { Injectable, EventEmitter } from '@angular/core';

/**
 * Inherited code setup from B2Bi team
 */

@Injectable({
  providedIn: 'root'
})
export class UserAuthenticationDetails {

  // Note:  Ng1.x sessionStorage.userAuthDetails.userDto   is equal to Ng9 sessionStorageuserAuthDetails ( i.e userDto)
  // sessionStorage = $sessionStorage;
  // 'use strict';
  requestObject = {};
  visibleMenu = {};
  userDto: any;
  invokeLoginEmitter = new EventEmitter();
  breadcrumbEmitter = new EventEmitter();

  /**
   * Set user details
   * @param userDataObj 
   */
  setUserAuthDetails(userDataObj) {
    sessionStorage.userAuthDetails = JSON.stringify(userDataObj);
    this.invokeLoginEmitter.emit();
    // sessionStorage.userAuthDetails = userDataObj;
  }

  /**
   * Get user details
   * @returns 
   */
  getUserAuthDetails() {
    this.userDto = JSON.parse(sessionStorage?.userAuthDetails);
    return  this.userDto;
  }

  /**
   * Clear user auth details
   */
  clearUserAuthDetails() {
    sessionStorage.userAuthDetails = undefined;
  }

  /**
   * Get login status
   * @returns 
   */
  getLoginStatus() {
    if (sessionStorage.userAuthDetails !== undefined) {
      return true;
    }
    return false;
  }
  getUserName() {
    if(this.userDto?.userName === undefined){
      return 'UNKNOWN';
    } else {
      return this.userDto.userName;
    }
  }

  setRequestObj(data) {
    this.requestObject = data;
  }
  getRequestObj() {
    return this.requestObject;
  }
  getUserEmail() {
    return this.userDto.email;
  }
  getUserPhoneNum() {
    return this.userDto.phoneNumber;
  }
  getUserId() {
    return this.userDto.userName;
  }

  getUserFirstName() {
    return this.userDto.firstName;
  }

  getUserLastNam() {
    return this.userDto.lastName;
  }
  getUserAlias() {
    return this.userDto.aliasName;

  }
  getUserLastLoginTime() {
    return this.userDto.lastLoginTime;

  }

  getProfileObj() {
    return this.userDto.profile;
  }

  setTempUserObj(obj) {
    sessionStorage.tempUserObj = obj;
  }

  getTempUserObj() {
    return sessionStorage.tempUserObj;
  }

  setTempUserObjString(obj) {
    sessionStorage.tempUserObjString = obj;
  }

  getTempUserObjString() {
    return sessionStorage.tempUserObjString;
  }

  clearTempUserObj() {
    if (sessionStorage.tempUserObj !== undefined) {
      sessionStorage.tempUserObj = undefined;
    }
  }

  getMfaDetails() {
    const mfaDetail= JSON.parse(sessionStorage.userAuthDetails)
    return mfaDetail?.mfa === undefined?false:mfaDetail.mfa;
  }

  get3SKeysFlag() {
    return sessionStorage.userAuthDetails.key3sSelfService;
  }

  getAliasName() {
    return sessionStorage.userAuthDetails.aliasName;
  }

  setFirstName(fname) {
    const tmp= JSON.parse(sessionStorage.userAuthDetails);
    tmp.firstName = fname;
    sessionStorage.userAuthDetails = JSON.stringify(tmp);
  }

  setLastName(lname) {
    const tmp= JSON.parse(sessionStorage.userAuthDetails);
    tmp.lastName = lname;
    sessionStorage.userAuthDetails = JSON.stringify(tmp);
  }

  setEmail(email) {
    const tmp= JSON.parse(sessionStorage.userAuthDetails);
    tmp.email = email;
    sessionStorage.userAuthDetails = JSON.stringify(tmp);
  }

  setPhoneNumber(phoneNum) {
    const tmp= JSON.parse(sessionStorage.userAuthDetails);
    tmp.phoneNumber = phoneNum;
    sessionStorage.userAuthDetails = JSON.stringify(tmp);
  }

  setCountryCode(code) {
    const tmp= JSON.parse(sessionStorage.userAuthDetails);
    tmp.countryCode = code;
    sessionStorage.userAuthDetails = JSON.stringify(tmp);
  }

  setVisibleMenu(menuList) {
    this.visibleMenu = menuList;
  }

  getVisibleMenu() {
    return this.visibleMenu;
  }

  setNewUser(value) {
    const tmp= JSON.parse(sessionStorage.userAuthDetails);
    tmp.newUser = value;
    sessionStorage.userAuthDetails = JSON.stringify(tmp);
  }

  clearSession(){
    sessionStorage.clear();
  }

  setCSRFToken(csrfToken: any){
    sessionStorage.setItem(csrfToken.tokenHeader, csrfToken.tokenValue);
  }

  /**
   * 
   * @returns 
   */
  getCSRFToken(){
    return sessionStorage.getItem('X-BFS-TOKEN');
  }
}