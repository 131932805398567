import { CertInfoDetail } from '../models/cert-info-detail';

export class CertInfo {

    responseData : CertInfoDetail[];

constructor(values: Object = {}){
    Object.assign(this, values);
    }

}