import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActionTypes, DataTableComponent, DataTableEventData, DataTableOptions, ExternalEventInfo } from '@phoenix/ui/datatable';
import { ModalOptions, ModalService } from '@phoenix/ui/modal';
import { UserAuthenticationDetails } from '../login/user.authentication.details';
import { KeyInfoDetail } from '../models/key-info-detail';
import { ThreesKeyResponse } from '../models/threeskey-response';
import { UserDto } from '../models/user-dto';
import { CommonService } from '../../core/services/common.service';
import { ServerProtocolService } from '../../core/services/server-protocol.service';

class KeyInfo {
	responseData: KeyInfoDetail[];

	constructor(values: Object = {}) {
		Object.assign(this, values);
	}
}

class ThreesKeyInfo {
	responseData: ThreesKeyResponse;

	constructor(values: Object = {}) {
		Object.assign(this, values);
	}
}
@Component({
	selector: 'dts-bank-keys',
	templateUrl: './bank-keys.component.html',
	styleUrls: ['./bank-keys.component.scss']
})
export class BankKeysComponent implements OnInit {

	keyInfo: KeyInfo;
	keyInfoDetail: KeyInfoDetail[];
	bankKeysCtrl = this;
	dltModalInstance: any;
	changeModalInstance: any;
	redirectModalInstance: any;
	addDetailsDisplay: boolean;
	pgpDataExists: boolean;
	sKeyDataExists: boolean;
	errDeletingToken: boolean;
	errExistingToken: boolean;
	errInUpdateKey: boolean;
	pgpRespSuccess: boolean;
	accountNameToAdd: string;
	show3SKeys: boolean;
	sKeyRespSuccess: boolean;
	fileIsSelected: boolean;
	blockState: boolean;
	tokenDelMsg: string;
	existingTokenMsg: string = "Token already exists.";
	inputToken: string;
	radId: string;
	pervValue: string;
	pgpErrMessage: string;
	sKeyErrMessage: string;
	dateOfCreation: number;
	updateKeyErrMsg: string;
	totalListSelected: string;
	pgpKeyRowSelected: {};
	count3skeylist = [];
	req3sKeySignature: any;
	gridOptions3sKeys: { isLoadingIcon: boolean; data: any[]; };
	gridOptionsPGPKeys: any;
	addTokenErrMsg: any;
	totalList: { 'label': string, 'value': string }[];
	data: any[];
	gridDataPgp: any = [];
	gridData3skey: any = [];
	noof3skeys: number;
	threesKeyResponse: ThreesKeyResponse;
	threesKeyInfo: ThreesKeyInfo;
	userObj: any;
	private modalRef: any;
	private updateKeyId: any;
	errUpdatingKey: any;
	pgpGridOptions: DataTableOptions;
	threeSkeyGridOption: DataTableOptions;
	selection = 'multiple';
	innerHeight = "200px";
	threesKeysinnerheight = '200px';
	@ViewChild('tblPGP') gridPGP: DataTableComponent;
	@ViewChild('tbl3sKey') grid3sKey: DataTableComponent;

	breadcrumbItems = [
		{ label: 'Home', link: '/home' },
		{ label: 'Manage keys' }
	];

	@ViewChild('changeActiveMsg', { read: TemplateRef }) changeActiveMsg: TemplateRef<any>;
	@ViewChild('selectButtonMsg', { read: TemplateRef }) selectButtonMsg: TemplateRef<any>;

	@ViewChild('deleteTokenHeader', { read: TemplateRef }) deleteTokenHeader: TemplateRef<any>;
	@ViewChild('deleteTokenMsg', { read: TemplateRef }) deleteTokenMsg: TemplateRef<any>;
	@ViewChild('selectButtonDelToken', { read: TemplateRef }) selectButtonDelToken: TemplateRef<any>;



	deleteTokenId: any;
	onChangeSignatue: boolean;

	constructor(private bankKeysService: ServerProtocolService,
		private userAuthenticationDetailsObj: UserAuthenticationDetails,
		private commonService: CommonService,
		private modalService: ModalService
	) {
		this.configureGridOptions();
	}

	ngOnInit(): void {
		this.userAuthenticationDetailsObj.breadcrumbEmitter.emit(this.breadcrumbItems);

		this.addDetailsDisplay = false;
		this.pgpDataExists = false;
		this.sKeyDataExists = true;
		this.errDeletingToken = false;
		this.errExistingToken = false;
		this.errDeletingToken = false;
		this.errInUpdateKey = false;
		this.pgpRespSuccess = true;
		this.show3SKeys = false;
		this.sKeyDataExists = true;
		this.sKeyRespSuccess = true;

		this.existingTokenMsg = 'existingTokenMsg ';
		this.pgpErrMessage = 'pgpErrMessage ';
		this.tokenDelMsg = 'tokenDelMsg ';
		this.updateKeyErrMsg = 'updateKeyErrMsg ';
		let obj1 = this.userAuthenticationDetailsObj.getTempUserObjString();
		obj1 = obj1.replace('\\', '');
		this.userObj = new UserDto(JSON.parse(obj1));
		this.accountNameToAdd = this.userObj.userName;

		//this.loadBankKeysData();
		this.inputToken = '';
		this.totalListSelected = 'value4';

		this.pgpKeyRowSelected = {};
		this.req3sKeySignature = '';

		this.totalList = [{ label: 'req3sKeySignature keys1', value: 'value1' },
		{ label: 'req3sKeySignature keys2', value: 'value2' },
		{ label: 'req3sKeySignature keys3', value: 'value3' },
		{ label: 'req3sKeySignature keys4', value: 'value4' },
		{ label: 'req3sKeySignature keys5', value: 'value5' }
		];
	}
	onChangeActive(row) {

		this.updateKeyId = row.pgpId;
		const modalOptions: ModalOptions = {
			title: 'Key Activation',
			content: this.changeActiveMsg,
			footer: this.selectButtonMsg,
			dismissable: true,
			position: {
				top: '120px'
			},
			size: 'md',
			hasBackdrop: true,
			draggable: false
		};
		this.modalRef = this.modalService.open(modalOptions);
	}

	/**
	 * Method to add the new token details from 3sKey grid.
	 */
	addTokenDetails() {
		this.errExistingToken = false;
		this.errDeletingToken = false;
		if (this.inputToken) {
			this.gridData3skey.forEach(element => {
				if (element.tokenid === this.inputToken) {
					this.errExistingToken = true;
					this.sKeyErrMessage = "Token already exists.";
				}
			});
			if (!this.errExistingToken) {
				const tokenToAdd = {
					tokenid: this.inputToken,
				};
				this.bankKeysService.createToken(tokenToAdd).subscribe(responseData => {
					this.addDetailsDisplay = false;
					let eventInfo: ExternalEventInfo = {
						keepSorting: true,
						resetData: true
					};
					if (this.grid3sKey) this.grid3sKey.api.refresh({ info: eventInfo });
					this.inputToken = "";
				}, error => {
					this.sKeyErrMessage = error.error.statusDescription;
					console.log('error in addTokenDetails', JSON.stringify(error));
				});
			}
		}
		else {
			this.sKeyErrMessage = "Please enter token to add.";
		}
	}

	encodeComponent(stringVal)
	{
		return encodeURIComponent(stringVal);
	}

	decodeComponent(encodeVal)
	{
		return decodeURIComponent(encodeVal);
	}

	/**
	 * Method to show the current date and user info.. 
	 */
	addDetailsSelected() {
		this.errDeletingToken = false;
		this.addDetailsDisplay = true;
		const todayDt = new Date();
		this.dateOfCreation = Date.now();
	}

	/**
	 * Method to close the add panel.
	 */
	cancelAdd() {
		this.addDetailsDisplay = false;
		this.inputToken = "";
		this.sKeyErrMessage = "";
	}

	onNameChange(){
		this.sKeyErrMessage = "";
	}

	/**
	 * Code to load data to grids.. 
	 * @param events 
	 */
	loadBankKeysDataHandler(events) {
		let keyName = 'PGPKey';
		this.bankKeysService.getPGPKeys(keyName).subscribe(responseData => {
			this.keyInfo = new KeyInfo(responseData);
			this.keyInfoDetail = this.keyInfo.responseData;
			const keyinfoDet: any = responseData;

			if (keyinfoDet.responseData === "No Bank Keys Data found.") {
				this.pgpErrMessage = "No Bank Key Data found.";
				this.pgpRespSuccess = true;
				this.pgpDataExists = false;
			} else {

				this.pgpDataExists = true;
				this.gridDataPgp = this.keyInfoDetail;
				this.pgpRespSuccess = true;

				if (this.gridDataPgp.length <= 1) {
					this.innerHeight = '50px';
				} else if (this.gridDataPgp.length > 1 && this.gridDataPgp.length <= 5) {
					this.innerHeight = '250px';
				} else {
					this.innerHeight = '200px';
				}

				if (events)
					this.onGridHandlerFn(events);
			}

		}, error => {
			this.pgpRespSuccess = false;
			this.pgpDataExists = false;
			this.pgpErrMessage = error.error.statusDescription;
			console.log('error of loading PGP keys', JSON.stringify(error));
		});

		keyName = '3skeys'

		if (this.userObj.key3sSelfService == true) {
			this.show3SKeys = true;
			this.bankKeysService.getPGPKeys(keyName).subscribe(responseData => {
				this.threesKeyInfo = new ThreesKeyInfo(responseData)
				this.threesKeyResponse = this.threesKeyInfo.responseData;
				this.noof3skeys = Number(this.encodeComponent(this.threesKeyResponse.req3sKeySignature));
				this.count3skeylist = this.getListOfArray(this.threesKeyResponse.listKeyDto.length.toString());
				this.gridData3skey = this.threesKeyResponse.listKeyDto.sort(this.sortData);

				this.sKeyRespSuccess = true;
				this.sKeyDataExists = true;
				this.errExistingToken = false;

				if (events)
					this.onGridHandlerFn3SKeys(events);
			}, error => {
				this.errExistingToken = true;
				this.sKeyRespSuccess = false;
				this.existingTokenMsg = error.error.statusDescription;
				console.log('error in loadBank3KeysData', JSON.stringify(error));
			});
		}
	}

	sortData(a, b) {
		return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
	  }

	getListOfArray(totalData) {
		const data = [];
		for (let i = 1; i <= totalData; i++) {
			if (i < 10) {
				data.push({ label: i, value: i });
			} else {
				data.push({ label: i, value: i });
			}

		}
		return data;
	}

	onChange(count3skey) {
		this.errDeletingToken = false;
		this.onChangeSignatue = true;
		this.noof3skeys = Number(this.encodeComponent(count3skey));
	}

	/**
	 * Code to handle the file downloads.
	 * @param row 
	 */
	fileDownload(row) {
		const keyId = row.keyId;
		this.bankKeysService.pgpKeyCertDownload(keyId).subscribe(responseData => {
			const respon: any = responseData
			const data = respon.responseData;
			const blob = new Blob([data], { type: 'application/octet-stream' });
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			const fileName = row.pgpId + '.txt';
			link.download = fileName;
			link.click();
			const ieVersion = this.commonService.isIE();
			if (ieVersion) {
				if (window.navigator && window.navigator.msSaveOrOpenBlob) {
					window.navigator.msSaveOrOpenBlob(blob, fileName);
				}

			}
			const ffVersion = this.commonService.isFireFox();
			if (ffVersion) {
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}, error => {
			console.log('Error in fileDownload ', JSON.stringify(error));
		});
	}

	/**
	 * 
	 */
	updatePgpKey() {
		this.close();
		let requestObj: any = {};
		requestObj = {
			keyId: this.updateKeyId,
		};
		this.bankKeysService.updatePgpKey(requestObj).subscribe(responseData => {
			//console.log('ResponseData of loadBankKeysData', JSON.stringify(responseData));
		}, error => {
			console.log('error in updatePgpKey', JSON.stringify(error));
			this.errUpdatingKey = error.error.statusDescription;
		});
		let eventInfo: ExternalEventInfo = {
			keepSorting: true,
			resetData: true
		};
		this.grid3sKey.api.refresh({ info: eventInfo });
	}

	deleteToken(row) {
		this.errDeletingToken = false;
		const select3skey: number = Number(this.noof3skeys);
		const list3skey: number = Number(this.threesKeyResponse.listKeyDto.length);
		if ((+this.noof3skeys) == (+this.threesKeyResponse.listKeyDto.length)) {
			this.errDeletingToken = true;
			this.tokenDelMsg = 'The number of key signatures cannot be more than the number of tokens in the below table.';
		} else {
			this.deleteTokenId = row.tokenid;
			const modalOptions: ModalOptions = {
				title: this.deleteTokenHeader,
				content: this.deleteTokenMsg,
				footer: this.selectButtonDelToken,
				dismissable: true,
				position: {
					top: '120px'
				},
				size: 'sm',
				hasBackdrop: true,
				draggable: false
			};
			this.modalRef = this.modalService.open(modalOptions);
		}
	}

	deleteTokenService() {
		this.close();
		let obj = this.deleteTokenId;
		obj = obj.replaceAll(' ', '');

		this.bankKeysService.deleteToken(obj).subscribe(data => {
			let eventInfo: ExternalEventInfo = {
				keepSorting: true,
				resetData: true
			};
			if (this.grid3sKey) this.grid3sKey.api.refresh({ info: eventInfo });
		}, error => {
			let eventInfo: ExternalEventInfo = {
				keepSorting: true,
				resetData: true
			};
			if (this.grid3sKey) this.grid3sKey.api.refresh({ info: eventInfo });
			this.errDeletingToken = true;
			this.tokenDelMsg = error.error.statusDescription;
			console.log('Failed to POST http data - err.status: ' + error.error.statusDescription);
		});


	}

	close() {
		this.modalService.close(this.modalRef.modal);
	}

	/**
   * Setup grid options
   */
	configureGridOptions() {
		/**
		 * Setup key grid options
		 */
		this.pgpGridOptions = {
			smartView: {
				alertMessage: 'No PGP data found',
				pagination: false,
				handleEventsInternally: true,
				idField: 'pgpId',
				selectField: 'selected',
				hideSelectAll: true
			},
			columnDefs: [
				{
					field: 'signAndDecrypt',
					name: 'Active',
					filter: { enabled: false },
					sort: { enabled: false },
					width: '80px'

				},
				{
					field: 'pgpId',
					name: 'Public key',
					filter: { enabled: false },
					width: '270px'
				},
				{
					field: 'keyName',
					name: 'Key name',
					filter: { enabled: false },
					width: '270px'
				},
				{
					field: 'keyId',
					name: 'Key info',
					filter: { enabled: false },
					width: '250px'
				},
				{
					field: 'expDate',
					name: 'Expiration',
					filter: { enabled: false },
					width: '150px'
				},
				{
					field: 'fingerPrint',
					name: 'Finger print',
					filter: { enabled: false },
					width: '300px'
				},
				{
					field: 'fileDownload', name: ' ', filter: { enabled: false }, sort: { enabled: false },

				}
			],
			data: []
		};

		this.threeSkeyGridOption = {

			smartView: {
				alertMessage: 'No 3SKey data found',
				pagination: false,
				handleEventsInternally: true,
				idField: 'pgpId',
				selectField: 'selected',
				hideSelectAll: true,
				sortable: false
			},
			columnDefs: [
				{
					field: 'tokenid',
					name: 'Token ID',
					filter: { enabled: false }
				},
				{
					field: 'userid',
					name: 'Account name',
					filter: { enabled: false }
				},
				{
					field: 'createdDate',
					name: 'Date added',
					filter: { enabled: false }
				},
				{
					field: 'deleteToken', name: ' ', filter: { enabled: false }, sort: { enabled: false },
					width: '50px'
				}
			],
			data: []
		};
	}

	/**
   * Handle PHX grid internal events
   * @param eventData Grid events
   * @returns 
   */
	handleEvents(eventData: DataTableEventData): void {
		const events = eventData.events;
		if (!events) return;
		if (events.length >= 2) {
			this.loadBankKeysDataHandler(events);
		}
	}

	/**
   * Handle PHX grid internal events
   * @param eventData Grid events
   * @returns 
   */
	handleEvents3SKeys(eventData: DataTableEventData): void {
		const events = eventData.events;

		if (!events) return;
		if (events.length >= 2) {
			this.loadBankKeysDataHandler(events);
		}
	}

	/**
	 * Rebind the grid data
	 * @param events Grid events
	 */
	onGridHandlerFn(events: any) {

		const loadEvent = events.find((event) => event.type === ActionTypes.LOAD_DATA);

		if (loadEvent) loadEvent.result = { data: this.gridDataPgp };

		this.gridPGP.api.handleResultEvents(events);
	}

	/**
	 * Rebind the grid data
	 * @param events Grid events
	 */
	onGridHandlerFn3SKeys(events: any) {

		const loadEvent = events.find((event) => event.type === ActionTypes.LOAD_DATA);

		if (loadEvent) loadEvent.result = { data: this.gridData3skey };

		this.grid3sKey.api.handleResultEvents(events);
	}
}