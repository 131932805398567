import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/DtsUI/src/environments/environment';
import { apiUrls } from '../../feature-modules/app.urls.config';
import { AppHttpService } from './app.http.service';

@Injectable()
export class InstanceService {

    private contentNameCCH: string = 'PHXcms';
    private appNameInCCH: string = 'b2bi';
    private publicBaseUrl: string = environment.dchDomainUrl + "/" + this.contentNameCCH + "/public/content/" + this.appNameInCCH + "/";
    private instanceLogo: string='';

    constructor(private http: HttpClient, private apphttpget: AppHttpService) {}
    private httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache'
        })
      };

    load(instanceFolder: string) {
        return new Promise ((resolve, reject) => {
            this.http.get<any>(this.publicBaseUrl + 'Collection/'+instanceFolder+'/Instance.txt').subscribe(
                response => {
					if (response) {
                        let responseStr: string = JSON.stringify(response);
						this.instanceLogo = JSON.parse(responseStr).instance;
					}
                    resolve(true);
                });
        });
    }

    getInstanceLogo(){
        return this.instanceLogo;
    }

    getlogout() {
      const url: string = environment.host + apiUrls.getlogout;
      const config: object = {
        ref: 'api/getlogout'
      };
      return this.apphttpget.doHttpGet(url, config, this.apphttpget.httpOptions);
    }

}