import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, Inject, InjectionToken, NgModule } from '@angular/core';
import { BrowserModule, Meta, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MockApiInterceptor, PhxCspInlineStylesModule } from '@phoenix/ui/common';
import { BUNDLE_URL_TOKEN, PhxTranslateModule, PhxTranslateService } from '@phoenix/ui/translate';

import { OverlayModule } from '@angular/cdk/overlay';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { StartupService } from '../app/core/services/startup.service';
import { environment } from './../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule, DataResolve } from './app.routes';
import { CoreModule } from './core/core.module';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { UserAuthDetailsInterceptor } from './core/interceptors/user-auth-details.interceptor';
import { FormatFileSizePipe } from './core/pipes/format-file-size';
import { AuthService } from './core/services/auth.service';
import { CommonService } from './core/services/common.service';
import { InstanceService } from './core/services/instance.service';
import { LoginMfaService } from './core/services/login.mfa.service';
import { ServerProtocolService } from './core/services/server-protocol.service';
import { UserInactivityComponent } from './core/userActivity/user-inactivity.component';
import { AccountmanagementComponent } from './feature-modules/accountmanagement/accountmanagement.component';
import { AddAccountComponent } from './feature-modules/accountmanagement/add-account.component';
import { ChangePasswordComponent } from './feature-modules/accountmanagement/change-password.component';
import { ChannelsComponent } from './feature-modules/accountmanagement/channels.component';
import { ManageMfaComponent } from './feature-modules/accountmanagement/manage-mfa.component';
import { MyAccountComponent } from './feature-modules/accountmanagement/my-account.component';
import { ApproverComponent } from './feature-modules/approver/approver.component';
import { ErrorPageComponent } from './feature-modules/error/error-page.component';
import { FeatureModuleServices } from './feature-modules/feature.module.services';
import { HomeComponent } from './feature-modules/home/home.component';
import { BankKeysComponent } from './feature-modules/informationCenter/bank-keys.component';
import { PlatformDocumentationComponent } from './feature-modules/informationCenter/platform-documentation.component';
import { QuickstartRootComponent } from './feature-modules/informationCenter/quickstartroot.component';
import { RequestBankCertComponent } from './feature-modules/informationCenter/request-bank-cert.component';
import { SecurityCenterComponent } from './feature-modules/informationCenter/security-center.component';
import { ServerProtocolComponent } from './feature-modules/informationCenter/server-protocol.component';
import { LoginComponent } from './feature-modules/login/login.component';
import { MultiFactorAuthenticationComponent } from './feature-modules/login/multi-factor-authentication.component';
import { ReportsLinksComponent } from './feature-modules/reports/reports-links.component';
import { ReportsComponent } from './feature-modules/reports/reports.component';
import { ThreeKeyAuditComponent } from './feature-modules/reports/three-key-audit.component';
import { TransmissionReportsComponent } from './feature-modules/reports/transmission-reports.component';
import { RetreiveFilesComponent } from './feature-modules/retreive-files/retreive-files.component';
import { SendFilesComponent } from './feature-modules/send-files/send-files.component';
import { CookieCenterModalComponent } from './shared/cookie-center-modal/cookie-center-modal.component';
import { ContactUsModalComponent } from './shared/modals/contact-us-modal.component';
import { MfaWarningModalComponent } from './shared/modals/mfa-warning-modal.component';
import { SharedModule } from './shared/shared.module';
import { SurveyComponent } from './shared/survey/survey.component';
import { TimePickerComponent } from './shared/time-picker/time-picker.component';

export function mockServerFactory() {
  return new MockApiInterceptor(environment['mockApiServer']);
}

export interface AppConfig {
  nonce: string;
}

export const APP_CONFIG = new InjectionToken<AppConfig>('app.nonce');

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    RetreiveFilesComponent,
    SendFilesComponent,
    ApproverComponent,
    ReportsComponent,
    LoginComponent,
    ServerProtocolComponent,
    BankKeysComponent,
    PlatformDocumentationComponent,
    RequestBankCertComponent,
    TransmissionReportsComponent,
    ThreeKeyAuditComponent,
    MultiFactorAuthenticationComponent,
    MyAccountComponent,
    ChangePasswordComponent,
    ChannelsComponent,
    ManageMfaComponent,
    AddAccountComponent,
    ErrorPageComponent,
    ReportsLinksComponent,
    SecurityCenterComponent,
    UserInactivityComponent,
    QuickstartRootComponent,
    AccountmanagementComponent,
    FormatFileSizePipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule, // replace with 'NoopAnimationsModule' to disable animations
    HttpClientModule,
    AppRoutingModule,
    CoreModule.forRoot(),
    SharedModule,
    PhxTranslateModule.forRoot(),
    ReactiveFormsModule,
    OverlayModule,
    PhxCspInlineStylesModule
  ],
  providers: [
    DataResolve,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useFactory: mockServerFactory,
    //   multi: true
    // },
    { provide: HTTP_INTERCEPTORS, useClass: UserAuthDetailsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: 'LOG_CONFIG', useValue: environment.logConfig },
    // { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
    { provide: BUNDLE_URL_TOKEN, useValue: environment.translationBundleUrl },
    { provide: FeatureModuleServices },
    { provide: ServerProtocolService },
    { provide: CommonService },
    { provide: AuthService },
    { provide: LoginMfaService },
    { provide: InstanceService },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: APP_INITIALIZER, useFactory: resourceProviderFactory, deps: [StartupService], multi: true },
    Title,
  ],
  exports: [ContactUsModalComponent, MfaWarningModalComponent, SurveyComponent, CookieCenterModalComponent, TimePickerComponent],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private translateService: PhxTranslateService, private meta: Meta, @Inject(APP_CONFIG) config: AppConfig, private startupService: StartupService) {
    this.translateService.use('en_US');
    this.meta.addTag({ name: 'PHX-CSP-NONCE', content: config.nonce });
  }
}

export function resourceProviderFactory(provider: StartupService) {
  return () => provider.load();
}
