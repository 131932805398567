import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Logger } from '@phoenix/ui/common';
import { DataTableOptions } from '@phoenix/ui/datatable';
import { ModalOptions, ModalRef, ModalService } from '@phoenix/ui/modal';
import { Subscription } from 'rxjs';
import { GlobalCountries } from './global-countries';

@Component({
  selector: 'dts-contact-us-modal',
  templateUrl: './contact-us-modal.component.html',
  styleUrls: ['./contact-us-modal.component.scss']
})
export class ContactUsModalComponent implements OnInit {

  @ViewChild('header', {read: TemplateRef}) header: TemplateRef<any>;
  @ViewChild('contactheader', {read: TemplateRef}) contactheader: TemplateRef<any>;  
  @ViewChild('troubleSiginIn', { read: TemplateRef }) troubleSiginIn: TemplateRef<any>;
  @ViewChild('troubleSiginInFooter', { read: TemplateRef }) troubleSiginInFooter: TemplateRef<any>;
  @ViewChild('globalheader', {read: TemplateRef}) globalheader: TemplateRef<any>;
  @ViewChild('hotLineNo', { read: TemplateRef }) hotLineNo: TemplateRef<any>;
  @ViewChild('hotLineNoFooter', { read: TemplateRef }) hotLineNoFooter: TemplateRef<any>;
  private TSigninInModalRef: ModalRef;
  private hotlineModalRef: ModalRef;

  public pageSizeScroll = 40;
  readonly totalScroll = 57;
  optionsScroll: DataTableOptions;
  loading = false;
  gridSubscription: Subscription;
  countryData: Array<any> = [];

  constructor(
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.countryData = GlobalCountries.COUNTRIES;
  }

  openTroubleSignInModal() {
    const modalOptions: ModalOptions = {
      title: this.header,
      content: this.troubleSiginIn,
      footer: this.troubleSiginInFooter,
      dismissable: true,
      position: {
        top: '30px'
      },
      size: 'lg',
      hasBackdrop: true,
      draggable: false,
      closeBtnAriaLabel: 'close'
    };
    this.TSigninInModalRef = this.modalService.open(modalOptions);
  }

  public openContactUsModal() {
    const modalOptions: ModalOptions = {
      title: this.contactheader,
      content: this.troubleSiginIn,
      footer: this.troubleSiginInFooter,
      dismissable: true,
      position: {
        top: '30px'
      },
      size: 'lg',
      hasBackdrop: true,
      draggable: false,
      closeBtnAriaLabel: 'close'
    };
    this.TSigninInModalRef = this.modalService.open(modalOptions);
  }

  /**
   * Close Contact us modal
   */
  public closeTSiginInHandler() {
    this.modalService.close(this.TSigninInModalRef.modal);
  }

  /**
   * Opens the modal with global numbers
   */
  public openGlobalNosHandler() {
    const modalOptions: ModalOptions = {
      title: this.globalheader,
      content: this.hotLineNo,
      footer: this.hotLineNoFooter,
      dismissable: true,
      position: {
        top: '30px'
      },
      size: 'lg',
      hasBackdrop: true,
      draggable: false,
      closeBtnAriaLabel: 'close',
      isOpenedBySide: true,
      height: '440px'
    };
    this.hotlineModalRef = this.modalService.open(modalOptions);
  }

  /**
   * Close hotline modal
   */
  public closeHotlineNoHandler() {
    this.modalService.close(this.hotlineModalRef.modal);
  }
}