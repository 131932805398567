import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Logger } from '@phoenix/ui/common';
import { LoadingScreenService } from '../../core/services/loading-screen.service';
import { UserAuthenticationDetails } from '../login/user.authentication.details';
import CryptoJS from 'crypto-js';
import { environment } from 'projects/DtsUI/src/environments/environment';
import { FeatureModuleServices } from '../feature.module.services';
import { countries } from '../home/countries';

@Component({
  selector: 'dts-manage-mfa',
  templateUrl: './manage-mfa.component.html',
  styleUrls: ['./manage-mfa.component.scss'],
})
export class ManageMfaComponent implements OnInit {

  @Input() showHideButton?: boolean;
  @Output() getFormStatus = new EventEmitter();

  mfaForm: FormGroup;
  mailRegEx: string;
  codeList: any;
  userData: any;
  isSubmittedForOtp: boolean = false;
  rootScope: any;
  deliveryTypeDto: any;
  messageType: string = null;
  message: string = null;
  validationFailed: boolean = false;
  mfaPasscode: string = null;
  showMobile: boolean;
  showEmail: boolean;

  breadcrumbItems = [
    { label: 'Home', link: '/home' },
    { label: 'User profile', link: '/accountmanagement' }
  ];

  email = new FormControl(null, Validators.compose([Validators.required, Validators.email]))
  mobileNum = new FormControl(null, Validators.compose([Validators.required, Validators.pattern('^[0-9]{10,10}$')]))
  countryCode = new FormControl(null, [Validators.required])

  constructor(
    private userAuthenticationDetails: UserAuthenticationDetails,
    private loadingService: LoadingScreenService,
    private mfaServices: FeatureModuleServices,
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    private logger: Logger
  ) { this.showHideButton = true; }

  ngOnInit(): void {
    this.rootScope = { base64Key: '', iv: '' };
    this.rootScope.base64Key = CryptoJS.enc.Base64.parse(environment.rootScopeBase64Key);
    this.rootScope.iv = CryptoJS.enc.Base64.parse(environment.rootScopeIv);

    this.userAuthenticationDetails.breadcrumbEmitter.emit(this.breadcrumbItems);
    this.mailRegEx = '^[a-z0-9._%+-]+@([a-z0-9][a-z0-9.-]+[.]{1})+[a-z]{2,9}$';
    this.codeList = countries;

    // Initialize country code for Empty values
    //this.countryCode = this.codeList[0].value;   

    if (this.userAuthenticationDetails.getUserAuthDetails() !== undefined) {
      this.loadUserDto();
    } else {
      this.initFormFields();
    }
  }

  ngAfterViewInit(): void {
    this.mfaForm.statusChanges.subscribe(res => {
      this.getFormStatus.emit(res);
    });

    this.mfaForm.valueChanges.subscribe(res => {
      this.getFormStatus.emit(res);
    });
  }

  ngAfterViewChanged() {
    this.cd.detectChanges();
  }

  /**
   * Load data
   */
  loadUserDto() {
    this.showMobile = false;
    this.showEmail = false;

    if (this.userAuthenticationDetails !== undefined) {
      this.userData = this.userAuthenticationDetails.userDto;

      this.logger.info('UserData : ', this.userData);
      if (this.userData !== undefined) {
        this.initFormFields();
      }
    }
  }

  /**
   * Initialize form data
   */
  initFormFields() {
    this.mfaForm = this.formBuilder.group({
      emailId: new FormControl('', Validators.compose([Validators.required, Validators.email])),
      mobileNum: new FormControl('', Validators.compose([Validators.required, Validators.pattern('^[0-9]{10,10}$')])),
      selectedCountryCode: new FormControl('', [Validators.required]),
      deliveryType: new FormControl('')//,
    });
    this.mfaForm.patchValue({
      emailId: this.userData?.deliveryTypeDto.email,
      mobileNum: this.userData?.deliveryTypeDto.mobile,
      selectedCountryCode: this.userData?.deliveryTypeDto.countryCode,
      deliveryType: this.userData?.deliveryTypeDto?.deliveryType.toLowerCase(),
      // mfaPasscode: null
    });
  }

  /**
   * Reset the form data to it original state.
   */
  onCancelClickHandler() {
    this.isSubmittedForOtp = false;
    this.mfaPasscode = '';
    this.initFormFields();
  }

  /**
   * Request OTP for the contact preference update
   */
  requestOTPHandler() {

    this.loadingService.startLoading();
    const key = this.rootScope.base64Key; // $rootScope.
    const iv = this.rootScope.iv;// $rootScope.

    let encryptedEmail = CryptoJS.AES.encrypt(this.mfaForm.value.emailId, key, { iv: iv });
    encryptedEmail = encryptedEmail.ciphertext.toString(CryptoJS.enc.Base64);

    let encryptedMobileNum = CryptoJS.AES.encrypt('' + this.mfaForm.value.mobileNum, key, { iv: iv });
    encryptedMobileNum = encryptedMobileNum.ciphertext.toString(CryptoJS.enc.Base64);

    this.deliveryTypeDto = {
      countryCode: this.mfaForm.value.selectedCountryCode,
      mobile: this.mfaForm.value.mobileNum,
      deliveryType: this.mfaForm.value.deliveryType,
      email: this.mfaForm.value.emailId
    }

    const requestOTPData = {
      countryCode: this.mfaForm.value.selectedCountryCode,
      email: encryptedEmail,
      mobile: encryptedMobileNum,
      deliveryType: this.mfaForm.value.deliveryType,
    };

    this.logger.info('ManageMulFacAuthComponent: OTP RequestSent \n', JSON.stringify(requestOTPData));
    this.requestOTPforMFA(requestOTPData);
  }

  /**
   * Helper function to get the OTP or submit data to update
   * @param requestOTPData 
   */
  private requestOTPforMFA(requestOTPData) {
    this.message = null;
    this.messageType = null;
    this.validationFailed = false;

    this.mfaServices.requestOTP(requestOTPData)
      .subscribe(responseData => {
        if (requestOTPData.token) {
          this.userData.deliveryTypeDto = this.deliveryTypeDto;
          this.logger.info('ManageMulFacAuthComponent: Delivery Details Updated \n', JSON.stringify(requestOTPData));
          this.logger.info('ManageMulFacAuthComponent: Updated UserDto \n', JSON.stringify(this.userData));
          this.userAuthenticationDetails.setUserAuthDetails(this.userData);

          this.isSubmittedForOtp = false;
          this.message = "Multi-factor authentication preferences have been successfully updated.";
          this.messageType = "success";
          this.mfaPasscode = '';
        }
        else {
          this.isSubmittedForOtp = true;
        }

        this.loadingService.stopLoading();
        this.ngAfterViewChanged();

      }, error => {

        if (requestOTPData.token) {
          this.validationFailed = true;
        } else {
          this.isSubmittedForOtp = false;
          this.message = 'Error occured while sending OTP, ' + error.error.statusDescription;
          this.messageType = "danger";
        }

        this.loadingService.stopLoading();
        this.logger.error('ERROR: ManageMulFacAuthComponent: OTP Request Validation Failed \n', JSON.stringify(error));
        this.ngAfterViewChanged();
      });
  }

  /**
   * Submit the OTP details for contact update
   */
  onSubmitOtpHandler() {

    const key = this.rootScope.base64Key; // $rootScope.
    const iv = this.rootScope.iv;// $rootScope.

    let encryptedEmail = CryptoJS.AES.encrypt(this.mfaForm.value.emailId, key, { iv: iv });
    encryptedEmail = encryptedEmail.ciphertext.toString(CryptoJS.enc.Base64);

    let encryptedMobileNum = CryptoJS.AES.encrypt('' + this.mfaForm.value.mobileNum, key, { iv: iv });
    encryptedMobileNum = encryptedMobileNum.ciphertext.toString(CryptoJS.enc.Base64);

    this.loadingService.startLoading();
    const submitOTPData = {
      countryCode: this.mfaForm.value.selectedCountryCode,
      email: encryptedEmail,
      mobile: encryptedMobileNum,
      deliveryType: this.deliveryTypeDto.deliveryType,
      token: this.mfaPasscode
    };

    this.logger.error('ManageMulFacAuthComponent: OTP Submit Validation Request \n', JSON.stringify(submitOTPData));
    this.requestOTPforMFA(submitOTPData);
  }

  /**
   * Format the last 4 digits for the telepohone #
   * @param inputValue 
   * @returns 
   */
  formatMobileNo(phoneNumber: string) {
    let format = phoneNumber;

    if (phoneNumber.length > 4) {
      return phoneNumber.substring(phoneNumber.length - 4, phoneNumber.length);
    }
    return phoneNumber;
  }

  /**
   * Formats the email
   * @param email 
   * @returns 
   */
  formatEmail(email: string) {
    var splitted = email.split('@', 2)
    let format = splitted[0];
    let subformat = format.substring(0, 4);
    let xString: string = '';
    for (let i = 0; i < format.length; i++) {
      xString = xString.concat('*');
    }
    return subformat + xString + '@' + splitted[1];
  }
  
 /* Removed the alert as it sets validationFailed alert
  mfaBlurHandler(){
    this.validationFailed = true;
  } */

  toggleShowMobile() {
    this.showMobile = !this.showMobile;
  }

  toggleShowEmail() {
    this.showEmail = !this.showEmail;
  }
}