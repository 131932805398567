<div>
	<div class="px-mt-4">
		<form [formGroup]="mfaForm" class="mfaForm">
			<div *ngIf="!isSubmittedForOtp">
				<div *ngIf="messageType">
					<phx-message type="{{messageType}}">
						{{message}}
					</phx-message>
				</div>
				<div class="px-mb-3 px-mt-3">Select your preferred contact method for multi-factor authentication.</div>
				<div class="px-form">
					<div>
						<phx-radio name="deliveryType" formControlName="deliveryType" label=" " value="email">
						</phx-radio>
						<div class="phoneNumber">
							<div>
								<label for="emailId">Email</label>
								<input phxInput [type]="showEmail ? 'text': 'password'" id="emailId" name="emailId" formControlName="emailId"
									required />
								<small class="px-form-text px-text-danger">
									<phx-error-control formControlName="emailId" id="emailError"
										[errors]="{ required: 'Email is required.', email: 'Email is invalid' }">
									</phx-error-control>
								</small>
							</div>
	
							<div>
								<i [class]="showEmail ? 'pxi px-eye': 'pxi px-eye-slash' " class="iconDiv" (click)="toggleShowEmail()"></i>
							</div>
						</div>
						
					</div>
					<div class="px-pt-5">
						<phx-radio name="deliveryType" formControlName="deliveryType" label=" " value="mobile">
						</phx-radio>
						<div class="phoneNumber">
							<div>
								<legend id="selectedCountryCodeLabel" class="labelContent">Country code</legend>
								<phx-dropdown id="selectedCountryCode" ariaLabelledBy="selectedCountryCodeLabel"
									[options]="codeList" placeholder="All" name="selectedCountryCode"
									formControlName="selectedCountryCode" style="width: 320px;" [maxHeight]="240">
								</phx-dropdown>
								<small class="px-form-text px-text-danger">
									<phx-error-control formControlName="selectedCountryCode" id="codeError"
										[errors]="{ required: 'Country code is required.' }"></phx-error-control>
								</small>
							</div>
							<div>
								<label for="mobileNum">Phone number</label>
								<input phxInput id="mobileNum" name="mobileNum" formControlName="mobileNum" [type]="showMobile ? 'text': 'password'"
									required />
								<small class="px-form-text px-text-danger">
									<phx-error-control formControlName="mobileNum" id="mobileError"
										[errors]="{ required: 'Phone Number is required.', pattern:'Phone number is invalid' }">
									</phx-error-control>
								</small>
							</div>
							<div>
								<i [class]="showMobile ? 'pxi px-eye': 'pxi px-eye-slash' " class="iconDiv" (click)="toggleShowMobile()"></i>
							</div>
						</div>
					</div>
					<div class="px-mt-3">
						<button type="submit" class="px-mr-3 px-pl-3 px-pr-3" [disabled]="!mfaForm.valid"
							phxButton="primary" (click)="requestOTPHandler()" title="Update">Update</button>
						<button type="button" phxButton="secondary" (click)="onCancelClickHandler()"
							title="Cancel">Cancel</button>
					</div>
				</div>
			</div>
		</form>
		<div *ngIf="isSubmittedForOtp">
			<form>
				<phx-message type="danger" [dismissible]="false" [(showMessage)]="validationFailed">
					The security code you entered is incorrect. Try requesting a new code. If you received multiple
					codes,
					please enter the latest code.
				</phx-message>
				<div class="summary">We sent you <ng-container *ngIf="mfaForm.get('deliveryType').value === 'email'">an
						email</ng-container>
					<ng-container *ngIf="mfaForm.get('deliveryType').value !== 'email'">a text message</ng-container>.
				</div>
				<div class="px-mb-3 px-mt-3">
					<ng-container *ngIf="mfaForm.get('deliveryType').value === 'email'">Look for the security code we
						sent to {{formatEmail(userData?.deliveryTypeDto.email)}} and enter it below.</ng-container>
					<ng-container *ngIf="mfaForm.get('deliveryType').value !== 'email'">Look for the security code we
						sent to (***) ***-{{formatMobileNo(userData?.deliveryTypeDto.mobile)}} and enter it below.
					</ng-container>
				</div>
				<div>
					<label for="mfaPasscode" class="required">Security code</label>
					<input phxInput id="mfaPasscode"  maxlength="20" name="mfaPasscode"
						[(ngModel)]="mfaPasscode" required />
						<!--(blur)="mfaBlurHandler(") (keypress)="mfaBlurHandler()"-->
				</div>
				<div class="px-mt-3 px-pt-3">
					<button type="submit" class="px-mr-3 px-pl-3 px-pr-3" [disabled]="!mfaPasscode" phxButton="primary"
						(click)="onSubmitOtpHandler()" title="Submit">Submit</button>
					<button type="button" phxButton="secondary" (click)="onCancelClickHandler()"
						title="Cancel">Cancel</button>
				</div>
			</form>
		</div>
	</div>
</div>