<section class="px-container">
    <div class="section-title">
        <h1>Manage keys</h1>
    </div>
    <div class="pageContent">
        <div>
            <h2>PGP keys</h2>
            <div class="gridMessage">Select and download PGP key that will be used for encryption of payload.
            </div>
            <div *ngIf="errInUpdateKey">
                <span><i class="fa fa-minus-circle" aria-hidden="true"></i></span>
                <span>{{updateKeyErrMsg}} : Error message.</span>
            </div>
            <div *ngIf="pgpRespSuccess">
                <div class="errMessage" *ngIf="!pgpDataExists || !pgpRespSuccess">
                    <i class="pxi px-minus-circle"></i>
                    {{ pgpErrMessage}}
                </div>
                <div class="_npl dts-clr-error _pt-5 " *ngIf="errUpdatingKey">
                    <span><i class="fa fa-minus-circle" aria-hidden="true"></i></span>
                    <span> {{ errUpdatingKey}}</span>
                </div>
            </div>
            <div [style.display]="pgpDataExists ? 'inline': 'none'">
                <phx-datatable [options]="pgpGridOptions" id="tblPGP" #tblPGP (onTableEvents)="handleEvents($event)"
                    [scrollHeight]="innerHeight">
                    <ng-template phxTemplate="column" phxField="signAndDecrypt" phxType="row" let-rowData>
                        <input type="radio" name="rowData.signAndDecrypt" #rowData.signAndDecrypt
                            [(ngModel)]="rowData.signAndDecrypt" value="{{rowData.signAndDecrypt}}" id=1
                            (change)="onChangeActive(rowData)" required />

                    </ng-template>
                    <ng-template phxTemplate="column" phxField="expDate" phxType="row" let-rowData>
                        {{ rowData.expDate | date: 'MMM dd, yyyy' }}
                    </ng-template>
                    <ng-template phxTemplate="column" phxField="fileDownload" phxType="row" let-rowData>
                        <a href="javascript:void(0)" (click)="fileDownload(rowData)" title="Download file with public key {{rowData.pgpId}}"><i
                                class="pxi px-download"></i></a>
                    </ng-template>
                </phx-datatable>
            </div>
        </div>
        <div *ngIf="show3SKeys" class="threeSKeys">
            <h2>3SKeys</h2>
            <div class="secgridMessage">Manage the 3skey associated to the account.</div>
            <div *ngIf="sKeyRespSuccess">
                <div *ngIf="sKeyDataExists" class="signDD">
                    <div class="px-form-group">
                        <legend class="labelContent" for="noof3skeys">Key signatures</legend>
                        <phx-dropdown id="noof3skeys" width="200px" #count3skey [ngModel]="noof3skeys"
                            (ngModelChange)="onChange($event)" [maxHeight]="200" [options]="count3skeylist">
                        </phx-dropdown>
                    </div>
                    <div class="tokenAddErrorDiv" *ngIf="errDeletingToken">
                        <i class="pxi px-minus-circle"></i> {{tokenDelMsg}}
                    </div>
                </div>
                <div [style.display]="sKeyRespSuccess ? 'inline': 'none'">
                    <phx-datatable [options]="threeSkeyGridOption" id="tbl3sKey" #tbl3sKey
                        (onTableEvents)="handleEvents3SKeys($event)" [scrollHeight]="threesKeysinnerheight">
                        <ng-template phxTemplate="column" phxField="createdDate" phxType="row" let-rowData>
                            {{ rowData.createdDate | date: 'MMM dd, yyyy hh:mm:ss a' }}
                        </ng-template>
                        <ng-template phxTemplate="column" phxField="deleteToken" phxType="row" let-rowData>
                            <a href="javascript:void(0)" (click)="deleteToken(rowData)" title="Delete Token"><i
                                    class="pxi px-trash-alt"></i></a>
                        </ng-template>
                    </phx-datatable>
                </div>

                <!-- <div *ngIf="errExistingToken">
                    <span><i class="fa fa-minus-circle" aria-hidden="true"></i></span>
                    <span>{{ existingTokenMsg }}</span>
                </div> -->
                <div class="addToken">
                    <a *ngIf="!addDetailsDisplay" (click)="addDetailsSelected()" style="cursor: pointer;"><i
                            class="pxi px-plus" title="Add a new Token"></i>
                        Add a new token</a>
                    <div class=newToken *ngIf="addDetailsDisplay">
                        <div class="inputSection">
                            <label for="addTokenInput">Token</label>
                            <input phxInput type="text" id="addTokenInput" [(ngModel)]="inputToken"
                                (ngModelChange)="onNameChange()" /> <button phxButton type="button"
                                (click)="addTokenDetails()" role="button" title="Add">Add</button><button
                                phxButton="link" type="button" (click)="cancelAdd()" role="button"
                                title="Cancel">Cancel</button>
                        </div>
                    </div>

                </div>

                <div *ngIf="sKeyErrMessage">
                    <div class="tokenAddErrorDiv">
                        <i class="pxi px-minus-circle"></i> {{sKeyErrMessage}}
                    </div>
                </div>
                <!-- <div *ngIf="sKeyErrMessage" style="margin-top: 10px;">
                    <phx-message type="danger" [dismissible]="true" [(showMessage)]="sKeyErrMessage">
                        {{sKeyErrMessage}}
                    </phx-message>
                </div> -->
            </div>
        </div>
    </div>
</section>
<ng-template #changeActiveMsg>
    <div class="_pl-40">If you activate a new key, the old key will immediately deactivate.Would you like to activate
        the new key?</div>
</ng-template>
<ng-template #deleteTokenHeader>
    <h2>Confirmation</h2>
</ng-template>
<ng-template #deleteTokenMsg>
    <span class="h-warning h-mr-2"><i class="pxi px-exclamation-triangle"
            title="Alert Are you sure you want to delete this folder?"><span
                class="px-sr-only">Alert</span></i></span>Remove
    key token
    <div class="divMessage"><span>Are you sure you want to remove this key token?</span></div>
</ng-template>
<ng-template #selectButtonMsg>
    <button phxButton type="button" (click)="updatePgpKey()" title="Yes">Yes</button>
    <button phxButton="link" type="button" (click)="close()" title="No">No</button>
</ng-template>
<ng-template #selectButtonDelToken>
    <button phxButton type="button" (click)="deleteTokenService()" title="Remove">Remove</button>
    <button phxButton="link" type="button" (click)="close()" title="Cancel">Cancel</button>
</ng-template>