import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { APP_CONFIG, AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

declare global {
  interface Crypto {
    randomUUID: () => string;
  }
}

const bootstrap = () => platformBrowserDynamic([{
  provide: APP_CONFIG,
  useValue: {nonce: crypto.randomUUID()}
}]).bootstrapModule(AppModule);
bootstrap().catch(err => console.log(err));
