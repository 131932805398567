import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalOptions, ModalService } from '@phoenix/ui/modal';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../core/services/auth.service';


@Component({
  selector: 'dts-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {
  surveyReq: Survey = {} as Survey;
  surveyForm: FormGroup;
  surveySubmitted: boolean;
  surveyError: boolean;
  codeList = [
    'Current Software does not support ciphers',
    'Current Software does not support HMACs',
    'Additional time needed to perform changes',
    'Other',
  ];
  code2Selected = '';

  errorMessage: string;
  modalContent: { header: string; content: string; primaryButton: string; cancelButton: string; deleteSummary?: string };
  @Output() onSaveAfter = new EventEmitter();
  @Output() onDiscardChanges = new EventEmitter();
  @Output() onCloseAfter = new EventEmitter();
  @ViewChild('surveyTitle') modalHeaderRef: TemplateRef<any>;
  @ViewChild('survey') surveyModalRef: TemplateRef<any>;
  @ViewChild('surveyFooter') modalFooterRef: TemplateRef<any>;

  @ViewChild('surveyConfirmationHeader') modalconfirmationHeader: TemplateRef<any>;
  @ViewChild('surveyConfirmation') modalconfirmationBody: TemplateRef<any>;
  @ViewChild('surveyConfirmationFooter') modalconfimrationFooter: TemplateRef<any>;

  constructor(private authService: AuthService, private modalService: ModalService) { }

  createFormGroup() {
    this.surveyForm = new FormGroup({
      addInfo: new FormControl(this.surveyReq.addInfo),
      cipherStatus: new FormControl(this.surveyReq.cipherStatus, Validators.required),
      companyName: new FormControl(this.surveyReq.companyName, Validators.required),
      driverType: new FormControl(this.surveyReq.driverType,),
      emailId: new FormControl(this.surveyReq.emailId, [Validators.required, Validators.email]),
      hmacStatus: new FormControl(this.surveyReq.hmacStatus, Validators.required),
      keyStatus: new FormControl(this.surveyReq.keyStatus, Validators.required),
      phoneNo: new FormControl(this.surveyReq.phoneNo, Validators.required),
      securityStatus: new FormControl(this.surveyReq.securityStatus, Validators.required),
      transmissionId: new FormControl(this.surveyReq.transmissionId, Validators.required),
      userName: new FormControl(this.surveyReq.userName, Validators.required)
    });

  }

  ngOnInit(): void {
    this.createFormGroup();
    this.surveyForm.get('securityStatus').valueChanges.pipe(tap((securityStatus: string) => {
      this.surveyForm.get('driverType').reset();
      if (securityStatus === 'false') {
        this.surveyForm.get('driverType').setValidators(Validators.required);
      } else {
        this.surveyForm.get('driverType').setValidators(null);
      }
      this.surveyForm.get('driverType').updateValueAndValidity();
    })).subscribe();
  }

  openModal() {
    this.surveySubmitted = false;
    this.errorMessage = null;
    var modalOptions: ModalOptions;

    modalOptions = {
      title: this.modalHeaderRef,
      content: this.surveyModalRef,
      footer: this.modalFooterRef,
      size: 'lg',
      hasBackdrop: true,
      height: '800px',
      draggable: false
    };

    this.modalService.open(modalOptions);
  }


  openConfirmationModal() {
    this.surveySubmitted = false;
    this.errorMessage = null;
    var modalOptions: ModalOptions;

    modalOptions = {
      title: this.modalconfirmationHeader,
      content: this.modalconfirmationBody,
      footer: this.modalconfimrationFooter,
      size: 'sm',
      hasBackdrop: true,
      //height: '800px',
      draggable: true
    };

    this.modalService.open(modalOptions);
  }

  /**
   * Method to add Survey details entered
   */
  addSurveyDetailsHandler() {
    //Object.keys(this.surveyForm.controls).forEach((key: string) => this.surveyForm.controls[key].disable());
    this.authService.addSurveyDetails(this.surveyForm.value).subscribe(() => {
      this.surveyError = false;
      this.surveySubmitted = true;
      this.surveyForm.reset();
      this.modalService.close();
      this.openConfirmationModal();
    }, error => {
      //Object.keys(this.surveyForm.controls).forEach((key: string) => this.surveyForm.controls[key].enable());
      this.surveySubmitted = false;
      this.surveyError = true;
    })
  }

  /**
   * Method to close the modal handler
   */
  closeSurveyHandler() {
    this.modalService.close();
    this.surveyForm.reset();
    this.surveyError = false;
      this.surveySubmitted = true;

    if (this.onCloseAfter)
      this.onCloseAfter.emit("Modal closed");
  }
}


interface Survey {
  addInfo: string;
  cipherStatus: boolean;
  companyName: string;
  driverType: string;
  emailId: string;
  hmacStatus: boolean;
  keyStatus: boolean;
  phoneNo: number;
  securityStatus: boolean;
  transmissionId: string;
  userName: string;
}