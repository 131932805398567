<section class="px-container">
    <div class="section-title">
        <h1>Approve</h1>
    </div>
    <div *ngIf="dirLoaded">
        <div class="filters">
            <div class="dropdown">

                <legend id="mailboxselectLabel" class="required labelContent" for="serverProtocol">Select mailbox
                </legend>
                <phx-dropdown aria-labelledby="mailboxselectLabel" placeholder="Select mailbox" id="mailboxselect"
                    [(ngModel)]="mailBoxSelected" (ngModelChange)="onModelChangeHandler($event)" [options]="mailboxList"
                    [(toggle)]="open">
                </phx-dropdown>
            </div>
            <div *ngIf="filelist" class="buttons">
                <button class="px-mr-3" type="button" phxButton [disabled]="!isAnyRowSelected"
                    (click)="onClickApprove()" title="Approve">Approve</button>
                <button class="px-mr-3" type="button" phxButton [disabled]="!isAnyRowSelected" (click)="onClickReject()"
                    title="Reject">Reject</button>
            </div>
        </div>
        <div *ngIf="!dirLoaded">
            <phx-message type="warning">
                {{dirErrMsg}}
            </phx-message>
        </div>
        <div [style.display]="mailBoxSelected ?'inline':'none'" class="buttons">
            <phx-datatable [options]="optionsScroll" id="tblScroll" #grid (onTableEvents)="handleEvents($event)"
                [selectionType]="selection">
                <ng-template phxTemplate="column" phxField="received" phxType="row" let-rowData>
                    {{ rowData.received | date: 'MMM dd, yyyy hh:mm:ss a' }}
                </ng-template>
                <ng-template phxTemplate="column" phxField="fileDownload" phxType="row" let-rowData>
                    <a href="javascript:void(0)" (click)="fileDownloadHandler(rowData)"
                        title="Download file {{rowData.fileName}}"><i class="pxi px-download"></i></a>
                </ng-template>
            </phx-datatable>
        </div>

    </div>
    <div *ngIf="!dirLoaded">
        <phx-message type="warning">
            {{dirErrMsg}}
        </phx-message>
    </div>
</section>
<ng-template #headerTitle>
    <h2>{{confirmationHeader}}</h2>
</ng-template>

<ng-template #headerTitleSuccess>
    <h2>{{responseMsg.header}}</h2>
</ng-template>

<ng-template #footerSuccess>
    <button phxButton (click)="closeModal()" type="button" title="Close">Close</button>
</ng-template>
<ng-template #successMsg>
    <div class="successDiv">
        <i class="pxi px-check-circle px-10x"></i> Success
    </div>
    <p>
        {{responseMsg.body}}
    </p>
</ng-template>
<ng-template #confirmMsg>
    <p>
        {{confirmationMsg}}
    </p>
</ng-template>
<ng-template #confirmFooter>
    <button phxButton (click)="submitResponse(userResponse)" role="button">
        {{userResponse === 'APP'? 'Approve':'Reject'}} </button>&nbsp;
    <button phxButton="link" (click)="closeConfirmModal()" role="button">Cancel</button>
</ng-template>