<section class="px-container">
    <div class="section-title">
        <h1>Platform documentation</h1>
    </div>
    <div class="summary">
        Explore the B2Bi documentation and guides.
    </div>
    <div class="cards">
        <div class="box">
            <div><i class="pxi px-brand-book px-10x" aria-hidden="true"></i></div>
            <div>
                <a href="javascript:void(0)" [routerLink]="['/quickStart/quickStartOverview']" title="Services Overview">
                    Services overview</a>
            </div>
            <div>
                Learn more about the B2Bi framework, features, and offerings.
            </div>
        </div>
        <div class="box" *ngIf="!isInternalApp">
            <div><i class="pxi px-file-pdf" aria-hidden="true"></i></div>
            <div>
                <a href="javascript:void(0)" (click)="docDownloadHandler('B2BiPortalGuide.pdf')" title="Portal guide">
                    <i class="pxi px-download" aria-hidden="true"></i> Portal guide</a>
            </div>
            <div>
                Download the B2Bi secure web transmission user guide.
            </div>
        </div>
        <div class="box" *ngIf="!isInternalApp">
            <div><i class="pxi px-file-pdf" aria-hidden="true"></i></div>
            <div>
                <a href="javascript:void(0)" (click)="docDownloadHandler('B2BiTechnicalGuide.pdf')" title="Technical guide">
                    <i class="pxi px-download" aria-hidden="true"></i> Technical guide</a>
            </div>
            <div>
                Download the B2Bi technical guide to learn more about the technical requirements.
            </div>
        </div>
    </div>
</section>