import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/DtsUI/src/environments/environment';
import { map } from 'rxjs/operators';
import { apiUrls } from '../../feature-modules/app.urls.config';

@Injectable({
    providedIn: 'root',
})
export class AppService {
    logoImage;
    constructor(private http: HttpClient) {
        this.loadImages();
    }

    loadImages() {
        this.logoImage = require('./../../../assets/images/logo-h.jpg').default;
    }

    updateUserDetails(requestObj: any){
        const url = environment.host + apiUrls.updateAccountDetails;

        return this.http.post(url,requestObj).pipe(
            map((data: any) => {
              return data;
            }, (err: { status: string; }) => {
              console.log('Something went wrong!: error: ' + JSON.stringify(err));
              return err;
            }
            )
          );
    }
}
