<section class="px-container">
  <div class="section-title">
    <h1>Server protocol</h1>
  </div>
  <div>
    <div class="required-warning">Asterisk items (<span>*</span>) are required to proceed.</div>
    <div>
      <div class="px-form-group">
        <legend id="serverProtocolLabel" class="required labelContent" for="serverProtocol">Protocol</legend>
        <phx-dropdown width="200px" aria-labelledby="serverProtocolLabel" id="serverProtocol" [required]="true"
          [(ngModel)]="selectedProtocolName" (ngModelChange)="onProtocolChangeHandler($event)" [options]="protocolList"
          [(toggle)]="openDropDown"></phx-dropdown>
      </div>
    </div>
    <div class="px-pb-4 px-pt-2">
      <div *ngIf="protocolSelected === 'SSH SFTP'">
        <div class="px-form-group">
          <label>Bank SFTP DNS</label>
          <div *ngFor="let dnsName of certInfoData?.dnsNames">{{ dnsName }}</div>
        </div>
        <div class="px-form-group">
          <label>Bank SSH IP address(es)</label>
          <div *ngFor="let ipAddress of certInfoData?.ipAddresses">{{ ipAddress }}</div>
        </div>
        <div class="px-form-group">
          <label>Bank SFTP port number</label>
          <div>{{ certInfoData?.portNumber }}</div>
        </div>
        <div class="px-form-group">
          <label>Protocol reference information</label>
          <div>{{ certInfoData?.protocolReferenceInfo }}</div>
        </div>
      </div>
      <div *ngIf="protocolSelected === 'FTP SSL'">
        <div class="px-form-group">
          <label>Bank FTP SSL DNS</label>
          <div *ngFor="let dnsName of certInfoData?.dnsNames">{{ dnsName }}</div>
        </div>
        <div class="px-form-group">
          <label>Bank FTP SSL IP address(es)</label>
          <div *ngFor="let ipAddress of certInfoData?.ipAddresses">{{ ipAddress }}</div>
        </div>
        <div class="px-form-group">
          <label>Bank FTP SSL port number</label>
          <div>{{ certInfoData?.portNumber }}</div>
        </div>
        <div class="px-form-group">
          <label>Protocol reference information</label>
          <div>{{ certInfoData?.protocolReferenceInfo }}</div>
        </div>
      </div>
      <div *ngIf="protocolSelected === 'AS2'">
        <div class="px-form-group">
          <label>Bank AS2 DNS</label>
          <div *ngFor="let dnsName of certInfoData?.dnsNames">{{ dnsName }}</div>
        </div>
        <div class="px-form-group">
          <label>Bank AS2 IP address(es)</label>
          <div *ngFor="let ipAddress of certInfoData?.ipAddresses">{{ ipAddress }}</div>
        </div>
        <div class="px-form-group">
          <label>Bank AS2 port number</label>
          <div>{{ certInfoData?.portNumber }}</div>
        </div>
        <div class="px-form-group">
          <label>Protocol reference information</label>
          <div>{{ certInfoData?.protocolReferenceInfo }}</div>
        </div>
      </div>
      <div *ngIf="protocolSelected === 'CD NDM'">
        <div class="px-form-group">
          <label>Bank connect direct DNS</label>
          <div *ngFor="let dnsName of certInfoData?.dnsNames">{{ dnsName }}</div>
        </div>
        <div class="px-form-group">
          <label>Bank connect direct IP address(es)</label>
          <div *ngFor="let ipAddress of certInfoData?.ipAddresses">{{ ipAddress }}</div>
        </div>
        <div class="px-form-group">
          <label>Bank connect direct port number</label>
          <div>{{ certInfoData?.portNumber }}</div>
        </div>
        <div class="px-form-group">
          <label>Protocol reference information</label>
          <div>{{ certInfoData?.protocolReferenceInfo }}</div>
        </div>
      </div>
      <div *ngIf="protocolSelected === 'HTTPS'">
        <div class="px-form-group">
          <label>Bank HTTPS DNS</label>
          <div *ngFor="let dnsName of certInfoData?.dnsNames">{{ dnsName }}</div>
        </div>
        <div class="px-form-group">
          <label>Bank HTTPS port number</label>
          <div>{{ certInfoData.portNumber }}</div>
        </div>
        <div class="px-form-group">
          <label>Protocol reference information</label>
          <div>{{ certInfoData.protocolReferenceInfo }}</div>
        </div>
      </div>
    </div>
    <div [style.display]="protocolSelected && protocolSelected !== 'SSH SFTP'?'inline':'none'">
      <phx-datatable [options]="certGridOptions" id="tblCert" #tblCert (onTableEvents)="handleEvents($event)">
        <ng-template phxTemplate="column" phxField="validFrom" phxType="row" let-rowData>
          {{ rowData.validFrom | date: 'MMM dd, yyyy' }}
        </ng-template>
        <ng-template phxTemplate="column" phxField="validTo" phxType="row" let-rowData>
          {{ rowData.validTo | date: 'MMM dd, yyyy' }}
        </ng-template>
        <ng-template phxTemplate="column" phxField="fileDownload" phxType="row" let-rowData>
          <a href="javascript:void(0)" (click)="fileDownloadHandler(rowData)" title="Download file with key {{ rowData.key_id}}"><i class="pxi px-download"></i></a>
        </ng-template>
      </phx-datatable>
    </div>
    <div [style.display]="protocolSelected && protocolSelected === 'SSH SFTP'?'inline':'none'">
      <phx-datatable [options]="keyGridOptions" id="tblOption" #tblOptions (onTableEvents)="handleEvents($event)">
        <ng-template phxTemplate="column" phxField="validFrom" phxType="row" let-rowData>
          {{ rowData.validFrom | date: 'MMM dd, yyyy' }}
        </ng-template>
        <ng-template phxTemplate="column" phxField="validTo" phxType="row" let-rowData>
          {{ rowData.validTo | date: 'MMM dd, yyyy' }}
        </ng-template>
        <ng-template phxTemplate="column" phxField="fileDownload" phxType="row" let-rowData>
          <a href="javascript:void(0)" (click)="fileDownloadHandler(rowData)" title="Download file with key {{ rowData.key_id}}"><i class="pxi px-download"></i></a>
        </ng-template>
      </phx-datatable>
    </div>
  </div>
</section>