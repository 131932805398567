<section class="px-container px-mt-4">
    <div class="section-title">
        <h1>Multi-factor authentication (MFA)</h1>
    </div>
    <form [formGroup]="mfaLoginForm" class="mfaLoginForm">
        <div *ngIf="!isSubmittedForOtp">
            <div class="summary">Let's make sure it's you.</div>
            <div class="px-form">
                <fieldset>
                    <legend>
                        <div class="px-mb-3 px-mt-3">For your security, we need to verify your identity. Select either an
                            email
                            address or phone number where you can receive a security code.</div>
                    </legend>
                    <ul class="px-list-unstyled px-mb-0">
                        <li>
                            <phx-radio name="deliveryType" formControlName="deliveryType" label="Email" value="email">
                            </phx-radio>
                            <label>
                                An email will be sent to {{mfaLoginForm.get('emailId').value}}.
                            </label>
                        </li>
                        <li class="px-mt-3">
                            <phx-radio name="deliveryType" formControlName="deliveryType" label="Text message"
                                value="mobile"></phx-radio>
                            <label>
                                A text message will be sent to (***) ***-{{mfaLoginForm.get('mobileNum').value}}.<br>
                                Message and data rates may apply.
                            </label>
                        </li>
                    </ul>
                </fieldset>
                <div class="px-mt-3 px-pt-3">
                    <button type="submit" class="px-mr-3 px-pl-3 px-pr-3" [disabled]="!mfaLoginForm.valid"
                        phxButton="primary" (click)="onNextClickHandler()" title="Next">Next</button>
                    <button type="button" phxButton="link" (click)="onCancelClickHandler()"
                        title="Cancel">Cancel</button>
                </div>
            </div>
        </div>
        <div *ngIf="isSubmittedForOtp">
            <phx-message type="danger" [dismissible]="false" [(showMessage)]="validationFailed">
                The security code you entered is incorrect. Try requesting a new code. If you received multiple codes,
                please enter the latest code.
            </phx-message>
            <div class="summary">We sent you <ng-container *ngIf="mfaLoginForm.get('deliveryType').value === 'Email'">an
                    email</ng-container>
                <ng-container *ngIf="mfaLoginForm.get('deliveryType').value !== 'email'">a text message</ng-container>.
            </div>
            <div class="px-mb-3 px-mt-3">
                <ng-container *ngIf="mfaLoginForm.get('deliveryType').value === 'email'">Look for the security code we
                    sent to {{mfaLoginForm.get('emailId').value}} and enter it below.</ng-container>
                <ng-container *ngIf="mfaLoginForm.get('deliveryType').value !== 'email'">Look for the security code we
                    sent to (***) ***-{{mfaLoginForm.get('mobileNum').value}} and enter it below.</ng-container>
            </div>
            <div class="px-form">
                <label for="mfaPasscode" class="required">Security code</label>
                <input phxInput id="mfaPasscode" (blur)="mfaBlurHandler()" (keypress)="mfaBlurHandler()" maxlength="20"
                    name="mfaPasscode" formControlName="mfaPasscode" required />
            </div>
            <div class="px-mt-3">
                Didn't receive the code? <a href="javascript:void(0)" (click)="onRequestCodeHandler()"
                    title="Click to request another passcode">Request another one</a>.
            </div>
            <div class="px-mt-3 px-pt-3">
                <button type="submit" class="px-mr-3 px-pl-3 px-pr-3"
                    [disabled]="!mfaLoginForm.get('mfaPasscode').value" phxButton="primary"
                    (click)="onSubmitOtpHandler()" title="Submit">Submit</button>
                <button type="button" phxButton="link" (click)="onCancelClickHandler()" title="Cancel">Cancel</button>
            </div>
        </div>
    </form>
</section>