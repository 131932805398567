// #Business Service URLS
export const restApiUrls: any = {
dtsB2biClientRequestServiceUrl : 'https://wcdre2du.cdr-p01.chp.bankofamerica.com/v1/transact/cirs?userId : {0}&page : {1}',
dtsB2biAuthenticateServiceUrl : '/b2biportal/api/authenticate',
dtsB2biRetrieveDirServiceUrl : '/b2biportal/api/file/dirlist',
dtsB2biRetrieveDirFilesServiceUrl : '/b2biportal/api/file/list',
dtsB2biSendFilesDirServiceUrl : '/b2biportal/api/file/directories',
dtsB2biUploadSendFileServiceUrl : '/b2biportal/api/file/upload',
dtsB2biTransmissionReportServiceUrl : '/b2biportal/api/transmission/list',
dtsB2biBankInfoCertfServiceUrl : '/b2biportal/api/file/certslist',
dtsB2biMyaccountServiceUrl : '/b2biportal/api/users/update/',
dtsB2biChangePasswordServiceUrl : '/b2biportal/api/users/changepassword/',
dtsB2biSendOTPServiceUrl : '/b2biportal/api/sendotp?userName : {0}&deliveryType : {1}',
dtsB2biSubmitOTPServiceUrl : '/b2biportal/api/validateusertoken?userName : {0}&otp : {1}',
dtsB2biRetieveFileDownloadServiceUrl : '/b2biportal/api/file/download',
dtsB2biFetchRecentTransmissionUrl : '/b2biportal/api/transmission/',
dtsB2biThreeKeyAuditReportsUrl : '/b2biportal/api/keys/auditreport',
dtsB2biPGPKeysUrl : '/b2biportal/api/keys/pgpkeylist',
dtsB2biFetch3sKeysUrl : '/b2biportal/api/keys/list',
dtsB2bi3sKeysCreateTokenUrl : '/b2biportal/api/keys/create',
dtsB2biUpdateMFAUrl : '/b2biportal/api/users/updatemfa/',
dtsB2biCertDownloadUrl : '/b2biportal/api/certificate/download?keyId :{0}',
dtsB2biCertListUrl : '/b2biportal/api/certificate/list?protocol : {0}',
dtsB2bi3sKeysDeleteTokenUrl : '/b2biportal/api/keys/delete/',
dtsB2biUserChannelsUrl : '/b2biportal/api/users/channels/',
dtsB2biUPdatePGPKeysUrl : '/b2biportal/api/keys/updatepgpkey',
dtsB2biMyUsageGraphUrl : '/b2biportal/api/transmission/graphmyusage',
dtsB2biMyAlertsUrl : '/b2biportal/api/users/notification',
dtsB2biDownloadPGPKeyUrl : '/b2biportal/api/keys/downloadpgpkey/',
dtsB2biCertInfoUrl : '/b2biportal/api/certificate/info',
dtsB2biAddSurveyServiceUrl : '/b2biportal/api/users/addsurvey',
dtsB2biApprovalDirServiceUrl : '/b2biportal/api/fileapprover/mailboxpath',
dtsB2biApprovalDirFilesServiceUrl : '/b2biportal/api/fileapprover/retrieveFiles',
dtsB2biApproveRejectServiceUrl : '/b2biportal/api/fileapprover/updateStatus'
};