import { Injectable } from '@angular/core';
import { environment } from 'projects/DtsUI/src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

// import { restApiUrls } from './rest.svc.urls';
import { AppHttpService } from '../../core/services/app.http.service';
import { apiUrls } from '../../feature-modules/app.urls.config';
import { restApiUrls } from '../../feature-modules/rest.svc.urls';

/*Service to fetch grid data for Server Protocol*/

// angular.module(Blankprint.appName)
// 	.service('getServerProtocolService',getServerProtocolService);
// getServerProtocolService.$inject = ['$http','$q'];

@Injectable({
	providedIn: 'root'
})
export class ServerProtocolService {


	private _bankKeys: BehaviorSubject<any[]>;
	private getBPURL = environment.host;

	constructor(private http: AppHttpService) { }

	get keys(): Observable<any[]> {
		return this. _bankKeys.asObservable();
	  }

	  loadAll() {
	  }

	getServerProtocolData() {
		const url: string = environment.host + '/' + restApiUrls.getServerProtocolData;
		const config: object = {
			ref: 'api/getServerProtocolData'
		}
		return this.http.doHttpGet(url, config, this.http.httpOptions);
	}


	getBankCertificatesDetails(prtcl) {
		const url: string = environment.host + '/' + apiUrls.requestBankKeys + prtcl;
		const config: object = {
			ref: 'api/requestBankKeys/' + prtcl
		}
		return this.http.doHttpGet(url, config, this.http.httpOptions);
	}

	getBankCertificatesInfo() {
		const url: string = environment.host + apiUrls.loadCertInfo;
		const config: object = {
			ref: 'api/loadCertInfo'
		}
		console.log('getBankCertificatesInfo:',url);
		return this.http.doHttpGet(url, config, this.http.httpOptions);

	}

	getPGPKeys(keyName) {
		const url: string = environment.host  + apiUrls.requestBankKeys + keyName;
		const config: object = {
			ref: 'api/requestBankKeys/' + keyName
		}
		return this.http.doHttpGet(url, config, this.http.httpOptions);
	}

	updatePgpKey(req) {
		const url: string = environment.host + apiUrls.updatePgpKey;
		const config: object = {
			ref: 'api/updatePgpKey'
		}
		return this.http.doHttpPost(url, req, this.http.httpOptions);
	}

	get3sKeys(keyName) {
		const url: string = environment.host + '/' + apiUrls.requestBankKeys + keyName;
		const config: object = {
			ref: 'api/requestBankKeys/' + keyName
		}
		return this.http.doHttpGet(url, config, this.http.httpOptions);
	}

	createToken(req) {
		const url: string = environment.host + apiUrls.token;
		const config: object = {
			ref: 'api/createToken'
		}
		return this.http.doHttpPost(url, req, this.http.httpOptions);
	}

	deleteToken(req) {
		const url: string = environment.host  + apiUrls.token + '/' + req;
		const config: object = {
			ref: 'api/deleteToken/' + req
		}
		return this.http.doHttpDelete(url, config, this.http.httpOptions)

	}

	certDownload(keyId) {
		const url: string = environment.host + apiUrls.certDownload + keyId;
		const config: object = {
			ref: 'api/certificate/' + keyId
		}
		return this.http.doHttpGet(url, config, this.http.httpOptions);
	}
	pgpKeyCertDownload(keyId) {
		const url: string = environment.host + apiUrls.pgpKeyCertDownload + keyId;
		const config: object = {
			ref: 'api/pgpKeyCertificate/' + keyId
		}
		return this.http.doHttpGet(url, config, this.http.httpOptions);
	}

	// Resources
	getBankCertificatesResourceFn(){
		const url: string = environment.host + '/' + apiUrls.getBankCertificatesDetails;
		const config: object = {
			ref: 'api/getBankCertificatesDetails'
		}
		return this.http.doHttpGet(url, config, this.http.httpOptions);

	}

	getServerProtocolDataReq(){
		// var url = '';//TODO: Strange. Why no subcontext in url?
		const url: string = environment.host + '' ;
		const config: object = {
			ref:'api/getServerProtocolData'
		}
		return this.http.doHttpGet(url, config, this.http.httpOptions);
	}

	// Transmission reports service
	loadTransmissionReports(req){
		const url: string = environment.host + '/' + apiUrls.getTransmissionReports;
		const config: object = {
			ref: 'api/getTransmissionReports'
		}
		return this.http.doHttpPost(url, req, this.http.httpOptions);
	}

}