import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AppHttpService {

    public httpOptions = {
        headers: new HttpHeaders({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            Authorization: ''
        })
    };

    constructor(private http: HttpClient) {
        // environment.host = environment.mockApiServer;
    }

    public setHeaderWithAuthorization(str){
     if (str) {
         this.httpOptions.headers.set('Authorization', str);
        }
    }

    // Generic HTTP request response query functions
    public doHttpGet<LoginResponseData>(url: string, config: object, httpOptions?: any) {

        httpOptions = httpOptions === undefined?this.httpOptions:httpOptions;
        return this.http.get(url, httpOptions)
            .pipe(
                tap(data => {
                    //console.log('http.get url: ' + url + '   response tap' + JSON.stringify(data));
                }, err => {
                    console.log('Failed to GET http data - err.status: ' + err.status);
                    //console.log('Failed to GET http data - err.status: ' + JSON.stringify(err));
                })
            );
    }


    public doHttpPost(url: string, body: object, httpOptions: any) {
        httpOptions = httpOptions === undefined?this.httpOptions:httpOptions;
        return this.http.post(url, body, httpOptions)
        .pipe(
            tap(data => {
            //console.log('http.post url: ' + url + '   response tap' + JSON.stringify(data));
            }, err => {
                console.log('Failed to POST http data - err.status: ' + err.status);
            })
        );
    }

    public doHttpPut(url: string, body: object, httpOptions: any) {
        httpOptions = httpOptions === undefined?this.httpOptions:httpOptions;
        return this.http.put(url, body, httpOptions)
        .pipe(
            tap(data => {
            //console.log('http.put url: ' + url + '   response tap' + JSON.stringify(data));
                // return data;
            }, err => {
                console.log('Failed to PUT http data - err.status: ' + err.status);
                // return err;
            }
        )
        );
    }

    public doHttpDelete(url: string, config: object, httpOptions?: any) {

        httpOptions = httpOptions === undefined?this.httpOptions:httpOptions;
        return this.http.delete(url, httpOptions)
        .pipe(
            tap(data => {
                //console.log('http.delete url: ' + url + '   response tap' + JSON.stringify(data));
            }, err => {
                console.log('Failed to delete http data - err.status: ' + err.status);
                //console.log('Failed to delete http data - err.status: ' + JSON.stringify(err));
            })
        );
    }
}