import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/DtsUI/src/environments/environment';
import { UserAuthenticationDetails } from '../../feature-modules/login/user.authentication.details';
import { apiUrls } from './../../feature-modules/app.urls.config';
import { PhxCsrfConfigService } from '@phoenix/ui/common';

@Injectable()
export class StartupService {
    
    constructor(private http: HttpClient,
        private userAuthenticationDetails: UserAuthenticationDetails,
        private phxCsrfConfigService: PhxCsrfConfigService) {}

    loadConfigData: any;
    private csrfToken = {
        tokenHeader:'',
        tokenValue:''
    }
    
    private httpOptions = {
        headers: new HttpHeaders({
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json;charset=UTF-8'
        })
    };

    /**
     * Loads environment details
     */
    load() {

        return new Promise ((resolve, reject) => {
            this.http.get<any>(environment.host + apiUrls.startupService, {headers:this.httpOptions.headers, observe:'response'}).subscribe(
                response => {
                    const xbt = response.headers.get('xbt');
                    
                    //ADD BFS TOKEN
                    if(xbt) {
                        this.csrfToken.tokenHeader=xbt.substr(xbt.indexOf('|') + 1, xbt.length);
                        this.csrfToken.tokenValue=xbt.substr(0, xbt.indexOf('|'));
                        this.userAuthenticationDetails.setCSRFToken(this.csrfToken);
                        this.phxCsrfConfigService.setCsrfConfig({
                                 header: this.csrfToken.tokenHeader,
                                 token: this.csrfToken.tokenValue
                         });
                    }
                    this.loadConfigData = response.body.responseData;
                    resolve(true);
                }
            );
        })
    }

    /**
     * Checks if the environment is Internal or External portal
     * Internal will not have footer cookies and some of the data table columns will be named different
     */
    isInternalApp () {
        return this.loadConfigData.environment !== "external";
    }

    /**
     * Checks if the instance is "Merrill, this will change the header logo"
     */
    isMarkets () {
        return this.loadConfigData.instance === "ML";
    }
    /**
     * Returns instance
     */
    getInstance(){
        return this.loadConfigData.instance;
    }
    /**
     * Returns OT Key
     */
    otKey () {
        return this.loadConfigData.otKey;
    }
    otHash () {
        return this.loadConfigData.otHash;
    }
    cspDefault() {
        return this.loadConfigData.cspDefault;
    }
    cspStyle() {
        return this.loadConfigData.cspStyle;
    }
    cspScript() {
        return this.loadConfigData.cspScript;
    }
    cspImg() {
        return this.loadConfigData.cspImg;
    }
    mailToLink() {
        return this.loadConfigData.mailToLink;
    }
    checkBrowser():boolean{
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return true;     
        }
    
        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            return true;
        }
        return false;
      }
}