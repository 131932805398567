import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Logger } from '@phoenix/ui/common';
import * as CryptoJS from 'crypto-js';
import { LoadingScreenService } from '../../core/services/loading-screen.service';
import { FeatureModuleServices } from '../feature.module.services';
import { DeliveryType } from '../models/delivery-type';
import { RequestOTPData } from '../models/request-otp-data';
import { SubmitOTPData } from '../models/submit-otp-data';
import { UserDto } from '../models/user-dto';
import { UserAuthenticationDetails } from './user.authentication.details';
import { LoginMfaService } from '../../core/services/login.mfa.service';
import { environment } from 'projects/DtsUI/src/environments/environment';
@Component({
  selector: 'dts-multi-factor-authentication',
  templateUrl: './multi-factor-authentication.component.html',
  styleUrls: ['./multi-factor-authentication.component.scss']
})
export class MultiFactorAuthenticationComponent implements OnInit {

  mfaLoginForm: FormGroup;

  countryCodeList: { 'id': string; 'value': string; }[];
  rootScope: { base64Key: any; iv: any; };

  //Declare Flags
  validated = false;
  validationFailed = false;
  isSubmittedForOtp = false;

  //Declare variables
  encryptedEmail: string;
  encryptedMobile: string;
  deliveryModeMobile = 'mobile';
  deliveryModeEmail = 'email';
  validatedMessage = '';

  deliveryTypeDto: DeliveryType;
  userDto: UserDto;

  constructor(private logger: Logger,
    private router: Router,
    private mfaServices: FeatureModuleServices,
    private loginMfaService: LoginMfaService,
    private formBuilder: FormBuilder,
		private loadingService: LoadingScreenService,
    private userAuthenticationDetails: UserAuthenticationDetails) { }

  ngOnInit(): void {

    this.rootScope = { 'base64Key': '', 'iv': '' };
    this.rootScope.base64Key = CryptoJS.enc.Base64.parse(environment.rootScopeBase64Key);
    this.rootScope.iv = CryptoJS.enc.Base64.parse(environment.rootScopeIv);

    this.mfaLoginForm = this.formBuilder.group({
      emailId: new FormControl({ value: null, disabled: true }),
      mobileNum: new FormControl({ value: null, disabled: true }),
      selectedCountryCode: new FormControl({ value: null, disabled: true }),
      deliveryType: new FormControl(null),
      mfaPasscode: new FormControl(null)
    });

    if (this.loginMfaService.deliveryDto && this.loginMfaService.deliveryDto.deliveryTypeDto) {
      this.logger.info('MultiFactorAuthenticationComponent: response received from loginMfaService', JSON.stringify(this.loginMfaService.deliveryDto));

      this.mfaLoginForm.setValue({
        emailId: this.formatEmail(this.loginMfaService.deliveryDto.deliveryTypeDto.email),
        mobileNum: this.formatMobileNo(this.loginMfaService.deliveryDto?.deliveryTypeDto.mobile),
        selectedCountryCode: this.loginMfaService.deliveryDto.deliveryTypeDto.countryCode,
        deliveryType: this.loginMfaService.deliveryDto?.deliveryTypeDto.deliveryType.toLowerCase() === this.deliveryModeEmail.toLowerCase() ? this.deliveryModeEmail : this.deliveryModeMobile,
        mfaPasscode: null
      });
    }
    //If the MFA is called or refreshed directly then reroute to Login page
    else {
      this.router.navigate(['/login']);
    }
  }

  /**
   * Submit for OTP
   */
  onNextClickHandler() {
    this.isSubmittedForOtp = true;

    // encrpting delivery details
    const key = this.rootScope.base64Key; // $rootScope.
    const iv = this.rootScope.iv;// $rootScope.

    const emaile = CryptoJS.AES.encrypt(this.loginMfaService.deliveryDto.deliveryTypeDto.email, key, { iv: iv });
    this.encryptedEmail = emaile.ciphertext.toString(CryptoJS.enc.Base64);

    const mobilee = CryptoJS.AES.encrypt(this.loginMfaService.deliveryDto?.deliveryTypeDto.mobile, key, { iv: iv });
    this.encryptedMobile = mobilee.ciphertext.toString(CryptoJS.enc.Base64);

    if (this.mfaLoginForm.value.deliveryType === this.deliveryModeEmail) {
      this.logger.info('MultiFactorAuthenticationComponent:  sent an OTP in Email.')
    }
    else if ((this.mfaLoginForm.value.deliveryType === this.deliveryModeMobile)) {
      this.logger.info('MultiFactorAuthenticationComponent:  sent an OTP to Mobile.')
    }

    this.requestPasscode();  
  }

  /**
   * Request passcode to selected delivery
   */
  requestPasscode() {
    const requestOTPData: RequestOTPData = {
      countryCode: this.loginMfaService.deliveryDto.deliveryTypeDto.countryCode,
      email: this.encryptedEmail,
      mobile: this.encryptedMobile,
      deliveryType: this.mfaLoginForm.value.deliveryType === this.deliveryModeEmail ? this.deliveryModeEmail : this.deliveryModeMobile
    };

    this.logger.info('MultiFactorAuthenticationComponent: OTPRequest ...', JSON.stringify(requestOTPData));
    this.validateOTPforMFA(requestOTPData);
  }

  /**
   * Cancel MFA request
   */
  onCancelClickHandler() {
    this.router.navigate(['/login']);
  }

  /**
   * Submit the OTP
   */
  onSubmitOtpHandler() {

    const submitOTPData: SubmitOTPData = {
      countryCode: this.loginMfaService.deliveryDto.deliveryTypeDto.countryCode,
      email: this.encryptedEmail,
      mobile: this.encryptedMobile,
      deliveryType: this.loginMfaService.deliveryDto?.deliveryTypeDto.deliveryType.toLowerCase() === this.deliveryModeEmail.toLowerCase() ? this.deliveryModeEmail : this.deliveryModeMobile,
      token: this.mfaLoginForm.value.mfaPasscode
    };
    this.validateOTPforMFA(submitOTPData);
  }

  /**
   * Request another passcode
   */
  onRequestCodeHandler() {
    this.mfaLoginForm.patchValue({
      mfaPasscode: null
    });

    this.requestPasscode();
  }

  /**
   * Format the last 4 digits for the telepohone #
   * @param inputValue 
   * @returns 
   */
  formatMobileNo(phoneNumber: string) {
    let format = phoneNumber;

    if(phoneNumber.length > 4)
    {
      return phoneNumber.substring(phoneNumber.length-4, phoneNumber.length);
    }
    return phoneNumber;
  }

  /**
   * Formats the email
   * @param email 
   * @returns 
   */
  formatEmail(email: string) {
    var splitted = email.split('@', 2)
    let format = splitted[0];
    let subformat = format.substring(0, 4);
    let xString: string = '';
    for (let i = 0; i < format.length; i++) {
      xString = xString.concat('*');
    }
    return subformat + xString + '@' + splitted[1];
  }

  /**
   * Call service to validate the passcode
   * @param requestOTPData 
   */
  private validateOTPforMFA(submitOTPData) {
    this.loadingService.startLoading();
    this.logger.info('MultiFactorAuthenticationComponent: calling OTP Verfication.. \n', submitOTPData);
    this.mfaServices.requestOTP(submitOTPData)
      .subscribe(responseData => {
        if (submitOTPData.token) {
          this.validated = true;
          this.validatedMessage = 'OTP is verified'
          this.userAuthenticationDetails.setTempUserObj(responseData);
          this.userAuthenticationDetails.setTempUserObjString(JSON.stringify(responseData));
          this.userDto = new UserDto(responseData);
          this.userAuthenticationDetails.setUserAuthDetails(this.userDto);
          this.logger.info("MultiFactorAuthenticationComponent:  userAuthenticationDetails set in MFA \n" + this.userAuthenticationDetails.getUserAuthDetails());
          this.router.navigate(['/home']);
        }
        // debugger;
        this.loadingService.stopLoading();
        this.logger.info('MultiFactorAuthenticationComponent:  Response of requestOTPforMFA(): \n' + JSON.stringify(responseData));
      }, error => {
        if (submitOTPData.token) {
          this.validationFailed = true;
          this.validatedMessage = error.error.statusDescription;
          this.logger.error(JSON.stringify(error));
          this.mfaLoginForm.patchValue({
            mfaPasscode: null
          });
        }
        this.loadingService.stopLoading();
        this.logger.error('ERROR: MultiFactorAuthenticationComponent requestOTPforMFA() responseData: error: \n' + JSON.stringify(error));
      });
  }

  /**
   * Remove validation error
   */
  mfaBlurHandler(){
    this.validationFailed = false;
  }
}