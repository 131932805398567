import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { UserAuthenticationDetails } from '../../feature-modules/login/user.authentication.details';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private authService: UserAuthenticationDetails,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    return next.handle(request)
      .pipe(
        catchError((err: any) => {
        
        if (err.status === 401 || err.status === 403) {
          this.router.navigate(['/login']);
          location.reload(true);
        }
        return throwError(err);
      })
      )
    }
}

export const ErrorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorInterceptor,
  multi: true,
};