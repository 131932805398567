<div>
	<div class="px-mt-3 required-warning">
		Asterisk items (<span>*</span>) are required to proceed.
	</div>
	<div class="px-mt-3">
		<form name="myAccountForm" [(formGroup)]="myAccountForm">
			<div class="px-mt-3">
				<div *ngIf="showMessage">
					<div *ngIf="!isSuccess" class="dts-clr-error">
						<phx-message type="danger" [dismissible]="true" [(showMessage)]="showMessage">
							{{ errMessage }}
						</phx-message>
					</div>
					<div *ngIf="isSuccess">
						<phx-message type="success" [dismissible]="true" [(showMessage)]="showMessage">
							User details saved successfully
						</phx-message>
					</div>
				</div>
			</div>
			<div class="addspace">
				<label for="accName">User ID</label>
				<input phxInput formControlName="accName" id="accName" name="accName" readonly required />
			</div>
			<div class="addspace">
				<label for="alias">Alias</label>
				<input phxInput type="text" id="alias" formControlName="alias" readonly required name="alias" />
			</div>
			<div class="addspace">
				<label for="compName">Company name</label>
				<input phxInput type="text" id="compName" formControlName="compName" readonly required
					name="compName" />
			</div>
			<div>
				<label for="firstName" class="required">First name</label>
				<input phxInput formControlName="firstName" id="firstName" name="firstName" required />
				<small class="px-form-text px-text-danger">
					<phx-error-control formControlName="firstName" id="firstNameError"
						[errors]="{ required: 'First Name is required.' }"></phx-error-control>
				</small>
			</div>

			<div>
				<label for="lastName" class="required">Last name</label>
				<input phxInput formControlName="lastName" id="lastName" name="lastName" required />
				<small class="px-form-text px-text-danger">
					<phx-error-control formControlName="lastName" id="lastNameError"
						[errors]="{ required: 'Last Name is required.' }"></phx-error-control>
				</small>
			</div>
			<div class="email">
				<div>
					<label for="email" class="required">Email</label>
					<input phxInput type="email" [type]="showEmail ? 'text': 'password'" id="email" name="email"
						formControlName="email" required />
					<small class="px-form-text px-text-danger">
						<phx-error-control formControlName="email" id="emailError"
							[errors]="{ required: 'Email is required.', email: 'Email is invalid' }">
						</phx-error-control>
					</small>
				</div>
				<div>
					<i [class]="showEmail ? 'pxi px-eye': 'pxi px-eye-slash' " class="iconDiv" (click)="toggleShowEmail()"></i>
				</div>
			</div>
			<div class="phoneNumber">
				<div>
					<legend id="countryCodeLabel" class="required labelContent">Country code</legend>
					<phx-dropdown id="countryCode" ariaLabelledBy="countryCodeLabel" [options]="codeList" placeholder="All" [required]="true"
						name="countryCode" formControlName="countryCode" style="width: 320px;" [maxHeight]="200">
					</phx-dropdown>
					<small class="px-form-text px-text-danger">
						<phx-error-control formControlName="countryCode" id="codeError"
							[errors]="{ required: 'Country code is required.' }"></phx-error-control>
					</small>
				</div>
				<div>
					<label for="mobileNum" class="required">Phone number</label>
					<input phxInput id="mobileNum" name="mobileNum" formControlName="mobileNum"
						[type]="showMobile ? 'text': 'password'" required />
					<small class="px-form-text px-text-danger">
						<phx-error-control formControlName="mobileNum" id="mobileError"
							[errors]="{ required: 'Phone Number is required.', pattern:'Phone number is invalid' }">
						</phx-error-control>
					</small>
				</div>
				<div>
					<i [class]="showMobile ? 'pxi px-eye': 'pxi px-eye-slash' " class="iconDiv" (click)="toggleShowMobile()"></i>
				</div>
			</div>

			<div class="px-mt-3" *ngIf="showHideButton">
				<button type="submit" phxButton="primary" class="px-mr-2" (click)="updateAccountHandler()"
					[disabled]="!(myAccountForm.valid && myAccountForm.dirty)" title="Save">Save</button>
				<button phxButton="secondary" [disabled]="!myAccountForm.dirty" (click)="cancelChangesHandler()" title="Cancel">Cancel</button>
			</div>

		</form>
	</div>
</div>