<section class="px-container">
    <div class="section-title">
        <h1>Channels</h1>
    </div>
    <div class="px-row px-pt-2 px-mb-2">
        <div class="px-col-12">
            <phx-message>These are the channels for this account. If you have any questions, please contact us.
            </phx-message>
        </div>
    </div>
    <div>
        <h3>Incoming to the bank</h3>
        <div *ngFor="let inChannel of inboundChannels">{{inChannel.destination}}</div>
        <div>
            <div [hidden]="!errorInbound">
                <span><i class="fa fa-minus-circle" aria-hidden="true"></i></span>
                <span>{{errorMsgInbound}}</span>
            </div>
        </div>
        <h3 class="px-mt-4">Outgoing from the bank</h3>
        <div *ngFor="let outChannel of outboundChannels">{{outChannel.source}}</div>
        <div>
            <div class="dts-clr-error _text-14" [hidden]="!errorOutbound">
                <span><i class="fa fa-minus-circle" aria-hidden="true"></i></span>
                <span>{{errorMsgOutbound}}</span>
            </div>
        </div>
    </div>
</section>