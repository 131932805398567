import { Component, OnInit, ViewChild } from '@angular/core';
import { Logger } from '@phoenix/ui/common';
import { ActionTypes, DataTableComponent, DataTableEventData, DataTableOptions, ExternalEventInfo } from '@phoenix/ui/datatable';
import { LoadingScreenService } from '../../core/services/loading-screen.service';
import { FeatureModuleServices } from '../feature.module.services';
import { UserAuthenticationDetails } from '../login/user.authentication.details';
import { CertInfoDetail } from '../models/cert-info-detail';
import { CertInfo } from '../models/cert-info-record';
import { CommonService } from '../../core/services/common.service';
import { ServerProtocolService } from '../../core/services/server-protocol.service';

@Component({
  selector: 'dts-server-protocol',
  templateUrl: './server-protocol.component.html',
  styleUrls: ['./server-protocol.component.scss'],
})
export class ServerProtocolComponent implements OnInit {
  [x: string]: any;

  @ViewChild('tblCert') gridCert: DataTableComponent;
  @ViewChild('tblOptions') gridOptions: DataTableComponent;

  breadcrumbItems = [
    { label: 'Home', link: '/home' },
    { label: 'Server protocol' }
  ];

  openDropDown = false;
  protocolList: { label: string; value: string; actualVal: string }[] = [
      { label: 'SSH SFTP', value: 'SSH SFTP', actualVal: 'SSH SFTP            ' },
      { label: 'FTP SSL', value: 'FTP SSL', actualVal: 'FTP SSL             ' },
      { label: 'AS2', value: 'AS2', actualVal: 'AS2                 ' },
      { label: 'Connect Direct', value: 'CD NDM', actualVal: 'CD NDM              ' },
      { label: 'HTTPS (B2BI Portal)', value: 'HTTPS', actualVal: 'HTTPS               ' },
    ];
  alertMessage: string = "No results to display.";
  protocolSelected: string;
  isRespSuccess: boolean;
  errMessage: string;
  isDataExists: boolean;
  responseData;
  certInfo: CertInfo[];
  paramReceived: any;
  selectedProtocol = '';
  selectedProtocolName = '';
  certInfoData: { dnsNames: string[]; ipAddresses: string[]; portNumber: string; protocolReferenceInfo: string };
  responseList: { label: string; value: string }[];
  certs: any[];

  certificateInfo: CertInfo;
  certificateInfoDetails: CertInfoDetail[];
  certInfoDetails: CertInfoDetail;
  keyGridOptions: DataTableOptions;
  certGridOptions: DataTableOptions;

  constructor(
    private getBankCertificatesService: ServerProtocolService,
    private userAuthenticationDetails: UserAuthenticationDetails,
    private bankKeysService: ServerProtocolService,
    private commonService: CommonService,
    private loadingService: LoadingScreenService,
    private featureServices: FeatureModuleServices,
    private logger: Logger
  ) { this.configureGridOptions(); }

  ngOnInit(): void {
    this.userAuthenticationDetails.breadcrumbEmitter.emit(this.breadcrumbItems);

    this.loadingService.startLoading();
    this.getBankCertificatesService.getBankCertificatesInfo().subscribe(
      (responseData) => {
        this.loadingService.stopLoading();
        this.certificateInfo = new CertInfo(responseData);
        this.certificateInfoDetails = this.certificateInfo.responseData;
      },
      (error) => {
        this.loadingService.stopLoading();
        this.logger.error('error of protocolInfo', JSON.stringify(error));
      }
    );

    this.errMessage = 'errMessage: Some Error occured ';
    this.isDataExists = false;
    this.isRespSuccess = true;
  }

  /**
   * Server protocol change event
   * @param selectedValue 
   */
  onProtocolChangeHandler(selectedValue: any | null) {
    this.protocolSelected = selectedValue;
    let eventInfo: ExternalEventInfo = {
      keepSorting: true,
      resetData: true
    };

    if(this.protocolSelected && this.protocolSelected === 'SSH SFTP')
      this.gridOptions.api.refresh({ info: eventInfo});
    else
      this.gridCert.api.refresh({ info: eventInfo});
    
  }

  /**
   * Data is selected to be downloaded
   * @param row 
   */
  fileDownloadHandler(row) {
    // this.showLoader = true;
    const keyId = row.key_id;
    this.bankKeysService.certDownload(keyId).subscribe(
      (responseData) => {
        const respon: any = responseData;
        const data = respon.responseData;
        const blob = new Blob([data], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const fileName = row.key_id + '.txt';
        link.download = fileName;
        link.click();
        const ieVersion = this.commonService.isIE();
        if (ieVersion) {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
          }
        }
        const ffVersion = this.commonService.isFireFox();
        if (ffVersion) {
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      },
      (error) => {
        this.logger.error('Error of Keys ID', JSON.stringify(error));
      }
    );
  }

  /**
   * Returns the selected protocol data
   * @param events 
   */
  getSelectedProtocolData(events) {
    if(!this.protocolSelected) return;

    this.logger.debug('getSelectedProtocolData Protocol to be implemented!!' + this.protocolSelected);
    this.loadingService.startLoading();
    const selectedProtocolObj = this.protocolList.find((protocolL) => protocolL.value === this.protocolSelected);
    this.selectedProtocolName = selectedProtocolObj.value;
    this.featureServices.requestBankKeys(this.protocolSelected).subscribe(
      (responseData) => {
        this.logger.debug('responseData of SelectedProtocol', responseData);
        this.loadingService.stopLoading();
        this.certs = responseData;
        
        if (events)
          this.onGridHandlerFn(events);
      },
      (error) => {
        this.loadingService.stopLoading();
        this.logger.debug('error of SelectedProtocol', JSON.stringify(error));
      }
    );

    if (this.certificateInfoDetails !== undefined || this.certificateInfoDetails !== null) {
      this.logger.debug('certInfoDetail=>', JSON.stringify(this.certificateInfoDetails));
      this.certInfoDetails = this.certificateInfoDetails?.find(
        (certInfoDetail) => certInfoDetail.certificateName.trim() === this.selectedProtocolName.trim()
      );
      this.certInfoData = {
        dnsNames: this.certInfoDetails?.dnsNames,
        ipAddresses: this.certInfoDetails?.ipAddresses,
        portNumber: this.certInfoDetails?.portNumber,
        protocolReferenceInfo: this.certInfoDetails?.protocolReferenceInfo,
      };
      this.logger.debug('responseData of SelectedProtocol certInfoData', this.certInfoData);
    }
  }

  /**
   * Setup grid options
   */
  configureGridOptions() {
    /**
     * Setup key grid options
     */
    this.keyGridOptions = {
      smartView: {
        alertMessage: this.alertMessage,
        pagination: false,
        handleEventsInternally: true,
      },
      columnDefs: [
        {
          field: 'key_id',
          name: 'Key name',
          filter: { enabled: false }
        },
        {
          field: 'key_type',
          name: 'Key info',
          filter: { enabled: false }
        },
        {
          field: 'protocol',
          name: 'Key type',
          filter: { enabled: false }
        },
        {
          field: 'validFrom',
          name: 'Valid from',
          filter: { enabled: false },
          width: '200px'
        },
        {
          field: 'validTo',
          name: 'Valid to',
          filter: { enabled: false },
          width: '200px'
        },
        {
           field: 'fileDownload', name: ' ', filter: { enabled: false }, sort: { enabled: false },
           width: '50px'
        }
      ],
      data: []
    };

    /**
     * Setup Cert grid options
     */
    this.certGridOptions = {
      smartView: {
        alertMessage: this.alertMessage,
        pagination: false,
        handleEventsInternally: true,
      },
      columnDefs: [
        {
          field: 'key_id',
          name: 'Certificate name',
          filter: { enabled: false }
        },
        {
          field: 'key_type',
          name: 'Certificate info',
          filter: { enabled: false }
        },
        {
          field: 'validFrom',
          name: 'Valid from',
          filter: { enabled: false },
          width: '200px',
          sort: {
            sortFunction: (a, b): any => {
              if (!a && !b) {
                return 0;
              } else if (!b) {
                return 1;
              } else if(typeof a === 'string' && typeof b === 'string'){
                  let test = a.substr(2,1);

                  let date1 = new Date(a);
                  let date2 = new Date(b);
                  if(test == '-' || test == '/'){
                    return this.calcuateDiff(date1, date2);
                  }

              } else {
                const result = a.length - b.length;
                if (result !== 0) {
                  return result;
                } else {
                  return a.localeCompare(b);
                }
              }
            }
          }
        },
        {
          field: 'validTo',
          name: 'Valid to',
          filter: { enabled: false },
          width: '200px',
          sort: {
            sortFunction: (a, b): any => {
              if (!a && !b) {
                return 0;
              } else if (!b) {
                return 1;
              } else if(typeof a === 'string' && typeof b === 'string'){
                  let test = a.substr(2,1);

                  let date1 = new Date(a);
                  let date2 = new Date(b);
                  if(test == '-' || test == '/'){
                    return this.calcuateDiff(date1, date2);
                  }

              } else {
                const result = a.length - b.length;
                if (result !== 0) {
                  return result;
                } else {
                  return a.localeCompare(b);
                }
              }
            }
          }
        },
        {
          field: 'fileDownload', name: ' ', filter: { enabled: false }, sort: { enabled: false },
          width: '50px'
        }
      ],
      data: []
    };

  }

  private calcuateDiff(date1, date2){
    let days = Math.floor((date1 - date2)/1000/60/60/24);

    return days;
  }

  /**
   * Handle PHX grid internal events
   * @param eventData Grid events
   * @returns 
   */
  handleEvents(eventData: DataTableEventData): void {
    const events = eventData.events;
    if (!events) return;
    if (events.length >= 2 && this.protocolSelected) {
      this.getSelectedProtocolData(events);
    }
  }

  /**
   * Rebind the grid data
   * @param events Grid events
   */
  onGridHandlerFn(events: any) {

    const loadEvent = events.find((event) => event.type === ActionTypes.LOAD_DATA);

    if (loadEvent) loadEvent.result = { data: this.certs };
    
    if(this.protocolSelected && this.protocolSelected === 'SSH SFTP')
      this.gridOptions.api.handleResultEvents(events);
    else
      this.gridCert.api.handleResultEvents(events);
  }
}