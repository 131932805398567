<ng-template #successMsgHeader>
  <h2>Upload completed</h2>
</ng-template>
<ng-template #successMsg>
  <div *ngIf="fileUploadResponse">
    <div class="successDiv">
      <i class="pxi px-check-circle px-10x"></i> Success
    </div>
    <p>
      If you subscribed to transmission status notifications, you will also receive an email or file notification
      advising of you transmission success or failure.
    </p>
  </div>
  <div *ngIf="quotaErrorResponse && !fileUploadResponse">
    <div class="failureDiv">
      <i class="pxi px-minus-circle px-10x"></i> Error
    </div>
    <p>
      The document failed to upload. Please try the upload again.
    </p>
    <p>
      If the problem persists. Please contact support.
    </p>
    </div>

  <div *ngIf="!quotaErrorResponse && !fileUploadResponse">
    <div class="failureDiv">
      <i class="pxi px-minus-circle px-10x"></i> Error
    </div>
    <p>
      {{quotaErrorResponseMsg}}
    </p>
  </div>
</ng-template>
<ng-template #footerSuccess>
  <button phxButton (click)="clearFile()" role="button" title="Close">Close</button>
</ng-template>
<ng-template #warningHeader>
  <h2>Warning</h2>
</ng-template>

<ng-template #warningBody>
  <div class="successDiv">
    <i class="pxi px-check-circle px-10x"></i> Only one file can be uploaded at a time.
  </div>
  <p>
    More than one file was selected.

    Do you want to replace the previous file with the newly selected file?
  </p>
</ng-template>
<ng-template #warningFooter>
  <button phxButton type="button" (click)="replaceFileHandler()" title="Replace the new file">Replace the new
    file</button>
  <button phxButton="link" type="button" (click)="previousFileHandler()" title="Keep the previous file">Keep the
    previous file</button>
</ng-template>
<section class="px-container">
  <div class="section-title">
    <h1>Send</h1>
  </div>
  <div class="page-content">
    <div>Select a mailbox and upload a file to proceed.</div>
    <div class="required-warning">Asterisk items (<span>*</span>) are required to proceed.</div>
    <form name="sendForm" no-validate>
      <div class="mailbox">
        <div>
          <legend id="serverProtocolLabel" class="required labelContent" for="serverProtocol">Select mailbox</legend>
          <phx-dropdown aria-labelledby="serverProtocolLabel" [required]="true" id="serverProtocol" class="dropdown" [(ngModel)]="mailboxSelected" name="example10"
            (ngModelChange)="onModelChangeHandler($event)" [options]="mailboxDisplayList"
            aria-placeholder="Select mailbox" placeholder="Select mailbox"
            [maxHeight]="mailboxDisplayList.length > 5? 200: mailboxDisplayList.length * 40">
          </phx-dropdown>
        </div>
        <div class="iconDiv">
          <i class="pxi px-info-circle info" tabindex="0"
            phxTooltip="If you do not have any dropdown options available, your file will be sent to its default delivery channel."
            alt="information-icon"></i>
        </div>
      </div>
      <div *ngIf="dropdownError">
        <div class="fileNameDiv">
          <i class="pxi px-minus-circle"></i> {{dropdownErrorMsg}}
        </div>
      </div>
      <div class="px-mt-3 filedrag">
        <div>
          <legend class="required labelContent" for="uploadId">
            File:
          </legend>

          <div class="px-row">
            <div class="px-col-12">
              <phx-filedragdrop [options]="fileOptions" aria-labelledby="uploadId" ariaDescribedBy="filedragdrop1label"
                [(files)]="fileList" (filesChange)="fileChangeHandler($event)" (delete)="removeFileHandler($event)"
                (filesDrop)="fileChangeHandler($event)"  [id]="fileUploadId">
              </phx-filedragdrop>
              <i class="pxi px-info-circle info" style="margin-top: 30px;vertical-align:top;margin-left: 10px;"
                tabindex="0" phxTooltip="Select a single file to upload." alt="information-icon"></i>
            </div>
            <div *ngIf="newfileUploadError">
              <div class="fileUploadErrorDiv">
                <i class="pxi px-minus-circle"></i> {{fileUploadErrorMsg}}
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="px-mt-3">
        <form>
          <div class="renameDiv">
            <div>
              <label for="newFileName">
                File rename/Batch ID:
              </label>
              <input phxInput type="text" id="newFileName" placeholder="" name="newFileName" [(ngModel)]="newFileName"
                (ngModelChange)="onNewFileNameChange()" style="width: 40em" />
            </div>
            <div class="iconDiv"><i class="pxi px-info-circle info"
                phxTooltip="You can use this option to rename a file which is sent to B2Bi system or Place a BatchID. It will modify the file to what you put in this section."
                tabindex="0" alt="information-icon"></i>
            </div>
          </div>
          <div *ngIf="newfileNameError">
            <div class="fileNameDiv">
              <i class="pxi px-minus-circle"></i> {{fileNameErrorMsg}}
            </div>
          </div>
        </form>
      </div>
      <div class="upload">
        <button class="px-mr-6" type="button" phxButton (click)="uploadFileHandler()" title="Upload"
          [disabled]="mailboxSelected === undefined || selectedFiles.length <= 0 || fileHasError === true || newfileNameError === true || newfileUploadError === true">
          Upload
        </button>
      </div>
      <div class="gridTitle">
        <div>
          <h2>Recent uploads</h2>
        </div>
        <div class="refreshButton">
          <button phxButton="secondary" type="button" value="Refresh" style="margin-left: 300px;"
            (click)="reloadRecentFileUploads()" title="Refresh">Refresh</button>
        </div>
      </div>
      <div style="margin-bottom: 24px;">
        <a href="javascript:void(0)" (click)="goToReports()">View additional
          transmission reports</a>
      </div>
      <div *ngIf="hasRecentUploadData && !recentUploadsErrMsg">
        <phx-datatable [options]="optionsScroll" id="tblTransmissions" #tblTransmissions
          (onTableEvents)="handleEvents($event)">
          <ng-template phxTemplate="column" phxField="fileName" phxType="row" let-rowData>
            <span [phxTooltip]="rowData.fileName" appendTo="target">
              {{rowData.fileName}}
            </span>

          </ng-template>

          <ng-template phxTemplate="column" phxField="modifiedFileName" phxType="row" let-rowData>
            <span [phxTooltip]="rowData.modifiedFileName" appendTo="target">
              {{rowData.modifiedFileName}}
            </span>

          </ng-template>

          <ng-template phxTemplate="column" phxField="messageId" phxType="label" let-column>
            <span phxTooltip="Message ID" class="px-mr-1" tooltipPosition="bottom">
              Message ID
            </span>
          </ng-template>

          <ng-template phxTemplate="column" phxField="businessUnit" phxType="label" let-column>
            <span phxTooltip="Business unit" class="px-mr-1" tooltipPosition="bottom">
              {{ isInternalApp ? "Trading partner" : "Business unit" }}
            </span>
          </ng-template>

          <ng-template phxTemplate="column" phxField="status" phxType="row" let-rowData>
            <span [phxTooltip]="rowData.status" class="px-mr-1" tooltipPosition="bottom">
              {{rowData.status}}
            </span>

          </ng-template>

          <ng-template phxTemplate="column" phxField="fileSize" phxType="label" let-column>
            <span phxTooltip="File Size" class="px-mr-1" tooltipPosition="bottom">
              Size
            </span>
          </ng-template>

          <ng-template phxTemplate="column" phxField="fileSize" phxType="row" let-rowData>
            <span [phxTooltip]="rowData.fileSize| formatFileSize: false" class="px-mr-1" tooltipPosition="bottom">
              {{rowData.fileSize | formatFileSize: false}}
            </span>

          </ng-template>

          <ng-template phxTemplate="column" phxField="receiptTimestamp" phxType="label" let-column>
            Received
            <span phxTooltip="Date processed by the bank" class="px-mr-1" tooltipPosition="bottom" tabindex="0">
              <i class="pxi px-info-circle info tooltip-color" aria-hidden="true" alt="information-icon"></i>
            </span>
          </ng-template>
          <ng-template phxTemplate="column" phxField="receiptTimestamp" phxType="row" let-rowData>
            <span [phxTooltip]="rowData.receiptTimestamp" tooltipPosition="bottom">
              {{ rowData.receiptTimestamp | date: 'MMM dd, yyyy hh:mm:ss a' }}
            </span>

          </ng-template>

          <ng-template phxTemplate="column" phxField="sendTimestamp" phxType="label" let-column>
            Sent
            <span phxTooltip="Date sent by the client" class="px-mr-1" tooltipPosition="bottom" tabindex="0">
              <i class="pxi px-info-circle info tooltip-color" aria-hidden="true" alt="information-icon"></i>
            </span>
          </ng-template>
          <ng-template phxTemplate="column" phxField="sendTimestamp" phxType="row" let-rowData>
            <span [phxTooltip]="rowData.sendTimestamp" tooltipPosition="bottom">
              {{ rowData.sendTimestamp | date: 'MMM dd, yyyy hh:mm:ss a' }}
            </span>

          </ng-template>
        </phx-datatable>
      </div>
      <!-- *ngIf="sdFilesCtrl.hasRecentUploadData && !sdFilesCtrl.recentUploadsErrMsg" > -->

      <div class="isc-col-md-12 _npl _pt-10" *ngIf="recentUploadsErrMsg">
        <div class="dts-clr-error">
          <span><i class="fa fa-minus-circle" aria-hidden="true"></i></span>&nbsp;
          <span>{{ recentUploadsErrMsg }}</span>
        </div>
      </div>

    </form>
  </div>
  <!--<div class="loadingWrapper" [hidden]="!showLoader">
  </div>-->
</section>
<phx-back-to-top></phx-back-to-top>