<div class="px-container">
  <div class="section-title">
    <h1>Transmissions</h1>
    <div>
      <button phxButton="secondary" (click)="exportToExcelHandler()" title="Click to export" *ngIf="isDataExists"><i
          _ngcontent-uty-c154="" class="pxi px-download"></i> Export list</button>
      <button class="px-mr-3" phxButton="secondary" title="Please select at least one sent file to resend."
        (click)="replayFilesHandler()" *ngIf="showHideReplay" [disabled]="selectedfileIds.length === 0">Resend selected
        files</button>
    </div>
  </div>
  <div class="px-row px-pt-2" *ngIf="error.isError">
    <div class="px-col-12">
      <phx-message type="danger" [dismissible]="false">
        {{error.errorMessage}}
      </phx-message>
    </div>
  </div>
  <div class="filters">
    <div class="datePicker">
      <legend id="retrieveFromDateLabel" class="labelContent" for="retrieveFromDate"> From date </legend>
      <phx-datepicker calendarId='datePicker1' aria-labelledby="retrieveFromDateLabel" [options]="dateRangeOptions">
        <input id="retrieveFromDate" phxDate [(ngModel)]="retrieveFromDate" [format]="date1Format"
          (focus)="validateDateTime()" />
      </phx-datepicker>
    </div>
    <div class="inputWithIcon">
      <legend id="retrieveFromTimeLabel" class="labelContent" for="retrieveFromTime"> From time</legend>
      <div class="px-input-group time-picker-control">
        <input phxInput id="retrieveFromTime" aria-labelledby="retrieveFromTimeLabel" readonly
          [(ngModel)]="retrieveFromTime" aria-label="HH:MM:SS" placeholder="HH:MM:SS" />
          <div class="px-input-group-append px-icon-addon">
            <button type="button" tabindex="0" 
              (click)="openFrom = !openFrom;setDateTimeValue('From')" 
              aria-label="Toggle timepicker"
              [phx-popup]="phxPopupFromTime"
              [(popupOpen)]="openFrom"
              [hasBackdrop]="true"
              (keydown.enter)="openFrom = !openFrom;setDateTimeValue('From')"> 
              <i class="pxi px-clock"></i>
            </button>
          </div>
        </div>
    </div>
    <div class="borderDiv">
    </div>
    <div class="datePicker">
      <legend id="retrieveToDateLabel" class="labelContent" for="retrieveToDate"> To date</legend>
      <phx-datepicker aria-labelledby="retrieveToDateLabel" calendarId='datePicker2' [options]="dateRangeOptions">
        <input id="retrieveToDate" phxDate [(ngModel)]="retrieveToDate" [format]="date1Format"
          (focus)="validateDateTime()" />
      </phx-datepicker>
    </div>
    <div class="inputWithIcon">
      <legend id="retrieveToTimeLabel" class="labelContent" for="retrieveToTime"> To time</legend>
      <div class="px-input-group time-picker-control">
        <input phxInput id="retrieveToTime" aria-labelledby="retrieveToTimeLabel" readonly [(ngModel)]="retrieveToTime"
          aria-label="HH:MM:SS" placeholder="HH:MM:SS" maxlength="200" />        
        <div class="px-input-group-append px-icon-addon"><button type="button" tabindex="0"
          (click)="openTo = !openTo;setDateTimeValue('To')"
          (keydown.enter)="openTo = !openTo;setDateTimeValue('To')"
          [phx-popup]="phxPopupToTime"
          [(popupOpen)]="openTo"
          [hasBackdrop]="true" aria-label="Toggle timepicker">  
          <i class="pxi px-clock"></i>
        </button></div>
      </div>
    </div>
    <div class="button">
      <button class="px-mr-3" type="button" phxButton (click)="searchTransmissionHandler()" [disabled]="error.isError"
        title="Search">Search</button>
      <button class="px-mr-3" type="button" phxButton="link" (click)="ngOnInit()" title="Reset">Reset</button>
    </div>
  </div>
  <div [style.display]="isDataExists ? 'inline': 'none'">
    <phx-datatable [options]="optionsScroll" id="tblTransmissions" #tblTransmissions
      (onTableEvents)="handleEvents($event)" (onInternalEvents)="internalEventHandler($event)"
      currentPageReportTemplate="Listing {first} - {last} of {total} results" [selectionType]="selection">
      <ng-template phxTemplate="column" phxField="fileName" phxType="row" let-rowData>
        <span [phxTooltip]="rowData.fileName" appendTo="target">
          {{rowData.fileName}}
        </span>
      </ng-template>
      <ng-template phxTemplate="column" phxField="modifiedFileName" phxType="row" let-rowData>
        <span [phxTooltip]="rowData.modifiedFileName" appendTo="target">
          {{rowData.modifiedFileName}}
        </span>
      </ng-template>
      <ng-template phxTemplate="column" phxField="messageId" phxType="label" let-column>
        Message ID
      </ng-template>
      <ng-template phxTemplate="column" phxField="businessUnit" phxType="label" let-column>
        {{ isInternalApp ? "Trading partner" : "Business unit" }}
      </ng-template>
      <ng-template phxTemplate="column" phxField="status" phxType="row" let-rowData>
        <span [phxTooltip]="rowData.status" class="px-mr-1" tooltipPosition="bottom" tabindex="0">
          {{rowData.status}}
        </span>
      </ng-template>
      <ng-template phxTemplate="column" phxField="fileSize" phxType="label" let-column>
        Size
      </ng-template>
      <ng-template phxTemplate="column" phxField="fileSize" phxType="row" let-rowData>
        <span [phxTooltip]="rowData.fileSize | formatFileSize: false" class="px-mr-1" tooltipPosition="bottom"
          tabindex="0">
          {{rowData.fileSize | formatFileSize: false}}
        </span>
      </ng-template>
      <ng-template phxTemplate="column" phxField="receiptTimestamp" phxType="label" let-column>
        Received
        <span phxTooltip="Date processed by the bank" class="px-mr-1" tooltipPosition="bottom" tabindex="0">
          <i class="pxi px-info-circle" style="vertical-align: middle;color:#009CDE;" aria-hidden="true"
            alt="information-icon"></i>
          <span class="px-sr-only">Date processed by the bank</span>
        </span>
      </ng-template>
      <ng-template phxTemplate="column" phxField="receiptTimestamp" phxType="row" let-rowData>
        <span [phxTooltip]="rowData.receiptTimestamp | date: 'MMM dd, yyyy hh:mm:ss a'" class="px-mr-1"
          tooltipPosition="bottom" tabindex="0">
          {{rowData.receiptTimestamp | date: 'MMM dd, yyyy hh:mm:ss a'}}
        </span>
      </ng-template>
      <ng-template phxTemplate="column" phxField="sendTimestamp" phxType="label" let-column>
        Sent
        <span phxTooltip="Date sent by the client" class="px-mr-1" tooltipPosition="bottom" tabindex="0">
          <i class="pxi px-info-circle" style="vertical-align: middle;color:#009CDE;" aria-hidden="true"
            alt="information-icon"></i>
          <span class="px-sr-only">Date sent by the client</span>
        </span>
      </ng-template>
      <ng-template phxTemplate="column" phxField="sendTimestamp" phxType="row" let-rowData>
        <span [phxTooltip]="rowData.sendTimestamp | date: 'MMM dd, yyyy hh:mm:ss a'" class="px-mr-1"
          tooltipPosition="bottom" tabindex="0">
          {{rowData.sendTimestamp | date: 'MMM dd, yyyy hh:mm:ss a'}}
        </span>
      </ng-template>
    </phx-datatable>
  </div>
  <div *ngIf="!isDataExists && errMessage" class="px-mt-3">
    <div>
      <phx-message type="warning" [(showMessage)]="errMessage">
        {{errMessage}}
      </phx-message>
    </div>
  </div>
  <div [hidden]="!showLoader">
    <div class="_grid-loading-container">
      <span class="_dts-loading-icon"><i class="fa-li fa fa-spinner fa-spin"></i></span>
    </div>
  </div>
</div>
<ng-template #headerTitle>
  <h2>Confirm resend</h2>
</ng-template>
<ng-template #headerTitleSuccess>
  <h2>{{responseMsg.header}}</h2>
</ng-template>
<ng-template #warningHeader>
  <h2>Warning</h2>
</ng-template>
<ng-template #footerSuccess>
  <button phxButton (click)="closeConfirmModalHandler()" type="button" title="Close">Close</button>
</ng-template>
<ng-template #successMsg>
  <div class="successDiv" *ngIf="responseMsg.header == 'Success'">
    <i class="pxi px-check-circle px-10x"></i> {{responseMsg.header}}
  </div>
  <div class="failureDiv" *ngIf="responseMsg.header == 'Failure'">
    <i class="pxi px-minus-circle px-10x"></i> {{responseMsg.header}}
  </div>
  <p>
    {{responseMsg.body}}
  </p>
</ng-template>
<ng-template #confirmMsg>
  <p>
    Are you sure you want to resend the selected files?
  </p>
</ng-template>
<ng-template #warningHeaderReplay>
  <h2 *ngIf="responseMsg.restReplay">Warning</h2>
  <h2 *ngIf="!responseMsg.restReplay">Error</h2>
</ng-template>
<ng-template #warningMsg>
  <div class="warningDiv">
    <i class="pxi px-exclamation-triangle px-10"></i>
    Some file(s) cannot be resent.
  </div>
  <div *ngIf="responseMsg.restReplay">
    The retention period has expired for the following files(s):
    <ul>
      <li *ngFor="let files of responseMsg.inEligibleFiles">
        {{ files }}
      </li>
    </ul>
    <div>
      Do you want to resend the eligible files?
    </div>
  </div>
</ng-template>
<ng-template #alertMsg>
  <div class="failureDiv">
    <i class="pxi px-minus-circle px-10"></i>
    File(s) cannot be resent.
  </div>
  <div *ngIf="responseMsg.noReplay">
    The retention period has expired for the following files(s):
    <ul>
      <li *ngFor="let files of responseMsg.inEligibleFiles">
        {{ files }}
      </li>
    </ul>
  </div>
</ng-template>
<ng-template #confirmFooter>
  <button phxButton role="button" *ngIf="!responseMsg.noReplay" (click)="submitReplayHandler()" title="Resend">
    Resend </button>&nbsp;
  <button phxButton="link" (click)="closeConfirmModalHandler()" role="button" title="Cancel">Cancel</button>
</ng-template>
<ng-template #phxPopupFromTime>
  <dts-time-picker [currentTime]="retrieveToTime" (timeValue)="getTimeHandler($event)"
    (cancelEvent)="hideTimePickerHandler($event)"></dts-time-picker>
</ng-template>
<ng-template #phxPopupToTime>
  <dts-time-picker [currentTime]="retrieveToTime" (timeValue)="getTimeHandler($event)"
    (cancelEvent)="hideTimePickerHandler($event)"></dts-time-picker>
</ng-template>