import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DchService } from '../../core/services/dch.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { UserAuthenticationDetails } from '../login/user.authentication.details';
import { environment } from 'projects/DtsUI/src/environments/environment';

@Component({
  selector: 'dts-quickstartroot',
  templateUrl: './quickstartroot.component.html',
  styleUrls: ['./quickstartroot.component.scss']//,
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuickstartRootComponent implements OnInit {
  currentAttachedLinks: any[]; /** Currently attached links to the header based on get content response */
  currentAttachedScripts: any[]; /** Currently attached scripts to the header based on get content response */
  contentBodyHtml: SafeHtml;
  pageReady: Boolean = false;
  quickStartNav: any = [];
  serverName: string = environment.dchDomainUrl;

  breadcrumbItems = [
    { label: 'Home', link: '/home' },
    { label: 'Platform documentation', link: '/informationCenter/platformDocumentation' },
    { label: 'B2Bi services' }
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dchService: DchService,    
    public userAuthenticationDetails: UserAuthenticationDetails,
    private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.setSideBar();
  }

  /**
   * Set side bar
   */
  setSideBar()
  {       
    this.quickStartNav = this.dchService.navigationLinks;

    this.route.params.subscribe(params => {
      
      if(!this.quickStartNav || !this.quickStartNav){
        this.router.navigate(['/home']);
        return null;
      }
      
      var identifier = params.infoPath ? params.infoPath.split("/")[2] : null;
      
      var navigationLink = identifier ? 
                            this.quickStartNav.quickStart.filter((a: any) => a.quickStart.route.toLowerCase() === identifier.toLowerCase())
                            : this.quickStartNav.quickStart;
      
      if(navigationLink && navigationLink.length > 0) {
        this.setPageContent(navigationLink[0].quickStart.article);
      }
      else {
        this.router.navigate(['/home']);        
      }
    });
  }
  
  /**
   * Get DCH content 
   */
  setPageContent(articlePath: string) {
     
    this.userAuthenticationDetails.breadcrumbEmitter.emit(this.breadcrumbItems);
    const headerElement = document.getElementsByTagName('head')[0];
    this.dchService.getCCHPageDetail(this.serverName + articlePath).subscribe((response: any) => {

    if(response.links && response.links.length > 0){
        this.currentAttachedLinks = [];
        
        response.links.forEach(linkDtls => {  
                      
            let node = document.createElement('link');
            node.href = this.serverName + linkDtls.href;
            node.rel = "stylesheet";

            this.currentAttachedLinks.push(node);
            headerElement.appendChild(node);
        });
    }
    this.currentAttachedScripts = [];
    
    if(response.scripts && response.scripts.length > 0){
        response.scripts.forEach(scriptDtl => {

            let script = document.createElement('script');
            script.src = this.serverName + scriptDtl.src;

            this.currentAttachedScripts.push(script);
            headerElement.appendChild(script);
        });
    }

    /*if(response.theme && response.theme.length > 0){
        response.theme.forEach(scriptDtl => {

            let script = document.createElement('script');
            script.src = this.serverName + scriptDtl.src;

            this.currentAttachedScripts.push(script);
            headerElement.appendChild(script);
        });
    }*/
    
    setTimeout (() => {
      if(response.bodyContent) this.contentBodyHtml = this.sanitizer.bypassSecurityTrustHtml(response.bodyContent);  
    }, 100);

    }, err => {
      console.log(err);
    });
  }
}