import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { apiUrls } from './app.urls.config';

import { environment } from 'projects/DtsUI/src/environments/environment';
import { AppHttpService } from '../core/services/app.http.service';
import { Logger } from '@phoenix/ui/common';

@Injectable()
export class FeatureModuleServices {

  // host: string;
  // TODO:  Gett the authorization token in HTTP Interceptor before calling these services
  private httpOptions = {
    headers: new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: 'Ag74CoqSebWUUu6e28eLortzvPJg8tBZn2fAJfsVBKY='

    })
  };

  constructor(private appHttpservice: AppHttpService,
    private httpClient: HttpClient,
    private logger: Logger) {
    // environment.host = environment.mockApiServer;
  }

  // HTTP GET Common  response/error handling
  doHttpGet(url, config, httpOptions) {

    return this.appHttpservice.doHttpGet(url, config, httpOptions)
      .pipe(
        map((data: any) => {
          this.logger.debug('http.get url: ' + url + '   response tap' + JSON.stringify(data));
          return data.responseData;
        }, (err: { status: string; }) => {
          this.logger.debug('Something went wrong!: error: ' + JSON.stringify(err));
          return err;
        }
        )
      );
  }


  // HTTP POST Common  response/error handling
  doHttpPost(url, req, httpOptions) {
    return this.appHttpservice.doHttpPost(url, req, httpOptions)
      .pipe(
        map((data: any) => {
          this.logger.debug('http.get url: ' + url + '   response tap' + JSON.stringify(data));
          return data.responseData;
        }, (err: { status: string; }) => {
          this.logger.debug('POST: Failed to POST http data - err.status: ' + err.status);
          this.logger.debug('Something went wrong!: error: ' + JSON.stringify(err));
          return err;
        })
      );
  }

  // HTTP POST Common  response/error handling
  doHttpPut(url, req, httpOptions) {
    return this.appHttpservice.doHttpPut(url, req, httpOptions)
      .pipe(
        map((data: any) => {
          this.logger.debug('http.get url: ' + url + '   response tap' + JSON.stringify(data));
          return data.responseData;
        }, (err: { status: string; }) => {
          this.logger.debug('PUT: Failed to PUT http data - err.status: ' + err.status);
          this.logger.debug('Something went wrong!: error: ' + JSON.stringify(err));
          return err;
        })
      );
  }

  uploadFile(requestData) {
    return this.uploadFileRequest(requestData)
  }
  uploadFileRequest(req) {
    const url: string = environment.host + apiUrls.uploadFile;
    const config: any = {
      ref: 'api/uploadFile'
    };
    return this.httpClient.post(url, req)
      .pipe(
        map((data: any) => {
          this.logger.debug('http.get url: ' + url + '   response tap' + JSON.stringify(data));
          return data.responseData;
        }, (err: { status: string; }) => {
          this.logger.debug('Failed to POST http data - err.status: ' + err.status);
          this.logger.debug('Something went wrong!: error: ' + JSON.stringify(err));
          return err;
        }
        )
      );
  }


  // loginService Login, Auth etc
  public loginService() {
    const isLoggedIn = true;
    // public getLoginStatus() { return isLoggedIn;};
    //   public setLoginStatus (status) { isLoggedIn = status;};
    return isLoggedIn;
  }

  // homeServices
  getUsageGraphData() {
    const url: string = environment.host + apiUrls.usage;
    const config: object = {
      ref: 'api/usage'
    };
    return this.doHttpGet(url, config, this.httpOptions);
  }

  // Approvers services
  public getApproverFileDirectory() {
    const url: string = environment.host + apiUrls.getApproverFileDirectories;
    const config: object = {
      ref: 'api/getApproverFileDirectories'
    };
    return this.doHttpGet(url, config, this.httpOptions);
  }

  getApproverMailBoxDetails(req) {
    const url: string = environment.host + apiUrls.getApproverMailBoxDetailsResults;
    const config: object = {
      ref: 'api/getRetrieveFilesMailBoxResults'
    };
    return this.doHttpPost(url, req, this.httpOptions);
  }
  public approveRejectFile(req) {
    const url: string = environment.host + apiUrls.approveRejectFile;
    const config: object = {
      ref: 'api/approveRejectFile'
    };
    return this.doHttpPost(url, req, this.httpOptions);
  }

  // Retreive services
  public retFileDownloadService(dirName, fileName) {
    const url: string = environment.host + apiUrls.retFileDownload + '?dirName=' + dirName + '&fileName=' + fileName;
    const config: any = {
      ref: 'api/retFileDownload',
      responseType: 'blob'
    };
    return this.doHttpGet(url, config, this.httpOptions);
  }

  public getRetrieveFileDirectoryService(): Observable<any> {
    const url: string = environment.host + apiUrls.getRetrieveFileDirectories;
    const config: object = {
      ref: 'api/getRetrieveFileDirectories'
    };
    return this.doHttpGet(url, config, this.httpOptions);
  }

  public getMailBoxDetailsService(data) {
    const url: string = environment.host + apiUrls.getRetrieveFilesMailBoxResults;
    const config: any = {
      ref: 'api/getRetrieveFilesMailBoxResults'
    };
    return this.doHttpPost(url, data, this.httpOptions);;
  }

  // Send files service
  public getLatestFileUploadsService() {
    const url: string = environment.host + apiUrls.fetchRecentTransmission;
    const config: object = {
      ref: 'api/recentTransmission'
    };
    return this.doHttpGet(url, config, this.httpOptions);
  }

  // mfa requestOTP
  public requestOTP(req): Observable<any> {
    const url: string = environment.host + apiUrls.requestOTP;
    const config: object = {
      ref: 'api/requestOTP'
    };
    return this.doHttpPost(url, req, this.httpOptions);
  }

    // requestBankKeys
    public requestBankKeys(req): Observable<any> {
      const url: string = environment.host + apiUrls.requestBankKeys + req;
      const config: object = {
        ref: 'api/requestBankKeys'
      };
      return this.doHttpGet(url, config, this.httpOptions);
    }


  public loadTransmissionReports(req) {
    const url: string = environment.host + apiUrls.getTransmissionReports;
    const config: object = {
      ref: 'api/getTransmissionReports'
    };
    return this.doHttpPost(url, req, this.httpOptions);
  }

  public replayTransmissionFiles(req) {
    const url: string = environment.host + apiUrls.replayTransmissionReports;
    const config: object = {
      ref: 'api/replayFile'
    };
    return this.appHttpservice.doHttpPost(url,req,this.httpOptions).pipe(
      map((data: any) => {
        return data;
      }, (err: { status: string; }) => {
        console.log('Something went wrong!: error: ' + JSON.stringify(err));
        return err;
      }
      )
    );
  }

  public getSendMailboxService() {
    const url: string = environment.host + apiUrls.getSendMailbox;
    const config: object = {
      ref: 'api/getSendMailbox'
    };
    return this.doHttpGet(url, config, this.httpOptions);
  }

  public getBankCertificatesInfo(prtcl: string) {
    const url: string = environment.host + apiUrls.requestBankKeys + prtcl;
    // var url = apiUrls.requestBankKeys + prtcl;
    const config: object = {
      ref: 'api/requestBankKeys/' + prtcl
    };

    return this.doHttpGet(url, config, this.httpOptions);
  }

  // Reports - All requests
  public loadAllRequests() {
    const url: string = environment.host + apiUrls.getAllRequests;
    // var url = apiUrls.requestBankKeys + prtcl;
    const config: object = {
      ref: 'api/getAllRequests'
    };

    return this.doHttpGet(url, config, this.httpOptions);
  }

  public addComment(req) {
    const url: string = environment.host + apiUrls.addComment;
    const config: object = {
      ref: 'api/addComment'
    };
    return this.doHttpPost(url, req, this.httpOptions);
  }

  public getRequestStatus(prtcl: string) {
    const url: string = environment.host + apiUrls.casestatus + prtcl;
    // var url = apiUrls.requestBankKeys + prtcl;
    const config: object = {
      ref: 'api/casestatus/' + prtcl
    };

    return this.doHttpGet(url, config, this.httpOptions);
  }

  loadSupportRequests() {
    const url: string = '';
    const config: object = {
      ref: 'api/getSupportRequestReports'
    };

    return this.doHttpGet(url, config, this.httpOptions);
  }

  loadThreeKeyAuditReports(req) {
    const url: string = environment.host + apiUrls.getThreeSKeyAuditReports;
    const config: object = {
      ref: 'api/getThreeSKeyAuditReports'
    };
    return this.doHttpPost(url, req, this.httpOptions);
  }

  cancelRequest(req) {
    const url: string = environment.host + apiUrls.cancelRequest + req.CASEID;
    const config: object = {
      ref: 'api/cancelRequest' + req.CASEID
    };
    return this.doHttpPut(url, req, this.httpOptions);
  }

  // Not sure if submitSupportRequest is supported anymore?
  sendSupportRequest(req) {
    // const url: string = environment.host + apiUrls.submitSupportRequest;
    // const config: object = {
    //   ref: 'api/submitSupportRequest',
    //   headers:{'Content-Type':undefined}
    // };
    // return this.doHttpPost(url, req, this.httpOptions);
  }

  //  Alerts
  getMessagesReq() {
    // Strange api for alerts. Check again
    // const url: string = apiUrls.apiGetAuthKeys;
    const url: string = apiUrls.apiAlerts;
    const config: object = {
      ref: 'api/apiAlerts'
    };
    return this.doHttpGet(url, config, this.httpOptions);
  }

}// End of class

// SAMPLE OLD CODE FOR REFERENCE.  TO BE DELETED
  // // Generic HTTP request response query functions
  // public doHttpGet(url: string, config: object, httpOptions: any) {

  //   this.http.get(url, this.httpOptions)
  //     .subscribe(data => {
  //       this.data = data;
  //       return data;
  //     }, err => {
  //       return err;
  //     });
  //   return { 'status': 'unknown http error' };
  // }

  // public doHttpPost(url: string, body: object, httpOptions: any) {

  //   return this.http.post(url, body, this.httpOptions)
  //     .subscribe(data => {
  //       this.data = data;
  //       // return data;
  //     }, err => {
  //       // return err;
  //     });

  // FUTURE: TODO: Hook up with Observable return data to propagate to UI
  // if (this.data.length) {
  //   this.source.next(this.data);
  //   return;
  // }

  // this.http.post(url, this.httpOptions).pipe(
  //   map(res => <any[]>res),
  //   tap(() => this.loading = false)
  // ).subscribe(data => {
  //   // this.data = data.map(item => this.readEvent(item));
  //   // this.data = data.map(item => this.readSchedEvent(item));
  //   // this.source.next(this.data);
  // });

  // }



