import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as CryptoJS from 'crypto-js';
import { LoadingScreenService } from '../../core/services/loading-screen.service';
import { UserDto } from '../models/user-dto';
import { AuthService } from '../../core/services/auth.service';
import { UserAuthenticationDetails } from './user.authentication.details';
import { environment } from 'projects/DtsUI/src/environments/environment';
import { LoginMfaService } from '../../core/services/login.mfa.service';
import { Logger } from '@phoenix/ui/common';
import { ContactUsModalComponent } from '../../shared/modals/contact-us-modal.component';
import { MfaWarningModalComponent } from '../../shared/modals/mfa-warning-modal.component';
import { DchService } from '../../core/services/dch.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SurveyComponent } from '../../shared/survey/survey.component';
import { StartupService } from '../../core/services/startup.service';

@Component({
  selector: 'dts-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @ViewChild(ContactUsModalComponent) contactUs: ContactUsModalComponent;
  @ViewChild(MfaWarningModalComponent) mfaWarningModal: MfaWarningModalComponent;
  @ViewChild(SurveyComponent) surveyModal: SurveyComponent;

  @ViewChild('surveyModalControl') surveyModalRef: SurveyComponent;
  // locale = bapAppLocaleService;
  onlineidlabel: string = 'onlineidlabel- TODO'; // loginCtrl.locale.translate('DtsUIApp.loginPage.onlineID');
  passcodelabel: string = 'passcodelabel- TODO'; // loginCtrl.locale.translate('DtsUIApp.loginPage.passcode');
  loginRegEx = '^[\\S]+$'; // Allow anything except white space

  onlineId = '';
  passcode = '';
  loginDisabled = this.startupService.checkBrowser();
  showMessage = false;
  rootScope: { base64Key: any; iv: any; isFirstTimeUser: any };
  isFirstTimeUser: boolean;
  userDto: UserDto;
  loginForm: FormGroup;
  announcements: any = [];
  mainContent: SafeHtml; //Content from DCH for Main page
  loginFailed: boolean = false;

  constructor(
    public router: Router,
    private authService: AuthService,
    private userAuthenticationDetails: UserAuthenticationDetails,
    private loginMfaService: LoginMfaService,
    private logger: Logger,
    private dchService: DchService,
    private loadingService: LoadingScreenService,
    private elRef: ElementRef,
    private sanitizer: DomSanitizer,
    public startupService: StartupService) {
  }

  ngOnInit(): void {
    this.rootScope = { base64Key: '', iv: '', isFirstTimeUser: false };
    this.rootScope.base64Key = CryptoJS.enc.Base64.parse(environment.rootScopeBase64Key);
    this.rootScope.iv = CryptoJS.enc.Base64.parse(environment.rootScopeIv);
    this.createFormGroup();

    this.getDynamicContentFromDCH();
  }

  /**
   * Show announcements
   */
  setAnnouncements(response: any) {      
    if (response && response.homePageContent && response.homePageContent.messages && response.homePageContent.messages.length > 0) {
      this.announcements = [];
        response.homePageContent.messages.forEach(announcement => {
                      
          if(announcement.maintenanceMessages.type && announcement.maintenanceMessages.message) {            
            this.announcements.push(announcement.maintenanceMessages);
          }
        }
      )
    }
  }

  /**
   * Sets the DCH data
   */
  getDynamicContentFromDCH() {
    this.dchService.getHomeContent().subscribe((response: any) => {

      if (response && response.homePageContent && response.homePageContent.mainContent) {
        this.mainContent = this.sanitizer.bypassSecurityTrustHtml(response.homePageContent.mainContent);

        let element = document.getElementById("additionalContent");
        element.innerHTML = response.homePageContent.maintenanceContent;

        let surveyElement = this.elRef.nativeElement.querySelectorAll('#aSurvey');
        if (surveyElement && surveyElement.length == 1)
          surveyElement[0].addEventListener('click', this.openSurveyModal.bind(this));
      }
      
      this.setAnnouncements(response);
    }, err => {
    });
  }

  /**
   * Open Survey modal
   */
  openSurveyModal() {
    this.surveyModal.openModal();
  }

  /**
   * Create login form
   */
  createFormGroup() {
    this.loginForm = new FormGroup({
      onlineId: new FormControl(this.onlineId, [Validators.required, Validators.pattern(this.loginRegEx)]),
      passcode: new FormControl(this.passcode, [Validators.required, Validators.pattern(this.loginRegEx)])
    });
  }

  /**
   * Login with provided user credentials
   */
  signInHandler() {
    this.loginFailed = false;

    if (this.loginForm.value.onlineId && this.loginForm.value.passcode) {
      const key = this.rootScope.base64Key; // $rootScope.
      const iv = this.rootScope.iv; // $rootScope.
      const encrypted = CryptoJS.AES.encrypt(this.loginForm.value.onlineId + ':' + this.loginForm.value.passcode, key, { iv: iv });
      const authdata = encrypted.ciphertext.toString(CryptoJS.enc.Base64);
      const reqObj = {
        onlineId: this.loginForm.value.onlineId,
        encryptedCredentials: authdata
      };
      this.logger.info('encrypted key: ' + encrypted + '\nauthdata:      ' + authdata);
      this.userAuthenticationDetails.setRequestObj(reqObj);
      
      // Make the HTTP service call to authenticate
      this.loadingService.startLoading();
      this.authService.authenticateUser(reqObj)
        .subscribe(response => {

          this.userDto = new UserDto(response);
          this.loadingService.stopLoading();
          this.loginForm.reset();
          
          if (this.userDto.mfa) {
            if (this.userDto.deliveryTypeDto !== null && this.userDto.deliveryTypeDto !== undefined) {
              this.logger.info('this.userDto.deliveryTypeDto: ' + this.userDto.deliveryTypeDto);

              this.loginMfaService.deliveryDto = this.userDto;
              this.router.navigate(['/multiFactorAuthentication']);
            } else {
              // Display modal window for MFA details not available
              this.openMfaDetailsErrorModal();
            }
          } else {

            this.userAuthenticationDetails.setTempUserObj(response);
            this.userAuthenticationDetails.setTempUserObjString(JSON.stringify(response));
            this.userAuthenticationDetails.setUserAuthDetails(this.userDto);
            this.logger.info('this.userDto: \n' + this.userAuthenticationDetails.getUserAuthDetails());

            if (this.userDto.profile) {
              this.logger.info('this.userDto.profile: ' + JSON.stringify(this.userDto.profile));
              this.userAuthenticationDetails.setUserAuthDetails(this.userDto);
              
              this.router.navigate(['/home']);
            } else {// If mfa flag is false but there is no profile object

              this.logger.info('mfa flag is false and there is no profile object ');
              this.router.navigate(['/home']);
            }
          }
        }, error => {
          this.logger.info('HTTP Error: AuthService.authenticateUser/login error ' + JSON.stringify(error));
          // } else
          this.loadingService.stopLoading();

          if (error?.error?.statusCode === '501') {
            // Redirect to change password screen
            this.userAuthenticationDetails.setTempUserObj(this.loginForm.value.onlineId);
            this.router.navigate(['/resetPassword']);
          } else {
            this.loginFailed = true;
          }
          
          this.loginForm.reset();
          return;
        });
    }
  }

  /**
   * Open warning when an user with MFA logs in without the delivery (email/contact #) setup
   */
  openMfaDetailsErrorModal() {
    this.logger.info('Method not implemented.');
    this.mfaWarningModal.openTroubleSignInModal();
  }

  /**
   * Open Trouble sign in modal
   */
  openTroubleSignInModal() {
    this.contactUs.openTroubleSignInModal();
  }

  /**
   * Remove login error
   */
  userIdPwdBlurHandler() {
    this.loginFailed = false;
  }
}