import { Injectable } from '@angular/core';

@Injectable()
export class CommonService {

    isFileValid(allFiles, er) { // through any Error in file formats and size limit
        let errMsg = '';

        if (allFiles.length <= 0 && er === null) {
            errMsg = 'No files uploaded yet';
        }

        if (er.doctype.length !== 0) {
            errMsg = 'One or more files selected is invalid';
        } else if (er.maxsize.length !== 0) {
            errMsg = 'File(s) you selected exceed maximum limit';
        }

        return errMsg;
    }

    getTotalForChart(dataArr) {
        let count = 0;

        dataArr.forEach((data) => {
            count = count + data.value;
        });

        return count;
    }

    checkFileNameValid(filesDroped) { // To check droped files name have symbol or not
        const pattern = new RegExp(/[!%*?<>\"/|\;&]/);
        const noOfFilesDragged = filesDroped.length;
        const validFileList = [];

        filesDroped.forEach( (file) => {
            if (!pattern.test(file.name)) {
                validFileList.push(file);
            }
        });

        if (validFileList.length !== noOfFilesDragged) {
            return false;
        } else {
            return true;
        }

    }

    getDateOption(frDate) { // date-options for isc-datepicker from today
        // vm.fromDate = frDate || new Date().toDateString().substring(4);
        // vm.fromDate = frDate || $filter('date')(new Date(new Date().setHours(0,0,0,0)),'MMM dd, yyyy h:mm:ss a');//TODO
        // vm.toDate = toDate || 'Dec 31 2029';

        const configDateOption = {
            // 'dateFormat':'MMM dd, yyyy',
            // 'disableWeekend':false,
            // 'minDate': vm.fromDate,
            // 'maxDate': ''
            inlineDisplay: true,
            dateFormat: 'MMM dd, yyyy h:mm:ss a',
            disableWeekend: false,
            minDate: '',/* fromDate */
            maxDate: ''
        };

        return configDateOption;
    }

    getDocHeight() {
        const D = document;
        return Math.max(
            D.body.scrollHeight, D.documentElement.scrollHeight,
            D.body.offsetHeight, D.documentElement.offsetHeight,
            D.body.clientHeight, D.documentElement.clientHeight
        );
    }

    filterData(data, gridOptions, options) {

        let oldData = data;
        // gridOptions.data = [];
        let newOpt = [];

        if (options.length === 0) {
            // gridOptions.data = data;
            // gridOptions.isLoadingIcon = false;
            return data;
        }

        options.forEach( (opt) => {

            oldData.forEach( (val) => {

                if (val[opt.name]) {
                    if ((val[opt.name].toLowerCase()).indexOf(opt.filterVal.toLowerCase()) !== -1) {
                        newOpt.push(val);
                    }
                }
            });
            oldData = newOpt;
            newOpt = [];
        });

        // gridOptions.data = oldData;
        // gridOptions.isLoadingIcon = false;
        return oldData;
    }

    /*Takes the date value in 21-08-2017 09:03:15 and formats it to MMM DD, YYYY HH:MM:SS */
    formatDate_1(dt) {
        const arr = dt.split(' ');
        const dateArr = arr[0];
        const temp = dateArr.split('-');
        const MMM = this.getMonth(temp[1]);
        const formattedDate = MMM + ' ' + temp[0] + ', ' + temp[2] + ' ' + arr[1];
        return formattedDate;
    }

    /*Takes value in 21/08/2017 09:03:15 format*/
    formatDate_2(d) {
        let formattedDate = d;
        const arr = d.split(' ');
        const dt = arr[0];
        const temp = dt.split('/');
        const MMM = this.getMonth(temp[1]);
        if (arr.length === 2) {
            formattedDate = MMM + ' ' + temp[0] + ', ' + temp[2] + ' ' + arr[1];
        } else if (arr.length === 3) {
            formattedDate = MMM + ' ' + temp[0] + ', ' + temp[2] + ' ' + arr[1] + ' ' + arr[2];
        }
        return formattedDate;
    }

    /*Takes date in 12 Jan 2016 12:45 p.m. format and return MMM DD, YYYY HH:MM:SS*/
    formatDate_3(d) {
        const arr = d.split(' ');
        // var time = arr[3];
        let ampm = arr[4];
        if (ampm === 'a.m.') {
            ampm = 'AM';
        } else if (ampm === 'p.m.') {
            ampm = 'PM';
        }
        const formattedDate = arr[1] + ' ' + arr[0] + ', ' + arr[2] + ' ' + arr[3] + ':00' + ' ' + ampm;
        return formattedDate;
    }
    
    getMonth(mm) {
        let MMM = '';
        switch (mm) {    // As date is in dd-mm-yyy format
            case '01':
            case '1':
                MMM = 'Jan';
                break;
            case '02':
            case '2':
                MMM = 'Feb';
                break;
            case '03':
            case '3':
                MMM = 'Mar';
                break;
            case '04':
            case '4':
                MMM = 'Apr';
                break;
            case '05':
            case '5':
                MMM = 'May';
                break;
            case '06':
            case '6':
                MMM = 'Jun';
                break;
            case '07':
            case '7':
                MMM = 'Jul';
                break;
            case '08':
            case '8':
                MMM = 'Aug';
                break;
            case '09':
            case '9':
                MMM = 'Sep';
                break;
            case '10':
                MMM = 'Oct';
                break;
            case '11':
                MMM = 'Nov';
                break;
            case '12':
                MMM = 'Dec';
                break;
        }

        return MMM;
    }

    isIE() {
        const match = navigator.userAgent.search(/(?:Edge|MSIE|Trident\/.*; rv:)/);
        let isIE = false;

        if (match !== -1) {
            isIE = true;
        }

        return isIE;
    }

    isFireFox() {
        let isFF = false;
        if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            isFF = true;
        }
        return isFF;
    }

    convertBytes(bytes) {
        const decimals = 2, k = 1024;
        // var sizes = ['bytes','kilobytes','megabytes','gigabytes','terabytes']; // change as per MAIL
        const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB'];
        if (bytes === 0 || bytes === 0.0) {
            return '0 b';
        }
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
    }
}