export class GlobalCountries {
    public static COUNTRIES = [{ 'country': 'ARGENTINA', 'number': '0800-6660088' },
    { 'country': 'AUSTRALOPT', 'number': '1-800-022078' },
    { 'country': 'AXTEL1', 'number': '001-8555156600' },
    { 'country': 'AXTEL2', 'number': '001-8555156600' },
    { 'country': 'AXTEL3', 'number': '001-8555156600' },
    { 'country': 'AXTEL4', 'number': '001-8555156600' },
    { 'country': 'BRITISH-VI', 'number': '1-8555156600' },
    { 'country': 'CHILE', 'number': '123-0-020-1095' },
    { 'country': 'CHIM800MOB', 'number': '4001-202995' },
    { 'country': 'COSTA-RICA', 'number': '0800-012-1935' },
    { 'country': 'DENMARK', 'number': '80-252369' },
    { 'country': 'DOM-REPUBL', 'number': '1-8001485083' },
    { 'country': 'DR-TRICOM', 'number': '1-8001485083' },
    { 'country': 'Fielisia', 'number': '0800-9-17737' },
    { 'country': 'FRANCE', 'number': '0800-919897' },
    { 'country': 'FRANCE_MOB', 'number': '0800-919897' },
    { 'country': 'GREECE', 'number': '00800-12-9582' },
    { 'country': 'GRENADA', 'number': '1-8555156600' },
    { 'country': 'HONGKONG', 'number': '800-965916' },
    { 'country': 'BHARTI', 'number': '000-800001-6933' },
    { 'country': 'RELIANCE', 'number': '000-800001-6933' },
    { 'country': 'INDIAVODAF', 'number': '000-800001-6933' },
    { 'country': 'TATACOMMS', 'number': '000-800001-6933' },
    { 'country': 'BSNL', 'number': '000-800001-6933' },
    { 'country': 'ISRAELBAR', 'number': '1-80-9303204' },
    { 'country': 'ITALY', 'number': '800-790646' },
    { 'country': 'JAPAN', 'number': '00531-1-22633' },
    { 'country': 'JAPANSB', 'number': '0066-33-813122' },
    { 'country': 'KOREA-DACM', 'number': '00308-1-23406' },
    { 'country': 'KOREA-TELE', 'number': '00798-14-800-9445' },
    { 'country': 'LUXEMBOURG', 'number': '8002-8665' },
    { 'country': 'MALAYSIA', 'number': '1-800-8-17222' },
    { 'country': 'MEXTELEFON', 'number': '0018-555156600' },
    { 'country': 'MEXTFN-MOB', 'number': '0018-555156600' },
    { 'country': 'MEXICO1', 'number': '001-8555156600' },
    { 'country': 'MEXICO2', 'number': '001-8555156600' },
    { 'country': 'MEXICO3', 'number': '001-8555156600' },
    { 'country': 'MEXICO4', 'number': '001-8555156600' },
    { 'country': 'NEW ZEALND', 'number': '0800-466456' },
    { 'country': 'NORWAY', 'number': '800-11755' },
    { 'country': 'PANAMA', 'number': '001-800-507-3272' },
    { 'country': 'POLAND', 'number': '00-800-1220050' },
    { 'country': 'RUSSIA', 'number': '8-10-8002-4675011' },
    { 'country': 'SD ARABIA', 'number': '800-8-110484' },
    { 'country': 'SINGAPORE', 'number': '800-1206439' },
    { 'country': 'SLOVAK REP', 'number': '0800-002401' },
    { 'country': 'SLOVENIA', 'number': '0800-8-1524' },
    { 'country': 'SO-AFRICA', 'number': '080-09-82592' },
    { 'country': 'SPAIN', 'number': '9009-91689' },
    { 'country': 'SWEDEN', 'number': '020-79-3160' },
    { 'country': 'SWEDEN/MOB', 'number': '020-79-3160' },
    { 'country': 'SWITZRLND', 'number': '0800-563023' },
    { 'country': 'SWISS/MOBL', 'number': '0800-563023' },
    { 'country': 'TAIWAN', 'number': '00801-136673' },
    { 'country': 'THAILAND', 'number': '001-800-12-066-4168' },
    { 'country': 'UK-BT', 'number': '08-082346104' },
    { 'country': 'URUGUAY', 'number': '000-413-598-4617' },
    ];
}