<!--threeKeyAudit.html-->
<div class="px-container">
  <div class="section-title">
    <h1>3SKey audit</h1>
  </div>
  <div class="px-row px-pt-2" *ngIf="error.isError">
    <div class="px-col-12">
      <phx-message type="danger" [dismissible]="false">
        {{error.errorMessage}}
      </phx-message>
    </div>
  </div>
  <div class="filters">
    <div class="pickerDiv">
      <legend id="retrieveFromDateLabel" class="labelContent" for="retrieveFromDate"> From date </legend>
      <phx-datepicker aria-labelledby="retrieveFromDateLabel" calendarId='datePicker1' [options]="dateRangeOptions">
        <input id="retrieveFromDate" phxDate [(ngModel)]="retrieveFromDate" [format]="date1Format"
          (focus)="validateDateTime()" />
      </phx-datepicker>
    </div>
    <div class="inputWithIcon">
      <legend id="retrieveFromTimeLabel" class="labelContent" for="retrieveFromTime"> From time</legend>
      <div class="px-input-group time-picker-control">
        <input phxInput aria-labelledby="retrieveFromTimeLabel" id="retrieveFromTime" readonly [(ngModel)]="retrieveFromTime" aria-label="HH:MM:SS"
          placeholder="HH:MM:SS" />
          <div class="px-input-group-append px-icon-addon">
            <button type="button" tabindex="0" 
              (click)="openFrom = !openFrom;setDateTimeValue('From')" 
              aria-label="Toggle timepicker"
              [phx-popup]="phxPopupFromTime"
              [(popupOpen)]="openFrom"
              [hasBackdrop]="true"
              (keydown.enter)="openFrom = !openFrom;setDateTimeValue('From')"> 
              <i class="pxi px-clock"></i>
            </button>
          </div>
        </div>
    </div>
    <div class="borderDiv">
    </div>
    <div class="pickerDiv">
      <legend id="retrieveToDateLabel" class="labelContent" for="retrieveToDate"> To date</legend>
      <phx-datepicker aria-labelledby="retrieveToDateLabel" calendarId='datePicker1' [options]="dateRangeOptions">
        <input id="retrieveToDate" phxDate [(ngModel)]="retrieveToDate" [format]="date1Format"
          (focus)="validateDateTime()" />
      </phx-datepicker>
    </div>
    <div class="inputWithIcon">
      <legend id="retrieveToTimeLabel" class="labelContent" for="retrieveToTime"> To time</legend>
      <div class="px-input-group time-picker-control">
        <input phxInput aria-labelledby="retrieveToTimeLabel" id="retrieveToTime" readonly [(ngModel)]="retrieveToTime" aria-label="HH:MM:SS"
          placeholder="HH:MM:SS" maxlength="200" />
        <div class="px-input-group-append px-icon-addon"><button type="button" tabindex="0"
          (click)="openTo = !openTo;setDateTimeValue('To')"
          (keydown.enter)="openTo = !openTo;setDateTimeValue('To')"
          [phx-popup]="phxPopupToTime"
          [(popupOpen)]="openTo"
          [hasBackdrop]="true" aria-label="Toggle timepicker">  
          <i class="pxi px-clock"></i>
        </button></div>
      </div>
    </div>
    <div class="button">
      <button class="px-mr-3" type="button" phxButton (click)="searchResults()" [disabled]="error.isError"
        title="Search">Search</button>
      <button class="px-mr-3" type="button" phxButton="link" (click)="ngOnInit()" title="Reset">Reset</button>
    </div>
  </div>
  <div [style.display]="isDataExists ? 'inline': 'none'">
    <phx-datatable [options]="gridOptions" id="threeSkeyGrid" #threeSkeyGrid (onTableEvents)="handleEvents($event)"
      currentPageReportTemplate="Listing {first} - {last} of {total} results">
      <ng-template phxTemplate="column" phxField="createdDate" phxType="label" let-column>
        Date
      </ng-template>
      <ng-template phxTemplate="column" phxField="createdDate" phxType="row" let-rowData>
        <span [phxTooltip]="rowData.createdDate | date: 'MMM dd, yyyy hh:mm:ss a'" class="px-mr-1"
          tooltipPosition="bottom" tabindex="0">
          {{rowData.createdDate | date: 'MMM dd, yyyy hh:mm:ss a'}}
        </span>

      </ng-template>
    </phx-datatable>
  </div>
  <div *ngIf="!isDataExists && errMessage">
    <phx-message type="warning" [(showMessage)]="errMessage">
      {{errMessage}}
    </phx-message>
  </div>
</div>
<ng-template #phxPopupFromTime>
  <dts-time-picker [currentTime]="retrieveToTime" (timeValue)="getTimeHandler($event)"
    (cancelEvent)="hideTimePickerHandler($event)"></dts-time-picker>
</ng-template>
<ng-template #phxPopupToTime>
  <dts-time-picker [currentTime]="retrieveToTime" (timeValue)="getTimeHandler($event)"
    (cancelEvent)="hideTimePickerHandler($event)"></dts-time-picker>
</ng-template>