import { ConsoleLogConfig, LoggerType, LogMode } from '@phoenix/ui/common';

export const environment = {
  appVersion: require('../../../../package.json').version,
  production: false,
  translationBundleUrl: 'assets/i18n/test/',
  logConfig: [
    {
      loggerType: LoggerType.CONSOLE,
      logLevel: LogMode.WARN
    } as ConsoleLogConfig
  ],
  mockApiServer: 'http://localhost:7878',
  host: '',
  bypassDtsController: false,
  rootScopeBase64Key : 'oI6+36nNRyCVYdo1JKLDRY7Sey5mLdUd+DhXmPUzwc8=',
  rootScopeIv : 'mtpqFlElKOT+4Lhy1AADrQ==',
  dchDomainUrl: ''//TODO: remove the server name

};
