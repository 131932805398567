<section class="px-container">
    <div>
        <div>
            <h2>{{quickStartNav.name}}</h2>
            <hr>
            <ul>
                <li *ngFor="let nav of quickStartNav.quickStart">
                    <a [routerLink]="['/quickStart/' + nav.quickStart.route]" routerLinkActive="active" title="{{nav.quickStart.name}}">{{nav.quickStart.name}}</a>
                </li>
            </ul>
        </div>
        <div>
            <div class="embed-container" [innerHtml]="contentBodyHtml">
            </div>
        </div>
    </div>
    <phx-back-to-top [scrollHeight]="100"></phx-back-to-top>
</section>