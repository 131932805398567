import { NgModule, Injectable } from '@angular/core';
import { RouterModule, Routes, UrlSegment, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { HomeComponent } from './feature-modules/home/home.component';
import { AuthGuard } from './core/interceptors/auth-guard';
import { DchService } from './core/services/dch.service';
import { AccountmanagementComponent } from './feature-modules/accountmanagement/accountmanagement.component';
import { AddAccountComponent } from './feature-modules/accountmanagement/add-account.component';
import { ChangePasswordComponent } from './feature-modules/accountmanagement/change-password.component';
import { ChannelsComponent } from './feature-modules/accountmanagement/channels.component';
import { MyAccountComponent } from './feature-modules/accountmanagement/my-account.component';
import { ApproverComponent } from './feature-modules/approver/approver.component';
import { BankKeysComponent } from './feature-modules/informationCenter/bank-keys.component';
import { PlatformDocumentationComponent } from './feature-modules/informationCenter/platform-documentation.component';
import { RequestBankCertComponent } from './feature-modules/informationCenter/request-bank-cert.component';
import { ServerProtocolComponent } from './feature-modules/informationCenter/server-protocol.component';
import { ErrorPageComponent } from './feature-modules/error/error-page.component';
import { GenericPageComponent } from './feature-modules/generic/genericPage.component';
import { QuickstartRootComponent } from './feature-modules/informationCenter/quickstartroot.component';
import { LoginComponent } from './feature-modules/login/login.component';
import { MultiFactorAuthenticationComponent } from './feature-modules/login/multi-factor-authentication.component';
import { ThreeKeyAuditComponent } from './feature-modules/reports/three-key-audit.component';
import { TransmissionReportsComponent } from './feature-modules/reports/transmission-reports.component';
import { RetreiveFilesComponent } from './feature-modules/retreive-files/retreive-files.component';
import { SendFilesComponent } from './feature-modules/send-files/send-files.component';
import { SecurityCenterComponent } from './feature-modules/informationCenter/security-center.component';
import { ManageMfaComponent } from './feature-modules/accountmanagement/manage-mfa.component';
import { LoginGuard } from './core/interceptors/login-guard';

@Injectable()
export class DataResolve implements Resolve<any> {
  constructor(private dchService: DchService) { }

  async resolve(route: ActivatedRouteSnapshot): Promise<any> {
    return this.dchService.getQuickStartNav();
  }
}

export const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [LoginGuard]},
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'send', component: SendFilesComponent, canActivate: [AuthGuard] },
  { path: 'retrieve', component: RetreiveFilesComponent, canActivate: [AuthGuard] },
  { path: 'approver', component: ApproverComponent, canActivate: [AuthGuard] },
  
  // Information Center
  { path: 'informationCenter/serverProtocol', component: ServerProtocolComponent, canActivate: [AuthGuard] }, // protocalinfo
  { path: 'informationCenter/manageKeys', component: BankKeysComponent, canActivate: [AuthGuard] }, // requestBankKeys/PGPKey
  { path: 'informationCenter/requestBankCertificates', component: RequestBankCertComponent, canActivate: [AuthGuard] }, // requestBankKeys/SSH%20SFTP  , requestBankKeys/HTTPS  etc
  { path: 'informationCenter/securityCenter', component: SecurityCenterComponent, canActivate: [AuthGuard] },
  { path: 'informationCenter/platformDocumentation', component: PlatformDocumentationComponent, canActivate: [AuthGuard] },

  // Reports menu
  { path: 'reports/transmissionsReport', component: TransmissionReportsComponent, canActivate: [AuthGuard] },
  { path: 'reports/threeKeyAudit', component: ThreeKeyAuditComponent, canActivate: [AuthGuard] },
  // MFA menu
  { path: 'multiFactorAuthentication', component: MultiFactorAuthenticationComponent },
  // My profile menu
  { path: 'resetPassword', component: ChangePasswordComponent, canActivate: [LoginGuard] },
  //{ path: 'allRequests', component: AllRequestsComponent },
  {
    path: 'userProfile', component: AccountmanagementComponent,
    children: [
      { path: 'myAccount', component: MyAccountComponent, canActivate: [AuthGuard] },
      { path: 'manageMultiFactorAuthentication', component: ManageMfaComponent, canActivate: [AuthGuard] },
      { path: 'changePassword', component: ChangePasswordComponent, canActivate: [AuthGuard] }],
    canActivate: [AuthGuard]
  },
  { path: 'accountManagement/addAccount', component: AddAccountComponent, canActivate: [AuthGuard] },
  { path: 'accountManagement/channels', component: ChannelsComponent, canActivate: [AuthGuard] },
  { path: 'information', component: GenericPageComponent },
  {
    path: 'quickStart',
    component: QuickstartRootComponent,
    resolve: { navigation: DataResolve },
    canActivate: [AuthGuard]
  },
  {
    matcher: quickStartPatternMatcher,
    component: QuickstartRootComponent,
    resolve: { navigation: DataResolve },
    canActivate: [AuthGuard]
  },
  { path: 'errorPage', component: ErrorPageComponent },

  { path: '**', redirectTo: '/login', pathMatch: 'full' },
];

export function quickStartPatternMatcher(url: UrlSegment[]) {

  if (url && url[0] && url[0].path.match(/^quickStart$/)) {

    return {
      consumed: url,
      posParams: {
        infoPath: new UrlSegment(url.reduce((acc, p) => acc + '/' + p.path, ''), {})
      }
    }
  }
  return null;
}

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled' })],//, enableTracing: true
  exports: [RouterModule]
})
export class AppRoutingModule { }