import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from "rxjs";
import { environment } from 'projects/DtsUI/src/environments/environment';
import { StartupService } from './startup.service';

@Injectable()
export class DchService {
    private contentNameCCH: string = 'PHXcms';
    private appNameInCCH: string = 'b2bi';
    private privateBaseUrl: string = environment.dchDomainUrl + "/" + this.contentNameCCH + "/private/content/" + this.appNameInCCH + "/";
    private publicBaseUrl: string = environment.dchDomainUrl + "/" + this.contentNameCCH + "/public/content/" + this.appNameInCCH + "/";
    public navigationLinks: any;
    private instance: string;

    constructor(private http: HttpClient,
        private startupService: StartupService) {
        this.instance = startupService.getInstance();
    }
    
    /**
     * Returns the CCH page for the selected navigation item
     * @param identifier 
     */
    getCCHPageDetail(link: string, addBaseUrl?: boolean): Observable<any> {

        let addedUrl = '';
        if(addBaseUrl){
            if(link.includes('private')) {
                link = link.replace('private','');
                addedUrl = this.privateBaseUrl;
            }
        }

        return this.http.get<any>(addedUrl + link.replace(".html", ".json"))
            .pipe(map((response: any) => {
                return response;
            }));
    }

    async getQuickStartNav() {

      if(this.navigationLinks)
      {
          return this.navigationLinks;
      }
      
      return await this.http.get<any>(this.privateBaseUrl + 'Collection/B2BiServices.json')
          .pipe(map((response: any) => {
             if(response && response.b2biServices && response.b2biServices){
                let linksUpdated: any = response.b2biServices;
                let updatedLinks: Array<any>= [];
                linksUpdated.name = response.b2biServices.name;
                response.b2biServices.quickStart.forEach((def)=>{
                    let articleloc : string = JSON.stringify(def);
                    updatedLinks.push(JSON.parse(articleloc.replace('/content/b2bi/InformationCenter/','/content/b2bi/InformationCenter/'+this.instance+'/')));
                });
                linksUpdated.quickStart = updatedLinks;
                this.navigationLinks = linksUpdated;
              }
              //this.invokeLoadNavItemsEmitter.emit(this.navigaionLinks);
              return this.navigationLinks;
          })).toPromise();
  }

  /**
   * Returns the Login & home page data from DCH
   * @param identifier 
   */
  getHomeContent(): Observable<any> {
    return this.http.get<any>(this.publicBaseUrl + 'Collection/'+this.instance+'/Login_and_home_page_content.json')
          .pipe(map((response: any) => {
              let responseUpdated: string = '';
              responseUpdated = this.replaceAll(JSON.stringify(response),
              '/private_instance/','/'+this.instance+'/');
              return JSON.parse(responseUpdated);
    }));
  }

  /**
   * Returns the replaced string
   */
   replaceAll(response: string,replaceString: string, toBeReplaced: string){
    let responseUpdated: string = response;
    while(responseUpdated.indexOf(replaceString) != -1){
      responseUpdated = responseUpdated.replace(replaceString, toBeReplaced);
    }
    return responseUpdated;
   }
}