import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Logger } from '@phoenix/ui/common';
import { LoadingScreenService } from '../../core/services/loading-screen.service';
import { UserAuthenticationDetails } from '../login/user.authentication.details';
import { AccountManagementService } from '../../core/services/account-management.service';
import { countries } from '../home/countries';
@Component({
  selector: 'dts-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent implements OnInit {

  @Input() showHideButton?: boolean;
  @Output() getFormStatus = new EventEmitter();

  myAccountForm: FormGroup;
  phoneNumRegEx: string;
  mailRegEx: string;
  codeList: any;
  mfaStatus: any;
  radioBtn: string;
  userData: {
    userName: any;
    companyName: any;
    firstName: string;
    lastName: string;
    email: any;
    aliasName: any;
    phoneNumber: string;
    countryCode: string;
    newUser: boolean;
  };
  isSuccess: boolean;
  isError: boolean;
  trustComputer: boolean;
  showMessage: boolean;
  // showLoader: boolean;
  succMessage: string;
  phoneNum: string;
  errMessage: string;
  isDisable: boolean;
  showMobile: boolean;
  showEmail: boolean;
  tabrights: any;
  addaccright: any;
  chnlright: any;
  code2Selected: any;
  profileObj: any;
  userAuthDetailsObj: any;
  mask: boolean = true;
  maskMobile: boolean = true;

  breadcrumbItems = [
    { label: 'Home', link: '/home' },
    { label: 'User profile', link: '/accountmanagement' }
  ];

  accName = new FormControl('', [Validators.required])
  alias = new FormControl('', [Validators.required])
  compName = new FormControl('', [Validators.required])
  firstName = new FormControl('', [Validators.required])
  lastName = new FormControl('', [Validators.required])
  email = new FormControl(null, Validators.compose([Validators.required, Validators.email]))
  mobileNum = new FormControl(null, Validators.compose([Validators.required, Validators.pattern('^[0-9]{10,10}$')]))
  countryCode = new FormControl(null, [Validators.required])

  constructor(
    private userAuthenticationDetails: UserAuthenticationDetails,
    private updateAccountDetailsService: AccountManagementService,
    private formBuilder: FormBuilder,
    private loadingService: LoadingScreenService,
    private cd: ChangeDetectorRef,
    private logger: Logger
  ) { this.showHideButton = true; }

  ngOnInit(): void {
    this.userAuthenticationDetails.breadcrumbEmitter.emit(this.breadcrumbItems);
    this.phoneNumRegEx = '^[0-9]{10,10}$';
    this.mailRegEx = '^[a-z0-9._%+-]+@([a-z0-9][a-z0-9.-]+[.]{1})+[a-z]{2,9}$';
    this.codeList = countries;

    // Initialize country code for Empty values
    this.countryCode = this.codeList[0].value;

    this.myAccountForm = this.formBuilder.group({
      accName: new FormControl('', [Validators.required]),
      alias: new FormControl('', [Validators.required]),
      compName: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
      mobileNum: new FormControl('', Validators.compose([Validators.required, Validators.pattern('^[0-9]{10,10}$')])),
      countryCode: new FormControl('', [Validators.required])
    });

    if (this.userAuthenticationDetails.getUserAuthDetails() !== undefined) {
      this.loadUserDto();
    } else {
      this.resetToOriginalData();
    }
  }

  ngAfterViewInit(): void{
    this.myAccountForm.statusChanges.subscribe(res => {
      this.getFormStatus.emit(res);
    });

    this.myAccountForm.valueChanges.subscribe(res => {
      this.getFormStatus.emit(res);
    });
  }

  loadUserDto() {

    // this.mfaStatus= this.userAuthenticationDetails.getMfaDetails() === undefined?false:this.userAuthenticationDetails.getMfaDetails();

    this.mfaStatus = false;
    this.radioBtn = 'email';
    this.isSuccess = false;
    this.isError = false;
    this.trustComputer = false;
    this.showMessage = false;
    this.succMessage = 'User Details';
    this.phoneNum = '';
    this.isDisable = false;
    this.showMobile = false;
    this.showEmail = false;

    if (this.userAuthenticationDetails !== undefined) {
      this.userData = this.userAuthenticationDetails.userDto;
     // this.logger.info('UserData : ', this.userData);
      if (this.userData !== undefined) {
        this.initFormFields();
      }

      this.profileObj = this.userAuthenticationDetails?.getProfileObj();
      if (this.profileObj !== undefined) {
        this.tabrights = this.profileObj?.rights;
        if (this.tabrights !== undefined) {
          this.addaccright = this.contains(this.tabrights, 'AccountManagement');
          this.chnlright = this.contains(this.tabrights, 'Channels');
        }
      }
    }
  }

  contains(a, obj) {
    if (a === undefined || a?.length === undefined) return false;

    for (let i = 0; i < a?.length; i++) {
      if (a[i].rightName === obj) {
        return true;
      }
    }
    return false;
  }

  toggleShowMobile() {
    this.showMobile = !this.showMobile;
  }

  toggleShowEmail() {
    this.showEmail = !this.showEmail;
  }

  initFormFields() {
    this.myAccountForm.patchValue({
      accName: this.userData?.userName,
      alias: this.userData?.aliasName,
      compName: this.userData?.companyName,
      firstName: this.userData?.firstName,
      lastName: this.userData?.lastName,
      email: this.userData?.email,
      mobileNum: this.userData?.phoneNumber,
      countryCode: this.userData?.countryCode
    });
  }

  resetToOriginalData() {
    this.initFormFields();
  }

  /**
   * Reset the form data to it original state.
   */
  cancelChangesHandler() {
    this.showMessage = false;
    this.myAccountForm.reset();
    this.resetToOriginalData();
  }

  ngAfterViewChanged() {
    this.cd.detectChanges();
  }

  /**
   * Update Account 
   */
  updateAccountHandler() {
    this.loadingService.startLoading();
    const requestObj = {
      firstName: this.myAccountForm.value.firstName,
      lastName: this.myAccountForm.value.lastName,
      email: this.myAccountForm.value.email,
      phoneNumber: this.myAccountForm.value.mobileNum,
      countryCode: this.myAccountForm.value.countryCode,
    };

    if (!this.isDisable) {
      this.logger.info('Making service call to update the account details');
      this.updateAccountDetailsService.updateAccDetails(requestObj).subscribe(
        (responseData) => {
          this.logger.info('UpdateAccountDetails service call completed successfully, response : ', responseData);
          this.showMessage = true;
          this.isSuccess = true;
          this.isError = false;
          this.succMessage = responseData.statusDescription;
          // Change the userDto object stored in sessionStorage
          this.userData.firstName = requestObj.firstName;
          this.userData.lastName = requestObj.lastName;
          this.userData.email = requestObj.email;
          this.userData.phoneNumber = requestObj.phoneNumber;
          this.userData.countryCode = requestObj.countryCode;
          this.userData.newUser = false;
          this.userAuthenticationDetails.setUserAuthDetails(this.userData);
          this.myAccountForm.reset()
          this.loadUserDto();
          this.loadingService.stopLoading();
          this.ngAfterViewChanged();
        },
        (error) => {
          this.logger.error('UpdateAccountDetails service call failed, error : ', error);
          this.isSuccess = false;
          this.isError = true;
          this.showMessage = true;
          this.loadingService.stopLoading();
          this.errMessage = error.statusText;
          this.ngAfterViewChanged();
        }
      );
    }
  }
}
