/* external Modules imports*/
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';

/* Internal Modules imports */
import { SharedModule } from '../shared/shared.module';

/* Components imports */
import { ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LoadingScreenComponent } from './loading-screen/loading-screen.component';
import { ContactUsModalComponent } from '../shared/modals/contact-us-modal.component';
import { MfaWarningModalComponent } from '../shared/modals/mfa-warning-modal.component';
import { DchService } from './services/dch.service';
import { CookieCenterModalComponent } from '../shared/cookie-center-modal/cookie-center-modal.component';
import { AccountManagementService } from './services/account-management.service';
import { UserAuthenticationDetailsService } from './services/user-authentication-details.service';
import { StartupService } from './services/startup.service';
// component, directive, pipes declarations in the following variable
const MY_DECLARATIONS = [
                          HeaderComponent, 
                          FooterComponent, 
                          LoadingScreenComponent
                        ];

@NgModule({
  declarations: [...MY_DECLARATIONS],
  imports: [
            CommonModule, 
            RouterModule, 
            SharedModule, 
            ReactiveFormsModule],
  exports: [...MY_DECLARATIONS, 
              ContactUsModalComponent,
              MfaWarningModalComponent,
              CookieCenterModalComponent],
  providers: [
              DchService,
              StartupService,
              AccountManagementService,
              UserAuthenticationDetailsService
            ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [],
    };
  }
}