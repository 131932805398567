import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Logger } from '@phoenix/ui/common';
import { CommonService } from '../../core/services/common.service';
import { FeatureModuleServices } from '../feature.module.services';
import { UserAuthenticationDetails } from '../login/user.authentication.details';
import { DatepickerOptions } from '@phoenix/ui/datepicker';
import { ActionTypes, DataTableComponent, DataTableEventData, DataTableOptions, ExternalEventInfo, FilterUIType, MatchMode, SortType } from '@phoenix/ui/datatable';
import { ModalOptions, ModalService } from '@phoenix/ui/modal';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { TimePickerComponent } from '../../shared/time-picker/time-picker.component';
import { StartupService } from '../../core/services/startup.service';
import { LoadingScreenService } from '../../core/services/loading-screen.service';

@Component({
  selector: 'dts-transmission-reports',
  templateUrl: './transmission-reports.component.html',
  styleUrls: ['./transmission-reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TransmissionReportsComponent implements OnInit {
  constructor(
    private logger: Logger,
    private userAuthenticationDetails: UserAuthenticationDetails,
    private getTransmissionReportsService: FeatureModuleServices,
    private commonService: CommonService,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private loadingService: LoadingScreenService,
    private startupService: StartupService
  ) {
    this.gridDataOptionsHandler();

    this.isInternalApp = this.startupService.isInternalApp();
  }

  gridData: TransmissionReport[];
  searchResult: TransmissionReport[];
  showHideReplay: boolean;
  disbaleCheckboxStatus: string;
  mockData: TransmissionReport[];
  userName: any;
  currentDate: Date;
  isDataExists: boolean;
  showLoader: boolean;
  transDirection: string;
  status: string;
  totalResults: number;
  errMessage: string;
  dateTime: Date;
  format: string;
  retrieveFromDate: Date;
  retrieveFromTime: Date;
  retrieveFromHours: string;
  retrieveFromMins: string;
  retrieveFromSecs: string;
  retrieveFromAMPM: string;
  retrieveToDate: Date;
  retrieveToTime: Date;
  retrieveToHours: string;
  retrieveToMins: string;
  retrieveToSecs: string;
  retrieveToAMPM: string;
  searchObject;
  selection = 'multiple';
  selectedfileIds: string[] = [];
  selectedRowItems: any[];
  isAnyRowSelected: boolean = this.selectedfileIds.length > 0;
  selectedfiles: TransmissionReport[] = [];
  modalRef: any;
  cnfMsg: any;
  responseMsg = {
    header: '',
    body: '',
    restReplay: false,
    noReplay: false,
    inEligibleMessageId: [],
    inEligibleMessageIdCum: [],
    inEligibleFiles: [],
    eligibleFiles: []
  };
  isInternalApp: boolean;
  filterValue: FilterCols[] = [];

  positions: ConnectedPosition[] = [
    {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
      offsetY: -5,
    },
    {
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
      panelClass: 'no-enogh-space-at-bottom',
    },
  ];

  date1Format = 'MM/DD/YYYY';
  hoursData: string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  hoursVal = 12;
  minsData: number[];
  minVal = 1;
  secsData: number[];
  secVal = 1;
  openFrom = false;
  openTo = false;
  timePickerSource = '';
  validDateRange: boolean = true;
  timePickerGroup: FormGroup;

  optionsScroll: DataTableOptions;
  @ViewChild('tblTransmissions') phxGrid: DataTableComponent;
  @ViewChild('confirmFooter', { read: TemplateRef }) confirmFooter: TemplateRef<any>;
  @ViewChild('headerTitle', { read: TemplateRef }) confirmHeader: TemplateRef<any>;
  @ViewChild('confirmMsg', { read: TemplateRef }) confirmMsg: TemplateRef<any>;
  @ViewChild('headerTitleSuccess', { read: TemplateRef }) successHeader: TemplateRef<any>;
  @ViewChild('successMsg', { read: TemplateRef }) successMsg: TemplateRef<any>;
  @ViewChild('footerSuccess', { read: TemplateRef }) footerSuccess: TemplateRef<any>;
  @ViewChild('warningMsg', { read: TemplateRef }) warningMsg: TemplateRef<any>;
  @ViewChild('warningHeader', { read: TemplateRef }) warningHeader: TemplateRef<any>;
  @ViewChild('alertMsg', { read: TemplateRef }) alertMsg: TemplateRef<any>;
  @ViewChild('timePicker', { read: TemplateRef }) timePickerBody: TemplateRef<any>;
  @ViewChild('timePickerFooter', { read: TemplateRef }) timePickerFooter: TemplateRef<any>;
  @ViewChild('warningHeaderReplay', { read: TemplateRef }) warningHeaderReplay: TemplateRef<any>;


  @ViewChild(TimePickerComponent) timePicker: TimePickerComponent;

  // Grid pagination options
  public pagerTypes = ['numeric', 'input'];
  public type = 'numeric';
  public buttonCount = 5;
  public info = true;
  public pageSizes = [
    10,
    20,
    50,
    {
      text: 'All',
      value: 'all',
    },
  ];
  public previousNext = true;
  public position = 'bottom';

  public pageSize = 10;

  dateRangeOptions: DatepickerOptions;

  breadcrumbItems = [
    { label: 'Home', link: '/home' },
    { label: 'Transmissions' }
  ];
  error: any = { isError: false, errorMessage: '' };

  ngOnInit(): void {
    // this.isRespSuccess = false;
    this.userAuthenticationDetails.breadcrumbEmitter.emit(this.breadcrumbItems);
    this.isDataExists = false;
    this.showLoader = false;
    this.transDirection = 'BOTH'; // By default .. TBD
    this.status = 'SELECT ONE'; // By Default
    this.format = 'MMM dd, yyyy h:mm:ss a';
    const d = new Date();
    const utc = d.getTime() + d.getTimezoneOffset() * 60000;
    this.currentDate = new Date(utc + 3600000 * -4);

    this.dateRangeOptions = {
      minDate: new Date(2015, 2, 10, 22),
      maxDate: new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth(),
        this.currentDate.getDate() + 1,
        this.hours12(this.currentDate),
        this.currentDate.getMinutes(),
        this.currentDate.getSeconds()
      )
    };
    // this.isRespSuccess = false;

    this.timePickerGroup = this.formBuilder.group({
      drHours: new FormControl('', Validators.compose([Validators.required])),
      drMins: new FormControl('', Validators.compose([Validators.required])),
      drSeconds: new FormControl('', [Validators.required]),
      AMPM: new FormControl('', [Validators.required])
    });

    this.initializeFormFields();
    this.searchTransmissionHandler();
    this.validateDateTime();
  }

  /**
   * Search transmissions for the selected dates
   */
  searchTransmissionHandler() {
    this.logger.info('Populating request object');
    const startHours = this.retrieveFromHours;
    const endHours = this.retrieveToHours;
    const startMins = this.retrieveFromMins;
    const endMins = this.retrieveToMins;
    const startSec = this.retrieveFromSecs;
    const endSec = this.retrieveToSecs;
    this.retrieveFromDate = new Date(this.retrieveFromDate);
    this.retrieveToDate = new Date(this.retrieveToDate);
    const requestObj = {
      fileName: '',
      direction: 'BOTH',
      status: 'SELECT ONE',
      startDate:
        this.retrieveFromDate.getFullYear() + '-' + (this.retrieveFromDate.getMonth() + 1) + '-' + this.retrieveFromDate.getDate(),
      startTime: startHours + ':' + startMins + ':' + startSec,
      endDate: this.retrieveToDate.getFullYear() + '-' + (this.retrieveToDate.getMonth() + 1) + '-' + this.retrieveToDate.getDate(),
      endTime: endHours + ':' + endMins + ':' + endSec,
      startAMPM: this.retrieveFromAMPM,
      endAMPM: this.retrieveToAMPM
    };
    this.searchObject = requestObj;
    //this.fetchTransmissionReports(requestObj);

    let eventInfo: ExternalEventInfo = {
      keepSorting: true,
      resetData: true
    };
    if (this.phxGrid)
      this.phxGrid.api.refresh({ info: eventInfo });
  }

  /**
   * Initialize search criteria
   */
  initializeFormFields() {
    this.logger.info('Initializing form fields');
    this.retrieveFromDate = new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth(),
      this.currentDate.getDate() - 1,
      this.currentDate.getHours(),
      this.currentDate.getMinutes(),
      this.currentDate.getSeconds()
    );
    this.retrieveFromHours = this.hours12(this.retrieveFromDate).toString();
    this.retrieveFromMins = this.retrieveFromDate.getMinutes() < 10 ? '0' + this.retrieveFromDate.getMinutes() : this.retrieveFromDate.getMinutes().toString();
    this.retrieveFromSecs = this.retrieveFromDate.getSeconds() < 10 ? '0' + this.retrieveFromDate.getSeconds().toString() : this.retrieveFromDate.getSeconds().toString();
    this.retrieveFromAMPM = this.retrieveFromDate.getHours() >= 12 ? 'PM' : 'AM';

    var fromTimeVal;
    fromTimeVal = this.retrieveFromHours + ":" + this.retrieveFromMins + ":" + this.retrieveFromSecs + " " + this.retrieveFromAMPM;
    this.retrieveFromTime = fromTimeVal;

    this.retrieveToDate = new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth(),
      this.currentDate.getDate(),
      this.currentDate.getHours(),
      this.currentDate.getMinutes(),
      this.currentDate.getSeconds()
    );
    this.retrieveToHours = this.hours12(this.retrieveToDate).toString();
    this.retrieveToMins = this.retrieveToDate.getMinutes() < 10 ? '0' + this.retrieveToDate.getMinutes() : this.retrieveToDate.getMinutes().toString();
    this.retrieveToSecs = this.retrieveToDate.getSeconds() < 10 ? '0' + this.retrieveToDate.getSeconds() : this.retrieveToDate.getSeconds().toString();
    this.retrieveToAMPM = this.retrieveToDate.getHours() >= 12 ? 'PM' : 'AM';
    var toTimeVal;
    toTimeVal = this.retrieveToHours + ":" + this.retrieveToMins + ":" + this.retrieveToSecs + " " + this.retrieveToAMPM;
    this.retrieveToTime = toTimeVal;
  }

  /**
   * Convert from military hour
   * @param date 
   * @returns 
   */
  hours12(date) {
    return (date.getHours() + 24) % 12 || 12;
  }

  /**
   * Fetch transmission data and bind the grid
   * @param events 
   */
  bindTransmissionReportsData(events) {
    this.logger.info('Making TransmissionReport service call, Request :', events);
    this.userName = this.userAuthenticationDetails.getUserName();

    if (!this.isReplayWarning) {
      this.responseMsg.noReplay = false;
      this.responseMsg.restReplay = false;
      this.responseMsg.inEligibleFiles = [];
    }
    this.loadingService.startLoading();
    this.getTransmissionReportsService.loadTransmissionReports(this.searchObject).subscribe(
      (responseData) => {
        this.logger.info('TransmissionReport service call successfully completed, responseData :', responseData);
        if (Array.isArray(responseData.recordsList)) {
          // this.isRespSuccess = true;
          this.isDataExists = true;
          this.searchResult = responseData.recordsList;
          this.searchResult.forEach((row) => {
            if (this.responseMsg.eligibleFiles && this.responseMsg.eligibleFiles.length > 0) {
              var selectedFileId = this.responseMsg.eligibleFiles.filter(c => c.toString() === row.messageId.toString());

              if (selectedFileId && selectedFileId.length > 0) {
                row.selected = true;
              }
            }
            this.responseMsg.eligibleFiles = [];
            this.isReplayWarning = false;
            //row.fileSizeModified = this.commonService.convertBytes(row.fileSize);
            row.fileSize = +row.fileSize;
            if (row.direction === 'TO_BANK') {
              row.direction = 'Sent';
            } else if (row.direction === 'FROM_BANK') {
              row.direction = 'Received';
            } else if (row.direction === 'BOTH') {
              row.direction = 'Both';
            }
            if (row.status === 'COMPLETED') {
              row.status = 'Completed';
            } else if (row.status === 'BULK-REPLAYED') {
              row.status = 'Resent';
            } else if (row.status === 'ERROR') {
              row.status = 'Error';
            }
          });
          this.showHideReplay = responseData.replayfiles;
          this.disbaleCheckboxStatus = responseData.fileReplaystatus;
          // if(this.showHideReplay){
          //   this.optionsScroll.smartView.hi
          // }
          this.gridData = this.searchResult;

          if (events)
            this.onGridHandlerFn(events);

        } else {
          // this.isRespSuccess = true;
          this.isDataExists = false;
          this.errMessage = 'Search criteria not found. Modify your search and try again';
          this.gridData = [];
        }
        this.loadingService.stopLoading();
      },
      (error) => {
        // this.isRespSuccess = false;
        this.isDataExists = false;
        this.errMessage = 'Search criteria not found. Modify your search and try again';

        this.gridData = [];
        if (events)
          this.onGridHandlerFn(events);
        this.logger.error('TransmissionReport service call failed, responseData :', error);
        this.loadingService.stopLoading();
      }
    );
  }

  closePanel(source) {
    if (source === 'From')
      this.openFrom = false;
    else
      this.openTo = false;
  }

  /**
   * Export to excel
   */
  public exportToExcelHandler(): void {
    this.logger.info('Exporting data to excel');
    let localData = this.gridData;
    if (this.filterValue.length > 0) {
      this.filterValue.map(filter => localData = localData.filter(item => item[filter.field] != null ? item[filter.field].toLowerCase().includes(filter.term.toLowerCase()): false));
    }
    this.downloadFile(localData, "Transmissions");
  }

  /**
   * Set transmission grid options
   */
  gridDataOptionsHandler() {
    this.optionsScroll = {
      smartView: {
        pageSize: this.pageSize,
        alertMessage: "No data found.",
        pagination: true,
        pageLinks: 8,
        idField: 'messageId',
        selectField: 'selected',
        rowsPerPageOptions: [10, 20, 40],
        handleEventsInternally: true,
        hideDisabledSelect: true,
        hideSelectAll: true
      },
      isRowSelectable: row => {
        return this.isRowCanBeSelected(row);
      },
      columnDefs: [
        {
          field: 'fileName',
          name: 'File name',
          filter: {
            matchMode: MatchMode.CONTAINS
          }
        },
        {
          field: 'modifiedFileName',
          name: 'Modified file name',
          filter: {
            matchMode: MatchMode.CONTAINS
          }
        },
        {
          field: 'messageId',
          name: 'Message ID',
          filter: {
            matchMode: MatchMode.CONTAINS
          }
        },
        {
          field: 'businessUnit',
          name: 'Business unit',
          filter: {
            matchMode: MatchMode.CONTAINS
          }
        },
        {
          field: 'status', name: 'Status',
          filter: {
            matchMode: MatchMode.CONTAINS
          }
        },
        {
          field: 'direction', name: 'Direction',
          filter: {
            label: 'Both',
            type: FilterUIType.SELECT,
            selectOptions: [{ value: '', label: 'Both' }, { value: 'sent', label: 'Sent' }, { value: 'received', label: 'Received' }]
          }
        },
        {
          field: 'fileSize', name: 'Size',
          filter: { enabled: false },
          sort: { type: SortType.NUMBER }
        },
        {
          field: 'receiptTimestamp', name: 'Received', filter: { enabled: false }
        },
        {
          field: 'sendTimestamp', name: 'Sent', filter: { enabled: false }
        }
      ],
      data: []
    };

  }

  /**
   * Checks if the grid row can be selected
   * @param row 
   * @returns 
   */
  isRowCanBeSelected(row) {
    if (this.showHideReplay) {
      if (row.direction == 'Sent') {
        if (this.disbaleCheckboxStatus.indexOf(row.status.toUpperCase()) > -1 || row.status.indexOf('Resent') > -1) {
          return true;
        }
        if (this.responseMsg.inEligibleMessageId.length > 0) {
          this.responseMsg.inEligibleMessageId.forEach((value, index) => {

            if (value.toString() === row.messageId.toString()) {
              return false;
            }
          });
        }
      }
    }
    else {
      return false;
    }
  }

  /**
   * Event to handle the row selection in the datatable. 
   * @param eventData Grid Events
   */
  internalEventHandler(eventData: DataTableEventData): void {
    if (eventData.events[0].type == 'SELECT_ROW') {
      var selectedIds = eventData.events[0].info.ids;
      if (Object.keys(selectedIds).length > 1) {
        var indexCount = 0;
        Object.values(selectedIds).forEach((value, index) => {
          if (value == 'n') indexCount++;
        });
        if (indexCount == Object.keys(selectedIds).length) {
          this.selectedfileIds = [];
        } else {
          this.selectedfileIds.push(...Object.keys(selectedIds));
          this.selectedfileIds = this.selectedfileIds.filter(
            (element, i) => i === this.selectedfileIds.indexOf(element)
          );
        }
      }
      else {
        if (Object.values(selectedIds).toString() == 'y') {
          this.selectedfileIds.push(Object.keys(selectedIds).toString());
        }
        else if (Object.values(selectedIds).toString() == 'n') {
          this.selectedfileIds.forEach((value, index) => {
            if (value == Object.keys(selectedIds).toString()) this.selectedfileIds.splice(index, 1);
          });
        }
      }
    }
    else if (eventData.events[0].type == 'LOAD') {
      window.scroll(0, 0);
    }
    else if (eventData.events[0].type === 'FILTER') {
      this.filterValue = eventData.events[0].info.filterCols;
    }
  }

  /**
   * Handle PHX grid internal events
   * @param eventData Grid events
   * @returns 
   */
  handleEvents(eventData: DataTableEventData): void {
    const events = eventData.events;
    if (!events) return;
    if (events.find(e => e.type === 'SELECT_ROW')) {
      this.getSelected();
      if (this.isAnyRowSelected) {
        const selectEvent = events.find(e => e.type === 'SELECT_ROW');
        const key = Object.keys(selectEvent.info.ids)[0];

        if (this.selectedfileIds.find(id => id === key)) {
          this.selectedfileIds = this.selectedfileIds.filter(id => id !== key);
          this.selectedfiles = this.selectedfiles.filter(item => item.messageId.toString() !== key);
        } else {
          this.selectedfileIds.push(key);
          const file = this.gridData.find(fileSelected => fileSelected.messageId.toString() === key);
          file.selected = true;
          this.selectedfiles.push(file);
        }
      }
      this.onGridHandlerFn(events);
    }
    else if (events.length >= 2 && events.find(e => e.type === 'LOAD')) {
      this.bindTransmissionReportsData(events);
    }
  }

  /**
   * Rebind the grid data
   * @param events Grid events
   */
  onGridHandlerFn(events: any) {
    const loadEvent = events.find((event) => event.type === ActionTypes.LOAD_DATA);

    if (loadEvent) loadEvent.result = { data: this.gridData };
    this.phxGrid.api.handleResultEvents(events);
  }

  /**
   * Download the specified file
   * @param data 
   * @param fileName 
   */
  downloadFile(data, fileName = '') {
    let csvData = this.convertoCSV(data, ['FILE NAME', 'MODIFIED FILE NAME', 'MESSAGE ID', 'BUSINESS UNIT', 'STATUS', 'DIRECTION', 'SIZE', 'RECEIVED', 'SENT']);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dllink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;

    if (isSafariBrowser) {
      dllink.setAttribute("target", "_blank");
    }
    dllink.setAttribute("href", url);
    dllink.setAttribute("download", fileName + '.csv');
    dllink.style.visibility = "hidden";
    document.body.appendChild(dllink);
    dllink.click();
    document.body.removeChild(dllink);
  }

  /**
   * Convert the result to CVS to download
   * @param objArray 
   * @param headerList 
   * @returns 
   */
  public convertoCSV(objArray, headerList) {
    let str = '';
    let row = '';
    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    str += row + '\r\n';
    objArray.forEach(element => {
      str += (element['fileName'] === null ? '' : element['fileName']) + ',';
      str += (element['modifiedFileName'] === null ? '' : element['modifiedFileName']) + ',';
      str += (element['messageId'] === null ? '' : element['messageId']) + ',';
      str += (element['businessUnit'] === null ? '' : element['businessUnit']) + ',';
      str += (element['status'] === null ? '' : element['status']) + ',';
      str += (element['direction'] === null ? '' : element['direction']) + ',';
      str += (element['fileSize'] === null ? '' : this.commonService.convertBytes(element['fileSize'])) + ',';
      str += (element['receiptTimestamp'] === null ? '' : element['receiptTimestamp']) + ',';
      str += (element['sendTimestamp'] === null ? '' : element['sendTimestamp']) + ',';
      str += '\r\n';
    });

    return str;
  }

  /**
   * On replay click handler
   */
  public replayFilesHandler(): void {
    this.openConfirmationModal();
  }

  /**
   * 
   * @returns Method to set disabled buttons based on the selected rows.
   */
  getSelected(): number {
    if (this.phxGrid && this.phxGrid.api.getSelectedCount() > 0) {
      this.isAnyRowSelected = true;
    }
    else {
      this.isAnyRowSelected = false;
    }
    return this.phxGrid ? this.phxGrid.api.getSelectedCount() : 0;
  }

  isReplayWarning: boolean;
  /**
   * Method to open confirmation modal on Approve/Reject button click.
   */
  openConfirmationModal() {
    const modalOptions: ModalOptions = {
      title: this.responseMsg.restReplay ? this.warningHeaderReplay : this.confirmHeader,
      content: this.responseMsg.restReplay ? this.warningMsg : this.confirmMsg,
      footer: this.confirmFooter,
      dismissable: true,
      position: {
        top: '120px'
      },
      size: 'md',
      hasBackdrop: true,
      draggable: false,
    };
    this.modalRef = this.modalService.open(modalOptions);
    if (this.responseMsg.restReplay) {
      this.isReplayWarning = true;

      this.searchTransmissionHandler();
    }
  }

  /**
   * Close confirm modal for replay
   */
  closeConfirmModalHandler() {
    this.modalService.close();
    this.responseMsg.restReplay = false;
    this.responseMsg.noReplay = false;
    this.responseMsg.inEligibleMessageId = [];
    this.responseMsg.inEligibleFiles = [];
  }

  /**
   * Method to replay selected IDs.
   */
  submitReplayHandler() {
    this.logger.info('Making call to Replay: ');
    this.modalService.close();
    var requestObj = this.selectedfileIds;
    this.getTransmissionReportsService.replayTransmissionFiles(requestObj)
      .subscribe((resp) => {
        this.logger.info('Replay service call completed successfully, response : ', resp);
        if (resp.statusDescription === 'SUCCESS') {
          this.responseMsg.header = 'Success';
          this.responseMsg.body = 'Files resent successfully.';
          this.openResponseModal();
          this.selectedfileIds = [];
          this.searchTransmissionHandler();
        } else if (resp.statusDescription === 'WARNING') {
          this.responseMsg.header = 'Warning';
          this.responseMsg.restReplay = true;
          resp.responseData.forEach(element => {
            this.responseMsg.inEligibleMessageId.push(element);
            this.responseMsg.inEligibleMessageIdCum.push(element);
            this.gridData.forEach(x => {
              if (element.toString() === x.messageId) {
                this.responseMsg.inEligibleFiles.push(x.fileName);
                x.selected = false;
                this.selectedfileIds.forEach((value, index) => {
                  if (value == x.messageId.toString()) this.selectedfileIds.splice(index, 1);
                });
              }
              else
                this.responseMsg.eligibleFiles.push(x.messageId);
            });
          });
          this.openConfirmationModal();
        } else if (resp.statusDescription === 'ALERT') {
          this.responseMsg.header = 'Alert';
          this.responseMsg.noReplay = true;
          resp.responseData.forEach(element => {
            this.responseMsg.inEligibleMessageId.push(element);
            this.responseMsg.inEligibleMessageIdCum.push(element);
            this.gridData.forEach(x => {
              if (element.toString() === x.messageId) {
                this.responseMsg.inEligibleFiles.push(x.fileName);
                x.selected = false;
              }
            });
          });
          this.openWarningModal();
        } else {
          this.responseMsg.header = "Failure";
          this.responseMsg.body = "Failed to Replay the file. Please try again.";
          this.openResponseModal();
        }
      }, error => {
        this.logger.error('Replay service call failed , error : ', error);
        this.responseMsg.header = 'Failure';
        this.responseMsg.body = error?.error?.statusDescription || 'Failed to process your request. Please try again.';
        this.openResponseModal();
      });
    this.responseMsg.noReplay = false;
  }

  /**
   * Open success modal.
   */
  openResponseModal() {
    const modalOptions: ModalOptions = {
      title: this.successHeader,
      content: this.successMsg,
      footer: this.footerSuccess,
      dismissable: true,
      position: {
        top: '120px'
      },
      size: 'md',
      hasBackdrop: true,
      draggable: false
    };
    this.modalRef = this.modalService.open(modalOptions);
  }

  /**
   * Open Warning modal
   */
  openWarningModal() {
    const modalOptions: ModalOptions = {
      title: this.warningHeaderReplay,
      content: this.alertMsg,
      footer: this.footerSuccess,
      dismissable: true,
      position: {
        top: '120px'
      },
      size: 'md',
      hasBackdrop: true,
      draggable: false
    };
    this.modalRef = this.modalService.open(modalOptions);
    if (this.responseMsg.noReplay) {
      this.isReplayWarning = true;

      this.searchTransmissionHandler();
    }

  };

  //Time picker methods
  onModelChange(selectedValue: number | null) {
  }

  /**
   * Method to open confirmation modal on Approve/Reject button click.
   */
  openTimepickerModal(source: string) {
    this.timePickerSource = source;
    const modalOptions: ModalOptions = {
      content: this.timePickerBody,
      footer: this.timePickerFooter,
      dismissable: true,
      position: {
        top: '120px'
      },
      size: 'md',
      hasBackdrop: true,
      draggable: false,
      //footerTemplateData: this.userResponse
    };
    this.modalRef = this.modalService.open(modalOptions);
  }

  /**
   * Get values from the time picker panel
   * @param data 
   */
  getTimeHandler(data) {
    if (this.timePickerSource == "From") {
      this.retrieveFromTime = data.timeVal;
      this.retrieveFromHours = data.drHours;
      this.retrieveFromMins = data.drMins;
      this.retrieveFromSecs = data.drSeconds;
      this.retrieveFromAMPM = data.AMPM;

      this.openFrom = false;
    } else {
      this.retrieveToTime = data.timeVal;
      this.retrieveToHours = data.drHours;
      this.retrieveToMins = data.drMins;
      this.retrieveToSecs = data.drSeconds;
      this.retrieveToAMPM = data.AMPM;

      this.openTo = false;
    }
    if (this.validateDateTime()) {
      this.timePickerGroup.reset();
    }
  }

  /**
   * Validate from and to date
   * @returns 
   */
  validateDateTime() {
    this.validDateRange = true;
    this.retrieveFromDate = new Date(this.retrieveFromDate);
    this.retrieveToDate = new Date(this.retrieveToDate);
    var fullFromDate = this.retrieveFromDate.getFullYear() + '-' + (this.retrieveFromDate.getMonth() + 1) + '-' + this.retrieveFromDate.getDate() + ' ' + this.retrieveFromTime;
    var fullToDate = this.retrieveToDate.getFullYear() + '-' + (this.retrieveToDate.getMonth() + 1) + '-' + this.retrieveToDate.getDate() + ' ' + this.retrieveToTime;
    
    if (new Date(fullToDate) < new Date(fullFromDate)) {
      this.error = { isError: true, errorMessage: 'End date should be greater than start date.' };
      this.validDateRange = false;
    } else {
      this.error = { isError: false, errorMessage: '' };
      this.validDateRange = true;
    }
    return this.validDateRange;
  }

  /**
   * Hide time picker overlay
   */
  hideTimePickerHandler(data) {
    this.openFrom = data;
    this.openTo = data;
  }

  /**
   * Set date time
   * @param source 
   */
  setDateTimeValue(source) {
    if (source == 'From') {
      this.openFrom = true;
      this.openTo = false;
      this.timePickerGroup.patchValue({
        drHours: this.retrieveFromHours,
        drMins: this.retrieveFromMins,
        drSeconds: this.retrieveFromSecs,
        AMPM: this.retrieveFromAMPM
      });
    } else {
      this.openFrom = false;
      this.openTo = true;
      this.timePickerGroup.patchValue({
        drHours: this.retrieveToHours,
        drMins: this.retrieveToMins,
        drSeconds: this.retrieveToSecs,
        AMPM: this.retrieveToAMPM
      });
    }
    this.timePickerSource = source;
  }
}

interface TransmissionReport {
  businessUnit: string;
  direction: string;
  receiptTimestamp: Date;
  sendTimestamp: Date;
  fileName: string;
  fileSize: number;
  status: string;
  messageId: number;
  modifiedFileName: string;
  fileSizeModified: string;
  selected: boolean;
}

interface FilterCols {
  field: string,
  name: string,
  term: string,
  matchMode: string
}