import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Logger } from '@phoenix/ui/common';
import { FeatureModuleServices } from '../feature.module.services';
import { UserAuthenticationDetails } from '../login/user.authentication.details';
import { ActionTypes, DataTableComponent, DataTableEventData, DataTableOptions, ExternalEventInfo, FilterUIType, MatchMode } from '@phoenix/ui/datatable';
import { DatepickerOptions } from '@phoenix/ui/datepicker';
import {
  CdkConnectedOverlay,
  ConnectedPosition,
  ScrollStrategyOptions,
  ScrollStrategy,
} from '@angular/cdk/overlay';
import { LoadingScreenService } from '../../core/services/loading-screen.service';

@Component({
  selector: 'dts-three-key-audit',
  templateUrl: './three-key-audit.component.html',
  styleUrls: ['./three-key-audit.component.scss'],
})
export class ThreeKeyAuditComponent implements OnInit {
  @ViewChild('threeSkeyGrid') threeSkeyGrid: DataTableComponent;
  @ViewChild('timePicker', { read: TemplateRef }) timePickerBody: TemplateRef<any>;
  constructor(
    private logger: Logger,
    private userAuthenticationDetails: UserAuthenticationDetails,
    private threeKeyAuditReportsService: FeatureModuleServices,
    private loadingService : LoadingScreenService,
    private formBuilder: FormBuilder
  ) { this.gridDataOptionsHandler(); }

  public rangeValidation: boolean = true;
  // isRespSuccess: boolean;
  isDataExists: boolean;
  errMessage: string;
  readonly USER_PERMISSION_DENIED = 'User Permission Denied.';
  currentDate: Date;
  gridData: [];
  retrieveFromDate: Date;
  retrieveFromTime: Date;
  retrieveFromHours: string;
  retrieveFromMins: string;
  retrieveFromSecs: string;
  retrieveFromAMPM: string;
  retrieveToDate: Date;
  retrieveToTime: Date;
  retrieveToHours: string;
  retrieveToMins: string;
  retrieveToSecs: string;
  retrieveToAMPM: string;
  format: string;
  searchObject;
  public pagerTypes = ['numeric', 'input'];
  public type = 'numeric';
  public buttonCount = 5;
  public info = true;
  public pageSizes = [
    10,
    20,
    50,
    {
      text: 'All',
      value: 'all',
    },
  ];
  public previousNext = true;
  public position = 'bottom';
  public pageSize = 10;
  gridOptions: DataTableOptions;
  dateRangeOptions: DatepickerOptions;
  validDateRange: boolean = true;
  date1Format = 'MM/DD/YYYY';
  hoursData: string[] = ['1', '2','3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  hoursVal = 12;
  minsData: number[];
  minVal = 1;
  secsData: number[];
  secVal = 1;
  openFrom = false;
  openTo = false;
  timePickerSource = '';

  timePickerGroup: FormGroup;
  error: any = { isError: false, errorMessage: '' };

  breadcrumbItems = [
    { label: 'Home', link: '/home' },
    { label: '3SKey audit' }
  ];

  positions: ConnectedPosition[] = [
    {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
      offsetY: -10,
    },
    {
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
      panelClass: 'no-enogh-space-at-bottom',
    },
  ];

  ngOnInit(): void {
    this.userAuthenticationDetails.breadcrumbEmitter.emit(this.breadcrumbItems);
    this.isDataExists = false;
    this.format = 'MMM dd, yyyy h:mm:ss a';
    const d = new Date();
    const utc = d.getTime() + d.getTimezoneOffset() * 60000;
    this.currentDate = new Date();

    this.dateRangeOptions = {
      minDate: new Date(2015, 2, 10, 22),
      maxDate: new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth(),
        this.currentDate.getDate(),
        this.hours12(this.currentDate),
        this.currentDate.getMinutes(),
        this.currentDate.getSeconds()
      )
    };

    this.timePickerGroup = this.formBuilder.group({
      drHours: new FormControl('', Validators.compose([Validators.required])),
      drMins: new FormControl('', Validators.compose([Validators.required])),
      drSeconds: new FormControl('', [Validators.required]),
      AMPM: new FormControl('', [Validators.required])
    });

    this.initializeFormFields();
    this.searchResults();
    this.getMinutes();
    this.validateDateTime();
  }

  hours12(date) {
    return (date.getHours() + 24) % 12 || 12;
  }

  searchResults() {
    this.logger.info('Populating request object for three-key-audit report');
    const startHours = this.retrieveFromHours;
    const endHours = this.retrieveToHours;
    const startMins = this.retrieveFromMins;
    const endMins = this.retrieveToMins;
    const startSec = this.retrieveFromSecs;
    const endSec = this.retrieveToSecs;
    this.retrieveFromDate = new Date(this.retrieveFromDate);
    this.retrieveToDate = new Date(this.retrieveToDate);
    const requestObj = {
      startDate:
        this.retrieveFromDate.getFullYear() + '-' + (this.retrieveFromDate.getMonth() + 1) + '-' + this.retrieveFromDate.getDate(),
      startTime: startHours + ':' + startMins + ':' + startSec,
      endDate: this.retrieveToDate.getFullYear() + '-' + (this.retrieveToDate.getMonth() + 1) + '-' + this.retrieveToDate.getDate(),
      endTime: endHours + ':' + endMins + ':' + endSec,
      startAMPM: this.retrieveFromAMPM,
      endAMPM: this.retrieveToAMPM
    };

    this.searchObject = requestObj;

    let eventInfo: ExternalEventInfo = {
      keepSorting: true,
      resetData: true
    };
    if (this.threeSkeyGrid)
      this.threeSkeyGrid.api.refresh({ info: eventInfo });
    this.logger.info('Making ThreeKeyAuditReports service call to fetch three-key audit report');
  }


  fetchThreeSKeyDataHandler(events) {
    if (!this.searchObject) return;
    this.loadingService.startLoading();
    this.threeKeyAuditReportsService.loadThreeKeyAuditReports(this.searchObject).subscribe(
      (responseData) => {
        this.logger.info('ThreeKeyAuditReports service call successfully completed, responseData', responseData);
        if (responseData.length) {
          this.isDataExists = true;
          this.gridData = responseData;
          if (events)
            this.onGridHandlerFn(events);
        } else {
          // this.showResults = false;
          this.isDataExists = false;
          this.errMessage = 'The search criteria provided no results. Modify your search criteria and try again';
        }
        this.loadingService.stopLoading();
      },
      (error) => {
        this.logger.info('ThreeKeyAuditReports service call failed, error', error);
        console.log(error);
        // this.showResults = false;
        this.isDataExists = false;
        if (this.USER_PERMISSION_DENIED === error?.error?.statusDescription) {
          this.errMessage = error?.error?.statusDescription;
        } else {
          this.errMessage = 'The search criteria provided no results. Modify your search criteria and try again.';
        }
        this.logger.error('Service call failed, error : ', error);
        this.loadingService.stopLoading();
      }
    );
  }

  initializeFormFields() {
    this.logger.info('Initialzing form fields');
    this.retrieveFromDate = new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth(),
      this.currentDate.getDate() - 1,
      this.currentDate.getHours(),
      this.currentDate.getMinutes(),
      this.currentDate.getSeconds()
    );
    this.retrieveFromHours = this.hours12(this.retrieveFromDate).toString();
    this.retrieveFromMins = this.retrieveFromDate.getMinutes() < 10 ? '0' + this.retrieveFromDate.getMinutes() : this.retrieveFromDate.getMinutes().toString();
    this.retrieveFromSecs = this.retrieveFromDate.getSeconds() < 10 ? '0' + this.retrieveFromDate.getSeconds().toString() : this.retrieveFromDate.getSeconds().toString();
    this.retrieveFromAMPM = this.retrieveFromDate.getHours() >= 12 ? 'PM' : 'AM';;

    var fromTimeVal;
    fromTimeVal = this.retrieveFromHours + ":" + this.retrieveFromMins + ":" + this.retrieveFromSecs + " " + this.retrieveFromAMPM;
    this.retrieveFromTime = fromTimeVal;

    this.retrieveToDate = new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth(),
      this.currentDate.getDate(),
      this.currentDate.getHours(),
      this.currentDate.getMinutes(),
      this.currentDate.getSeconds()
    );

    this.retrieveToHours = this.hours12(this.retrieveToDate).toString();
    this.retrieveToMins = this.retrieveToDate.getMinutes() < 10 ? '0' + this.retrieveToDate.getMinutes() : this.retrieveToDate.getMinutes().toString();
    this.retrieveToSecs = this.retrieveToDate.getSeconds() < 10 ? '0' + this.retrieveToDate.getSeconds() : this.retrieveToDate.getSeconds().toString();
    this.retrieveToAMPM = this.retrieveToDate.getHours() >= 12 ? 'PM' : 'AM';
    var toTimeVal;
    toTimeVal = this.retrieveToHours + ":" + this.retrieveToMins + ":" + this.retrieveToSecs + " " + this.retrieveToAMPM;
    this.retrieveToTime = toTimeVal;
    console.log(this.retrieveFromDate, this.retrieveToDate, new Date());
    console.log(fromTimeVal, toTimeVal);
  }

  /**
   * Setup fields for Grid.
   */
  gridDataOptionsHandler() {
    this.gridOptions = {
      smartView: {
        pageSize: this.pageSize,
        alertMessage: "No data found.",
        pagination: true,
        pageLinks: 8,
        rowsPerPageOptions: [10, 20, 40],
        handleEventsInternally: true
      },
      columnDefs: [
        {
          field: 'tokenid',
          name: 'Token ID',
          width: '15%',
          filter: {
            matchMode: MatchMode.CONTAINS
          }
        },
        {
          field: 'userAlias',
          name: 'Client alias',
          width: '15%',
          filter: { enabled: false }
        },
        {
          field: 'userid',
          name: 'Account name',
          width: '10%',
          filter: { enabled: false }
        },
        {
          field: 'operation',
          name: 'Operations',
          width: '10%',
          filter: {
            label: 'All',
            type: FilterUIType.SELECT,
            selectOptions: [{ value: '', label: 'All' }, { value: 'create', label: 'Create' }, { value: 'delete', label: 'Delete' }]
          }

        },
        {
          field: 'createdDate',
          name: 'Date',
          width: '10%',
          filter: { enabled: false }
        }
      ],
      data: []
    };

  }

  /**
   * Handle PHX grid internal events
   * @param eventData Grid events
   * @returns 
   */
  handleEvents(eventData: DataTableEventData): void {
    const events = eventData.events;

    if (!events) return;
    if (events.length >= 2) {
      this.fetchThreeSKeyDataHandler(events);
    }
  }

  /**
   * Rebind the grid data
   * @param events Grid events
   */
  onGridHandlerFn(events: any) {
    const loadEvent = events.find((event) => event.type === ActionTypes.LOAD_DATA);

    if (loadEvent) loadEvent.result = { data: this.gridData };

    this.threeSkeyGrid.api.handleResultEvents(events);
  }

  getMinutes() {
    var x = 1; //minutes interval
    var times = []; // time array
    var tt = 0; // start time
    for (var i = 0; tt < 60; i++) {
      var mm = (tt); // getting minutes of the hour in 0-55 format
      times[i] = ("0" + mm).slice(-2);
      tt = tt + x;
    }
    this.minsData = times;
    this.secsData = times;
  }

  /**
   * Get values from the time picker panel
   * @param data 
   */
   getTimeHandler(data) {
    if (this.timePickerSource == "From") {
      this.retrieveFromTime = data.timeVal;
      this.retrieveFromHours = data.drHours;
      this.retrieveFromMins = data.drMins;
      this.retrieveFromSecs = data.drSeconds;
      this.retrieveFromAMPM = data.AMPM;

      this.openFrom = false;
    } else {
      this.retrieveToTime = data.timeVal;
      this.retrieveToHours = data.drHours;
      this.retrieveToMins = data.drMins;
      this.retrieveToSecs = data.drSeconds;
      this.retrieveToAMPM = data.AMPM;

      this.openTo = false;
    }
    if (this.validateDateTime()) {
      this.timePickerGroup.reset();
    }
  }

  validateDateTime() {
    this.validDateRange = true;
    this.retrieveFromDate = new Date(this.retrieveFromDate);
    this.retrieveToDate = new Date(this.retrieveToDate);
    var fullFromDate = this.retrieveFromDate.getFullYear() + '-' + (this.retrieveFromDate.getMonth() + 1) + '-' + this.retrieveFromDate.getDate() + ' ' + this.retrieveFromTime;
    var fullToDate = this.retrieveToDate.getFullYear() + '-' + (this.retrieveToDate.getMonth() + 1) + '-' + this.retrieveToDate.getDate() + ' ' + this.retrieveToTime;
    
    if (new Date(fullToDate) < new Date(fullFromDate)) {
      this.error = {isError: true, errorMessage: 'End date should be greater than start date.'};
      this.validDateRange = false;
    }else{
      this.error = {isError: false, errorMessage: ''};
      this.validDateRange = true;
    }
    return this.validDateRange;
  }

  /**
   * Hide time picker overlay
   */
  hideTimePickerHandler(data) {
    this.openFrom = data;
    this.openTo = data;
  }


  setDateTimeValue(source){
    if(source == 'From'){
      this.openFrom = true;
      this.openTo = false;
      this.timePickerGroup.patchValue({
        drHours: this.retrieveFromHours,
        drMins: this.retrieveFromMins,
        drSeconds: this.retrieveFromSecs,
        AMPM: this.retrieveFromAMPM
      });
    }else{
      this.openFrom = false;
      this.openTo = true;
      this.timePickerGroup.patchValue({
        drHours: this.retrieveToHours,
        drMins: this.retrieveToMins,
        drSeconds: this.retrieveToSecs,
        AMPM: this.retrieveToAMPM
      });
    }
    this.timePickerSource = source;
  }

  closePanel(source) {
    if (source === 'From')
      this.openFrom = false;
    else
      this.openTo = false;
  }
}
