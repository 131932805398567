import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { apiUrls } from '../../feature-modules/app.urls.config';
import { environment } from 'projects/DtsUI/src/environments/environment';
import { AppHttpService } from './app.http.service';
import { UserAuthenticationDetails } from '../../feature-modules/login/user.authentication.details';
import { Logger } from '@phoenix/ui/common';

@Injectable()
export class AccountManagementService {

  constructor(private appHttpservice: AppHttpService,
              private logger: Logger,
              private userAuthenticationDetails: UserAuthenticationDetails) { }
  host: string;
  private data: any;

  // TODO:  Gett the authorization token in HTTP Interceptor before calling these services
  private httpOptions = {
    headers: new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    })
  };

  doHttpGet(url, config, httpOptions){
    return this.appHttpservice.doHttpGet(url, config, httpOptions)
    .pipe(
      map((data: any) => {
        this.logger.info('AccountManagementService http.get url: ' + url + '   response tap' + JSON.stringify(data));
        return data.responseData;
      }, (err: { status: string; }) => {
        this.logger.info('AccountManagementService GET: Failed to GET http data - err.status: ' + err.status);
        this.logger.error('AccountManagementService Something went wrong!: error: ' + JSON.stringify(err));
        return err;
      }
      )
    );
  }

  doHttpPost(url, req, httpOptions){
    return this.appHttpservice.doHttpPost(url, req, httpOptions)
    .pipe(
      map((resp: any) => {
        this.logger.info('AccountManagementService http.get url: ' + url + '   response tap' + JSON.stringify(resp));
        return resp.responseData;
      }, (err: { status: string; }) => {
        this.logger.error('AccountManagementService POST: Failed to POST http data - err.status: ' + err.status);
        this.logger.error('AccountManagementService Something went wrong!: error: ' + JSON.stringify(err));
        return err;
      })
    );
  }

  submitChangePasswordRequest(req) {
    const url: string = environment.host + apiUrls.changePassword;
    //this.logger.info('AccountManagementService this.userAuthenticationDetails.getCSRFToken()',this.userAuthenticationDetails.getCSRFToken());
    this.httpOptions.headers.append('X-BFS-TOKEN', this.userAuthenticationDetails.getCSRFToken());
    return this.doHttpPost(url, req, this.httpOptions);
  }

  // Channel service
  getChannels(user: string) {
    const url: string = environment.host + apiUrls.channels;
    const config: object = {
      ref: 'api/channels'
    };
    return this.doHttpGet(url, config, this.httpOptions);
  }

  // Account management service
  loadCurrentAccountData() {
    const url: string = '';
    const config: object = {
      ref: 'api/getCurrentAccountData'
    };
    return this.doHttpGet(url, config, this.httpOptions);
  }

  loadRowExpandedContent(req) {
    const url: string = '';
    const config: object = {
      ref: 'api/getAccountData'
    };
    return this.doHttpPost(url, req, this.httpOptions);
  }

  // manageMFAService
  requestOTP(req) {
    const url: string = environment.host + apiUrls.requestOTP;
    const config: object = {
      ref: 'api/requestOTP'
    };
    return this.doHttpPost(url, req, this.httpOptions);
  }

  validateOTPForMFA(req) {
    const url: string = environment.host + apiUrls.validateOTPForMFA;
    const config: object = {
      ref: 'api/validateOTPForMFA'
    };
    return this.doHttpPost(url, req, this.httpOptions);
  }

  // Update account details
  updateAccDetails(req) {
    const url: string = environment.host + apiUrls.updateAccountDetails;
    const config: object = {
      ref: 'api/updateAccountDetails'
    };
    return this.doHttpPost(url, req, this.httpOptions);
  }
}