<section class="px-container">
  <div class="section-title">
    <h1>Manage account</h1>
  </div>
  <h2>Current accounts</h2>
  <div>
    <phx-datatable [options]="optionsScroll" id="tblAccount" #tblAccount  (onTableEvents)="handleEvents($event)">
      <ng-template phxTemplate="column" phxField="userName" phxType="row" let-rowData>
        <a href="javascript:void(0)" (click)="viewDropoffUserInfo(rowData)" title="Click to view details">{{rowData.userName}}</a>
      </ng-template>
      <ng-template phxTemplate="column" phxField="profile" phxType="row" let-rowData>
        <a href="javascript:void(0)" *ngIf="rowData.role.length > 0" (click)="viewDropoffUserInfo(rowData)" title="Click to view details">{{rowData.profile}}</a>
      </ng-template>
    </phx-datatable>
  </div>
</section>
<ng-template #dropOffUserInformation>
  <div class="add-account-modal-content">
    <!-- style="line-height: 1.6;" -->
    <div class="px-form-group">
      <legend>Account Name</legend>
      <div>{{accountName}}</div>
    </div>
    <div class="px-form-group">
      <legend for="companyName">Company Name</legend>
      <div>{{companyName}}</div>
    </div>
    <div class="px-form-group">
      <legend for="firstName">First Name</legend>
      <div>{{firstName}}</div>
    </div>
    <div class="px-form-group">
      <legend for="lastName">Last Name</legend>
      <div>{{lastName}}</div>
    </div>
    <div class="px-form-group">
      <legend for="email">Email</legend>
      <div>{{email}}</div>
    </div>
    <div class="px-form-group">
      <div class="px-row">
        <div class="px-col-md-6"><legend for="mobileNum">Country code</legend></div>
        <div class="px-col-md-6"> <legend for="mobileNum">Phone Number</legend></div>
        <div class="px-col-md-6">{{phoneCode}}</div>
        <div class="px-col-md-6">{{phoneNumber}}</div>
      </div>
    </div>
    <div class="px-form-group">
      <legend for="mobileNum">Profile Type</legend>
      <div>{{profile}}</div>
    </div>
  </div>
</ng-template>
<ng-template #footerClose>
  <button class="px-mr-2" type="button" phxButton (click)="closeHandler()" title="Close">Close</button>
</ng-template>