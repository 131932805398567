<footer class="_text-14">
  <div class="px-container">
    <div>
      <a [routerLink]="null" title="Contact us" (click)="contactUs.openContactUsModal()">Contact us</a>
      &nbsp;|&nbsp;
      <a href="https://www.bankofamerica.com/privacy/overview.go" target="_blank" title="Privacy & security (Consumer)">Privacy & security (Consumer)</a>
      &nbsp;|&nbsp;
      <a href="https://business.bofa.com/content/dam/flagship/gbgmr/global-privacy-notices/BofA-Institutional-Digital-Privacy-Notice.pdf" target="_blank" title="Digital Privacy Notice (Institutional)">Digital Privacy Notice (Institutional)</a>
      <ng-container *ngIf="!isInternalApp">
        &nbsp;|&nbsp;
         <a id="AIT_54071" class="ot-sdk-show-settings" title="Your Privacy Choices" [routerLink]="null"></a> &nbsp;<img height="10" width="30" src="assets/images/privacyChoice_white.png">
      </ng-container>
    </div>
    <div>© {{ year }} Bank of America Corporation. All rights reserved.</div>
    <div>
      <img src="assets/images/logos/BofA_logo_white.svg" title="Bank of America logo" alt="Bank of America logo" />
    </div>
  </div>
</footer>
<dts-contact-us-modal></dts-contact-us-modal>
<dts-cookie-center-modal></dts-cookie-center-modal>
