import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActionTypes, DataTableComponent, DataTableEventData, DataTableOptions, ExternalEventInfo, SortType } from '@phoenix/ui/datatable';
import { ModalOptions, ModalService } from '@phoenix/ui/modal';
import { UserAuthenticationDetails } from '../login/user.authentication.details';
import { UserDto } from '../models/user-dto';
import { Subscription } from 'rxjs';
// import { userDtoSample } from '../models/user-dto-sample';

@Component({
  selector: 'dts-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.scss']
})
export class AddAccountComponent implements OnInit {


  @ViewChild('dropOffUserInformation', { read: TemplateRef }) dropOffUserInformation: TemplateRef<any>;
  @ViewChild('footerClose', { read: TemplateRef }) footerClose: TemplateRef<any>;
  @ViewChild('tblAccount') phxGrid: DataTableComponent;

  countryCodeList: { 'id': string; 'value': string; }[];
  mfaStatus: any;
  isError: boolean;
  nonMfaUser: any;
  profileList: { 'label': string; 'value': string; }[];
  userDto: UserDto;
  tabrights: any;
  addaccright: boolean;
  chnlright: boolean;
  scope: any;
  dltModalInstance: any;
  rowContentModalInstance: any;
  showAddAccount: boolean;
  accountName: any;
  passcode: any;
  profile: any;
  emailForMfa: any;
  mobileCodeMfa: any;
  mobileForMfa: any;
  companyName: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneCode: string;
  phoneNumber: string;
  accountInfo: FormGroup;


  iscModal: any;
  accountData: any;
  modalRef: any;

  public pageSizeScroll = 10;
  readonly totalScroll = 10;
  optionsScroll: DataTableOptions;
  loading = false;
  gridSubscription: Subscription;

  breadcrumbItems = [
    { label: 'Home', link: '/home' },
    { label: 'Manage account', link: '/accountmanagement/addAccount' }
  ];


  constructor(private userAuthenticationDetails: UserAuthenticationDetails,
    private modalService: ModalService) { 
      this.gridDataHandler();
    }


  ngOnInit(): void {

    this.userAuthenticationDetails.breadcrumbEmitter.emit(this.breadcrumbItems);
    this.countryCodeList = [
      { id: '+91', value: 'India +91' },
      { id: '+1', value: 'United States +1' },
      //{ id: '+1', value: 'Canada +1' },
      { id: '+61', value: 'Australia +61' },
      { id: '+43', value: 'Austria +43' },
      { id: '+81', value: 'Japan +81' },
      { id: '+39', value: 'Italy +39' },
      { id: '+358', value: 'Finland +358' },
      { id: '+238', value: 'Cape Verde +238' },
      { id: '+975', value: 'Bhutan +975' },
      { id: '+32', value: 'Belgium +32' },
      { id: '+55', value: 'Brazil +55' },
      { id: '+86', value: 'China +86' }
    ];

    this.profileList = [{ label: 'B2BI_ADMIN', value: 'Admin' },
    { label: 'B2BI_MBI_SEND', value: 'MBI Send' },
    { label: 'B2BI_MBI_RETRIEVE', value: 'MBI Retrieve' },
    { label: 'B2BI_MBI_ALL', value: 'MBI All' },
    { label: 'B2BI_ContactOnly', value: 'Contact only' },
    { label: 'B2BI_API_Account', value: 'API Account' },
    { label: 'B2BI_SupportAccount', value: 'SupportAccount' },
    { label: 'B2BI_ServiceAccount', value: 'ServiceAccount' }
    ];

    this.accountInfo = new FormGroup({
      userName: new FormControl({ value: '', disabled: true }, [Validators.required]),
      companyName: new FormControl({ value: '', disabled: true }, [Validators.required]),
      firstName: new FormControl({ value: '', disabled: true }, [Validators.required]),
      lastName: new FormControl({ value: '', disabled: true }, [Validators.required]),
      email: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.email]),
      countryCode: new FormControl({ value: '', disabled: true }),
      mobileNum: new FormControl({ value: '', disabled: true }),
      profileType: new FormControl({ value: '', disabled: true })
    });

    this.mfaStatus = false;
    this.isError = false;
    let eventInfo: ExternalEventInfo = {
      keepSorting: true,
      resetData: true
    };
  }

  loadUserAuthDetails(events) {
    let obj1 = this.userAuthenticationDetails.getTempUserObjString();
    if (obj1 != undefined) {
      obj1 = obj1.replace('\\', '');
      this.userDto = new UserDto(JSON.parse(obj1));
      if (this.userDto.profile !== undefined) {
        console.log('loadUserAuthDetails():this.profile ' + JSON.stringify(this.userDto.profile));
        this.tabrights = this.userDto.profile.rights;
        this.addaccright = this.contains(this.tabrights, 'AccountManagement');
        this.chnlright = this.contains(this.tabrights, 'Channels');
      }
      this.accountData = this.userDto.dropOffUsersInfo;
      if (events)
        this.onGridHandlerFn(events);
    }
  }

  contains(a, obj) {
    for (let i = 0; i < a.length; i++) {
      if (a[i].rightName === obj) {
        return true;
      }
    }
    return false;
  }

  displayRowContent(row) {
    row.updateSuccess = false;
    this.openContentModalWindow(row);
  }

  deleteRow(row, data) {
    this.scope.deleteObj = {
      row: row
    };
    this.openDeleteModal(data);
  }

  openDeleteModal(data) {
    const config = {
      templateUrl: 'scripts/accountManagement/partials/accountDeleteModal.html',
      style: 'isc-internal',
      size: 'sm',
      draggable: true,
      scope: this.scope,
      height: '100px'
    };
    this.scope.data = data;
    this.dltModalInstance = this.iscModal.open(config);
  }

  deleteAccount() {
    if (this.scope.deleteObj) {
      const row = this.scope.deleteObj.row;

      const indexOfRow = this.scope.gridOptions.data.indexOf(row);
      if (indexOfRow !== -1) {
        this.scope.gridOptions.data.splice(indexOfRow, 1);
      }
    }
    this.dltModalInstance.close();
  }

  addAccount() {
    this.showAddAccount = true;
  }

  submitAddAccount() {
    // Make service call to submit add account request
  }

  updateRow(row) {
    row.updateSuccess = true;
  }

  disableUpdate(row) {
    if (row.inputData !== undefined) {
      if (row.inputData.accountName === '' || row.inputData.accountName === undefined || row.inputData.profileType === undefined) {
        return true;
      }
      if (this.nonMfaUser) { // check for this MFA condition
        if (row.inputData.emailForMfa === '' || row.inputData.emailForMfa === undefined || row.inputData.mobileCodeMfa === undefined || row.inputData.mobileForMfa === '' || row.inputData.mobileForMfa === undefined) {
          return true;
        }
      }
    }
    return false;
  }
  disableAdd() {
    if (this.accountName === undefined || this.accountName === '' || this.passcode === undefined || this.passcode === '' || this.profile === undefined || this.profile === '') {
      return true;
    }
    if (this.nonMfaUser) {
      if (this.emailForMfa === undefined || this.emailForMfa === '' || this.mobileCodeMfa === undefined || this.mobileCodeMfa === '' || this.mobileForMfa === undefined || this.mobileForMfa === '') {
        return true;
      }
    }
    return false;
  }

  cancelAdd() {
    this.accountName = '';
    this.companyName = '';
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.phoneCode = '';
    this.phoneNumber = '';
    this.passcode = '';
    this.profile = '';
    this.emailForMfa = '';
    this.mobileCodeMfa = '';
    this.mobileForMfa = '';
    this.showAddAccount = false;
  }

  openContentModalWindow(row) {
    const config = {
      templateUrl: 'scripts/accountManagement/partials/rowContentModal_AccManagement.html',
      style: 'isc-internal',
      size: 'lg',
      draggable: true,
      scope: this.scope

    };
    // console.log(row);
    this.scope.row = row;
    // this.scope.errorAddingComment = false;
    this.rowContentModalInstance = this.iscModal.open(config);
  }

  viewDropoffUserInfo(accountData) {
    //console.log('viewDropoffUserInfo', JSON.stringify(this.userDto.dropOffUsersInfo));
    this.accountName = accountData.userName;
    this.companyName = accountData.companyName;
    this.firstName = accountData.firstName;
    this.lastName = accountData.lastName;
    this.email = accountData.email;
    this.phoneCode = accountData.countryCode;
    this.phoneNumber = '';
    this.profile = '';
    const modalOptions: ModalOptions = {
      title: 'Account Details for ' + accountData.userName,
      content: this.dropOffUserInformation,
      footer: this.footerClose,
      dismissable: true,
      position: {
        top: '120px'
      },
      size: 'lg',
      hasBackdrop: true,
      draggable: true,
      styleClass: 'dropOffUserInformationStyle',
      closeBtnAriaLabel: 'close'
    };
    this.modalRef = this.modalService.open(modalOptions);
  }

  /**
   * Close modal
   */
  closeHandler() {
    this.modalService.close(this.modalRef.modal);
  }

  gridDataHandler() {
    this.optionsScroll = {
      smartView: {
        alertMessage: 'No data found in Manage account',
        pagination: false,
        handleEventsInternally: true
      },
      columnDefs: [
        {
          field: 'userName',
          name: 'Account name',
          filter: { enabled: false }
        },
        {
          field: 'role', name: 'Profile',
          filter: { enabled: false }
        }],
      data: []
    };
  }

  /**
   * Handle PHX grid internal events
   * @param eventData Grid events
   * @returns 
   */
  handleEvents(eventData: DataTableEventData): void {
    
    const events = eventData.events;
    console.log(events);
    if (!events) return;
    if (events.length >= 2) {
      this.loadUserAuthDetails(events);
    }
  }

  /**
   * Rebind the grid data
   * @param events Grid events
   */
  onGridHandlerFn(events: any) {
    const loadEvent = events.find((event) => event.type === ActionTypes.LOAD_DATA);

    if (loadEvent) loadEvent.result = { data: this.accountData };

    this.phxGrid.api.handleResultEvents(events);
  }
}