<ng-template #header>
  <h2>
    Multi-Factor Authentication Error
  </h2>
</ng-template>
<ng-template #troubleSiginIn>
  <div class="contact-modal-content">
    <p>Your details for multi-factor authentication are not available. Contact us for support.</p>
    <h3>Assistance by phone</h3>
    <div class="contact-list">
      <div>
        <p>U.S. callers <br>
        Option 1: File verifications<br>
        Option 2: Technical support</p>
        <span>
          1-855-515-6600
        </span>
      </div>
      <div>
        <p>Callers outside the U.S. <br>
          Availability: 24 hours/day X 7 days/week 
        </p>
        <span>
          1-469-201-4811
        </span>
      </div>
    </div>
    <h3>Assistance by email</h3>
    <div class="contact-list px-mb-5">
      <div>
        <span><a href="mailto:dts.service.desk@bofa.com"
            title="Mail to - dts.service.desk@bofa.com">dts.service.desk@bofa.com</a></span>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #troubleSiginInFooter>
  <button class="px-mr-2" type="button" phxButton (click)="closeTSiginInHandler()" title="Close">Close</button>
</ng-template>