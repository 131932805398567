import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Logger } from '@phoenix/ui/common';
import { Router } from '@angular/router';
import { ModalOptions, ModalService } from '@phoenix/ui/modal';
import { FeatureModuleServices } from '../feature.module.services';
import { ApproveFile } from '../models/approve-file';
import { UserAuthenticationDetails } from '../login/user.authentication.details';
import { Subscription } from 'rxjs';
import { ActionTypes, DataTableComponent, DataTableEventData, DataTableOptions, ExternalEventInfo } from '@phoenix/ui/datatable';
import { LoadingScreenService } from '../../core/services/loading-screen.service';

@Component({
  selector: 'dts-approver',
  templateUrl: './approver.component.html',
  styleUrls: ['./approver.component.scss']
})
export class ApproverComponent implements OnInit {

  constructor(private approverService: FeatureModuleServices,
    private modalService: ModalService,
    private logger: Logger,
    private userAuthenticationDetails: UserAuthenticationDetails,
    private loadingService: LoadingScreenService,
    public router: Router) {
    this.selectedRowItems = [];
    this.gridDataOptionsHandler();
  }

  @ViewChild('grid') gridOptions: DataTableComponent;
  @ViewChild('successMsg', { read: TemplateRef }) successMsg: TemplateRef<any>;
  @ViewChild('footerSuccess', { read: TemplateRef }) footerSuccess: TemplateRef<any>;
  @ViewChild('confirmMsg', { read: TemplateRef }) confirmMsg: TemplateRef<any>;
  @ViewChild('confirmFooter', { read: TemplateRef }) confirmFooter: TemplateRef<any>;
  @ViewChild('headerTitle', { read: TemplateRef }) confirmHeader: TemplateRef<any>;
  @ViewChild('headerTitleSuccess', { read: TemplateRef }) successHeader: TemplateRef<any>;

  dirLoaded: boolean = false;
  showMailBoxGrid: boolean = true;
  isRespSuccess: boolean = true;
  isDataExists: boolean = true;
  showLoader: boolean = false;
  selection = 'multiple';

  dirErrMsg: string = 'Something went wrong. Please try again later.'
  noDirTextMsg: string = 'There are no files pending for approval.';
  noFileTextMsg: string = 'There are no files pending for approval.';
  unavailableServiceTextMsg: string = 'Something went wrong. Please try again later.';
  errMessage: string = 'errMessage - TODO'
  disableLink: boolean = false;
  selectAll: boolean = true;
  submitDisable: boolean = true;
  mailBoxSelected: string;
  totalRows: any;
  noDataMessage: string;
  directoriesLength: number;
  gridData: any;
  mailBoxTypes = [];
  mailboxList: string[] = [''];
  isShowSelectAll: boolean;
  gridSubscription: Subscription;

  skip = 0;
  pageSize = 5;
  checkboxOnly = true;
  mode: any = 'multiple';
  drag = false;
  selectedfileIds: string[] = [];
  selectedRowItems: any[];
  filelist: ApproveFile[];
  isAnyRowSelected: boolean = this.selectedfileIds.length > 0;
  selectedfiles: ApproveFile[] = []
  modalRef: any;
  responseMsg = {
    header: '',
    body: ''
  };
  confirmApprove: string = 'Are you sure you want to approve these files?';
  confirmReject: string = 'Are you sure you want to reject these files?';
  confirmationMsg: string;
  userResponse: string;
  confirmationHeader: string;
  open = false;
  optionsScroll: DataTableOptions;

  breadcrumbItems = [
    { label: 'Home', link: '/home' },
    { label: 'Approve' }
  ];

  ngOnInit(): void {
    this.userAuthenticationDetails.breadcrumbEmitter.emit(this.breadcrumbItems);
    this.showMailBoxGrid = true;
    this.isRespSuccess = true;
    this.isShowSelectAll = true;
    this.isDataExists = true;
    this.directoriesLength = 0;
    this.mailBoxTypes = [];
    this.dirLoaded = true;
    this.submitDisable = false;
    this.getApproverFileDirectoriesService();
  }

  /**
   * Method to invoke on dropdown change. 
   * @param value 
   */
  onModelChangeHandler(value) {
    this.logger.info('dropdown model changed to: ', value);
    this.logger.info('Mailbox selected: ' + this.mailBoxSelected);

    let eventInfo: ExternalEventInfo = {
      keepSorting: true,
      resetData: true
    };
    if (this.mailBoxSelected)
      this.gridOptions.api.refresh({ info: eventInfo });
  }

  /**
   * Method to call on the approve button click
   */
  public onClickApprove(): void {
    this.logger.info('Approve button was clicked event: ' + event);
    this.userResponse = 'APP';
    this.openConfirmationModal();
  }

  /**
   * Method to call on the reject button click
   */
  public onClickReject(): void {
    this.logger.info('Reject button was clicked event: ' + event);
    this.userResponse = 'DEN';
    this.openConfirmationModal();
  }

  /**
   * 
   * @param row Method to handle the selected file download from the table.
   */
  fileDownloadHandler(row: { fileName: string; nodeName: string; }) {
    this.logger.info('FILEDOWNLOAD: Mailbox: ' + this.mailBoxSelected + ' for row data: ' + JSON.stringify(row));
    this.logger.info('Mailbox: ' + this.mailBoxSelected + ' row.fileName: ' + row.fileName + ' row.nodeName: ' + row.nodeName);
    window.open('/b2bi/services/approverFileDownload?dirName=' + this.mailBoxSelected + '&fileName=' + row.fileName + '&nodeName=' + row.nodeName, '_self');

  }

  /**
   * Method to load mailboxes list to dropdown.
   */
  getApproverFileDirectoriesService() {
    this.loadingService.startLoading();
    this.approverService.getApproverFileDirectory()
      .subscribe(response => {
        this.logger.info('Service call completed successfully, response : ', response);
        this.mailboxList = [...response];
        this.directoriesLength = this.mailboxList.length;

        this.mailBoxTypes = response;
        this.createMailBoxObj();

        this.dirLoaded = true;
        this.loadingService.stopLoading();
      }, err => {
        this.logger.error('Service call failed, error: ', err.status, err.statusText);
        this.dirLoaded = false;
        switch (err.status) {
          case 500:      // internal error for when there's no mailbox directories
            this.dirErrMsg = this.noDirTextMsg;
            break;
          case 503:      // service unavailable
            this.dirErrMsg = this.unavailableServiceTextMsg;
            break;
          case 303:      // Downstream comm error from service, 5100 backend service error code
            this.dirErrMsg = this.unavailableServiceTextMsg;
            break;
          case 403:     // request forbidden
            this.router.navigate(['/home']);
            break;
          case 408:     // timeout error
            this.router.navigate(['/login']);
            break;
        }
	this.loadingService.stopLoading();
      });
    if ((this.mailBoxSelected !== undefined || this.mailBoxSelected !== null || this.mailBoxSelected !== '') && this.mailboxList.includes(this.mailBoxSelected)) {
      this.gridOptions.api.refresh();
    }
  }

  /**
   * Method to assign mailbox values.
   */
  createMailBoxObj() {
    const mailBoxTypesObj = [];
    this.mailBoxTypes.forEach((value) => {
      if (value === '/') {
        this.mailBoxSelected = value;
      }
      mailBoxTypesObj.push({ label: value, value: value });
    });
    this.mailBoxTypes = mailBoxTypesObj;
  }

  /**
 * Returns the selected protocol data
 * @param events 
 */
  public getApproverMailBoxDetailsService(events) {
    this.logger.info('Making call to Approverservice');
    const sortEvent = events.find((event) => event.type === ActionTypes.SORT);

    this.approverService.getApproverMailBoxDetails(this.mailBoxSelected)
      .subscribe(resp => {
        if (Array.isArray(resp)) {
          this.filelist = resp;

          if (sortEvent && sortEvent.info.sortCols.length > 0)
            this.filelist.sort(this.sortDynamic(sortEvent.info.sortCols[0].field, sortEvent.info.sortCols[0].direction));
          else
            this.filelist.sort(this.sortDynamic("received", "desc"));

          this.isAnyRowSelected = false;
          this.selectedfiles = [];
          this.selectedfileIds = [];

          if (events)
            this.onGridHandlerFn(events);
        } else {
          this.isDataExists = false;
          this.noDataMessage = resp;
          this.gridData = [];
          this.isAnyRowSelected = false;
        }

      }, err => {
        this.logger.error('Mailbox details service, error : ', err.status, err.statusText);
        this.dirLoaded = false;
        switch (err.status) {
          case 500:      // internal error for when there's no file for approval
            this.dirErrMsg = this.noFileTextMsg;
            break;
          case 503:      // service unavailable
            this.dirErrMsg = this.unavailableServiceTextMsg;
            break;
          case 303:      // Downstream comm error from service, 5100 backend service error code
            this.dirErrMsg = this.unavailableServiceTextMsg;
            break;
          case 403:     // request forbidden
            this.router.navigate(['/home']);
            break;
          case 408:     // timeout error
            this.router.navigate(['/login']);
            break;
        }
      });

  }

  /**
   * Custom method to implement sorting on the grid datatable.
   * @param key 
   * @param order 
   * @returns 
   */
  sortDynamic(key, order = 'asc') {
    const sorderOrder = order === 'asc' ? 1 : -1;
    return (a, b) => {
      const A = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
      const B = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];

      if (A < B) {
        return sorderOrder * -1;
      } else if (A > B) {
        return sorderOrder * 1;
      } else {
        return 0;
      }
    }
  }

  /**
   * Method to open confirmation modal on Approve/Reject button click.
   */
  openConfirmationModal() {
    this.confirmationMsg = 'APP' === this.userResponse ? this.confirmApprove : this.confirmReject;

    this.confirmationHeader = 'APP' === this.userResponse ? "Approve" : "Reject";
    const modalOptions: ModalOptions = {
      title: this.confirmHeader,
      content: this.confirmMsg,
      footer: this.confirmFooter,
      dismissable: true,
      position: {
        top: '120px'
      },
      size: 'md',
      hasBackdrop: true,
      draggable: false,
      footerTemplateData: this.userResponse
    };
    this.modalRef = this.modalService.open(modalOptions);
  }

  /**
   * 
   * @returns Method to set disabled buttons based on the selected rows.
   */
  getSelected(): number {
    if (this.gridOptions && this.gridOptions.api.getSelectedCount() > 0) {
      this.isAnyRowSelected = true;
    }
    else {
      this.isAnyRowSelected = false;
    }
    return this.gridOptions ? this.gridOptions.api.getSelectedCount() : 0;
  }

  /**
   * Method to approve/reject selected files.
   * @param response 
   */
  submitResponse(response: string) {
    this.logger.info('Making call to Approverservice: ' + response);
    this.submitDisable = true;
    const requestObj = {
      approvalStatus: response,
      approvalFileResp: this.selectedfiles
    };
    this.modalService.close();
    this.approverService.approveRejectFile(requestObj)
      .subscribe((resp: any) => {
        this.logger.info('Approver service call completed successfully, response : ', resp);
        if (resp === 'SUCCESS' && response === 'APP') {
          this.responseMsg.header = 'Approval completed';
          this.responseMsg.body = 'Files have been approved successfully.';
          this.openResponseModal();
        } else if (resp === 'SUCCESS' && response === 'DEN') {
          this.responseMsg.header = 'Reject completed';
          this.responseMsg.body = 'Files have been rejected successfully.';
          this.openResponseModal();
        } else {
          this.responseMsg.header = 'Failure';
          this.responseMsg.body = 'Failed to process your request. Please try again.';
          this.openResponseModal();
        }
      }, error => {
        this.logger.error('Approver service call failed , error : ', error);
        this.responseMsg.header = 'Failure';
        this.responseMsg.body = error?.error?.statusDescription || 'Failed to process your request. Please try again.';
        this.openResponseModal();
      });
    this.userResponse = null;
    this.selectedfileIds = [];
  }

  /**
   * Open success modal.
   */
  openResponseModal() {
    const modalOptions: ModalOptions = {
      title: this.successHeader,
      content: this.successMsg,
      footer: this.footerSuccess,
      dismissable: true,
      position: {
        top: '120px'
      },
      size: 'md',
      hasBackdrop: true,
      draggable: false
    };
    this.modalRef = this.modalService.open(modalOptions);
    this.modalRef
      .beforeClosed()
      .pipe(
        this.getApproverFileDirectoriesService()
      ).subscribe();
  }
  closeModal() {
    this.modalService.close();
  }
  closeConfirmModal() {
    this.userResponse = null;
    this.modalService.close();
  }

  /**
   * Method to set columns in the grid.
   */
  gridDataOptionsHandler() {
    this.optionsScroll = {
      smartView: {
        alertMessage: 'No results to display.',
        pagination: false,
        handleEventsInternally: false,
        idField: 'msgId',
        selectField: 'selected',
        hideSelectAll: true
      },
      columnDefs: [
        {
          field: 'fileName',
          name: 'File name',
          filter: { enabled: false }
        },
        {
          field: 'received',
          name: 'Received',
          width: '200px',
          filter: { enabled: false }
        },
        {
          field: 'sender',
          name: 'Sender',
          filter: { enabled: false },
          width: '100px'
        },
        {
          field: 'status',
          name: 'Status',
          filter: { enabled: false },
          width: '200px'
        },
        {
          field: 'fileDownload',
          name: ' ',
          filter: { enabled: false },
          sort: { enabled: false },
          width: '40px'
        }
      ],
      data: []
    };
  }

  /**
   * Method to handle grid events. Load/select row/sort
   * @param eventData 
   * @returns 
   */
  handleEvents(eventData: DataTableEventData): void {
    const events = eventData.events;
    if (!events) return;

    if (events.find(e => e.type === 'SELECT_ROW')) {
      this.getSelected();
      if (this.isAnyRowSelected) {
        const selectEvent = events.find(e => e.type === 'SELECT_ROW');
        const key = Object.keys(selectEvent.info.ids)[0];

        if (this.selectedfileIds.find(id => id === key)) {
          this.selectedfileIds = this.selectedfileIds.filter(id => id !== key);
          this.selectedfiles = this.selectedfiles.filter(item => item.msgId !== key);
        } else {
          this.selectedfileIds.push(key);
          const file = this.filelist.find(fileSelected => fileSelected.msgId === key);
          file.selected = true;
          this.selectedfiles.push(file);
        }
      }
      this.onGridHandlerFn(events);
    }
    else if (events.length >= 2 && this.mailBoxSelected && events.find(e => e.type === 'LOAD')) {
      this.getApproverMailBoxDetailsService(events);
    }
    else {
      this.onGridHandlerFn(events);
    }


  }

  /**
   * Method to refresh data.
   * @param events 
   */
  onGridHandlerFn(events: any) {
    const loadEvent = events.find((event) => event.type === ActionTypes.LOAD_DATA);
    if (loadEvent) loadEvent.result = { data: this.filelist };
    this.gridOptions.api.handleResultEvents(events);
  }

  logInternalEvent(eventData: DataTableEventData): void {
    this.logger.info('Internal events: ', eventData.events);
  }
}
