<ng-template #header>
  <h2>
    Sign in assistance
  </h2>
</ng-template>
<ng-template #contactheader>
  <h2>
    Contact us
  </h2>
</ng-template>
<ng-template #troubleSiginIn>
  <div class="contact-modal-content">
    <p>Contact us for technical support.</p>
    <h3>Assistance by phone</h3>
    <div class="contact-list">
      <div>
        <p>U.S. callers </p>
        <span>
          1-855-515-6600
        </span>
      </div>
      <div>
        <p>U.S. Public Sector/Government Agencies </p>
        <span>
        1-855-546-3405
        </span>
      </div>
      <div>
        <p>Callers outside the U.S. <br>
          <a href="javascript:void(0)" title="View global numbers" (click)="openGlobalNosHandler()">View global
            numbers</a>
        </p>
        <span>
          1-469-201-4811
        </span>
      </div>
    </div>
    <h3>Assistance by email</h3>
    <div class="contact-list px-mb-5">
      <div>
        <span><a href="mailto:dts.service.desk@bofa.com"
            title="Mail to - dts.service.desk@bofa.com">dts.service.desk@bofa.com</a></span>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #troubleSiginInFooter>
  <button class="px-mr-2" type="button" phxButton (click)="closeTSiginInHandler()" title="Close">Close</button>
</ng-template>

<ng-template #globalheader>
  <h2>
    Global numbers
  </h2>
</ng-template>
<ng-template #hotLineNo style="overflow: visible;">
  <div class="px-mt-1">
    <div class="cu-table">
      <table border="1px" style="border-collapse: collapse; width: 100%; border-color: #CCC; border-style: solid;">
        <tbody class="cu-table-border-none">
          <tr>
            <th style="width: 50%; text-align: left;" class="cu-header cu-table-header">Country</th>
            <th style="width: 50%; text-align: left;" class="cu-header cu-table-header">Number</th>
          </tr>
          <tr *ngFor="let countryObj of countryData">
            <td style="width: 50%; text-align: left;">{{countryObj.country}}</td>
            <td style="width: 50%;text-align: left;">{{countryObj.number}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>
<ng-template #hotLineNoFooter>
  <button class="px-mr-2" type="button" phxButton (click)="closeHotlineNoHandler()" title="Close">Close</button>
</ng-template>