  <!-- start : header -->
<dts-header></dts-header>
<!-- end : header -->
<!--<dts-nav-clear *ngIf="authDetails?.getLoginStatus()"></dts-nav-clear>-->
<dts-user-inactivity *ngIf="authDetails?.getLoginStatus()"></dts-user-inactivity>
<!-- <dts-dashboard></dts-dashboard> -->
<dts-loading-screen></dts-loading-screen>
<!--start : router content-->
<div id="mainContent" [style.minHeight.px]="innerHeightNumber">    
    <div class="px-container breadcrumb-panel">
        <div *ngIf="breadcrumbItems && breadcrumbItems.length > 0">
            <phx-breadcrumb [list]="breadcrumbItems"></phx-breadcrumb>
        </div>
        <div *ngIf="breadcrumbItems && breadcrumbItems.length > 0">
            Last sign in: {{ userDto.lastLoginTime | date: 'EEEE, MMM dd, yyyy, hh:mm:ss a' }}
        </div>
    </div>
    <div role="main">
        <div class="px-mr-0 px-row" style="margin-bottom: -15px;">
            <div class="px-pr-0 px-col-12">  
              <phx-message type="warning" [(showMessage)]="showMessage">
                Your browser or operating system is out of date and not supported by B2Bi due to security rules. Upgrade your browser to avoid business disruption.
              </phx-message>
            </div>
          </div>
        <router-outlet></router-outlet>
    </div>
</div>
<!--end : router content-->
<!-- start : footer -->
<div id="footer" #footer>
    <dts-footer></dts-footer>
</div>
<!-- end : footer -->