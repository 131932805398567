import { Client } from './client';
import { DeliveryType } from './delivery-type';
import { Profile } from './profile';

export class UserDto {
    clientId: string;
	userName: string;
    aliasName: string;
    firstName: string;
	lastName: string;
    email: string;
    phoneNumber: string;
    userType: string;
    dropOffUsersInfo?: Client[];
	profile?: Profile;
    deliveryTypeDto?: DeliveryType;
    mfa?: boolean;
    key3sSelfService: boolean;
	req3sKeySignature: number;
    lastLoginTime: string;
    // lastLogin: string;
	countryCode: string;
	companyName: string;
    newUser: boolean;

    constructor(values: Object = {}){
        const temp = values;
        Object.assign(this, values['userDto']);

    }

}
