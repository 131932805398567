<section class="px-container home-page">
  <div class="px-row">
    <div class="px-col-md-9">
      <div>
        <h1>Welcome to the B2Bi Portal</h1>
      </div>
      <div class="summary px-pb-5">
        Enabling secure information exchange with every connection.
      </div>
      <h2>Learn more about B2Bi capabilites</h2>
      <div class="sub-summary">
        Explore the platform documentation to learn more about the B2Bi framework, features, and offerings.
      </div>
      <button type="button" [routerLink]="['/informationCenter/platformDocumentation']" phxbutton="secondary"
        class="px-btn px-btn-secondary" title="View the platform documentation">
        View the platform documentation
      </button>
      <div [innerHtml]="whatsNew"></div>
      <a [href]="mailToLink" id="mailToLink" phxbutton="secondary" class="px-btn px-btn-secondary" title="Email the DTS Service Desk"> <i class="pxi px-envelope" aria-hidden="true"></i> Email the DTS Service Desk  </a>
    </div>
    <div class="px-col-md-3" [innerHtml]="additionalContent">
    </div>
  </div>
</section>
<dts-survey></dts-survey>
<ng-template #userUpdateFooter>
  <button type="submit" phxButton="primary" class="px-mr-3" (click)="updateAccountHandler()"
    [disabled]="childFormStatus == 'VALID'? false: true" title="Confirm">Confirm</button>
  <button class="px-mr-3" type="button" phxButton="secondary" (click)="closeUserUpdateModal()" title="Close">Close</button>
</ng-template>
<ng-template #userUpdate>
  <div class="reviewProfile">Help us keep your profile accurate so that we may quickly assist you should servicing
    issues arise.</div>

  <div class="reviewProfiles">Please review and correct or confirm your information.</div>
  <dts-my-account [showHideButton]="false" (getFormStatus)="onGetFormStatus($event)"></dts-my-account>
</ng-template>
<ng-template #userUpdateHeader>
  <h2>Review profile details</h2>
</ng-template>
