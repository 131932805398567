<header [class]="onAuthChange() ?'login':'no-login'">
  <div class="px-container section-top">
    <div></div>
    <div *ngIf="!isMarketApp">
      <img src="assets/images/logos/Bofa_primary.svg" title="Bank of America logo" alt="Bank of America logo" />
    </div>
    <div *ngIf="isMarketApp">
      <img src="assets/images/logos/merrill_lo1_rgb.svg" style="height: 50px;" title="Merrill Lynch logo" alt="Merrill Lynch logo" />
    </div>
    <div>
      <div class="user-profile" *ngIf="onAuthChange()">
        <b>Alias:</b> {{ userDto.aliasName }} <i class="pxi px-circle" title="Icon"></i> <b> User ID:</b> {{ userDto.userName }}
      </div>
    </div>
  </div>
  <div class="px-container section-bottom">
    <div>
      <div class="menu-container" *ngIf="onAuthChange()">
          <phx-sidebar [(open)]="menuStatus" [options]="options" [zIndex]="1002">
            <phx-sidebar-content>
              <div class="menu-drawer">
                <div class="menu-title">
                  <div *ngIf="isMainMenu">
                    <h2>Menu</h2>
                  </div>
                  <div *ngIf="!isMainMenu" class="sub-menu">
                    <a href="javascript:void(0);" (click)="isMainMenu=true" title="Back to Main menu">
                      <span class="pxi px-chevron-left" title="Menu back icon"></span>
                      <h4>Main menu</h4>
                    </a>
                  </div>
                  <div>
                    <a href="javascript:void(0);" title="Close menu" (click)="menuStatus = !menuStatus"><i class="pxi px-times" title="Close menu"></i></a>
                  </div>
                </div>
                <div class="menu-profile" *ngIf="isMainMenu">
                  <div>
                    <i class="pxi px-user-circle" title="User profile"></i>
                  </div>
                  <div>
                    <div><a [routerLink]="['/userProfile/myAccount']" title="User profile" (click)="menuStatus = !menuStatus">User profile</a></div>
                    <div>User ID: {{userDto.userName}}</div>
                    <div>Alias: {{userDto.aliasName}}</div>
                  </div>
                </div>
                <div class="menu-sublinks" *ngIf="!isMainMenu">
                  <h3>{{selectedNav.name}}</h3>
                  <ul>
                    <li *ngFor="let nav of subNavItems" [ngClass]="navActiveClass(nav)">
                      <a [routerLink]="[nav.route]" routerLinkActive="active" title="{{nav.name}}" (click)="navClickHandler(nav)"
                        [attr.aria-current]="navActiveClass(nav) === '' ? false : true">
                        {{nav.name}}
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="menu-links" *ngIf="isMainMenu">
                  <ul>
                    <li *ngFor="let nav of navItems" [ngClass]="navActiveClass(nav)">
                      <a 
                        [routerLink]="nav.route ? nav.route : null"
                        routerLinkActive="{{nav.hasSubNav?'':'active'}}" title="{{nav.name}}" (click)="navClickHandler(nav)" 
                        [attr.aria-current]="navActiveClass(nav) === '' ? false : true"
                        [attr.aria-haspopup]="nav.hasSubNav?'menu':false">
                        <div>
                          <span *ngIf="nav.icon" class="menu-link-icon pxi {{nav.icon}}"></span>
                        </div>
                        <div>{{nav.name}}</div>
                        <span *ngIf="nav.hasSubNav" class="menu-link-icon pxi px-chevron-right"></span>
                      </a>
                    </li>
                    <li class="menu-links-separator" *ngIf="validatePermission('AccountManagement')" [ngClass]="navActiveClass({hasSubNav: true, name: 'accountManagement'})">
                      <a [routerLink]="null" title="Administration" (click)="navClickHandler({name:'Administration', hasSubNav: true})"
                        [attr.aria-current]="navActiveClass({hasSubNav: true, name: 'accountManagement'}) === '' ? false : true" aria-haspopup="menu">
                        <div></div>
                        <div>Administration</div>
                        <span class="menu-link-icon pxi px-chevron-right"></span>
                      </a>
                    </li>
                    <li [ngClass]="validatePermission('AccountManagement') ? '' : 'menu-links-separator'">
                      <a [routerLink]="null" title="Sign out" (click)="signOutHandler()">
                        <div>
                          <span class="menu-link-icon pxi pxi px-sign-out"></span>
                        </div>
                        <div>Sign out</div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </phx-sidebar-content>
            <phx-sidebar-base>
              <a href="javascript:void(0);" title="Menu" class="menu" (click)="menuStatus = !menuStatus; isMainMenu = true">
                <div>
                  <i class="pxi px-bars" title="Menu"></i>
                </div>
                <!-- <div>Menu</div> -->
              </a>
            </phx-sidebar-base>
          </phx-sidebar>
      </div>
      <div class="main-title">B2Bi Portal</div>
    </div>
    <div *ngIf="!isMarketApp">
      <img src="assets/images/logos/flagscape.svg" title="Bank of America logo" *ngIf="isStickyHeader" />
    </div>

    <div *ngIf="isMarketApp">
      <img src="assets/images/logos/merrill_lo1_rgb.svg" style="height: 50px;" title="Merrill Lynch logo"  *ngIf="isStickyHeader" />
    </div>

    <div>
      <a href="javascript:void(0);" title="Sign out" (click)="signOutHandler()" *ngIf="onAuthChange()">
        <i class="pxi px-sign-out" title="Sign out"></i>
      </a>
    </div>
  </div>
  <ng-template #logoutModalFooter>
    <button class="px-mr-3" type="button" (click)="logoutHandler()" phxButton title="Sign out">Sign out</button>
    <button class="px-mr-3" type="button" (click)="closeHandler()" phxButton="secondary" title="Cancel">Cancel</button>
  </ng-template>
  <ng-template #logoutModal>
    Are you sure you want to sign out of your session?
  </ng-template>
  <ng-template #logoutHeader>
    <h2>Sign out?</h2>
  </ng-template>
</header>