<section class="px-container">
    <div class="section-title">
        <h1>Server keys and certificates</h1>
    </div>
    <div>
        <div>Select the protocol to display protocol specific keys and certificates.</div>
        <div class="required-warning">Asterisk items (<span>*</span>) are required to proceed.</div>
        <div class="px-form-group">
            <legend id="serverProtocolLabel" class="required labelContent" for="serverProtocol">Protocol</legend>
            <phx-dropdown width="200px" aria-labelledby="serverProtocolLabel" id="serverProtocol" [required]="true"
                [(ngModel)]="protocolSelected" (ngModelChange)="onSelectProtocolHandler($event)" [options]="protocolList">
            </phx-dropdown>
        </div>
        <div>
            <div *ngIf="isRespSuccess">
                <!-- Grid goes here -->
                <div class="bankCertGrid" *ngIf="showResults">
                    <div *ngIf="isDataExists">
                        <!-- <div grid-directive="gridOptions" class="_npl _width-100 table-grid"></div> -->
                    </div>
                    <div class="dts-clr-error _pt-10 " *ngIf="!isDataExists">
                        <span><i class="fa fa-minus-circle" aria-hidden="true"></i></span>
                        <span>{{noDataMessage}} : Error message.</span>
                    </div>
                </div>
                <div class="dts-clr-error _pt-10 " *ngIf="!isDataExists">
                    <span><i class="fa fa-minus-circle" aria-hidden="true"></i></span>
                    <span>{{ errMessage }} </span>
                </div>
            </div>
            <div class="gridMessage" *ngIf="protocolSelected">
                <div class="isc-col-md-12 ">If using key-based authentication, download one of the keys below.</div>
            </div>
            <div [style.display]="protocolSelected ?'inline':'none'">
                <phx-datatable [options]="optionsScroll" id="tblCerts" #tblCerts (onTableEvents)="handleEvents($event)">
                    <ng-template phxTemplate="column" phxField="validFrom" phxType="row" let-rowData>
                        {{ rowData.validFrom | date: 'MMM dd, yyyy' }}
                    </ng-template>

                    <ng-template phxTemplate="column" phxField="validTo" phxType="row" let-rowData>
                        {{ rowData.validTo | date: 'MMM dd, yyyy' }}
                    </ng-template>

                    <ng-template phxTemplate="column" phxField="fileDownload" phxType="row" let-rowData>
                      <a href="javascript:void(0)" (click)="fileDownloadHandler(rowData)" title="Download file with certificate {{rowData.key_id}}"><i class="pxi px-download"></i></a>
                    </ng-template>

                </phx-datatable>
            </div>
        </div>
    </div>
</section>