import { AfterViewInit, Component, OnInit, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalOptions, ModalRef, ModalService } from '@phoenix/ui/modal';
import { UserAuthenticationDetails } from '../../feature-modules/login/user.authentication.details';
import { UserDto } from '../models/user-dto';
import { AppService } from '../../core/services/app.service';
import { DchService } from '../../core/services/dch.service';
import { LoadingScreenService } from '../../core/services/loading-screen.service';
import { countries } from './countries';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SurveyComponent } from '../../shared/survey/survey.component';
import { MyAccountComponent } from '../accountmanagement/my-account.component';
import { StartupService } from '../../core/services/startup.service';

@Component({
  selector: 'dts-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {
  @ViewChild('userUpdateHeader', { read: TemplateRef }) userUpdateHeader: TemplateRef<any>;
  @ViewChild('userUpdate', { read: TemplateRef }) userUpdate: TemplateRef<any>;
  @ViewChild('userUpdateFooter', { read: TemplateRef }) userUpdateFooter: TemplateRef<any>;
  @ViewChild(SurveyComponent) surveyModal: SurveyComponent;
  @ViewChild(MyAccountComponent) myaccountModal: MyAccountComponent;

  modalRef: ModalRef;
  logoImage: any;
  userUpdateForm: FormGroup;
  countries = countries;
  userUpdateResponse: any = {};
  additionalContent: SafeHtml; //Content from DCH for Main section
  whatsNew: SafeHtml; //Content from DCH for whats new section
  htmlstring: SafeHtml;
  childFormStatus;
  mailToLink: string;
  breadcrumbItems = [
    { label: 'Home', link: '/home' }
  ];

  constructor(public appService: AppService,
    private userAuthenticationDetails: UserAuthenticationDetails,
    private modalService: ModalService,
    private dchService: DchService,
    private loadingService: LoadingScreenService,
    private sanitizer: DomSanitizer,
    private elRef: ElementRef,
    private startupService: StartupService) {
    this.mailToLink=this.startupService.mailToLink();
    console.log("mailToLink: "+ this.mailToLink);
  }
  userDto: UserDto = this.userAuthenticationDetails.getUserAuthDetails();
  

  ngOnInit() {
    this.logoImage = this.appService.logoImage;
    this.userAuthenticationDetails.breadcrumbEmitter.emit(this.breadcrumbItems);
    this.createUserUpdateForm();
    this.getDynamicContentFromDCH();
  }

  ngAfterViewInit() {
    if (this.userDto.newUser) {
      this.userUpdateResponse.show = false;
      this.userUpdateResponse.type = 'success'
      this.userUpdateResponse.msg = '';
      this.openUserUpdateModal();
    }
  }

  /**
   * Create user update form
   */
  createUserUpdateForm() {
    this.userUpdateForm = new FormGroup({
      accName: new FormControl(this.userDto.userName, Validators.required),
      alias: new FormControl(this.userDto.aliasName, Validators.required),
      compName: new FormControl(this.userDto.companyName, Validators.required),
      firstName: new FormControl(this.userDto.firstName, Validators.required),
      lastName: new FormControl(this.userDto.lastName, Validators.required),
      email: new FormControl(this.userDto.email, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@([a-z0-9][a-z0-9.-]+[\.]{1})+[a-z]{2,9}$')]),
      code2Selected: new FormControl(this.userDto.countryCode, Validators.required),
      mobileNum: new FormControl(this.userDto.phoneNumber, [Validators.required, Validators.pattern('^[0-9]{10,10}$')])
    });
  }

  /**
   * Update User review data
   */
  openUserUpdateModal() {
    if (this.userUpdateFooter != undefined || this.userUpdate != undefined) {
      const modalOptions: ModalOptions = {
        title: this.userUpdateHeader,
        content: this.userUpdate,
        footer: this.userUpdateFooter,
        dismissable: true,
        position: {
           top: '10px'
        },
        size: 'lg',
        hasBackdrop: true,
        draggable: false,
        contentTemplateData: this.userUpdateResponse
      };

      this.modalRef = this.modalService.open(modalOptions);
    }
  }

  onGetFormStatus(status) {
    this.childFormStatus = status;
  }

  updateAccountHandler() {
    this.myaccountModal.updateAccountHandler();
    this.modalService.close(this.modalRef.modal);
  }

  /**
   * Close user update form
   */
  closeUserUpdateModal() {
    this.modalService.close(this.modalRef.modal);
    this.createUserUpdateForm();
  }

  /**
   * Update user details
   */
  updateUserDetails() {

    const requestObj = {
      firstName: this.userUpdateForm.value.firstName,
      lastName: this.userUpdateForm.value.lastName,
      email: this.userUpdateForm.value.email,
      phoneNumber: parseInt(this.userUpdateForm.value.mobileNum),
      countryCode: this.userUpdateForm.value.code2Selected
    };
    this.loadingService.startLoading();
    this.appService.updateUserDetails(requestObj).subscribe(response => {
      this.userUpdateResponse.show = true;
      this.userUpdateResponse.type = 'success';
      this.userUpdateResponse.msg = response.statusDescription;
      this.userAuthenticationDetails.setFirstName(requestObj.firstName);
      this.userAuthenticationDetails.setLastName(requestObj.lastName);
      this.userAuthenticationDetails.setPhoneNumber(requestObj.phoneNumber);
      this.userAuthenticationDetails.setCountryCode(requestObj.countryCode);
      this.userAuthenticationDetails.setNewUser(false);
      this.userDto = this.userAuthenticationDetails.getUserAuthDetails();
      this.loadingService.stopLoading();
    }, error => {
      this.userUpdateResponse.show = true;
      this.userUpdateResponse.type = 'danger';
      this.userUpdateResponse.msg = error.statusDescription != null ? error.statusDescription : 'Something went wrong. Please try agin later.';
      this.loadingService.stopLoading();
    })
  }

  /**
   * Sets the DCH data
   */
  getDynamicContentFromDCH() {
    this.loadingService.startLoading();
    this.dchService.getHomeContent().subscribe((response: any) => {

      this.loadingService.stopLoading();

      if (response && response.homePageContent) {
        if (response.homePageContent.homePageContent) {
          this.loadingService.startLoading();
          this.additionalContent = this.sanitizer.bypassSecurityTrustHtml(response.homePageContent.homePageContent);

          setTimeout(() => {

            let surveyElement = this.elRef.nativeElement.querySelectorAll('#aSurvey');

            if (surveyElement && surveyElement.length == 1)
              surveyElement[0].addEventListener('click', this.openSurveyModal.bind(this));

            this.loadingService.stopLoading();

          }, 100);
        }

        if (response.homePageContent.whatsNew) {
          this.whatsNew = this.sanitizer.bypassSecurityTrustHtml(response.homePageContent.whatsNew);
        }
      }
    }, err => {
      this.loadingService.stopLoading();
    });
  }

  /**
   * Open Survey modal
   */
  openSurveyModal() {
    this.surveyModal.openModal();
  }
}