import { Component, OnInit, Input, Output, EventEmitter, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Logger } from '@phoenix/ui/common';

@Component({
  selector: 'dts-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit, OnDestroy {

  timePickerGroup: FormGroup;
  hoursData: string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  hoursVal = 12;
  minsData: number[];
  minVal = 1;
  secsData: number[];
  secVal = 1;

  @Input() currentTime: string;
  @Output() timeValue = new EventEmitter<{ timeVal: string, drHours: string, drMins: string, drSeconds: string, AMPM: string }>();
  @Output() cancelEvent = new EventEmitter<boolean>();

  constructor(
    private logger: Logger,
    private formBuilder: FormBuilder,
    private elementRef: ElementRef
  ) {

  }

  focusables = ['input', 'select', 'radio'];
  ngOnInit(): void {

    this.timePickerGroup = this.formBuilder.group({
      drHours: new FormControl('', Validators.compose([Validators.required])),
      drMins: new FormControl('', Validators.compose([Validators.required])),
      drSeconds: new FormControl('', [Validators.required]),
      AMPM: new FormControl('', [Validators.required])
    });

    this.getMinutes();
    this.setDateTimeValue();
  }

  ngOnDestroy(): void {
      this.hideTimePickerHandler();
  }

  /**
   * Set date time
   * @param source 
   */
  setDateTimeValue() {
    var ss = this.currentTime.split(':');
    var amPmValu;
    if (ss[2].indexOf(' ') > -1) {
      amPmValu = ss[2].split(' ');
    }
    this.timePickerGroup.patchValue({
      drHours: ss[0],
      drMins: ss[1],
      drSeconds: amPmValu[0],
      AMPM: amPmValu[1]
    });

  }

  /**
   * Get current minutes
   */
  getMinutes() {
    var x = 1; //minutes interval
    var times = []; // time array
    var tt = 0; // start time
    for (var i = 0; tt < 60; i++) {
      var mm = (tt); // getting minutes of the hour in 0-55 format
      times[i] = ("0" + mm).slice(-2);
      tt = tt + x;
    }
    this.minsData = times;
    this.secsData = times;
  }

  /**
   * Get time value from the front end.
   */
  getTimeHandler() {
    var timeVal1;
    timeVal1 = this.timePickerGroup.value.drHours + ":" +
      this.timePickerGroup.value.drMins + ":" +
      this.timePickerGroup.value.drSeconds + " " +
      this.timePickerGroup.value.AMPM;
    this.timeValue.emit({
      timeVal: timeVal1, drHours: this.timePickerGroup.value.drHours,
      drMins: this.timePickerGroup.value.drMins, drSeconds: this.timePickerGroup.value.drSeconds,
      AMPM: this.timePickerGroup.value.AMPM
    });
  }

  /**
   * Hide time picker overlay
   */
  hideTimePickerHandler() {
    this.cancelEvent.emit(false);
  }
}