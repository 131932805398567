<section class="px-container" id="retreiveSection">
  <div class="section-title">
    <h1>Retrieve</h1>
  </div>
  <div class="px-row px-pt-2" *ngIf="error.isError">
    <div class="px-col-12">
      <phx-message type="danger" [dismissible]="false">
        {{error.errorMessage}}
      </phx-message>
    </div>
  </div>
  <div class="filters">
    <div class="dropdown">
      <legend class="required labelContent" id="dropMailboxesLabel" aria-label="dropMailboxes" for="dropMailboxes">
        Select mailbox</legend>
      <phx-dropdown name="dropMailboxes" aria-labelledby="dropMailboxesLabel" placeholder="Select mailbox"
        id="dropMailboxes" [(ngModel)]="mailBoxSelected" (ngModelChange)="searchHandler()" [options]="mailBoxTypes"
        [required]="true">
      </phx-dropdown>
    </div>
    <div class="borderDiv">
    </div>
    <div class="pickers">
      <div class="picker">
        <legend id="retrieveFromDateLabel" class="labelContent" for="retrieveFromDate"> From date </legend>
        <phx-datepicker aria-labelledby="retrieveFromDateLabel" calendarId='datePicker1' [options]="dateRangeOptions">
          <input id="retrieveFromDate" phxDate [(ngModel)]="retrieveFromDate" [format]="date1Format"
            (focus)="validateDateTime()" />
        </phx-datepicker>
      </div>
      <div class="inputWithIcon">
        <legend id="retrieveFromTimeLabel" class="labelContent" for="retrieveFromTime"> From time</legend>        
        <div class="px-input-group time-picker-control">
          <input phxInput aria-labelledby="retrieveFromTimeLabel" id="retrieveFromTime" readonly [(ngModel)]="retrieveFromTime" aria-label="HH:MM:SS"
            placeholder="HH:MM:SS" />        
          <div class="px-input-group-append px-icon-addon">
            <button type="button" tabindex="0" 
              (click)="openFrom = !openFrom;setDateTimeValue('From')" 
              aria-label="Toggle timepicker"
              [phx-popup]="phxPopupFromTime"
              [(popupOpen)]="openFrom"
              [hasBackdrop]="true"
              (keydown.enter)="openFrom = !openFrom;setDateTimeValue('From')"> 
              <i class="pxi px-clock"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="borderDiv">
      </div>
      <div class="picker">
        <legend id="retrieveToDateLabel" class="labelContent" for="retrieveToDate"> To date</legend>
        <phx-datepicker aria-labelledby="retrieveToDateLabel" calendarId='datePicker1' [options]="dateRangeOptions">
          <input id="retrieveToDate" phxDate [(ngModel)]="retrieveToDate" [format]="date1Format"
            (focus)="validateDateTime()" />
        </phx-datepicker>
      </div>
      <div class="inputWithIcon">
        <legend id="retrieveToTimeLabel" class="labelContent" for="retrieveToTime"> To time</legend>
        <div class="px-input-group time-picker-control">
          <input phxInput aria-labelledby="retrieveToTimeLabel" id="retrieveToTime" readonly [(ngModel)]="retrieveToTime" aria-label="HH:MM:SS"
            placeholder="HH:MM:SS" maxlength="200" />
          <div class="px-input-group-append px-icon-addon"><button type="button" tabindex="0"
            (click)="openTo = !openTo;setDateTimeValue('To')"
            (keydown.enter)="openTo = !openTo;setDateTimeValue('To')"
            [phx-popup]="phxPopupToTime"
            [(popupOpen)]="openTo"
            [hasBackdrop]="true" aria-label="Toggle timepicker">  
            <i class="pxi px-clock"></i>
          </button></div>
        </div>
      </div>
    </div>
    <div class="buttonDiv">
      <button class="px-mr-3" type="button" phxButton [disabled]="!mailBoxSelected" (click)="searchHandler()"
        title="Search">Search</button>
      <button type="button" phxButton="link" class="px-pl-2" (click)="clearHandler()" title="Reset">Reset</button>
    </div>
  </div>
  <div [style.display]="showMailBoxGrid ? 'inline': 'none'" class="px-mt-2">
    <phx-datatable [options]="tableOptions" id="tblRetrieve" #tblRetrieve (onTableEvents)="handleEvents($event)"
      currentPageReportTemplate="Listing {first} - {last} of {total} results"
      (onInternalEvents)="internalEventHandler($event)">
      <ng-template phxTemplate="column" phxField="fileDate" phxType="row" let-rowData>
        {{ rowData.fileDate | date: 'MMM dd, yyyy hh:mm:ss a' }}
      </ng-template>
      <ng-template phxTemplate="column" phxField="fileDownload" phxType="row" let-rowData>
        <a href="javascript:void(0)" (click)="fileDownloadHandler(rowData)"
          title="Download file {{rowData.fileName}}"><i class="pxi px-download"></i></a>
      </ng-template>

      <ng-template phxTemplate="column" phxField="fileDate" phxType="label" let-column>
        Received file date
        <span phxTooltip="Date processed by the bank" class="px-mr-1" tooltipPosition="bottom" tabindex="0">
          <i class="pxi px-info-circle" style="vertical-align: middle;color:#009CDE;" aria-hidden="true"
            alt="information-icon"></i>
        </span>
      </ng-template>
      <ng-template phxTemplate="column" phxField="fileSizeBytes" phxType="label" let-column>
        <span phxTooltip="File Size" class="px-mr-1" tooltipPosition="bottom" tabindex="0">
          Size
        </span>
      </ng-template>

      <ng-template phxTemplate="column" phxField="fileSizeBytes" phxType="row" let-rowData>
        <span [phxTooltip]="rowData.fileSizeBytes| formatFileSize: false" class="px-mr-1" tooltipPosition="bottom"
          tabindex="0">
          {{rowData.fileSizeBytes | formatFileSize: false}}
        </span>

      </ng-template>
    </phx-datatable>
  </div>
  <div class="px-mt-3">
    <phx-message type="warning" [dismissible]="false" [(showMessage)]="!isDataExists">
      The search criteria provided no results. Modify your search criteria and try again.
    </phx-message>
  </div>
  <phx-back-to-top [scrollHeight]="100"></phx-back-to-top>
  <ng-template #phxPopupFromTime>
    <dts-time-picker [currentTime]="retrieveToTime" (timeValue)="getTimeHandler($event)"
      (cancelEvent)="hideTimePickerHandler($event)"></dts-time-picker>
  </ng-template>
  <ng-template #phxPopupToTime>
    <dts-time-picker [currentTime]="retrieveToTime" (timeValue)="getTimeHandler($event)"
      (cancelEvent)="hideTimePickerHandler($event)"></dts-time-picker>
  </ng-template>
</section>