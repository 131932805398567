import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserAuthenticationDetails } from '../../feature-modules/login/user.authentication.details';


@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(private authService: UserAuthenticationDetails, private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean  {
    if(!this.authService.getLoginStatus() && this.router.getCurrentNavigation().trigger === 'imperative'){
      return true;
    }else{
      window.location.reload();
      return false;
    }

  }
}
