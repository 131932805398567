import { Component, OnInit } from '@angular/core';
import { UserAuthenticationDetails } from '../login/user.authentication.details';
import { environment } from 'projects/DtsUI/src/environments/environment';
import { StartupService } from '../../core/services/startup.service';

@Component({
  selector: 'dts-platform-documentation',
  templateUrl: './platform-documentation.component.html',
  styleUrls: ['./platform-documentation.component.scss']
})
export class PlatformDocumentationComponent implements OnInit {

  height = 100;
  serverName: string = environment.dchDomainUrl;
  isInternalApp: boolean;
  instance: string;
  
  breadcrumbItems = [
    { label: 'Home', link: '/home' },
    { label: 'Platform documentation', link: '/informationCenter/platformDocumentation' }
  ];

  constructor(private userAuthenticationDetails: UserAuthenticationDetails,
    private startupService: StartupService) { 
      this.isInternalApp = startupService.isInternalApp();
      this.instance = startupService.getInstance();
    }

  ngOnInit(): void {
    this.userAuthenticationDetails.breadcrumbEmitter.emit(this.breadcrumbItems);
  }

  /**
   * Download the passed PDF documents
   * @param docname Doc name 
   */
  docDownloadHandler(docname) {
    window.open(this.serverName + '/PHXcms/private/content/b2bi/Documents/'+ this.instance+ '/' + docname, '_blank');
  }
}