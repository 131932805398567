import { UserChannelAttrib } from './user-channel-attrib';

export class UserChannels {

countInbound: number;
countOutbound: number;
inbound: UserChannelAttrib[];
outbound: UserChannelAttrib[];

constructor(values: Object = {}) {
    Object.assign(this, values);
}


}

// constructor(id?: number, ait?: string, status?: string, title?: string, desc?: string, createDate?: string, requestor?: string, requestorEmail?: string, owner?: string, ownerEmail?: string, completionTime?: string, impactedEnv?: string, nextUptimestampTime?: string, resType?: string, res?: string) {
//     this.id = id;
//     this.status = status;
//     this.ait = ait;
//     this.title = title;
//     this.description = desc;
//     this.createDate = createDate;
//     this.requestedBy = requestor;
//     this.requestEmail = requestorEmail;
//     this.issueOwner = owner;
//     this.ownerEmail = ownerEmail;
//     this.completionTime = completionTime;
//     this.impactedEnv = impactedEnv;
//     this.nextUpdateTime = nextUptimestampTime;
//     this.resolutionType = resType;
//     this.resolution = res;
// }