import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { Router,ActivatedRoute, NavigationEnd } from '@angular/router';
import { Logger } from '@phoenix/ui/common';
import { FileDragDropOptions, FileDragDropResponse, FileDragDropService } from '@phoenix/ui/file-drag-drop';
import { ModalOptions, ModalService } from '@phoenix/ui/modal';
import { LoadingScreenService } from '../../core/services/loading-screen.service';
import { CommonService } from '../../core/services/common.service';
import { FeatureModuleServices } from '../feature.module.services';
import { UserAuthenticationDetails } from '../login/user.authentication.details';
import { ActionTypes, DataTableComponent, DataTableEventData, DataTableOptions, ExternalEventInfo, SortType } from '@phoenix/ui/datatable';
import { StartupService } from '../../core/services/startup.service';

@Component({
  selector: 'dts-send-files',
  templateUrl: './send-files.component.html',
  styleUrls: ['./send-files.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class SendFilesComponent implements OnInit, AfterViewInit {
  optionsScroll: DataTableOptions;
  @ViewChild('tblTransmissions') phxGrid: DataTableComponent;

  @ViewChild('successMsgHeader', { read: TemplateRef }) successMsgHeader: TemplateRef<any>;
  @ViewChild('successMsg', { read: TemplateRef }) successMsg: TemplateRef<any>;
  @ViewChild('footerSuccess', { read: TemplateRef }) footerSuccess: TemplateRef<any>;

  @ViewChild('warningHeader', { read: TemplateRef }) warningHeader: TemplateRef<any>;
  @ViewChild('warningBody', { read: TemplateRef }) warningBody: TemplateRef<any>;
  @ViewChild('warningFooter', { read: TemplateRef }) warningFooter: TemplateRef<any>;



  fileList: FileDragDropResponse;
  tempFileList: FileDragDropResponse;
  mailboxSelected: string;
  selectedFiles: any = [];
  public gridData: any[];// = recentUploadsResponseData;
  maxFileCount: any;
  maxFileSize: any;
  fileOptions: FileDragDropOptions = {
    accept: "*",
    reject: '.PHP,.DOCX,.EXE,.BAT,.SH,.CMD,.COM,.CSH,.DEK,.DLD,.DS,.EBM,.ESH,.EZS,.FKY,.FRS,.FXP,.GADGET,.HMS,.HTA,.ICD,.INX,.IPF,.ISU,.JAR,.JS,.JSE,.JSX,.KIX,.LUA,.MCR,.MEM,.MPX,.MS,.MSI,.MST,.OBS,.PAF,.PEX,.PIF,.PRC,.PRG,.PVD,.PWC,.PY,.PYC,.PYO,.QPX,.RBX,.REG,.RGS,.ROX,.RPJ,.SCAR,.SCR,.SCRIPT,.SCT,.SHB,.SHS,.SPR,.TLB,.TMS,.U3P,.UDF,.VB,.VBE,.VBS,.VBSCRIPT,.WCM,.WPK,.WS,.WSF,.XQTm',
    maxFileSize: '200MB',
    maxFileCount: 1,
    required: true,
    noFileMessage: 'No file uploaded yet.',
    showErrors: false,
    multiple: false,
    preserveFiles: false,
    allowNoExtension: true
    //fileNameRegEx: /^[-_.#@a-z0-9]+$/gi
  };
  uploadId = 'uploadId';
  fileNameRegex = '[%?*:"|<>\/]';
  newFileNameRegex = /[!%*:"\\|<>\/?]/;
  filesDragged = [];
  error = {};
  noFileUploaded = true;
  noFileUploadedMessage = 'No file uploaded yet.';
  docTypeErrMsg = 'The file type is invalid. Please select a valid file type and try again.';
  maxSizeErrMsg = 'The file size exceeds the allowable limit. Please select a file within the allowable file size.';
  uploadFailureErrMsg = 'The upload failed. Please try again.';
  maxFileErrMsg = 'The maximum number of uploaded files has been reached. Please remove excess files.';
  invalidFileTypeGlobalErrMsg = 'One or more file types is invalid. Please select a valid file type and try again.';
  fileNameError = false;
  fileNameErrMsg = 'Following characters % ? * : " | < > \\ / !  are not allowed on file name. Please change file name and try again.';
  newfileNameError = false;
  newfileUploadError = false;
  dropdownError = false;
  fileUploadErrorMsg: string = null;//'Following characters % ? * : " | < > \ / are not allowed on file name. Please change file name and try again.';
  fileNameErrorMsg: string = null;
  dropdownErrorMsg: string = null;
  recentFileUploads: any = [];
  showLoader = false;
  hasRecentUploadData = false;
  newFileName: string = '';
  recentUploadsErrMsg = '';
  fileHasError = false;
  directoriesLength: number;
  userData: any;
  accName: any;
  mailboxList: string[] = [];
  userName: any;
  private modalRef: any;
  fileUploadResponse: boolean = false;
  mailboxDisplayList: string[] = [];
  uploadedFileName: string = null;
  isInternalApp: boolean;
  quotaErrorResponse: boolean;
  quotaErrorResponseMsg: string;
  events: Array<any>;
  fileUploadId: string = 'uploadId';
  inValidFileType: Array<string>  = ['.PHP','.DOCX','.EXE','.BAT','.SH','.CMD','.COM','.CSH','.DEK','.DLD',
        '.DS','.EBM','.ESH','.EZS','.FKY','.FRS','.FXP','.GADGET','.HMS','.HTA','.ICD','.INX','.IPF',
        '.ISU','.JAR','.JS','.JSE','.JSX','.KIX','.LUA','.MCR','.MEM','.MPX','.MS','.MSI','.MST',
        '.OBS','.PAF','.PEX','.PIF','.PRC','.PRG','.PVD','.PWC','.PY','.PYC','.PYO','.QPX','.RBX',
        '.REG','.RGS','.ROX','.RPJ','.SCAR','.SCR','.SCRIPT','.SCT','.SHB','.SHS','.SPR','.TLB',
        '.TMS','.U3P','.UDF','.VB','.VBE','.VBS','.VBSCRIPT','.WCM','.WPK','.WS','.WSF','.XQTm'];
    
  breadcrumbItems = [
    { label: 'Home', link: '/home' },
    { label: 'Send' }
  ];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private sendFilesService: FeatureModuleServices,
    private userAuthenticationDetails: UserAuthenticationDetails,
    private commonService: CommonService,
    private router: Router,
    private modalService: ModalService,
    private loadingService: LoadingScreenService,
    private logger: Logger,
    private startupService: StartupService,
    private fileDragDropService: FileDragDropService) {
    this.gridDataOptionsHandler();
    this.isInternalApp = this.startupService.isInternalApp();
  }

  ngAfterViewInit(): void {
    const ngThis = this;
    if(this.successMsgHeader) {
      const interval = setInterval(() => {
        const ele = document.querySelector('span[id^="modal_close-"]');
        if (ele) {
          ele.parentNode.addEventListener('click', () => {
            ngThis.clearFile();
          }, true)
        }
      },3000);
    }
  }

  ngOnInit(): void {
    this.userAuthenticationDetails.breadcrumbEmitter.emit(this.breadcrumbItems);
    this.showLoader = false;
    this.hasRecentUploadData = true;
    this.recentUploadsErrMsg = '';
    this.userData = this.userAuthenticationDetails.getUserAuthDetails();
    this.accName = this.userData.userName;
    this.initSend();
  }

  getRecentTransmissions(events){
    this.sendFilesService.getLatestFileUploadsService()
      .subscribe(responseData => {
        this.loadingService.stopLoading();
        this.logger.info('SendFiles service call completed successfully, response : ', responseData);
        if (responseData !== null && responseData !== undefined) {
          this.recentFileUploads = responseData;
          this.gridData = responseData;
          this.recentUploadsErrMsg = '';
          const data: any = responseData;
          this.hasRecentUploadData = Array.isArray(data);
          this.changeDetectorRef.markForCheck();
          if (this.hasRecentUploadData && data.length === 0) {
            this.recentUploadsErrMsg = 'No data Available.';
            this.changeDetectorRef.detectChanges();
            return;
          }

          data.forEach((val) => {

            if (val.direction === 'TO_BANK') {
              val.direction = 'Sent';
            } else if (val.direction === 'FROM_BANK') {
              val.direction = 'Received';
            } else if (val.direction === 'BOTH') {
              val.direction = 'Both';
            }

            if (val.status === 'COMPLETED') {
              val.status = 'Completed';
            }
            else if (val.status === 'BULK/REPLAYED') {
              val.status = 'Resent';
            }
            else if (val.status === 'ERROR') {
              val.status = 'Error';
            }});
            
            if (events)
              this.onGridHandlerFn(events);

        } else {
          this.recentUploadsErrMsg = 'Error occured while fetching recent uploads.';
        }
        
      }, error => {
        this.logger.error('Send Files service call failed , error : ', error);
        this.recentUploadsErrMsg = 'Error occured while fetching recent uploads.';
        this.loadingService.stopLoading();
      });    
  }
  /**
   * Method to call when dropdown value is changed.
   * @param value 
   */
  onModelChangeHandler(value: any) {
    // this.mailboxSelected = value;
    this.logger.info('Dropdown demo 6 model changed to: ', value);
    this.logger.info('Mailbox selected ' + this.mailboxSelected);
    this.dropdownError = false;
    if (!this.mailboxSelected) {
      this.dropdownError = true;
      this.dropdownErrorMsg = "Please select mailbox.";
    }
  }

  /**
   * Method to validate filename.
   */
  onNewFileNameChange() {
    this.newfileNameError = false;
    this.dropdownError = false;
    if (this.newFileName.length > 0) {
      if (new RegExp(this.newFileNameRegex).test(this.newFileName)) {
        this.newfileNameError = true;
        this.fileNameErrorMsg = this.fileNameErrMsg;
        return;
      }

      let fileExtension = this.newFileName.substring(this.newFileName.lastIndexOf("."),this.newFileName.length).toUpperCase();
      if (this.inValidFileType.includes(fileExtension)){
        this.newfileNameError = true;
        this.fileNameErrorMsg = this.docTypeErrMsg;
        return;
      }

    }
    if (!this.mailboxSelected) {
      this.dropdownError = true;
      this.dropdownErrorMsg = "Please select mailbox.";
    }
  }


  /**
   * Method to handle file removal from upload control.
   * @param $event 
   */
  removeFileHandler($event) {
    //this.fileList.getError().clear();
    this.newfileUploadError = false;

    this.fileChangeHandler($event);
    this.dropdownError = false;

  }

  /**
   * Making call to SendFiles service
   */
  initSend() {
    this.loadingService.startLoading();
    this.sendFilesService.getSendMailboxService()
      .subscribe(responseData => {
        this.logger.info('SendFiles service call completed successfully, response : ', responseData);
        this.mailboxList = responseData;
        this.directoriesLength = this.mailboxList.length;
        this.setDefaultMailbox();
        this.loadingService.stopLoading();
      }, error => {
        this.loadingService.stopLoading();
        this.logger.error('SendFiles service call failed , error : ', error);
      });

    let eventInfo: ExternalEventInfo = {
      keepSorting: true,
      resetData: true
    };

    if (this.phxGrid)
      this.phxGrid.api.refresh({ info: eventInfo });
  }

  /**
   * To update the filename change
   * @param value 
   */
  onFileNameChange(value: string) {
    this.newFileName = this.encodeComponent(value);
  }

  /**
   * Select the default mailbox. 
   */
  setDefaultMailbox() {
    this.mailboxDisplayList = [];
    this.fileList = null;
    this.mailboxList.forEach(mailbox => this.mailboxDisplayList.push(mailbox));
    if (this.mailboxList.length === 1) {
      if (this.mailboxList[0] === '/') {
        this.mailboxDisplayList[0] = this.accName + " - Selection Not Needed";
        this.mailboxSelected = this.mailboxDisplayList[0];
      } else {
        this.mailboxSelected = this.mailboxDisplayList[0];
      }
    }
    else {
      this.mailboxSelected = undefined;
    }
    this.logger.info('SendFiles:setDefaultMailbox():  ' + this.mailboxSelected)
  }

  /**
   * Method which handles the file uploading part. 
   */
  uploadFileHandler() {
    this.uploadedFileName = null;
    this.logger.info('uploadFile: Work in progress')
    if (this.selectedFiles.length > 0 && this.mailboxSelected && this.fileHasError === false) {
      this.loadingService.startLoading();
      this.showLoader = false;
      const requestData = new FormData();
      requestData.append('dirName', this.mailboxSelected.indexOf("Selection Not Needed") >= 0 ? "/" : this.mailboxSelected);
      requestData.append('file', this.selectedFiles[0]);

      if (this.newFileName !== '') {
        requestData.append('fileName', this.newFileName);
      }
      else {
        requestData.append('fileName', '');
      }
      this.fileUploadResponse = false;
      this.sendFilesService.uploadFile(requestData)
        .subscribe(response => {
          this.logger.info('SendFiles service call completed successfully, response : ', response);
          this.newFileName = '';
          this.fileUploadResponse = true;
          this.quotaErrorResponse = true;
          this.loadingService.stopLoading();
          this.openFileUploadSucessModal();
          this.setDefaultMailbox();
        }, error => {
          this.logger.error('SendFiles service call failed , error : ', error);

          this.loadingService.stopLoading();
          if (error.error.statusCode === "DTS-UI-451") {
            this.quotaErrorResponseMsg = error.error.statusDescription;
            this.quotaErrorResponse = false;
            this.fileUploadResponse = false;
          } else {
            this.fileUploadResponse = false;
            this.quotaErrorResponse = true;
            this.setDefaultMailbox();
          }
          this.openFileUploadFailureModal();
          this.newFileName = '';
        });
    }
  }

  /**
   * Method to clear the file and close the success modal.
   */
  clearFile() {
    this.logger.info('clearFile: Not implemented');
    
    this.modalService.close(this.modalRef.modal);
    if (this.fileUploadResponse) {
      this.modalRef
        .beforeClosed()
        .pipe(
        ).subscribe();
    }

    if (this.quotaErrorResponse && this.fileUploadResponse) {
      this.fileDragDropService.reset(this.fileUploadId);
      this.fileList = null;
      this.selectedFiles=[];
      this.ngOnInit();
      //this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['/send']));
    }
  }

  /**
   * Method to open modal with file upload failure. 
   */
  openFileUploadFailureModal() {
    const modalOptions: ModalOptions = {
      title: 'Upload failed',
      content: this.successMsg,
      footer: this.footerSuccess,
      dismissable: true,
      position: {
        top: '120px'
      },
      size: 'md',
      hasBackdrop: true,
      draggable: false
    };
    this.modalRef = this.modalService.open(modalOptions);
  }

  /**
   * Method to open modal with file upload success. 
   */
  openFileUploadSucessModal() {
    this.logger.info('openFileUploadSucessModal: Not implemented');
    const modalOptions: ModalOptions = {
      title: this.successMsgHeader,
      content: this.successMsg,
      footer: this.footerSuccess,
      dismissable: true,
      position: {
        top: '120px'
      },
      size: 'md',
      hasBackdrop: true,
      draggable: false
    };
    this.modalRef = this.modalService.open(modalOptions);
  }

  openConfirmModal() {
    const modalOptions: ModalOptions = {
      title: this.warningHeader,
      content: this.warningBody,
      footer: this.warningFooter,
      dismissable: true,
      position: {
        top: '120px'
      },
      size: 'md',
      hasBackdrop: true,
      draggable: false
    };
    this.modalRef = this.modalService.open(modalOptions);
  }

  /**
   * Method to refresh the transmissions table
   */
  reloadRecentFileUploads() {
    this.loadingService.startLoading();
    this.getRecentTransmissions(this.events);
    //this.fetchRecentFileUploads(this.events);
    this.logger.info('reloadRecentFileUploads: from HTTP service To be implemented');

  }

  /**
   * Method to fetch the recent uploads and show it in table.
   * @param events 
   */
  fetchRecentFileUploads(events) {
    this.userName = this.userAuthenticationDetails.getUserName();
    const requestObj = { userName: this.userName };
    this.sendFilesService.getLatestFileUploadsService()
      .subscribe(responseData => {
        this.logger.info('SendFiles service call completed successfully, response : ', responseData);
        //this.loadingService.stopLoading();
        if (responseData !== null && responseData !== undefined) {
          this.recentFileUploads = responseData;
          this.gridData = responseData;
          this.recentUploadsErrMsg = '';
          const data: any = responseData;
          this.hasRecentUploadData = Array.isArray(data);
          this.changeDetectorRef.markForCheck();
          if (this.hasRecentUploadData && data.length === 0) {
            this.recentUploadsErrMsg = 'No data Available.';
            this.changeDetectorRef.detectChanges();
            return;
          }

          let formattedDate = '';
          data.forEach((val) => {

            if (val.direction === 'TO_BANK') {
              val.direction = 'Sent';
            } else if (val.direction === 'FROM_BANK') {
              val.direction = 'Received';
            } else if (val.direction === 'BOTH') {
              val.direction = 'Both';
            }

            if (val.status === 'COMPLETED') {
              val.status = 'Completed';
            }
            else if (val.status === 'BULK/REPLAYED') {
              val.status = 'Resent';
            }
            else if (val.status === 'ERROR') {
              val.status = 'Error';
            }

            //val.fileSize = this.commonService.convertBytes(val.fileSize);
          });
          formattedDate = '';

          if (events)
            this.onGridHandlerFn(events);

        } else {
          this.recentUploadsErrMsg = 'Error occured while fetching recent uploads.';
        }
      }, error => {
        //this.loadingService.stopLoading();
        this.logger.error('Send Files service call failed , error : ', error);
        this.recentUploadsErrMsg = 'Error occured while fetching recent uploads.';
      });
  }

  /**
   * Method to redirect to Transmissions page.
   */
  goToReports() {
    this.router.navigate(['/reports/transmissionsReport']);
  }

  /**
   * Method to update the filelist as per the changed file name. 
   * @param fileList 
   */
  fileChangeHandler(fileList: FileDragDropResponse) {
    this.fileUploadErrorMsg = '';
    if (!this.mailboxSelected && fileList.success.length > 0) {
      this.dropdownError = true;
      this.dropdownErrorMsg = "Please select mailbox";
    }

    this.newfileUploadError = false;
    if (fileList.success && fileList.success.length > 0 && new RegExp(this.newFileNameRegex).test(fileList.success[0].name)) {
      this.newfileUploadError = true;
      this.fileUploadErrorMsg = this.fileNameErrMsg;
    }
    else if (fileList.error) {
      let fileName = '';
      for (let key of fileList.error.keys()) {
        fileName = key.name;
      }
      fileList.error.forEach(error => {
        this.fileUploadErrorMsg = fileName + ' : ';
        if (error.doctype) {
          this.fileUploadErrorMsg += 'File type not supported. ';
        }
        if (error.maxsize) {
          this.fileUploadErrorMsg += 'File size should not exceed 200 MB.';
        }
      })

      this.newfileUploadError = true;
    }
    else if (fileList.success && fileList.success.length > 0) {

      if (this.uploadedFileName && this.uploadedFileName !== fileList.success[0].name) {
        // this.tempFileList = fileList;
        // this.openConfirmModal();
        //TBD:: uncomment above code.
      }
      //else {
      this.fileList = fileList;
      this.selectedFiles = fileList.success;
      this.uploadedFileName = fileList.success[0].name;
      //}
    }
  }
  replaceFileHandler() {
    this.fileList = this.tempFileList;
    this.selectedFiles = this.tempFileList.success;
    this.uploadedFileName = this.tempFileList.success[0].name;
  }

  previousFileHandler() {
    this.modalService.close();
  }

  /**
   * Handler to set columns for the datatable.
   */
  gridDataOptionsHandler() {
    this.optionsScroll = {
      smartView: {
        alertMessage: "No data found.",
        pagination: false,
        handleEventsInternally: true,
      },
      columnDefs: [
        {
          field: 'fileName',
          name: 'File name',
          filter: { enabled: false },
          resizable: false
        },
        {
          field: 'modifiedFileName',
          name: 'Modified file name',
          filter: { enabled: false },
          resizable: false
        },
        {
          field: 'messageId',
          name: 'Message ID',
          filter: { enabled: false },
          resizable: false
        },
        {
          field: 'businessUnit',
          name: 'Business unit',
          filter: { enabled: false },
          resizable: false
        },
        {
          field: 'status', name: 'Status',
          filter: { enabled: false },
          resizable: false
        },
        {
          field: 'direction', name: 'Direction',
          filter: { enabled: false },
          resizable: false
        },
        {
          field: 'fileSize', name: 'Size',
          filter: { enabled: false },
          sort: { type: SortType.NUMBER }
        },
        {
          field: 'receiptTimestamp', name: 'Received', filter: { enabled: false },
          resizable: false
        },
        {
          field: 'sendTimestamp', name: 'Sent', filter: { enabled: false },
          resizable: false
        }
      ],
      data: []
    };

  }

  /**
   * Handle PHX grid internal events
   * @param eventData Grid events
   * @returns 
   */
  handleEvents(eventData: DataTableEventData): void {
    const events = eventData.events;
    if (!events) return;
    if (events.length >= 2) {
      this.fetchRecentFileUploads(events);
    }
    this.events = events;
  }

  /**
   * Rebind the grid data
   * @param events Grid events
   */
  onGridHandlerFn(events: any) {

    const loadEvent = events.find((event) => event.type === ActionTypes.LOAD_DATA);

    if (loadEvent) loadEvent.result = { data: this.gridData };

    this.phxGrid.api.handleResultEvents(events);
  }

  encodeComponent(stringVal)
	{
		return encodeURIComponent(stringVal);
	}

	decodeComponent(encodeVal)
	{
		return decodeURIComponent(encodeVal);
	}

}