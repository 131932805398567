<div class="timePickerParent">
    <fieldset>
        <legend class="timePickerHeader">
            Select a time
        </legend>
        <form name="myAccountForm" [(formGroup)]="timePickerGroup">
            <div class="timePicker">
                <div>
                    <legend id="drHoursLabel" for="drHours" class="labelContent">Hours</legend>
                    <phx-dropdown id="drHours" aria-labelledby="drHoursLabel" [options]="hoursData" name="drHours"
                        [maxHeight]=200 formControlName="drHours" aria-placeholder="HH" placeholder="HH" [width]="'80px'">
                    </phx-dropdown>
                </div>
                <div>
                    <legend id="drMinsLabel" for="drMins" class="labelContent">Minutes</legend>
                    <phx-dropdown id="drMins" aria-labelledby="drMinsLabel" [options]="minsData" name="drMins" [maxHeight]=200
                        formControlName="drMins" [width]="'80px'" aria-placeholder="MM" placeholder="MM"></phx-dropdown>
                </div>
                <div>
                    <legend id="drSecondsLabel" for="drSeconds" class="labelContent">Seconds</legend>
                    <phx-dropdown id="drSeconds" aria-labelledby="drSecondsLabel" [options]="secsData" name="drSeconds"
                        [maxHeight]=200 formControlName="drSeconds" [width]="'80px'" aria-placeholder="SS" placeholder="SS">
                    </phx-dropdown>
                </div>
                <div>
                    <ul class="px-list-unstyled" style="margin-top: 20px;">
                        <li>
                            <phx-radio name="AMPM" label="AM" value="AM" formControlName="AMPM">
                            </phx-radio>
                        </li>
                        <li>
                            <phx-radio name="AMPM" label="PM" value="PM" formControlName="AMPM">
                            </phx-radio>
                        </li>
                    </ul>
                </div>
            </div>
        </form>
        <div class="timeButtons">
            <button phxButton role="button" (click)="getTimeHandler()" [disabled]="!timePickerGroup.valid" title="Confirm">
                Confirm </button>&nbsp;
            <button phxButton="link" (click)="hideTimePickerHandler()" role="button" title="Cancel">Cancel</button>
        </div>
    </fieldset>
</div>