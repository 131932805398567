import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserDto } from '../../feature-modules/models/user-dto';
import { UserAuthenticationDetails } from '../../feature-modules/login/user.authentication.details';
import { LoginMfaService } from '../services/login.mfa.service';


@Injectable()
export class UserAuthDetailsInterceptor implements HttpInterceptor {

  authDetails: any;

  constructor(
    private authService: UserAuthenticationDetails,
    private router: Router,
    private route: ActivatedRoute,
    private loginMfaService: LoginMfaService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    
    if (this.authService.getLoginStatus() !== true && this.loginMfaService.deliveryDto.deliveryTypeDto === null) {
      this.router.navigate(['/login']);
    }
    return next.handle(request);
  }
}

export const UserAuthDetailsInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: UserAuthDetailsInterceptor,
  multi: true,
};