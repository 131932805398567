import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PhxTranslateModule } from '@phoenix/ui/translate';
import { PHX_COMPONENT_MODULES } from './phoenix-components.const';
import { ContactUsModalComponent } from './modals/contact-us-modal.component';
import { MfaWarningModalComponent } from './modals/mfa-warning-modal.component';
import { SurveyComponent } from './survey/survey.component';
import { CookieCenterModalComponent } from './cookie-center-modal/cookie-center-modal.component';
import { TimePickerComponent } from './time-picker/time-picker.component';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ...PHX_COMPONENT_MODULES,
    PhxTranslateModule.forChild(),
    ReactiveFormsModule
  ],
  declarations: [ContactUsModalComponent,MfaWarningModalComponent,SurveyComponent, CookieCenterModalComponent, TimePickerComponent],
  providers: [],
  exports: [
    FormsModule,
    ...PHX_COMPONENT_MODULES,
    PhxTranslateModule,
    ContactUsModalComponent,
    MfaWarningModalComponent,
    SurveyComponent,
    CookieCenterModalComponent,
    TimePickerComponent
  ],
})
export class SharedModule {}
