import { AccordionModule } from '@phoenix/ui/accordion';
import { BackToTopModule } from '@phoenix/ui/back-to-top';
import { BreadCrumbModule } from '@phoenix/ui/bread-crumb';
import { ButtonModule } from '@phoenix/ui/button';
import { ButtonToggleModule } from '@phoenix/ui/button-toggle';
import { CheckboxModule } from '@phoenix/ui/checkbox';
import { PhxCommonModule } from '@phoenix/ui/common';
import { DataTableModule } from '@phoenix/ui/datatable';
import { DatepickerModule } from '@phoenix/ui/datepicker';
import { PhxDropdownModule } from '@phoenix/ui/dropdown';
import { FieldMessageModule } from '@phoenix/ui/field-message';
import { FileDragDropModule } from '@phoenix/ui/file-drag-drop';
import { ImageViewerModule } from '@phoenix/ui/image-viewer';
import { InputModule } from '@phoenix/ui/input';
import { InputMaskModule } from '@phoenix/ui/input-mask';
import { IntroModule } from '@phoenix/ui/intro';
import { LoadingModule } from '@phoenix/ui/loading';
import { MessageModule } from '@phoenix/ui/message';
import { ModalModule } from '@phoenix/ui/modal';
import { RadioModule } from '@phoenix/ui/radio';
import { ReactiveErrorControlModule } from '@phoenix/ui/reactive-error-control';
import { SidebarModule } from '@phoenix/ui/sidebar';
import { SwitchModule } from '@phoenix/ui/switch';
import { TabsetModule } from '@phoenix/ui/tabs';
import { TextToggleModule } from '@phoenix/ui/text-toggle';
import { TooltipModule } from '@phoenix/ui/tooltip';
import {PopupModule} from '@phoenix/ui/popup';


export const PHX_COMPONENT_MODULES: any[] = [
    AccordionModule,
    InputModule,
    ButtonModule,
    //AmountRangeModule,
    BreadCrumbModule,
    CheckboxModule,
    PhxCommonModule,
    FieldMessageModule,
    MessageModule,
    ModalModule,
    RadioModule,
    ReactiveErrorControlModule,
    TooltipModule,
    TabsetModule,
    DatepickerModule,
    LoadingModule,
    PhxDropdownModule,
    InputMaskModule,
    SwitchModule,
    DataTableModule,
    SidebarModule,
    BackToTopModule,
    TextToggleModule,
    ButtonToggleModule,
    // CarouselModule,
    FileDragDropModule,
    // ShuttleModule,
    // MobileDatePickerModule,
    // ThumbnailSliderModule,
    //BarChartModule,
    //ChartPopupModule,
    //LineChartModule,
    //PieChartModule,
    IntroModule,
    // SliderModule,
    ImageViewerModule,
    PopupModule
];
