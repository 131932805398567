import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DchService } from '../../core/services/dch.service';
import { UserAuthenticationDetails } from '../login/user.authentication.details';
import { environment } from 'projects/DtsUI/src/environments/environment';

@Component({
  selector: 'dts-genericPage',
  templateUrl: './genericPage.component.html',
  styleUrls: ['./genericPage.component.scss']
})
export class GenericPageComponent implements OnInit {
  serverName: string = environment.dchDomainUrl; //TODO: remove this after B2Bi servers add Content "/PHXcms"
  dchPath: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dchService: DchService,    
    public userAuthenticationDetails: UserAuthenticationDetails
    ) {
      this.route.queryParams.subscribe(
        params => {
          this.dchPath = params['path'];
        }
      );
    }

  ngOnInit(): void {
    this.setPageContent();
  }
  
  /**
   * Get DCH content 
   */
  setPageContent() {
     
    this.dchService.getCCHPageDetail(this.serverName + this.dchPath).subscribe((response: any) => {

      setTimeout (() => {
        if(response.bodyContent) 
        {
          let element = document.getElementById("content");
          element.innerHTML = response.bodyContent; 
        }
      }, 100);

    }, err => {
      console.log(err);
    });
  }
}