<ng-template #surveyTitle>
    <h2>
        Security upgrade survey
    </h2>
</ng-template>
<ng-template #survey>
    <div class="survey-modal-content">
        <p>In order to provide efficient support for our clients, please complete all fields.</p>
        <div class="px-mt-3 required-warning">
            Asterisk items (<span>*</span>) are required to proceed.
        </div>
        <div class="px-mt-3">
            <form [(formGroup)]="surveyForm">
                <div>
                    <label for="transmissionId" class="required"> Transmission ID or User ID</label>
                    <input phxInput type="text" formControlName="transmissionId" id="transmissionId"
                        name="transmissionId" />
                    <phx-error-control formControlName="transmissionId" id="transmissionIdError"
                        [errors]="{ required: 'Transmission ID is required.' }"></phx-error-control>
                </div>
                <div>
                    <label for="companyName" class="required">Company name </label>
                    <input phxInput type="text" id="companyName" formControlName="companyName" name="companyName" />
                    <phx-error-control formControlName="companyName" id="companyNameError"
                        [errors]="{ required: 'Company Name is required.' }"></phx-error-control>
                </div>
                <div>
                    <label for="userName" class="required">Name</label>
                    <input phxInput type="text" id="userName" formControlName="userName" name="userName" />
                    <phx-error-control formControlName="userName" id="userNameError"
                        [errors]="{ required: 'User Name is required.' }"></phx-error-control>
                </div>
                <div>
                    <label for="phoneNo" class="required">Phone number</label>
                    <input phxInput id="phoneNo" formControlName="phoneNo"
                        aria-label="10 digit phone number" name="phoneNo" />
                    <phx-error-control formControlName="phoneNo" id="phoneNoError"
                        [errors]="{ required: 'Phone Number is required.' }"></phx-error-control>
                </div>
                <div>
                    <label for="emailId" class="required">Email</label>
                    <input phxInput phxFloatingLabel type="email" id="emailId" formControlName="emailId" />
                    <phx-error-control formControlName="emailId" id="emailIdError"
                        [errors]="{ required: 'Email is required.', email: 'Email is invalid.' }"></phx-error-control>
                </div>
                <div>
                    <fieldset role="radiogroup">
                        <legend class="labelContent" for="keyStatus">Have you added our new 2048 Host Key?</legend>
                        <ul class="px-list-unstyled">
                            <li>
                                <phx-radio name="keyStatus" label="Yes" value="true" formControlName="keyStatus">
                                </phx-radio>
                            </li>
                            <li>
                                <phx-radio name="keyStatus" label="No" value="false" formControlName="keyStatus">
                                </phx-radio>
                            </li>
                        </ul>
                    </fieldset>
                </div>
                <div>
                    <fieldset role="”radiogroup”">
                        <legend for="cipherStatus" class="labelContent">Have you verified support for the Strong
                            Ciphers?</legend>
                        <ul class="px-list-unstyled">
                            <li>
                                <phx-radio name="cipherStatus" label="Yes" value="true" formControlName="cipherStatus">
                                </phx-radio>
                            </li>
                            <li>
                                <phx-radio name="cipherStatus" label="No" value="false" formControlName="cipherStatus">
                                </phx-radio>
                            </li>
                        </ul>
                    </fieldset>
                </div>
                <div>
                    <fieldset role="”radiogroup”">
                        <legend for="hmacStatus" class="labelContent">Have you verified support for Strong HMAC?
                        </legend>
                        <ul class="px-list-unstyled">
                            <li>
                                <phx-radio name="hmacStatus" label="Yes" value="true" formControlName="hmacStatus">
                                </phx-radio>
                            </li>
                            <li>
                                <phx-radio name="hmacStatus" label="No" value="false" formControlName="hmacStatus">
                                </phx-radio>
                            </li>
                        </ul>
                    </fieldset>
                </div>
                <div>
                    <fieldset role="”radiogroup”">
                        <legend for="securityStatus" class="labelContent">Are you going to be ready for security
                            upgrade changes?</legend>
                        <ul class="px-list-unstyled">
                            <li>
                                <phx-radio name="securityStatus" label="Yes" value="true"
                                    formControlName="securityStatus">
                                </phx-radio>
                            </li>
                            <li>
                                <phx-radio name="securityStatus" label="No" value="false"
                                    formControlName="securityStatus">
                                </phx-radio>
                            </li>
                        </ul>
                    </fieldset>
                </div>
                <div [hidden]="surveyForm.value.securityStatus === 'true' || surveyForm.value.securityStatus === null">
                    <label for="driverType">What is preventing you from migration</label>
                    <phx-dropdown id="driverType" ariaLabelledBy="driverType" formControlName="driverType"
                        [options]="codeList" placeholder="" name="example4"></phx-dropdown>
                </div>
                <div>
                    <label for="addInfo">Additional information</label>
                    <textarea phxInput id="addInfo" formControlName="addInfo" aria-label="Additional information"
                        phxCountdown="1000"></textarea>
                </div>
                <br />
                <div class="px-col-12" *ngIf="surveySubmitted" style="padding-left: 0;">
                    <phx-message type="success">
                        Thank you for your feedback.
                    </phx-message>
                </div>
                <div class="px-col-12" *ngIf="surveyError" style="padding-left: 0;">
                    <phx-message type="danger">
                        Something went wrong. Please try later.
                    </phx-message>
                </div>
            </form>
        </div>
    </div>
</ng-template>
<ng-template #surveyFooter>
    <button type="submit" class="px-mr-3" [disabled]="!surveyForm.valid || surveySubmitted" phxButton="primary"
        (click)="addSurveyDetailsHandler()" title="Submit">Submit
    </button>
    <button class="px-mr-3" type="button" phxButton="link" (click)="closeSurveyHandler()" title="Cancel">Cancel</button>
</ng-template>
<dts-contact-us-modal></dts-contact-us-modal>
<ng-template #surveyConfirmationHeader>
    <h2>Confirmation</h2>
</ng-template>
<ng-template #surveyConfirmation>
    <div class="successDiv">
        <i class="pxi px-check-circle px-10x"></i> Survey Submitted
    </div>
    <p>
        Thank you for your feedback.
    </p>
</ng-template>
<ng-template #surveyConfirmationFooter>
    <button phxButton type="button" (click)="closeSurveyHandler()" title="Close">Close</button>
</ng-template>