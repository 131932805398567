import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Logger } from '@phoenix/ui/common';
import { TabsetComponent, TabSetOptions } from '@phoenix/ui/tabs';
import { UserAuthenticationDetails } from '../login/user.authentication.details';
import { UserDto } from '../models/user-dto';
import { ChangePasswordComponent } from './change-password.component';
import { MyAccountComponent } from './my-account.component';

@Component({
  selector: 'dts-accountmanagement',
  templateUrl: './accountmanagement.component.html',
  styleUrls: ['./accountmanagement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountmanagementComponent implements OnInit {

  //activeTab: number = 0;

  tabInfo: TabSetOptions;

  userAuthDetailsObj: UserDto;
  

  breadcrumbItems = [
    { label: 'Home', link: '/home' },
    { label: 'User profile', link: '/userProfile/myAccount' }
  ];

  @ViewChild('routingTab') routingTab: TabsetComponent;
  constructor(private userAuthenticationDetails: UserAuthenticationDetails,
    private logger: Logger) {
  }

  ngOnInit(): void {
    this.userAuthenticationDetails.breadcrumbEmitter.emit(this.breadcrumbItems);
    this.tabInfo = {
      tabs: [
        {
          id: 'myAccount',
          heading: 'Profile details',
          route: 'myAccount'
        },
        {
          id: 'changePassword',
          heading: 'Change password',
          route: 'changePassword'
        }
      ]
    };

    //Add MFA configuration route if the logged user has MFA enabled
    if(this.checkMFA()) {
      this.tabInfo.tabs.push(
                              {
                                id: 'manageMultiFactorAuthentication',
                                heading: 'Manage multi-factor authentication',
                                route: 'manageMultiFactorAuthentication'
                              });
    }
  }  

  /**
   * Check if the logged user has MFA enabled
   * @returns 
   */
  checkMFA() {
    let mfaEnabledId = false;
    if (this.userAuthenticationDetails.getMfaDetails()) {
      mfaEnabledId = true;
    }
    return mfaEnabledId;
  }

  doSelectTab(index: number) {
    this.routingTab.activateTab(index);
  }

  // activeTabChange($event: number) {
  //   this.activeTab = $event;
  // }
}