import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoadingScreenService } from '../../core/services/loading-screen.service';
import { UserAuthenticationDetails } from '../login/user.authentication.details';
import { UserChannelAttrib } from '../models/user-channel-attrib';
import { UserChannels } from '../models/user-channels';
import { AccountManagementService } from '../../core/services/account-management.service';

@Component({
  selector: 'dts-channels',
  templateUrl: './channels.component.html',
  styleUrls: ['./channels.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ChannelsComponent implements OnInit {

  errorOutbound: boolean;
  errorInbound: boolean;
  errorMsgInbound: string;
  errorMsgOutbound: string;
  mfaStatus: any;
  tabrights: any;
  addaccright: boolean;
  chnlright: boolean;
  inboundChannels: UserChannelAttrib[];
  outboundChannels: UserChannelAttrib[];
  outChannel: any;
  inChannel: any;
  channels: UserChannels;
  responseData: string;
  
	breadcrumbItems = [
		{ label: 'Home', link: '/home' },
		{ label: 'Channels' }
	];

  constructor(private changeDetectorRef: ChangeDetectorRef,
    private channelsService: AccountManagementService,
    private loadingService: LoadingScreenService,
    private userAuthenticationDetails:UserAuthenticationDetails
  ) { }

  ngOnInit(): void {
		this.userAuthenticationDetails.breadcrumbEmitter.emit(this.breadcrumbItems);

    this.outChannel = { source: 'outChannel.source' };
    this.inChannel = { destination: 'inChannel.destination' };
    // this.showLoader = false;
    this.errorOutbound = false;
    this.errorInbound = false;
    this.errorMsgInbound = 'Error fetching incoming channels.';
    this.errorMsgOutbound = 'Error fetching outgoing channels.';
    this.initChannels();

  }

  contains(a, obj) {
    for (let i = 0; i < a.length; i++) {
      if (a[i].rightName === obj) {
        return true;
      }
    }
    return false;
  }
  // call service and load the channels from backend
  public initChannels() {
    this.loadingService.startLoading();
    let user: string = this.userAuthenticationDetails.getUserName();
    user = user === undefined ? 'ExxonTP' : user;
    
    this.channelsService.getChannels(user)
      .subscribe(responseData => {
        this.responseData = JSON.stringify(responseData);
    
          this.loadingService.stopLoading();
        if (responseData !== null || responseData !== undefined) {
            this.channels = new UserChannels(responseData);
            this.inboundChannels = this.channels.inbound;
            this.outboundChannels = this.channels.outbound;
            this.changeDetectorRef.markForCheck();
          } else {
            this.errorOutbound = true;
            this.errorInbound = true;
          }
      }, error => {
        this.responseData = JSON.stringify(error);
        this.loadingService.stopLoading();
        this.errorMsgOutbound= error.error.statusDescription;
      });
      this.changeDetectorRef.detectChanges();
  }
}
