import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FeatureModuleServices } from '../feature.module.services';
import { CertInfoDetail } from '../models/cert-info-detail';
import { CommonService } from '../../core/services/common.service';
import { ServerProtocolService } from '../../core/services/server-protocol.service';
import { UserAuthenticationDetails } from '../login/user.authentication.details';
import { Subscription } from 'rxjs';
import { ActionTypes, DataTableComponent, DataTableEventData, DataTableOptions, ExternalEventInfo } from '@phoenix/ui/datatable';
import { CertInfo } from '../models/cert-info-record';

@Component({
  selector: 'dts-request-bank-cert',
  templateUrl: './request-bank-cert.component.html',
  styleUrls: ['./request-bank-cert.component.scss']
})
export class RequestBankCertComponent implements OnInit {
  isDataExists: boolean;
  showResults: boolean;
  isRespSuccess: boolean;
  errMessage: string;
  noDataMessage: string;
  certInfoDetaila: CertInfoDetail;
  certificateInfoDetails: CertInfoDetail[];
  certInfoData: { 'dnsNames': string[]; 'ipAddresses': string[]; 'portNumber': string; 'protocolReferenceInfo': string; };
  certificateInfo: CertInfo;
  certs: any[];
  protocolSelected: string;
  public allowUnsort = true;

  optionsScroll: DataTableOptions;
  totalScroll = 10;
  pageSize = 10;
  init = false;
  reloadCall = false;
  keepSort = false;
  keepFilter = false;
  sortEnabled: boolean = false;
  sortField: any;
  isSortAsc: boolean;
  filteredtableData: any[];
  isRebindDataTable: boolean = false;
  dataTableFilter: string = '';
  gridSubscription: Subscription;

  @ViewChild('tblCerts') grid: DataTableComponent;

  protocolList: { label: string; value: string; actualVal: string }[] = [
    { label: 'SSH SFTP', value: 'SSH SFTP', actualVal: 'SSH SFTP            ' },
    { label: 'FTP SSL', value: 'FTP SSL', actualVal: 'FTP SSL             ' },
    { label: 'AS2', value: 'AS2', actualVal: 'AS2                 ' },
    { label: 'Connect Direct', value: 'CD NDM', actualVal: 'CD NDM              ' },
    { label: 'HTTPS (B2BI Portal)', value: 'HTTPS', actualVal: 'HTTPS               ' },
    { label: 'WebService', value: 'WebService', actualVal: 'WebService           ' }

  ];

  breadcrumbItems = [
    { label: 'Home', link: '/home' },
    { label: 'Server keys and certificates' }
  ];

  constructor(private featureServices: FeatureModuleServices,
    private commonService: CommonService,
    private userAuthenticationDetails: UserAuthenticationDetails,
    private bankKeysService: ServerProtocolService) {
    this.configureGridOptions();
  }

  ngOnInit(): void {
    this.userAuthenticationDetails.breadcrumbEmitter.emit(this.breadcrumbItems);
    this.isDataExists = true;
    this.isRespSuccess = true;

    this.bankKeysService.getBankCertificatesInfo().subscribe(
      (responseData) => {
        this.certificateInfo = new CertInfo(responseData);
        this.certificateInfoDetails = this.certificateInfo.responseData;
      },
      (error) => {
        //this.logger.error('error of protocolInfo', JSON.stringify(error));
      }
    );


  }

  /**
   * Server protocol change event
   * @param selectedValue 
   */
  onSelectProtocolHandler(selectedProtocol: any | null) {
    
    this.protocolSelected = this.encodeComponent(selectedProtocol);
    let eventInfo: ExternalEventInfo = {
      keepSorting: true,
      resetData: true
    };
    
    this.grid.api.refresh({ info: eventInfo });
  }

  loadGridDataHandler(events) {
    if (!this.protocolSelected) return;

    this.protocolSelected = this.protocolSelected;
    this.featureServices.requestBankKeys(this.protocolSelected).subscribe(responseData => {
      this.certs = responseData;
      this.isDataExists = true;
      if (events)
        this.onGridHandlerFn(events);
    }, error => {
      this.isDataExists = false;
      this.errMessage = error.error.statusDescription;
      console.log('error of SelectedProtocol', JSON.stringify(error));
    });
  }

  /**
   * Method to download file from grid. 
   */
  fileDownloadHandler(row) {
    const keyId = row.key_id;
    this.bankKeysService.certDownload(keyId).subscribe(responseData => {
      const respon: any = responseData
      const data = respon.responseData;
      const blob = new Blob([data], { type: 'application/octet-stream' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      const fileName = row.key_id + '.txt';
      link.download = fileName;
      link.click();
      const ieVersion = this.commonService.isIE();
      if (ieVersion) {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        }

      }
      const ffVersion = this.commonService.isFireFox();
      if (ffVersion) {
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

    }, error => {
      console.log('Error of Keys ID', JSON.stringify(error));
    });
  }

  /**
   * Method to configure columns for grid.
   */
  configureGridOptions() {
    this.optionsScroll = {
      smartView: {
        totalRowNumber: this.totalScroll,
        pageSize: this.pageSize,
        //filteredRowNumber: this.totalScroll,
        alertMessage: "No results to display.",
        pagination: false,
        pageLinks: 8,
        //rowsPerPageOptions: [10, 20, 40],
        handleEventsInternally: true,
      },
      columnDefs: [
        {
          field: 'key_id',
          name: 'Certificate name',
          filter: { enabled: false }
        },
        {
          field: 'key_type',
          name: 'Certificate info',
          filter: { enabled: false },
        },
        {
          field: 'validFrom',
          name: 'Valid from',
          filter: { enabled: false },
          width: '200px',
          sort: {
            sortFunction: (a, b): any => {
              if (!a && !b) {
                return 0;
              } else if (!b) {
                return 1;
              } else if(typeof a === 'string' && typeof b === 'string'){
                  let test = a.substr(2,1);

                  let date1 = new Date(a);
                  let date2 = new Date(b);
                  if(test == '-' || test == '/'){
                    return this.calcuateDiff(date1, date2);
                  }

              } else {
                const result = a.length - b.length;
                if (result !== 0) {
                  return result;
                } else {
                  return a.localeCompare(b);
                }
              }
            }
          }
        },
        {
          field: 'validTo',
          name: 'Valid to',
          filter: { enabled: false },
          width: '200px',
          sort: {
            sortFunction: (a, b): any => {
              if (!a && !b) {
                return 0;
              } else if (!b) {
                return 1;
              } else if(typeof a === 'string' && typeof b === 'string'){
                  let test = a.substr(2,1);

                  let date1 = new Date(a);
                  let date2 = new Date(b);
                  if(test == '-' || test == '/'){
                    return this.calcuateDiff(date1, date2);
                  }

              } else {
                const result = a.length - b.length;
                if (result !== 0) {
                  return result;
                } else {
                  return a.localeCompare(b);
                }
              }
            }
          }
        },
        {
          field: 'fileDownload',
          name: ' ',
          filter: { enabled: false },
          sort: { enabled: false },
          width: '50px'
        }
      ],
      data: []
    };

  }

  /**
   * Code to calculate difference between two dates and use it as part of grid sort handler.
   * @param date1 
   * @param date2 
   * @returns 
   */
  private calcuateDiff(date1, date2){
    let days = Math.floor((date1 - date2)/1000/60/60/24);

    return days;
  }
  
  /**
    * Handle PHX grid internal events
    * @param eventData Grid events
    * @returns 
    */
  handleEvents(eventData: DataTableEventData): void {
    const events = eventData.events;
    if (!events) return;
    if (events.length >= 2 && this.protocolSelected) {
      this.loadGridDataHandler(events);
    }
  }

  /**
   * Rebind the grid data
   * @param events Grid events
   */
  onGridHandlerFn(events: any) {

    const loadEvent = events.find((event) => event.type === ActionTypes.LOAD_DATA);

    if (loadEvent) loadEvent.result = { data: this.certs };

    this.grid.api.handleResultEvents(events);
  }

  encodeComponent(stringVal)
	{
		return encodeURIComponent(stringVal);
	}

	decodeComponent(encodeVal)
	{
		return decodeURIComponent(encodeVal);
	}

}