import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'projects/DtsUI/src/environments/environment';
import { ContactUsModalComponent } from '../../shared/modals/contact-us-modal.component';
import { CookieCenterModalComponent } from '../../shared/cookie-center-modal/cookie-center-modal.component';
import { StartupService } from '../services/startup.service';

@Component({
  selector: 'dts-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @ViewChild(ContactUsModalComponent) contactUs : ContactUsModalComponent;
  @ViewChild(CookieCenterModalComponent) cookieCenter: CookieCenterModalComponent;
  isInternalApp: boolean;

  year = new Date().getFullYear();
  appVersion: any;

  constructor(private startupService: StartupService) {
    this.isInternalApp = startupService.isInternalApp();
  }

  ngOnInit() {
    this.appVersion = environment.appVersion
  } 
}