import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class UserAuthenticationDetailsService {

  requestObject = {};
  visibleMenu = {};

  constructor(private authService: AuthService) {
  }
  userAuthenticationDetails() {
    return {
      setUserAuthDetails(userDataObj) {
        // console.log('userDataObj:'+userDataObj);
        sessionStorage.userAuthDetails = userDataObj;
        // console.log('sessionStorage.userAuthDetails:'+angular.toJson(sessionStorage.userAuthDetails));
      },
      getUserAuthDetails: () => {
        return sessionStorage.userAuthDetails;
      },
      clearUserAuthDetails: () => {
        sessionStorage.$reset();
      },
      getLoginStatus: () => {
        if (sessionStorage.userAuthDetails !== undefined) {
          return true;
        }
        return false;
      },
      getUserName: () => {
        if (sessionStorage.userAuthDetails !== undefined) {
          const userDetails = sessionStorage.userAuthDetails;
          // return userDetails.firstName+' '+ userDetails.lastName;
          return userDetails.userDto.userName;
        }
      },
      setRequestObj: (data) => {
        this.requestObject = data;
      },
      getRequestObj: () => {
        return this.requestObject;
      },
      getUserEmail: () => {
        if (sessionStorage.userAuthDetails !== undefined) {
          return sessionStorage.userAuthDetails.userDto.email;
        }
      },
      getUserPhoneNum: () => {
        if (sessionStorage.userAuthDetails !== undefined) {
          return sessionStorage.userAuthDetails.userDto.phoneNumber;
        }
      },
      getUserId: () => {
        if (sessionStorage.userAuthDetails !== undefined) {
          return sessionStorage.userAuthDetails.userDto.userName;
        }
      },
      getUserFirstName: () => {
        if (sessionStorage.userAuthDetails !== undefined) {
          return sessionStorage.userAuthDetails.userDto.firstName;
        }
      },
      getUserLastName: () => {
        if (sessionStorage.userAuthDetails !== undefined) {
          return sessionStorage.userAuthDetails.userDto.lastName;
        }
      },
      getUserAlias: () => {
        if (sessionStorage.userAuthDetails !== undefined) {
          return sessionStorage.userAuthDetails.userDto.aliasName;
        }
      },
      getUserLastLoginTime: () => {
        if (sessionStorage.userAuthDetails !== undefined) {
          return sessionStorage.userAuthDetails.userDto.lastLoginTime;
        }
      },
      getProfileObj: () => {
        if (sessionStorage.userAuthDetails !== undefined) {
          return sessionStorage.userAuthDetails.userDto.profile;
        }
      },
      setTempUserObj: (obj) => {
        sessionStorage.tempUserObj = obj;
      },
      getTempUserObj: () => {
        return sessionStorage.tempUserObj;
      },
      clearTempUserObj: () => {
        if (sessionStorage.tempUserObj !== undefined) {
          sessionStorage.tempUserObj = undefined;
        }
      },
      getMfaDetails: () => {
        return sessionStorage.userAuthDetails.userDto.mfa;
      },
      get3SKeysFlag: () => {
        return sessionStorage.userAuthDetails.userDto.key3sSelfService;
      },
      getAliasName: () => {
        return sessionStorage.userAuthDetails.userDto.aliasName;
      },
      setFirstName: (fname) => {
        sessionStorage.userAuthDetails.userDto.firstName = fname;
      },
      setLastName: (lname) => {
        sessionStorage.userAuthDetails.userDto.lastName = lname;
      },
      setEmail: (email) => {
        sessionStorage.userAuthDetails.userDto.email = email;
      },
      setPhoneNumber: (phoneNum) => {
        sessionStorage.userAuthDetails.userDto.phoneNumber = phoneNum;
      },
      setCountryCode: (code) => {
        sessionStorage.userAuthDetails.userDto.countryCode = code;
      },
      setVisibleMenu: (menuList) => {
        this.visibleMenu = menuList;
      },
      getVisibleMenu: () => {
        return this.visibleMenu;
      },
      setNewUser: (value) => {
        sessionStorage.userAuthDetails.userDto.newUser = value;
      }
    };
  }
}

