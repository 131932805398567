import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ModalOptions, ModalRef, ModalService } from '@phoenix/ui/modal';
declare const launchCookieCenter: any;

@Component({
  selector: 'dts-cookie-center-modal',
  templateUrl: './cookie-center-modal.component.html',
  styleUrls: ['./cookie-center-modal.component.scss']
})
export class CookieCenterModalComponent implements OnInit {
  @ViewChild('cookieCenterHeader', { read: TemplateRef }) cookieCenterHeader: TemplateRef<any>;
  @ViewChild('cookieCenter', { read: TemplateRef }) cookieCenter: TemplateRef<any>;
  @ViewChild('cookieCenterFooter', { read: TemplateRef }) cookieCenterFooter: TemplateRef<any>;
  private TSigninInModalRef: ModalRef;
  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
  }

  public openCookieCenterModal() {

    launchCookieCenter();
  }

  closeCookieCenterHandler(){
    this.modalService.close(this.TSigninInModalRef.modal);
  }
}