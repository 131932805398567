
<ng-template #sessionLogoutHeader>
  <h2>Session time out</h2>
</ng-template>

<ng-template #sessionLogoutMsg>
  <div>
    You have been logged out due to inactivity.
  </div>
</ng-template>

<ng-template #logutCloseMsg>
  <button class="px-mr-3 modal-close" type="button" phxButton="primary" (click)="closeHandler()" title="Close">Close</button>
</ng-template>

<ng-template #sessionLogoutConfirmMsg>
  <div class="px-mb-3">
    You are about to be signed out due to inactivity. Would you like to remain signed in?  
    <br/>
    Time out in : {{ countdownTimer }}
    <!--<input #seconds type="number" min="0" max="59"  [value]="countdownTimer"/>-->
    seconds
  </div>
</ng-template>

<ng-template #confirmMsgcloseMsg>
  <button class="px-mr-3" type="button" phxButton="primary" (click)="continueLoggedInHandler()" title="Yes">Yes</button>
  <button class="px-mr-3" type="button" phxButton="link" (click)="logoutHandler()" title="No">No</button>
</ng-template>